import React, { Component, createRef, ReactElement } from 'react';
import { TableHeadColumnsInterface, TableProps, TableState } from './Table.interface';
import { TableService } from './Table.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { TableStyles } from '@/Modules/App/Components/Library/Table/Table.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { formatDateToFR } from '@/Utils/DateUtils';
import { LuArrowDown, LuArrowUp } from 'react-icons/lu';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class Table extends Component<TableProps, TableState>
{
	private tableService = new TableService();
	private scrollContainerRef = createRef<HTMLDivElement>();

	constructor(props: TableProps)
	{
		super(props);

		// Config service
		this.tableService.setProps(this.props);
		this.tableService.subscribeState(this);

		// State
		this.state = this.tableService.getState();
	}

	async componentDidMount(): Promise<void>
	{
		await this.tableService.init();

		this.updateShadows(); // Initial check
		this.scrollContainerRef.current?.addEventListener('scroll', this.updateShadows);
	}

	componentWillUnmount(): void
	{
		this.tableService.unsubscribe();
		this.scrollContainerRef.current?.removeEventListener('scroll', this.updateShadows);
	}

	/**
	 * Update Shadows by scroll position
	 */
	updateShadows = (): void =>
	{
		const container = this.scrollContainerRef.current;
		if (container) {
			const hasLeftShadow = container.scrollLeft > 0;
			const hasRightShadow =
				container.scrollLeft < container.scrollWidth - container.clientWidth;

			this.setState({ hasLeftShadow, hasRightShadow });
		}
	};

	/**
	 * Function to access a nested property
	 * @param object - The object in which to search
	 * @param path - The key string, separated by dots (e.g., 'address.city')
	 * @returns The found value or undefined if not found
	 */
	getNestedValue = (object: any, path: string): any =>
	{
		return path.split('.').reduce((acc, part) => acc && acc[part], object);
	}

	/**
	 * Handle row click and provide row data.
	 * @param item - The data for the row that was clicked
	 */
	onRowClick = (item: any, event?: any) =>
	{
		this.props.onRowClick(item, event);
	}

	renderCellContent = (item: any, column: TableHeadColumnsInterface): React.ReactNode =>
	{
		const cellValue = Array.isArray(column.keyTag)
			? column.keyTag.map((key: any) => this.getNestedValue(item, key)).join(' ')
			: this.getNestedValue(item, column.keyTag)
		;

		if (column.keyType === 'date') {
			return formatDateToFR(cellValue);
		}

		if (column.keyType === 'number') {
			return cellValue.replace(".", ",");
		}

		if (column.keyType === 'extSellsyId') {
			return (item.extSellsyId) ? <img src="/img/logo-sellsy.png" alt="logo Sellsy" width={ 25 }/> : '-';
		}

		if (column.keyType === 'boolean') {
			return (cellValue === true) ? 'Oui' : 'Non';
		}

		if (column.keyType === 'url') {
			return <img width="50" src={ cellValue } alt={ 'logo' }/>;
		}

		if (column.keyType === 'countLegalNotice') {
			const count = Array.isArray(item.legalNotices) ? item.legalNotices.length : 0;
			return <span style={{ fontWeight: 'bold', color: count > 0 ? 'green' : 'red' }}>{ count }</span>;
		}

		if (column.keyType === 'gift') {
			return this.giftRenderCell(item);
		}

		if (column.keyType === 'giftStatus') {
			if (item?.clientGifts?.length > 0) {
				return <Tag
					value={ item.clientGifts[0].status }
					enumName={ 'CommercialGiftStatusEnum' }
				/>
			}
		}

		if (column.keyType === 'addressFull') {
			const { address } = item;
			if (!address) return '—';

			return (
				<div style={{ lineHeight: '1.5', whiteSpace: 'nowrap' }}>
					<strong>{ address.city }</strong>
					<br />
					{ address.number ? `${ address.number }, ` : '' }
					{ address.street || 'Rue non renseignée' }
				</div>
			);
		}

		return column.enumClass ? (
			<Tag
				value={ cellValue }
				enumName={ column.enumClass }
			/>
		) : (
			cellValue
		);
	};

	render(): ReactElement
	{
		const { columns, list } = this.props;
		const { hasRightShadow, hasLeftShadow } = this.state;

		return (
			<div style={ {
				...TableStyles.container,
				boxShadow: `
            ${ hasLeftShadow ? 'inset 15px 0 10px -10px rgba(0,0,0,0.08)' : '' }
            ${ hasRightShadow ? 'inset -15px 0 10px -10px rgba(0,0,0,0.08)' : '' }
          `,
				transition: 'box-shadow 0.3s ease',
			} }
					 ref={this.scrollContainerRef}
			>
				<table style={ TableStyles.table }>
					<thead style={ UserConnectedService.isDeveloper() ? TableStyles.devThead : TableStyles.thead }>
					<tr style={ TableStyles.thead_tr }>
						{ columns.map((column) => (
							<th
								key={ column.title }
								style={ TableStyles.thead_th(column, UserConnectedService.isDeveloper()) }
								onClick={ (event: any) => this.tableService.onOrderBy(column.tag) }
							>
								<div style={ {
									...TableStyles.thead_th_div,
									justifyContent: (column.textAlign) ?? 'flex-start',
								} }>
									{ column.title }
									{ this.props.filterParams && this.props.filterParams.orderBy === column.tag && (
										<span style={ { marginLeft: 20 } }>
										{ this.props.filterParams.sort === 'asc' ? <LuArrowUp/> : <LuArrowDown/> }
									</span>
									) }
								</div>
							</th>
						)) }
					</tr>
					</thead>
					<tbody style={ TableStyles.tbody }>
					{ (list === undefined) || (list?.items && list?.items.length === 0) ? (
						<tr style={ {} }>
							<td style={ { padding: '10px 0', color: UserConnectedService.isDeveloper() ? colors.white : undefined } }>
								Aucune donnée disponible
							</td>
						</tr>
					) : (
						list.items.map((item, rowIndex) => (
							<Hovered key={ rowIndex }>
								{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
									<tr
										key={ rowIndex }
										ref={ ref }
										style={ TableStyles.tbody_tr(isHovered, UserConnectedService.isDeveloper()) }
										onMouseEnter={ onMouseEnter }
										onMouseLeave={ onMouseLeave }
										onClick={ (event: any) => this.onRowClick(item, event) }
									>
										{ columns.map((column) => (
											<td key={ column.title } style={ TableStyles.tbody_td }>
												<div style={ {
													...TableStyles.tbody_td_div,
													width: column.keyType === 'number' ? '100%' : (column.columnWidth ? column.columnWidth : '100%'),
													fontWeight: (column.fontWeight) ?? 400,
													textAlign: column.keyType === 'number' ? 'right' : (column.textAlign ?? 'left'),
													...(column.enumClass) ? { padding: 0 } : {},
													color : (UserConnectedService.isDeveloper()) ? colors.white : undefined
												} }>
													{ this.renderCellContent(item, column) }
												</div>
											</td>
										)) }
									</tr>
								) }
							</Hovered>
						))
					) }
					</tbody>
				</table>
			</div>
		);
	}

	private giftRenderCell(item: any): ReactElement
	{
		if (!Array.isArray(item.clientGifts) || item.clientGifts.length === 0) {
			return <span style={{ fontStyle: 'italic', color: 'gray' }}>Aucun cadeau</span>;
		}

		return (
			<ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
				{ item.clientGifts.map((giftItem: any, index: any) => (
					<li key={ index } style={{ marginBottom: 5 }}>
						{ giftItem.commercialGift?.label } - <strong>{ giftItem.quantity }</strong>
					</li>
				))}
			</ul>
		);
	}
}

export default withGlobalContext(Table);

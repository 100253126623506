import React, { Component, ReactElement } from 'react';
import { LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState } from './LegalNoticeHandleEstimate.interface';
import { LegalNoticeHandleEstimateService } from './LegalNoticeHandleEstimate.service';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import PublishDatePicker from '@/Modules/LegalNotice/Components/Form/PublishDatePicker/PublishDatePicker';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

class LegalNoticeHandleEstimate extends Component<LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState>
{
	private legalNoticeHandleEstimateService = new LegalNoticeHandleEstimateService();

	constructor(props: LegalNoticeHandleEstimateProps)
	{
		super(props);

		// Config service
		this.legalNoticeHandleEstimateService.setProps(this.props);
		this.legalNoticeHandleEstimateService.subscribeState(this);

		// State
		this.state = this.legalNoticeHandleEstimateService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeHandleEstimateService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeHandleEstimateService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <div>Chargement</div>;
		}

		return (
			<>
				<div style={ { padding: 20, width: 500, display: 'flex', flexDirection: 'column', gap: 10, } }>
					<div style={ FontStyles.textHelp }>
						Êtes-vous sûr de vouloir accepter ce devis ? En validant cette action, une facture
						sera automatiquement générée et un email de confirmation sera envoyé au client. Vous pouvez
						également définir la date de publication ci-dessous avant de finaliser l'opération.
					</div>
					<GlobalContextProvider>
						<PublishDatePicker
							newspaper={ this.props.legalNotice.newspaper }
							stateUpdater={ {
								selectedDate: new Date(this.state.publishDate),
							} }
							callbackState={ (state) =>
							{
								this.legalNoticeHandleEstimateService.onChangePublishDate(state.selectedDate!);
							} }
						/>
					</GlobalContextProvider>

					{ this.legalNoticeHandleEstimateService.isPublishDateExceeded() &&
            <div style={ {
							marginTop: 15,
							backgroundColor: colors.error50,
							...borderStyles,
							borderColor: colors.error100,
							borderRadius: 6,
							color: colors.error600,
							padding: '5px 10px',
							minHeight: 38,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						} }>
              <span>La date sélectionnée n'est plus valide pour la publication. <br/> Veuillez choisir une date ultérieure.</span>
            </div>
					}
				</div>

				<div style={ { ...ModalStyles.bottomActions, gap: 5, marginTop: 20 } }>
					<Button
						label={ 'Refuser et Annuler' }
						variant={ 'dark' }
						onClick={ () => this.legalNoticeHandleEstimateService.onRefusedQuote() }
					/>
					<Button
						label={ 'Accepter et Planifier' }
						variant={ 'primary' }
						onClick={ () => this.legalNoticeHandleEstimateService.onValidateQuote() }
						disabled={ this.legalNoticeHandleEstimateService.isPublishDateExceeded() }
					/>
				</div>
			</>
		);
	}
}

export default LegalNoticeHandleEstimate;
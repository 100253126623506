import React, { Component, ReactElement } from 'react';
import { LegalNoticeConfigProps, LegalNoticeConfigState } from './LegalNoticeConfig.interface';
import { LegalNoticeConfigService } from './LegalNoticeConfig.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
	LegalNoticeConfigStyles
} from '@/Modules/LegalNotice/Components/Form/LegalNoticeConfig/LegalNoticeConfig.styles';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Input from '@/Modules/App/Components/Library/Input/Input';
import PublishDate from '@/Modules/LegalNotice/Components/Form/PublishDatePicker/PublishDatePicker';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { ClipLoader } from 'react-spinners';

class LegalNoticeConfig extends Component<LegalNoticeConfigProps, LegalNoticeConfigState>
{
	private legalNoticeConfigService = new LegalNoticeConfigService();

	constructor(props: LegalNoticeConfigProps)
	{
		super(props);

		// Config service
		this.legalNoticeConfigService.setProps(this.props);
		this.legalNoticeConfigService.subscribeState(this);

		// State
		this.state = this.legalNoticeConfigService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeConfigService.init();
	}

	componentDidUpdate(prevProps: LegalNoticeConfigProps, prevState: LegalNoticeConfigState): void
	{
		if (this.props.isDisplayBlock && !prevProps.isDisplayBlock) {
			this.setState({ shouldRender: true });
		}

		if ((prevState.selectedDepartment !== this.state.selectedDepartment && this.legalNoticeConfigService.isExternalDepartment()) && !this.props.isEdit) {
			this.setState({ isLoading: true });
			this.legalNoticeConfigService.setSelectedNewspaperState(null);
		}
	}

	componentWillUnmount(): void
	{
		this.legalNoticeConfigService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement | null
	{
		const {
			isLoading,
			selectedNewspaperType,
			departments,
			selectedDepartment,
			newspapers,
			selectedNewspaper,
			shouldRender,
			journalConfigurations
		} = this.state;

		if(isLoading) {
			return <LoaderComponent />;
		}

		const status = (typeof this.state.legalNoticeStatus === 'string')
			? PublishStateEnum.findByValue(this.state.legalNoticeStatus)
			: this.state.legalNoticeStatus;
		const isDisabled : boolean = this.props.isEdit && status !== PublishStateEnum.DRAFT;

		return (
			<>
				<div style={ LegalNoticeConfigStyles.container(shouldRender) }>
					<>
						{/* 01 Select Newspaper Type */ }
						<div style={ LegalNoticeConfigStyles.form }>
							<Select
								label={ 'Choix du support ' }
								listOptions={ NewspaperTypeEnum.options }
								onSelect={ (newspaperType: NewspaperTypeEnum) =>
									this.legalNoticeConfigService.setSelectedNewspaperTypeState(newspaperType) }
								value={ selectedNewspaperType?.label }
								displayKey={ 'label' }
								isRequired={ true }
								isDisabled={ isDisabled }
							/>

							{/* 02 Select department */ }
							<Select
								label={ 'Département de publication' }
								listOptions={ departments || [] }
								onSelect={ (department: DepartmentInterface) =>
									this.legalNoticeConfigService.setSelectedDepartmentState(department) }
								value={ selectedDepartment?.name }
								displayKey={ 'name' }
								isSearchNeeded={ true }
								isRequired={ true }
								isDisabled={ isDisabled }
							/>

							{/* 03 Select Newspaper */ }
							{ this.state.isCheckNewspaperLoading
								? <div style={{ display: 'flex', justifyContent: 'center' }}><ClipLoader /></div>
								: <Select
									key={ selectedDepartment?.id! + selectedNewspaperType?.value! + isLoading.toString() + this.state.selectedNewspaper }
									label={ 'Journal de publication' }
									listOptions={ newspapers || [] }
									onSelect={ (newspaper: NewspaperInterface) =>
										this.legalNoticeConfigService.setSelectedNewspaperState(newspaper) }
									value={ (selectedNewspaper!) ? selectedNewspaper?.name! : null }
									displayKey={ 'name' }
									isSearchNeeded={ true }
									isRequired={ true }
									isDisabled={ isDisabled }
								/>
							}

							{ journalConfigurations && Object.keys(journalConfigurations).length > 0 && (
								<div style={ {
									backgroundColor: colors.error50,
									...borderStyles,
									borderColor: colors.error100,
									borderRadius: 6,
									color: colors.error600,
									padding: '5px 10px',
									minHeight: 38,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								} }>
									<ul>
										{ Object.values(journalConfigurations).map((message, index) => (
											<li key={ index } style={ { color: 'red', fontSize: '14px' } }>
												{ message }
											</li>
										)) }
									</ul>
								</div>
							) }


							{/* 04 Publish Date */ }
							{ (selectedNewspaper!) &&
                <PublishDate
                  key={ selectedNewspaper?.name }
                  newspaper={ selectedNewspaper! }
                  stateUpdater={ {
										selectedDate: this.props.stateUpdater.publishDate
									} }
                  callbackState={ (state) =>
										this.legalNoticeConfigService.setPublishDateState(state?.selectedDate!)
									}
                />
							}

							{/* 05 Number of copies if Newspaper Type = Paper */ }
							{ (selectedNewspaperType === NewspaperTypeEnum.PAPER) &&
                <Input
                  type={ 'text' }
                  label={ 'Nombre de justificatif papier' }
                  name={ 'number-of-copies' }
                  value={ this.state.numberOfCopies?.toString() || '' }
                  onChange={ this.legalNoticeConfigService.handleNumberOfCopiesChange }
                  autocomplete={ 'false' }
                  allowedCharacters={ 'digits' }
                />
							}
						</div>

						<div style={ {
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
							height: 50
						} }>
							<Button
								label={ 'Suivant' }
								variant={ 'small' }
								onClick={
									() => this.props.callbackState(this.state, true)
								}
							/>
						</div>
					</>
				</div>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeConfig);
import { CSSProperties } from 'react';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const FormSectionStyles = {
	container: { display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20 } as CSSProperties,
	title: {
		...FontStyles.h3,
		color: colors.blueRibbon500,
		fontWeight: 700
	} as CSSProperties,

	subTitle: {
		...FontStyles.h4,
		color: colors.gray500,
		fontWeight: 700
	} as CSSProperties,

} as const;
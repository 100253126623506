import React, { Component, ReactElement } from 'react';
import { UpdateSellsyAddressProps, UpdateSellsyAddressState } from './UpdateSellsyAddress.interface';
import { UpdateSellsyAddressService } from './UpdateSellsyAddress.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { LuCheckCircle } from 'react-icons/lu';
import {
	UpdateSellsyAddressStyles
} from '@/Modules/App/Components/Sellsy/UpdateSellsyAddress/UpdateSellsyAddress.styles';

class UpdateSellsyAddress extends Component<UpdateSellsyAddressProps, UpdateSellsyAddressState>
{
	private updateSellsyAddressService = new UpdateSellsyAddressService();

	constructor(props: UpdateSellsyAddressProps)
	{
		super(props);

		// Config service
		this.updateSellsyAddressService.setProps(this.props);
		this.updateSellsyAddressService.subscribeState(this);

		// State
		this.state = this.updateSellsyAddressService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.updateSellsyAddressService.init();
	}

	componentWillUnmount(): void
	{
		this.updateSellsyAddressService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<div style={ UpdateSellsyAddressStyles.container }>
					{ this.clientAddressRender() }
					{ this.props.client?.address.extSellsyId &&
						this.clientSellsyAddressRender()
					}
				</div>
				<div style={ UpdateSellsyAddressStyles.buttonAction }>
					<Button
						label={ (!this.props.clientSellsyAddress || !this.props.client?.address.extSellsyId) ? 'Créer l‘adresse' : 'Utiliser cette adresse' }
						variant={ 'primary' }
						onClick={ () =>
						{
							if (!this.props.clientSellsyAddress || !this.props.client?.address.extSellsyId) {
								this.updateSellsyAddressService.handleCreateSellsyAddress();
							} else {
								this.updateSellsyAddressService.handleEditSellsyAddress(this.state.selectedAddressToUpdate);
							}
						} }
					/>
				</div>
			</>
		);
	}

	private clientAddressRender(): ReactElement
	{
		const { client, selectedAddressToUpdate } = this.state;

		return (
			<>
				<Hovered>
					{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
						<a
							ref={ ref }
							style={ UpdateSellsyAddressStyles.cardHovered(isHovered) }
							onClick={ (event) =>
							{
								event.stopPropagation();
								this.setState({
									selectedAddressToUpdate: {
										isFromSellsy: false,
										extSellsyId: client?.address?.extSellsyId!,
									}
								});
							} }
							onMouseLeave={ onMouseLeave }
							onMouseEnter={ onMouseEnter }
						>
							{ !selectedAddressToUpdate.isFromSellsy &&
                <div style={ UpdateSellsyAddressStyles.selectedAddressCheck }>
                  <LuCheckCircle color={ colors.success300 }/>
                </div>
							}
							<div
								style={ UpdateSellsyAddressStyles.cardContainer(!this.state.selectedAddressToUpdate.isFromSellsy, isHovered) }>
								<div style={ { ...FontStyles.h5, marginBottom: 20, textDecoration: 'underline' } }>Adresse actuelle
								</div>
								{ client?.name &&
                  <div style={ { fontWeight: 600 } }>{ client.name }</div> }
								{ (client?.address?.number || client?.address?.street) && (
									<div>
										{ client?.address?.number }
										{ client?.address?.number && client?.address?.street ? ', ' : '' }
										{ client?.address?.street }
									</div>
								) }
								{ client?.address?.additionalData && (
									<div>{ client?.address.additionalData }</div>
								) }
								{ client?.address?.zipCode && client?.address?.city && (
									<div>{ `${ client?.address.zipCode } ${ client?.address.city }` }</div>
								) }
							</div>
						</a>
					) }
				</Hovered>
			</>
		);
	}

	private clientSellsyAddressRender(): ReactElement
	{
		const { sellsyAddress, selectedAddressToUpdate } = this.state;

		return (
			<>
				{ sellsyAddress ? (
					<>
						<Hovered>
							{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
								<a
									ref={ ref }
									style={ UpdateSellsyAddressStyles.cardHovered(isHovered) }
									onClick={ (event) =>
									{
										event.stopPropagation();
										this.setState({
											selectedAddressToUpdate: {
												isFromSellsy: true,
												extSellsyId: sellsyAddress?.extSellsyId!,
											}
										});
									} }
									onMouseLeave={ onMouseLeave }
									onMouseEnter={ onMouseEnter }
								>
									{ selectedAddressToUpdate.isFromSellsy &&
                    <div style={ UpdateSellsyAddressStyles.selectedAddressCheck }>
                      <LuCheckCircle color={ colors.success300 }/>
                    </div>
									}
									<div
										style={ UpdateSellsyAddressStyles.cardContainer(selectedAddressToUpdate.isFromSellsy, isHovered) }>
										<div style={ UpdateSellsyAddressStyles.cardTitle }>
											Adresse de facturation
										</div>
										{ sellsyAddress?.name && (
											<div style={ { fontWeight: 600 } }>
												{ sellsyAddress?.name }
											</div>
										) }
										{ (sellsyAddress?.number || sellsyAddress?.street) && (
											<div>
												{ sellsyAddress?.number }
												{ sellsyAddress?.number && sellsyAddress?.street ? ', ' : '' }
												{ sellsyAddress?.street }
											</div>
										) }
										{ sellsyAddress?.additionalData && (
											<div>{ sellsyAddress?.additionalData }</div>
										) }
										{ sellsyAddress?.zipCode && sellsyAddress?.city && (
											<div>{ `${ sellsyAddress?.zipCode } ${ sellsyAddress?.city }` }</div>
										) }
									</div>
								</a>
							) }
						</Hovered>
					</>
				) : (
					<div style={ {
						...FontStyles.textHelp,
						...UpdateSellsyAddressStyles.cardContainer(false, false),
						opacity: 1,
					} }>
						Aucune adresse existante dans sellsy.
						Veuillez en créer une nouvelle à partir de l'adresse du client.
					</div>
				) }
			</>
		);
	}
}

export default withGlobalContext(UpdateSellsyAddress);
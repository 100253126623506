import React, { Component, ReactElement } from 'react';
import { AddressFormProps, AddressFormState } from './AddressForm.interface';
import { AddressFormService } from './AddressForm.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class AddressForm extends Component<AddressFormProps, AddressFormState>
{
	private addressFormService = new AddressFormService();

	constructor(props: AddressFormProps)
	{
		super(props);

		// Config service
		this.addressFormService.setProps(this.props);
		this.addressFormService.subscribeState(this);

		// State
		this.state = this.addressFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.addressFormService.init();
	}

	componentWillUnmount(): void
	{
		this.addressFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<Input
					type={ 'text' }
					label={ 'Numéro de la voie' }
					name={ 'street-number' }
					onChange={ (event: any) => this.addressFormService.setAddressState({ number: event.target.value }) }
					value={ this.state.address.number || '' }
					required={ false }
					allowedCharacters={ 'digits' }
				/>

				<div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
					<Input
						type={ 'text' }
						style={ { width: '100%' } }
						label={ 'Nom de la voie' }
						name={ 'street' }
						onChange={ (event: any) => this.addressFormService.setAddressState({ street: event.target.value }) }
						value={ this.state.address.street || '' }
						errorMessage={ this.props?.errors?.street! }
						required={ true }
					/>
					<Button
						iconName={ 'LuCaseUpper' }
						variant={ 'secondary' }
						onClick={ () => this.addressFormService.toggleCaseForField('street') }
					/>
				</div>

				<div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
					<Input
						type={ 'text' }
						style={ { width: '100%' } }
						label={ 'Infos supplémentaires' }
						name={ 'additional-data' }
						onChange={ (event: any) => this.addressFormService.setAddressState({ additionalData: event.target.value }) }
						value={ this.state.address.additionalData || '' }
						required={ false }
					/>
					<Button
						iconName={ 'LuCaseUpper' }
						variant={ 'secondary' }
						onClick={ () => this.addressFormService.toggleCaseForField('additionalData') }
					/>
				</div>

				<Input
					type={ 'text' }
					style={ { width: '100%' } }
					label={ 'Code Postal' }
					name={ 'zip-code' }
					onChange={ (event: any) => this.addressFormService.setAddressState({ zipCode: event.target.value }) }
					value={ this.state.address.zipCode || '' }
					allowedCharacters={ 'digits' }
					errorMessage={ this.props?.errors?.zipCode! }
					required={ true }
				/>

				<div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
					<Input
						type={ 'text' }
						style={ { width: '100%' } }
						label={ 'Ville' }
						name={ 'city' }
						onChange={ (event: any) => this.addressFormService.setAddressState({ city: event.target.value }) }
						value={ this.state.address.city || '' }
						errorMessage={ this.props?.errors?.city! }
						required={ true }
					/>
					<Button
						iconName={ 'LuCaseUpper' }
						variant={ 'secondary' }
						onClick={ () => this.addressFormService.toggleCaseForField('city') }
					/>
				</div>
			</>
		);
	}
}

export default withGlobalContext(AddressForm);
import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const ClientLogoStyles = {
	container: {} as CSSProperties,

	modalContainer: {
		width: 500,
		padding: 20,
		display: 'grid',
		gridTemplateRows: 'auto 20px 150px',
		overflowY: 'scroll',
		overflowX: 'hidden',
	} as CSSProperties,

	tableContainer: {
		height: '100%',
		overflowY: 'scroll',
	} as CSSProperties,

	tableRow: {
		display: 'flex',
		width: '100%',
		borderBottom: `1px solid ${ colors.gray200 }`,
		maxWidth: '100%',
		overflowX: 'scroll',
	} as CSSProperties,

	buttons: {
		height: 50,
		width: '100%',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	} as CSSProperties,

	selectLogo: {
		height: 120,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	} as CSSProperties,

} as const;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeProps, LegalNoticeState } from '@/Modules/LegalNotice/Common/LegalNotice.interface';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import {
	LegalNoticeControlsState
} from '@/Modules/LegalNotice/ComponentsOld/LegalNoticeControls/LegalNoticeControls.interface';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: LegalNoticeState = {
	isLoading: true,
	errorMessage: null,
	legalNotice: {} as LegalNoticeInterface,
	editedContent: null
};

export class LegalNoticeService extends BaseComponentService<LegalNoticeProps, LegalNoticeState>
{
	apiLegalNoticeService: ApiLegalNoticeService = new ApiLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(legalNoticeId: number): Promise<void>
	{
		try {
			await this.show(legalNoticeId);
		} catch (error: any) {
			this.setState({ isLoading: false });
			console.error('Failed to init data LEGALNOTICESERVICE', error.errorMessage);
		}
	}

	/**
	 * Legal notice
	 * @return Promise<LegalNoticeInterface | undefined>
	 */
	async show(legalNoticeId: number): Promise<LegalNoticeInterface | undefined>
	{
		try {
			const legalNotice = await this.apiLegalNoticeService.show(LocalStorageService.getAuthClient(), legalNoticeId);

			this.setState({ legalNotice }, () => this.setState({ isLoading: false }));
			return legalNotice;
		} catch (error: any) {
			console.log('Failed to fetch legal notice show', error.message);
			return undefined;
		}
	}

	/**
	 * Handle CallBack State from LegalNoticeControls
	 * @param legalNoticeControlsState
	 * @param currentState
	 */
	async handleAction(legalNoticeControlsState: LegalNoticeControlsState, currentState: LegalNoticeState): Promise<LegalNoticeControlsState | undefined>
	{
		this.setState({ isLoading: true });

		try {
			// Handle Delete | handle duplicate
			if (legalNoticeControlsState.isDeleted || legalNoticeControlsState.isDuplicate || legalNoticeControlsState.isQuoteResponse) {
				await this.show(currentState.legalNotice.id);
				this.setState({ isLoading: false });
			}

			this.setState({ isLoading: false });

			return legalNoticeControlsState;

		} catch (error: any) {
			console.error(`Error occurred on ${ this.constructor.name }`, error);
		}

	}
}
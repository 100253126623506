import React, { Component, ReactElement } from 'react';
import { SirenConsumerAutoFillProps, SirenConsumerAutoFillState } from './SirenConsumerAutoFill.interface';
import { SirenConsumerAutoFillService } from './SirenConsumerAutoFill.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class SirenConsumerAutoFill extends Component<SirenConsumerAutoFillProps, SirenConsumerAutoFillState>
{
	private sirenConsumerAutoFillService = new SirenConsumerAutoFillService();

	constructor(props: SirenConsumerAutoFillProps)
	{
		super(props);

		// Config service
		this.sirenConsumerAutoFillService.setProps(this.props);
		this.sirenConsumerAutoFillService.subscribeState(this);

		// State
		this.state = this.sirenConsumerAutoFillService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.sirenConsumerAutoFillService.init();
	}

	componentWillUnmount(): void
	{
		this.sirenConsumerAutoFillService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<div style={ { display: 'flex', gap: 5, alignItems: 'flex-end', width: '100%' } }>
				<Input
					type={ 'text' }
					label={ 'Siren' }
					name={ 'siren' }
					onChange={ (event: any) => this.sirenConsumerAutoFillService.setSirenState(event) }
					autocomplete={ 'false' }
					allowedCharacters={ 'digits' }
					value={ this.state.siren || '' }
					required={ true }
					style={ { width: '100%' } }
					errorMessage={ this.state.errorMessage! || this.props?.errorMessage! }
				/>

				{ this.state.siren && !this.state.errorMessage &&
          <Button
            label={ 'auto' }
            onClick={ () => this.sirenConsumerAutoFillService.callApiSiren(Number(this.state.siren!)) }
            isLoading={ this.state?.isLoading }
            disabled={ this.state?.isLoading }
          />
				}
			</div>
		);
	}
}

export default withGlobalContext(SirenConsumerAutoFill);
import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles, boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export const AssociateSellsyCompanyStyles = {
	container: (containerCss: CSSProperties) => ({
		position: 'relative',
		backgroundColor: colors.purple50,
		zIndex: 99,
		maxWidth: 700,
		borderRadius: 8,
		padding: 10,
		...borderStyles,
		borderColor: colors.purple200,
		...containerCss,
	}) as CSSProperties,

	title: {
		...FontStyles.h4,
		marginBottom: 5,
		color: colors.purple500,
	} as CSSProperties,

	results: {
		display: 'flex',
		flexDirection: 'column',
		gap: 5
	} as CSSProperties,

	resultTitle: {
		...FontStyles.h5,
		borderBottom: `1px solid ${ colors.gray200 }`,
		paddingBottom: 5,
		marginBottom: 5,
	} as CSSProperties,

	resultName: {
		fontSize: 14,
		fontWeight: 600,
		color: colors.gray800,
	} as CSSProperties,

	resultInfo: {
		fontSize: 12,
		fontWeight: 500,
		color: colors.gray400,
	} as CSSProperties,

	openList: (isOpen: boolean) => ({
		position: 'absolute',
		maxHeight: 200,
		flexWrap: 'nowrap',
		overflowY: 'scroll',
		zIndex: 500,
		bottom: 32,
		width: 'auto',
		display: (isOpen) ? 'block' : 'none',
		backgroundColor: colors.white,
		borderRadius: 6,
		padding: 8,
		...borderStyles,
		...boxShadows.short
	}) as CSSProperties,

	item: (isHovered: boolean) => ({
		backgroundColor: (isHovered) ? colors.gray100 : 'inherit',
		cursor: 'pointer',
		borderRadius: 8,
		padding: '5px 10px',
	}) as CSSProperties,

	selectedItemContainer: {
		width: '100%',
		backgroundColor: colors.white,
		borderRadius: 8,
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	} as CSSProperties,

	createCompany: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 500,
		gap: 10
	} as CSSProperties,

	selectedResultCardContainer: {
		width: '100%',
		...borderStyles,
		borderColor: colors.gray50,
		borderRadius: 6,
		backgroundColor: colors.white,
		marginTop: 15,
		padding: 15,
	} as CSSProperties,

	selectedResultCardContent: {
		display: 'grid',
		fontSize: 12,
		fontWeight: 500,
		color: colors.gray400,
		justifyContent: 'space-between',
		gridTemplateColumns: 'repeat(2, 1fr)',
		width: '100%'
	} as CSSProperties,

} as const;
import React, { Component, ReactElement } from 'react';
import {
  UpdateLegalNoticeContentProps, UpdateLegalNoticeContentState
} from '@/Modules/LegalNotice/Components/UpdateLegalNoticeContent/UpdateLegalNoticeContent.interface';
import {
  UpdateLegalNoticeContentService
} from '@/Modules/LegalNotice/Components/UpdateLegalNoticeContent/UpdateLegalNoticeContent.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { Wysiwyg } from '@/Modules/App/Components/Atom/Form/Wysiwyg';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import {
  UpdateLegalNoticeContentStyles
} from '@/Modules/LegalNotice/Components/UpdateLegalNoticeContent/UpdateLegalNoticeContent.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export class UpdateLegalNoticeContent extends Component<UpdateLegalNoticeContentProps, UpdateLegalNoticeContentState>
{
  private componentService: UpdateLegalNoticeContentService = new UpdateLegalNoticeContentService();

  //<editor-fold desc="Ctor" defaultstate="collapsed">

  constructor(props: UpdateLegalNoticeContentProps)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //</editor-fold>

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (!this.state.content) {
      return <LoaderComponent/>;
    }

    return (
      <>
        <div style={ UpdateLegalNoticeContentStyles.container }>
          <div style={{ ...FontStyles.textHelp, marginBottom: 10 }}>
            Ce formulaire permet uniquement de modifier le texte de l'annonce et le certificat. <br/>
            <strong>Les autres documents tels que le devis, la facture ou l'avoir ne seront pas affectés.</strong>
          </div>
          <hr/>


          {/* TITLE */}
          <Input
            type="text"
            label="Titre de l'annonce"
            width="100%"
            name="title"
            value={ this.state.title ?? '' }
            onChange={(event) =>  this.setState({ title: event.target.value }) }
          />

          {/* CONTENT */}
          <div style={{ maxHeight: '100%', height: 400, marginTop: 10  }}>
            <Wysiwyg
              initialContent={
                (this.state.content)
                  ? this.componentService.formatTextWithLineBreaks(this.state.content!)
                  : ''
              }
              onContentChange={(content) => this.componentService.onChangeContent(content) }
            />
          </div>

          {/* SIGNATURE */}
          <div>
            <Input
              type="text"
              style={{ display: 'flex' }}
              label="Signature"
              name="legalNoticeData.signature"
              value={ this.state.signature ?? '' }
              onChange={(event) => this.setState({ signature: event.target.value }) }
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            <Button
              label="Valider les changements ?"
              variant="primary"
              onClick={ () => { this.componentService.save(this.state) } }
            />
          </div>
        </div>
      </>
    );
  }
}
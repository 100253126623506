import { CSSProperties } from 'react';

export const LegalNoticePriceRenderStyles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		height: '100%',
		padding: '0 20px'
	} as CSSProperties,

	totalPrice: {
		fontSize: 18,
		fontWeight: 700
	} as CSSProperties,

	nbCharacters: {
		fontSize: 10,
		marginRight: 20

	} as CSSProperties,
} as const;
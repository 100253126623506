import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ButtonVariantType } from '@/Modules/App/Components/Library/Button/Button.interfaces';

export const ButtonStyles = {
	container: (isHovered: boolean, variant: ButtonVariantType = 'primary', disabled: boolean = false) =>
	{
		const baseStyle: CSSProperties = {
			height: 28,
			borderRadius: 6,
			padding: '5px 8px',
			outline: 'none',
			display: 'inline-flex',
			gap: 5,
			alignItems: 'center',
			justifyContent: 'center',
			transition: 'background 270ms ease-out, border 270ms ease-out, color 270ms ease-out',
			msUserSelect: 'none',
			cursor: disabled ? 'not-allowed' : 'pointer',
			fontSize: 14,
			fontWeight: 600,
			userSelect: 'none',
			opacity: disabled ? 0.6 : 1,
		};

		const variantStyles: Record<ButtonVariantType, CSSProperties> = {
			primary: {
				backgroundColor: isHovered ? colors.blueRibbon600 : colors.blueRibbon500,
				border: `1px solid ${ isHovered ? colors.blueRibbon700 : colors.blueRibbon600 }`,
				color: colors.white,
			},
			secondary: {
				backgroundColor: isHovered ? colors.gray100 : colors.white,
				border: `1px solid ${ isHovered ? colors.gray300 : colors.gray200 }`,
				color: colors.gray700,
			},
			dark: {
				backgroundColor: isHovered ? colors.gray800 : colors.gray900,
				border: `1px solid ${ isHovered ? colors.gray900 : colors.gray900 }`,
				color: colors.white,
			},
			success: {
				backgroundColor: isHovered ? colors.success600 : colors.success500,
				border: `1px solid ${ isHovered ? colors.success700 : colors.success600 }`,
				color: colors.white,
			},
			warning: {
				backgroundColor: isHovered ? colors.warning600 : colors.warning500,
				border: `1px solid ${ isHovered ? colors.warning700 : colors.warning600 }`,
				color: colors.white,
			},
			error: {
				backgroundColor: isHovered ? colors.error600 : colors.error500,
				border: `1px solid ${ isHovered ? colors.error700 : colors.error600 }`,
				color: colors.white,
			},
			inline: {
				backgroundColor: 'transparent',
				border: 'none',
				color: isHovered ? colors.blueRibbon600 : colors.blueRibbon500,
				padding: 0,
			},
			sellsy: {
				backgroundColor: colors.purple500,
				border: `1px solid ${ isHovered ? colors.purple700 : colors.purple600 }`,
				color: colors.white,
			},
			outlineSellsy: {
				backgroundColor: colors.white,
				border: `1px solid ${ isHovered ? colors.purple300 : colors.purple200 }`,
				color: isHovered ? colors.purple600 : colors.purple500,
			},
			close: {
				backgroundColor: isHovered ? colors.gray100 : 'transparent',
				border: 'none',
				color: isHovered ? colors.gray900 : colors.gray700,
				cursor: 'pointer',
			},
			small: {
				height: 24,
				fontSize: 12,
				padding: '4px 8px',
				backgroundColor: colors.white,
				border: `1px solid ${ isHovered ? colors.gray500 : colors.gray200 }`,
				color: colors.gray700,
			},
			smallPrimary: {
				height: 24,
				fontSize: 12,
				padding: '4px 8px',
				backgroundColor: isHovered ? colors.blueRibbon600 : colors.blueRibbon500,
				border: `1px solid ${ isHovered ? colors.blueRibbon700 : colors.blueRibbon600 }`,
				color: colors.white,
			},
			smallDark: {
				height: 24,
				fontSize: 12,
				padding: '4px 8px',
				backgroundColor: colors.gray700,
				border: `1px solid ${ isHovered ? colors.gray500 : colors.gray200 }`,
				color: colors.white
			},
			smallError: {
				height: 24,
				fontSize: 12,
				padding: '4px 8px',
				backgroundColor: colors.error700,
				border: `1px solid ${ isHovered ? colors.error500 : colors.error800 }`,
				color: colors.white
			},
			smallSuccess: {
				height: 24,
				fontSize: 12,
				padding: '4px 8px',
				backgroundColor: isHovered ? colors.success600 : colors.success500,
				border: `1px solid ${ isHovered ? colors.success500 : colors.success600 }`,
				color: colors.white
			},
			filter: {
				height: 24,
				color: colors.gray600,
				display: 'flex',
				alignItems: 'center',
				fontWeight: 600,
				fontSize: 12,
				border: `1px dashed ${colors.gray300}`,
				borderRadius: 50,
				padding: '3px 6px',
				backgroundColor: (isHovered) ? colors.gray100 : 'transparent',
				gap: 2
			},
			document: {
				backgroundColor: isHovered ? colors.blueRibbon100 : colors.blueRibbon50,
				border: `1px solid ${ isHovered ? colors.blueRibbon300 : colors.blueRibbon200 }`,
				color: colors.white,
			},
		};

		return { ...baseStyle, ...variantStyles[variant] };
	},

	text: (disabled: boolean, variant: ButtonVariantType): CSSProperties => ({
		fontSize: (variant === 'filter') ? 12 : 14,
		fontWeight: 600,
		color: disabled ? colors.gray500 : 'inherit',
	}),
} as const;

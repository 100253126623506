import React, { ReactElement } from 'react';
import { Router as RemixRouter } from '@remix-run/router/dist/router';
import NotFoundView from '@/Modules/App/View/NotFoundView';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import DashboardView from '@/Modules/Dashboard/View/DashboardView';
import AuthViewWrapper from '@/Modules/Auth/Wrapper/AuthViewWrapper';
import AuthClientViewWrapper from '@/Modules/AuthClient/Wrapper/AuthClientViewWrapper';
import { checkProtectedRoute } from '@/Loader/CheckProtectedRoute';
import LayoutWrapper from '@/Modules/App/Components/Layout/Layout.wrapper';
import RespondInvitationViewWrapper from '@/Modules/Collaborator/Wrapper/RespondInvitationViewWrapper';
import ForgotPasswordViewWrapper from '@/Modules/App/Wrapper/ForgotPasswordViewWrapper';
import '@/Assets/sass/main.scss';
import PricingAdminViewWrapper from '@/Modules/Pricing/Admin/Wrapper/PricingAdminViewWrapper';
import AskForgotPasswordViewWrapper from '@/Modules/App/Wrapper/AskForgotPasswordViewWrapper';
import FormBuilderAdminViewWrapper from '@/Modules/FormBuilder/Admin/Wrapper/FormBuilderAdminViewWrapper';
import AuthCompanyWrapper from '@/Modules/AuthCompany/AuthCompany.wrapper';
import DashboardDeveloper from '@/Modules/Dashboard/Developer/DashboardDeveloper';
import ParticularLegalNoticeViewWrapper
	from '@/Modules/LegalNotice/Particular/Wrapper/ParticularLegalNoticeViewWrapper';
import PaymentCallbackWrapper from '@/Modules/App/Wrapper/PaymentCallbackWrapper';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import NotificationProvider from '@/Provider/NotificationProvider';
import UserAdmin from '@/Modules/User/Admin/List/UsersAdmin';
import ModalProvider from '@/Provider/ModalProvider';
import { FlashMessageProvider } from '@/Provider/FlashMessageProvider';
import ClientsAdmin from '@/Modules/Client/Admin/List/ClientsAdmin';
import { AuthProvider } from '@/Provider/Auth/Auth.provider';
import { AuthClientProvider } from '@/Provider/AuthClient/AuthClient.provider';
import { AuthCompanyProvider } from '@/Provider/AuthCompanyProvider';
import LegalNoticesAdmin from '@/Modules/LegalNotice/Admin/List/Regular/LegalNoticesAdmin';
import LegalNoticeAdmin from '@/Modules/LegalNotice/Admin/Show/LegalNoticeAdmin';
import ClientAdmin from '@/Modules/Client/Admin/Show/ClientAdmin';
import DashboardAdmin from '@/Modules/Dashboard/Admin/DashboardAdmin';
import OffcanvaProvider from '@/Provider/OffcanvaProvider';
import LegalNoticesValidateAdmin from '@/Modules/LegalNotice/Admin/List/Validate/LegalNoticesValidateAdmin';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import Client from '@/Modules/Client/User/Show/Client';
import LegalNotices from '@/Modules/LegalNotice/User/List/Regular/LegalNotices';
import LegalNotice from '@/Modules/LegalNotice/User/Show/LegalNotice';
import NewspapersClosureManagement from '@/Modules/NewspaperManagement/Admin/List/NewspapersClosureManagements';
import NewspaperClosureManagement from '@/Modules/NewspaperManagement/Admin/Show/NewspaperClosureManagement';
import LegalNoticesDraftAdmin from '@/Modules/LegalNotice/Admin/List/Draft/LegalNoticesDraftAdmin';
import LegalNoticesQuoteAdmin from '@/Modules/LegalNotice/Admin/List/Quote/LegalNoticesQuoteAdmin';
import UsersDeveloper from '@/Modules/User/Developer/List/UsersDeveloper';
import ClientsDeveloper from '@/Modules/Client/Developer/List/ClientsDeveloper';
import LegalNoticesDeveloper from '@/Modules/LegalNotice/Developer/List/LegalNoticesDeveloper';
import CreateLegalNoticeAdmin from '@/Modules/LegalNotice/Admin/Create/CreateLegalNoticeAdmin';
import ErrorBoundary from '@/Modules/App/Components/ErrorBundary/ErrorBoundary';
import CommercialAdmin from '@/Modules/Commercial/Admin/Page/CommercialAdmin';
import LegalNoticeCheckout from '@/Modules/LegalNotice/Page/LegalNoticeCheckout/LegalNoticeCheckout';

export class App
{
  router(): RemixRouter
  {
    return createBrowserRouter([
      {
        path: '/auth',
        element: this.layoutWrapperOutlet('auth'),
        children: [
          {
            index: true,
            element: <AuthViewWrapper/>
          },
          {
            path: 'client',
            element: <AuthClientViewWrapper/>,
            loader: () => checkProtectedRoute()
          },
          {
            path: 'company',
            element: <AuthCompanyWrapper/>,
            loader: () => checkProtectedRoute()
          }
        ]
      },
      {
        path: '/',
        element: this.layoutWrapperOutlet('default'),
        handle: {
          crumb: () => ({ title: 'Accueil', path: '/' })
        },
        children: [
          {
            index: true,
            element: <DashboardView/>,
            handle: {
              crumb: () => ({ title: 'Tableaux de bord', path: '/' })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: 'clients/:clientId',
            element: <Client/>,
            handle: {
              crumb: () => ({ title: 'Mon entreprise', path: 'clients/:clientId' })
            },
            loader: async ({ params }) => {
              const clientId = await LocalStorageService.getAuthClient();
              const protectedRoute = await checkProtectedRoute();
              return { clientId, protectedRoute };
            },
          },
          {
            path: 'legal-notices',
            element: <LegalNotices />,
            handle: {
              crumb: () => ({ title: 'Annonces légales', path: '/legal-notices' })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: '/legal-notices/:legalNoticeId',
            element: <LegalNotice />,
            handle: {
              crumb: () => ({
                title: 'Détails annonce légale',
                path: '/legal-notice/:legalNoticeId',
                isAdmin: false
              })
            },
            loader: () => checkProtectedRoute(false, false)
          },
          {
            path: '/legal-notices/create',
            element: <LegalNoticeCheckout />,
            handle: {
              crumb: () => ({ title: 'Créer une annonce légale', path: '/legal-notices/create' })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: '/legal-notices/free-writing/create',
            element: <LegalNoticeCheckout />,
            handle: {
              crumb: () => ({ title: 'Créer une annonce légale', path: '/legal-notices/create' })
            },
            loader: () => checkProtectedRoute()
          },
        ]
      },
      {
        path: '/developer',
        element: this.layoutWrapperOutlet('developer'),
        handle: {
          crumb: () => ({ title: 'Accueil', path: '/developer' })
        },
        children: [
          {
            index: true,
            element: <DashboardDeveloper />,
            handle: {
              crumb: () => ({ title: 'Tableaux de bord', path: '/' })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          },
          {
            path: '/developer/users',
            element: <UsersDeveloper />,
            handle: {
              crumb: () => ({ title: 'Utilisateurs', path: '/developer/users', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          },
          {
            path: '/developer/clients',
            element: <ClientsDeveloper />,
            handle: {
              crumb: () => ({ title: 'Donneur d\'ordre', path: '/developer/clients', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          },
          {
            path: '/developer/legal-notices',
            element: <LegalNoticesDeveloper />,
            handle: {
              crumb: () => ({ title: 'Annonce légale', path: '/developer/legal-notices', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          }
        ]
      },
      {
        path: '/admin',
        element: this.layoutWrapperOutlet('admin'),
        handle: {
          crumb: () => ({ title: 'Accueil', path: '/admin' })
        },
        children: [
          {
            index: true,
            element: <DashboardAdmin/>,
            handle: {
              crumb: () => ({ title: 'Tableaux de bord', path: '/', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/users',
            element: <UserAdmin/>,
            handle: {
              crumb: () => ({ title: 'Utilisateurs', path: '/admin/users', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/clients',
            element: <ClientsAdmin/>,
            handle: {
              crumb: () => ({ title: 'Donneur d\'ordre', path: '/admin/clients', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/clients/:clientId',
            element: <ClientAdmin/>,
            handle: {
              crumb: () => ({ title: 'Donneur d\'ordre', path: '/admin/clients', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/settings/form-builder',
            element: <FormBuilderAdminViewWrapper/>,
            handle: {
              crumb: () => ({ title: 'Form Builder', path: '/admin/settings/form-builder', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true, true)
          },
          {
            path: '/admin/settings/pricing',
            element: <PricingAdminViewWrapper/>,
            handle: {
              crumb: () => ({ title: 'Tarifications', path: '/admin/settings/pricing', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true, true)
          },
          {
            path: '/admin/legal-notices',
            element: <LegalNoticesAdmin/>,
            handle: {
              crumb: () => ({ title: 'Annonces légales', path: '/admin/legal-notices', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notices/:legalNoticeId',
            element: <LegalNoticeAdmin />,
            handle: {
              crumb: () => ({
                title: 'Détails annonce légale',
                path: '/admin/legal-notices/:legalNoticeId',
                isAdmin: true
              })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notices/create',
            element: <CreateLegalNoticeAdmin/>,
            handle: {
              crumb: () => ({
                title: 'Création d\'annonces légales',
                path: '/admin/legal-notices/fast/create',
                isAdmin: true
              })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notices/validate',
            element: <LegalNoticesValidateAdmin/>,
            handle: {
              crumb: () => ({ title: 'Annonces légales', path: '/admin/legal-notices/validate', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notices/quote',
            element: <LegalNoticesQuoteAdmin/>,
            handle: {
              crumb: () => ({ title: 'Annonces légales', path: '/admin/legal-notice/quote', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notices/draft',
            element: <LegalNoticesDraftAdmin/>,
            handle: {
              crumb: () => ({ title: 'Annonces légales', path: '/admin/legal-notice/draft', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/newspaper-management',
            element: <NewspapersClosureManagement/>,
            handle: {
              crumb: () => ({ title: 'Journal', path: '/admin/newspaper-management', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/newspaper-management/:newspaperManagementId',
            element: <NewspaperClosureManagement/>,
            handle: {
              crumb: () => ({ title: 'Journal détail', path: '/admin/newspaper-management', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/commercial',
            element: <CommercialAdmin />,
            handle: {
              crumb: () => ({ title: 'Commercial', path: '/admin/commercial', isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
        ]
      },
      {
        path: '/',
        element: this.layoutWrapperOutlet('public'),
        children: [
          {
            path: '/particular/legal-notice/',
            element: <ParticularLegalNoticeViewWrapper/>
          },
          {
            path: 'respond-invitation/:code',
            element: <RespondInvitationViewWrapper/>
          },
          {
            path: 'ask-forgot-password',
            element: <AskForgotPasswordViewWrapper/>
          },
          {
            path: 'forgot-password/:token',
            element: <ForgotPasswordViewWrapper/>
          },
        ]
      },
      {
        path: '/payment/callback',
        element: this.layoutWrapperOutlet('public'),
        children: [
          {
            path: 'success',
            element: <PaymentCallbackWrapper paymentStatus="success"/>
          },
          {
            path: 'failure',
            element: <PaymentCallbackWrapper paymentStatus="failure"/>
          }
        ]
      },
      {
        path: 'not-found',
        element: <NotFoundView/>
      },
      {
        path: '*',
        element: <NotFoundView/>
      }
    ]);
  }

	private layoutWrapperOutlet(type: 'developer' | 'admin' | 'auth' | 'public' | 'default'): ReactElement
	{

		const Providers = () => (
			process.env.NODE_ENV === 'development' ?
				(
					<FlashMessageProvider>
						<AuthProvider>
							<AuthClientProvider>
								{ type !== 'default' && (
									<AuthCompanyProvider>
										<OffcanvaProvider>
											<ModalProvider>
												<GlobalContextProvider>
													<NotificationProvider>
														<LayoutWrapper layoutType={ type }>
															<Outlet/>
														</LayoutWrapper>
													</NotificationProvider>
												</GlobalContextProvider>
											</ModalProvider>
										</OffcanvaProvider>
									</AuthCompanyProvider>
								) }
								{ type === 'default' && (
									<OffcanvaProvider>
										<ModalProvider>
											<GlobalContextProvider>
												<NotificationProvider>
													<LayoutWrapper layoutType={ type }>
														<Outlet/>
													</LayoutWrapper>
												</NotificationProvider>
											</GlobalContextProvider>
										</ModalProvider>
									</OffcanvaProvider>
								) }
							</AuthClientProvider>
						</AuthProvider>
					</FlashMessageProvider>
				) : (
					<FlashMessageProvider>
						<ErrorBoundary>
							<AuthProvider>
								<AuthClientProvider>
									{ type !== 'default' && (
										<AuthCompanyProvider>
											<OffcanvaProvider>
												<ModalProvider>
													<GlobalContextProvider>
														<NotificationProvider>
															<LayoutWrapper layoutType={ type }>
																<Outlet/>
															</LayoutWrapper>
														</NotificationProvider>
													</GlobalContextProvider>
												</ModalProvider>
											</OffcanvaProvider>
										</AuthCompanyProvider>
									) }
									{ type === 'default' && (
										<OffcanvaProvider>
											<ModalProvider>
												<GlobalContextProvider>
													<NotificationProvider>
														<LayoutWrapper layoutType={ type }>
															<Outlet/>
														</LayoutWrapper>
													</NotificationProvider>
												</GlobalContextProvider>
											</ModalProvider>
										</OffcanvaProvider>
									) }
								</AuthClientProvider>
							</AuthProvider>
						</ErrorBoundary>
					</FlashMessageProvider>
				)
		);

    return <Providers />;
  }
}

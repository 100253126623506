import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';

export class ApiAdminFormBuilderCategoryService extends BaseService
{

	formBuilderUrl: string = `${ BaseService.API_URL }/v1/admin/form-builder/categories`;

	async list(order: 'alphabetical' | 'sortOrder' = 'sortOrder'): Promise<any> {
		try {
			const url = new URL(this.formBuilderUrl);
			url.searchParams.append('order', order);

			const response = await fetch(url.toString(), {
				method: HttpVerbEnum.GET,
				headers: this.buildHeaders(),
			});

			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(categoryId: number)
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + categoryId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(formBuilderCategoryData: Partial<FormBuilderCategoryInterface>): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(formBuilderCategoryData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(editCategoryData: any, categoryId: number)
	{
		try {
			const response = await fetch(
					this.formBuilderUrl + '/' + categoryId,
					{
						method: HttpVerbEnum.PATCH,
						headers: this.buildHeaders(),
						body: JSON.stringify(editCategoryData)
					},
				)
			;

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(categoryId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/${ categoryId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async checkAvailableType(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/check/available-category`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
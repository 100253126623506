import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export const UpdateSellsyAddressStyles = {
	container: {
		display: 'grid',
		padding: 30,
		columnGap: 25,
		gridTemplateColumns: 'repeat(2, 1fr)',
		width: 650,
	} as CSSProperties,

	buttonAction: {
		minHeight: 50,
		width: '100%',
		padding: 20,
		gap: 10,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	} as CSSProperties,

	cardContainer: (isSelected: boolean, isHovered: boolean) => ({
		padding: 20,
		...borderStyles,
		opacity: (isSelected)
			? 1
			: (isHovered) ? 1 : 0.4,
		borderColor: (isSelected)
			? colors.success200
			: (isHovered) ? colors.success200 : colors.gray200,
		borderRadius: 6,
		height: '100%',
		transition: 'opacity 270ms ease'
	}) as CSSProperties,

	cardHovered: (isHovered: boolean) => ({
		height: '100%',
		cursor: 'pointer',
		opacity: 1,
		backgroundColor: (isHovered) ? colors.success50 : colors.white,
		borderRadius: 6,
		transition: 'background-color 500ms ease',
	}) as CSSProperties,

	cardTitle: {
		...FontStyles.h5,
		marginBottom: 20,
		textDecoration: 'underline'
	} as CSSProperties,

	selectedAddressCheck: {
		height: 30,
		width: 30,
		position: 'absolute',
		zIndex: 10,
		backgroundColor: colors.success50,
		borderRadius: 50,
		...borderStyles,
		borderColor: colors.success300,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transform: 'translate(265px, -10px)',
	} as CSSProperties,

} as const;
import React, { ReactElement } from 'react';
import {
  CommercialStatsAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/List/CommercialStatsAdmin.service';
import {
  CommercialStatsAdminProps, CommercialStatsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/List/CommercialStatsAdmin.interface';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import CommercialTableStats from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import CommercialStatAdmin from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Show/CommercialStatAdmin';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class CommercialStatsAdmin extends React.Component<CommercialStatsAdminProps, CommercialStatsAdminState>
{
  private componentService: CommercialStatsAdminService = new CommercialStatsAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {

    return (
      <>
        <OffcanvaContext.Consumer>
          { (offcanvaContext) => (
            <CommercialTableStats
              refreshKey={ this.state.refreshKey }
              onRowClick={
                async (event: React.MouseEvent<HTMLTableRowElement>, client: ClientInterface) => {
                event.stopPropagation();
                event.preventDefault();

                offcanvaContext?.content(
                  null,
                  <GlobalContextProvider>
                    <CommercialStatAdmin
                      client={ client }
                      refreshClient={ async () => this.setState(prevState => ({
                        refreshKey: prevState.refreshKey + 1
                      }))}
                      modalContext={ this.props.modalContext! }
                      flashMessageContext={ this.props.flashMessageContext! }
                    />
                  </GlobalContextProvider>
                );

                offcanvaContext?.isOpen(true);
              }}
            />
          )}
        </OffcanvaContext.Consumer>
      </>
    );
  }
}

export default withGlobalContext(CommercialStatsAdmin);
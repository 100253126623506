import { CSSProperties } from 'react';

export const SelectorCategoriesStyles = {
	container: (isDisplayBlock: boolean) => ({
		position: 'relative',
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		visibility: isDisplayBlock ? 'visible' : 'hidden',
		opacity: isDisplayBlock ? 1 : 0,
		transform: isDisplayBlock ? 'translateY(0)' : 'translateY(20px)',
		transition: 'opacity 300ms ease, transform 300ms ease',
	}) as CSSProperties,
} as const;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	NewspapersClosureManagementProps,
	NewspapersClosureManagementState
} from './NewspapersClosureManagements.interface';
import { FiltersInterface, TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { ApiAdminNewspaperClosureManagementService } from '@/Service/Admin/ApiAdminNewspaperClosureManagementService';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';

const initState: NewspapersClosureManagementState = {
	newspaperManagementList: {} as PaginateInterface,
	filterParams: {
		page: 1,
		itemsPerPage: 15,
		search: null,
		filters: null,
		orderBy: null,
		sort: null,
	},
};

export class NewspapersClosureManagementsService extends BaseComponentService<NewspapersClosureManagementProps, NewspapersClosureManagementState>
{

	private apiAdminNewspaperManagement: ApiAdminNewspaperClosureManagementService = new ApiAdminNewspaperClosureManagementService();
	constructor()
	{
		super({} as NewspapersClosureManagementProps, initState);

	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		const list = await this.list({ filterParams: this.state.filterParams });
		this.setState({ newspaperManagementList: list });
	}

	// @ts-ignore
	async list({ filterParams }: { filterParams: FilterParamsInterface }): Promise<PaginateInterface>
	{
		try {
			const params = typeof filterParams === 'string' ? filterParams : paramsToString(filterParams);
			return await this.apiAdminNewspaperManagement.list(false, params) as Promise<PaginateInterface>

		} catch (error) {
			console.error('Failed to fetch newspaper closure management list', error);

			return {
				items: [],
				totalCount: 0,
				totalPages: this.state.filterParams.page || 1,
				itemsPerPage: this.state.filterParams.itemsPerPage || 10,
			};
		}
	}

	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Support',
				tag: 'newspaperType',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'newspaper.type'
			},
			{
				title: 'Numéro d’édition',
				tag: 'editionNumber',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'editionNumber'
			},
			{
				title: 'Nom du journal',
				tag: 'newspaper',
				columnWidth: 250,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'newspaper.name'
			},
			{
				title: 'Département',
				tag: 'department',
				columnWidth: 200,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['department.name']
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'tag',
				enumClass: 'PublishStateEnum',
				keyTag: 'status'
			},
			{
				title: 'Date de bouclage',
				tag: 'publishDate',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'publishDate'
			},
			{
				title: 'Nombre d’annonce',
				tag: '',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'countLegalNotice',
				keyTag: ''
			},
		];
	}

	buildFilters(): FiltersInterface[]
	{
		return [
			{
				title: 'Département (code)',
				tag: 'department',
				type: 'search',
			},
			{
				title: 'Journal',
				tag: 'newspaper',
				type: 'search',
			},
			{
				title: 'date de bouclage',
				tag: 'date',
				type: 'date',
			}
		];
	}
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { FiltersInterface, TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { ApiUserDeveloperService } from '@/Service/Developer/ApiUserDeveloperService';
import { UsersDeveloperProps, UsersDeveloperState } from '@/Modules/User/Developer/List/UsersDeveloper.interface';

const initState: UsersDeveloperState = {
	isLoading: false,
	errorMessage: null,
	userList: {} as PaginateInterface,
	filters: {},
	refreshKey: 0,
	filterParams: {
		page: 1,
		itemsPerPage: 15,
		search: null,
		filters: null,
		orderBy: null,
		sort: null,
	},
	currentOffcanvaContent: null
};

export class UsersDeveloperService extends BaseComponentService<UsersDeveloperProps, UsersDeveloperState>
{
	apiUserService = new ApiUserDeveloperService();

	constructor()
	{
		super({} as UsersDeveloperProps, initState);

		// Bind
		this.create = this.create.bind(this);
		this.refresh = this.refresh.bind(this);
	}

	async refresh(): Promise<any>
	{
		await this.list();
	}

	/**
	 * User List
	 * @return Promise<PaginateInterface>
	 */
	async list(): Promise<PaginateInterface | undefined>
	{
		try {
			const userList = await this.apiUserService.list(paramsToString(this.state.filterParams));
			this.setState({ userList });
			return userList;
		} catch (error: any) {
			console.log('Failed to fetch user List', error.message);
			return undefined;
		}
	}

	/**
	 * Create new User
	 * @param formData
	 */
	async create(formData: UserInterface): Promise<void>
	{
		// Add data Active Status
		formData.status = ActiveStatusEnum.ACTIVE.value;

		try {
			// Loading Modal
			this.props.modalContext!.isLoadingFull(true);

			const response = await this.apiUserService.create(formData);

			if (response.errorMessage) {
				const translateError: string = this.mappedError(response.errorMessage);
				this.props.modalContext!.errorMessage(translateError);
				this.props.modalContext!.isOpen(true);
				this.props.modalContext!.isLoadingFull(false);
				return;
			}

			this.props.flashMessageContext!.flashMessage(
				'Utilisateur créé avec succès',
				'Votre nouvel utilisateur a bien été créé, vous pouvez désormais l\'associer en tant que collaborateur',
				'success'
			);

			this.props.modalContext!.isOpen(false);
			this.props.modalContext!.isLoadingFull(false);
			this.props.modalContext!.errorMessage('');

			// Refresh List
			await this.list();

		} catch (error: any) {
			console.log('Issue from create user : ', error.errorMessage);
		}
	}

	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Nom',
				tag: 'lastname',
				columnWidth: 250,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: ['lastname', 'firstname']
			},
			{
				title: 'Entreprise',
				tag: 'company.name',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 500,
				keyType: 'string',
				keyTag: 'company.name'
			},
			{
				title: 'E-mail',
				tag: 'email',
				columnWidth: 320,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'email'
			},
			{
				title: 'Télephone',
				tag: 'phone',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'phone'
			},
			{
				title: 'Crée le...',
				tag: 'createdAt',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'createdAt'
			},
			{
				title: 'Rôle',
				tag: 'role',
				columnWidth: 90,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'UserRoleEnum',
				keyTag: 'role'
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'ActiveStatusEnum',
				keyTag: 'status'
			}
		];
	}

	buildFilters(): FiltersInterface[]
	{
		return [
			{
				title: 'Statut',
				tag: 'status',
				type: 'selector',
				options: ActiveStatusEnum.optionsFilter
			},
			{
				title: 'Rôle',
				tag: 'role',
				type: 'selector',
				options: UserRoleEnum.optionsFilter
			}
		];
	}

	/**
	 * Mapped Error Message to translate
	 * @param error
	 * @private
	 */
	private mappedError(error: string): string {

		if (/The value ".*" already exists/.test(error)) {
			return "Cet email existe déjà. Veuillez en utiliser un autre.";
		}

		return error;
	}
}
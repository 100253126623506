import React, { Component, ReactElement } from 'react';
import { ClientLogoProps, ClientLogoState } from './ClientLogo.interface';
import { ClientLogoService } from './ClientLogo.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ClientLogoStyles } from '@/Modules/Client/Components/ClientLogos/Show/ClientLogo.styles';
import { formatDateToFR } from '@/Utils/DateUtils';
import Select from '@/Modules/App/Components/Library/Select/Select';

class ClientLogo extends Component<ClientLogoProps, ClientLogoState>
{
	private clientLogoService = new ClientLogoService();

	constructor(props: ClientLogoProps)
	{
		super(props);

		// Config service
		this.clientLogoService.setProps(this.props);
		this.clientLogoService.subscribeState(this);

		// State
		this.state = this.clientLogoService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientLogoService.init();
	}

	componentWillUnmount(): void
	{
		this.clientLogoService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { logo } = this.props;

		return (
			<>
				<div style={ {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: 25
				} }>
					<div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
						<img
							style={ { width: 288, marginTop: '10px', padding: '10px', border: '1px solid black', marginBottom: 20 } }
							src={ logo.logoUrl }
							alt={ 'logo-' + logo.name }
						/>
					</div>
					<div style={ { display: 'flex', gap: 10 } }>
						<Button
							label={ 'Télécharger' }
							variant={ 'primary' }
							iconName={ 'LuFileDown' }
							onClick={ () => this.clientLogoService.downloadImage(logo)
							}/>
						<Button
							label={ 'Supprimer' }
							variant={ 'error' }
							iconName={ 'LuTrash2' }
							onClick={ async (event: any) =>
							{
								event.stopPropagation();
								const isLegalNoticeAssociate = await this.clientLogoService.getLegalNoticesAssociate(logo.id);
								if (!isLegalNoticeAssociate) {
									this.clientLogoService.delete();
									return;
								}

								// modal
								this.props.modalContext?.content(
									'Annonces légales liées',
									<div style={ ClientLogoStyles.modalContainer }>
										<div style={ { ...FontStyles.textHelp, marginBottom: 10 } }>Ce logo est utilisé dans les annonces
											légales ci-dessous.
											Avant de pouvoir le supprimer, merci de lier un autre logo à ces annonces légales.
										</div>
										<div style={ { ...ClientLogoStyles.tableRow, height: 20 } }>
											<div style={ { width: 200, fontSize: 12, fontWeight: 600 } }>Donneur d'ordre</div>
											<div style={ { width: 200, fontSize: 12, fontWeight: 600 } }>Affaire</div>
											<div style={ { width: 100, fontSize: 12, fontWeight: 600 } }>Parution</div>
										</div>
										<div style={ ClientLogoStyles.tableContainer }>
											{ this.state.legalNotices.length > 0 && this.state.legalNotices.map((legalNotice: LegalNoticeInterface) => (
												<div
													style={ { ...ClientLogoStyles.tableRow, height: 25, display: 'flex', alignItems: 'center' } }>
													<div style={ {
														width: 200,
														fontWeight: 600,
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis'
													} }>
														{ legalNotice.client.name }
													</div>
													<div style={ {
														width: 200,
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis'
													} }>
														{ legalNotice.consumer.name }
													</div>
													<div style={ { width: 100 } }>{ formatDateToFR(legalNotice.publishDate) }</div>
												</div>
											)) }
										</div>
										<div style={ ClientLogoStyles.selectLogo }>
											<Select
												listOptions={ this.props.listLogos.items.filter((logo) => logo.id !== this.props.logo.id) }
												displayKey={ 'name' }
												menuPosition={ { bottom: 28 } }
												styles={ { width: '100%' } }
												label={ 'Liste des logos' }
												textHelp={ 'Veuillez associer un autre logo aux annonces légales avant de supprimer celui-ci.' }
												onSelect={ (clientLogo) => this.clientLogoService.setSelectedLogoState(clientLogo) }
												isRequired={ false }
											/>
										</div>
										<div style={ ClientLogoStyles.buttons }>
											<Button
												label={ 'Modifier' }
												variant={ 'smallPrimary' }
												onClick={ () => this.clientLogoService.editClientLogoIdOnLegalNotices(
													this.state.selectedLogo?.id!,
													this.state.legalNotices,
													this.props.modalContext
												) }
											/>
										</div>
									</div>,
									true,
								);
							} }
						/>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(ClientLogo);
import {
	AssociateSellsyCompanyProps,
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { SearchCompanySellsyInterface } from '@/Modules/Client/Interface/SearchCompanySellsyInterface';
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';
import { CreateCompanySellsyRequestInterface } from '@/Interface/Sellsy/CreateCompanySellsyRequestInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { SellsyCompanyResponseInterface } from '@/Interface/Sellsy/SellsyCompanyResponse.interface';
import {
	SellsyCreateCompanyFromLegalNoticeInterface
} from '@/Interface/Sellsy/SellsyCreateCompanyFromLegalNotice.interface';
import { SellsyErrorResponseInterface } from '@/Interface/Sellsy/SellsyErrorResponse.interface';
import { SellsyCompanyAddressesResponseInterface } from '@/Interface/Sellsy/SellsyCompanyAddressesResponse.interface';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

const initState: AssociateSellsyCompanyState = {
	formData: {} as ClientModel,
	consumer: null,
	searchTerm: null,
	companySellsySearchResults: null,
	selectedCompanySellsy: null,
	isLoadingSellsySearch: false,
	isLoadingEditAddress: false,
	createSellsyResponse: null,
	isOpen: false,
};

export class AssociateSellsyCompanyService extends BaseComponentService<AssociateSellsyCompanyProps, AssociateSellsyCompanyState>
{
	private apiClientSellsyService: ApiAdminSellsyService;
	private apiAdminSellsyService: ApiAdminSellsyService = new ApiAdminSellsyService();

	constructor()
	{
		super({} as AssociateSellsyCompanyProps, initState);

		// Dispatch Service
		this.apiClientSellsyService = (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin())
			? new ApiAdminSellsyService(true)
			: new ApiAdminSellsyService(false);

		// Bind
		this.searchCompanySellsy = this.searchCompanySellsy.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleSelectedCompanySellsy = this.handleSelectedCompanySellsy.bind(this);
		this.handleCancelSelectedCompanySellsy = this.handleCancelSelectedCompanySellsy.bind(this);
		this.handleCreateNewCompanySellsy = this.handleCreateNewCompanySellsy.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.isEdit!) {
			await this.getSellsyCompany(this.props.associateCompany?.extSellsyId! as number);
		} else if (this.isClient(this.props.associateCompany)) {
			this.setState({ formData: new ClientModel() });
		} else {
			this.setState({
				consumer: this.props.consumer,
				selectedCompanySellsy: null,
			}, () => this.props.getComponentState(this.state));
		}
	}

	/**
	 * Handle Click Outside Element
	 * @param event
	 * @param containerRef
	 */
	handleClickOutside(event: MouseEvent, containerRef: React.RefObject<HTMLDivElement>): void
	{
		if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
			this.setState({ isOpen: false });
		}
	}

	/**
	 * Search Company from sellsy
	 * @param event
	 * @param searchTerm
	 * @param companyId
	 */
	async searchCompanySellsy(event: any, searchTerm: string | null, companyId?: number): Promise<any>
	{
		this.setState({ isLoadingSellsySearch: true });
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		try {
			let company: number;
			if (companyId) {
				company = companyId;
			} else {
				company = this.props.authCompanyContext?.authCompany?.id! || this.props.authContext?.user?.company?.id!;
			}

			if (company && searchTerm) {
				const companySearchResult = await this.apiClientSellsyService.searchCompanyByTerm(
					company,
					searchTerm
				);

				this.setState({
					searchTerm,
					companySellsySearchResults: companySearchResult,
					isOpen: true,
				});

				return companySearchResult;
			}

		} catch (error) {
			console.error('Erreur lors de la recherche de la société sur Sellsy:', error);
		} finally {
			this.setState({ isLoadingSellsySearch: false });
		}
	}

	/**
	 * Handle Selected Company
	 * @param event
	 * @param companySellsy
	 */
	handleSelectedCompanySellsy(event: any, companySellsy: SearchCompanySellsyInterface): void
	{
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				extSellsyId: companySellsy.object.id
			},
			selectedCompanySellsy: companySellsy,
			isOpen: false
		}), () => this.props.getComponentState(this.state));
	}

	/**
	 * Prepare to create new company Sellsy
	 * @param event
	 * @param companyToCreate
	 */
	handleCreateNewCompanySellsy(event: any, companyToCreate: ClientInterface | ConsumerDataInterface): void
	{
		event.preventDefault();
		event.stopPropagation();

		if (this.props.isWaitingCreate) {
			this.tempCreateCompanySellsy();
		} else {
			this.createCompanySellsy(companyToCreate);
		}
	}

	/**
	 * Get Company from Sellsy
	 * @param sellsyCompanyId
	 * @param companyId
	 * @param isCallbackState
	 * @return Promise<any> qui résout avec selectedCompanySellsy
	 */
	async getSellsyCompany(sellsyCompanyId: number, isCallbackState: boolean = true, companyId?: number): Promise<any>
	{
		this.setState({ isLoadingEditAddress: true });

		let company: number;
		if (companyId) {
			company = companyId;
		} else {
			company = this.props.authCompanyContext?.authCompany?.id!;
		}

		try {
			const response = await this.apiClientSellsyService.getCompany(company, sellsyCompanyId);
			const errorResponse = response as SellsyErrorResponseInterface;
			if (errorResponse.statusCode !== undefined && errorResponse.statusCode !== 200) {
				console.error(errorResponse.errorMessage);
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					`Une erreur est survenue : ${ errorResponse.errorMessage }`,
					'error'
				);
				throw new Error(errorResponse.errorMessage);
			}

			const getAddresses = await this.apiClientSellsyService.getCompanyAddressList(
				company,
				Number(sellsyCompanyId)
			);

			const getAddress = (getAddresses as SellsyCompanyAddressesResponseInterface).data.find(
				(address: any) => address.id === (response as SellsyCompanyResponseInterface).invoicing_address_id
			);

			return new Promise((resolve) =>
			{
				this.setState(
					{
						selectedCompanySellsy: {
							...this.state.selectedCompanySellsy!,
							id: (response as SellsyCompanyResponseInterface).id,
							object: {
								type: '',
								sub_type: '',
								id: (response as SellsyCompanyResponseInterface).id
							},
							created: (response as SellsyCompanyResponseInterface).created,
							name: (response as SellsyCompanyResponseInterface).name,
							main_contact_id: null,
							delivery_address_id: getAddress?.id ?? null,
							delivery_address: getAddress ?? null,
							invoicing_address: getAddress ?? null,
						}
					},
					() =>
					{
						this.setState({ isLoadingEditAddress: false });

						if (isCallbackState) {
							this.props.getComponentState(this.state);
						}
						resolve(this.state.selectedCompanySellsy);
					}
				);
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	}


	/**
	 * Canceled / Reset selected company
	 * @param event
	 */
	handleCancelSelectedCompanySellsy(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState({
			selectedCompanySellsy: null,
			formData: {
				...this.state.formData,
				isSellsyCreated: false,
			}
		});
	}


	/**
	 * Update Sellsy Address
	 */
	async updateSellsyConsumerAddress(state: AssociateSellsyCompanyState): Promise<void>
	{
		const consumer = state.consumer;

		this.setState({ isLoadingEditAddress: true });

		await this.apiAdminSellsyService.updateCompanyAddress(
			this.props.authCompanyContext?.authCompany?.id!,
			Number(this.state.selectedCompanySellsy?.object.id!),
			this.state.selectedCompanySellsy?.invoicing_address?.id!,
			consumer,
		)
			.then((response) =>
				this.setState({
					isLoadingEditAddress: false,
					selectedCompanySellsy: {
						...this.state.selectedCompanySellsy!,
						invoicing_address: response,
						delivery_address: response,
					}
				})
			)
			.catch(() => this.setState({ isLoadingEditAddress: false }))
		;

	}

	/**
	 * Create company sellsy
	 * @private
	 */
	private tempCreateCompanySellsy(): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				isSellsyCreated: true,
				extSellsyId: null
			}
		}), () =>
		{
			this.props.getComponentState(this.state);
		});
	}

	/**
	 *
	 * @param companyToCreate
	 * @param searchCompanyNeeded
	 * @param companyId
	 */
	async createCompanySellsy(companyToCreate: ClientInterface | ConsumerDataInterface, searchCompanyNeeded: boolean = true, companyId?: number): Promise<void>
	{
		let company: number;
		if (companyId) {
			company = companyId;
		} else {
			company = this.props.authCompanyContext?.authCompany?.id! || this.props.authContext?.user?.company?.id!;
		}

		/* Find collaborator email */
		const emailCollaboratorOwner = (companyToCreate as ClientInterface) && 'collaborators' in companyToCreate && Array.isArray(companyToCreate.collaborators)
			? companyToCreate.collaborators.find(collab => collab.role === 'ROLE_OWNER')?.email || null
			: null;

		/* Prepare company Data */
		const prepareCompanyData = {
			name: (this.state.searchTerm !== companyToCreate.name) ? this.state.searchTerm : companyToCreate.name,
			type: 'client',
			emailCollaboratorOwner,
			phone_number: (companyToCreate as ClientInterface).phone,
			siren: companyToCreate.siren?.substring(0, 9),
			address: {
				...companyToCreate.address,
				country: 'France',
			},
		} as CreateCompanySellsyRequestInterface;

		/* Trigger Loading */
		this.setState({ isLoadingSellsySearch: true });

		/* Create company */
		await this.apiClientSellsyService.createCompanyFromLegalNotice(
			company,
			prepareCompanyData,
		)
			.then(async (response) =>
			{
				const errorResponse = response as SellsyErrorResponseInterface;
				if (errorResponse.statusCode !== undefined && errorResponse.statusCode !== 200) {
					console.error((response as SellsyErrorResponseInterface).errorMessage);
					this.props.flashMessageContext?.flashMessage!(
						'Erreur',
						`Une erreur est survenue : ${ (response as SellsyErrorResponseInterface).errorMessage }`,
						'error'
					);
					this.setState({ isLoadingSellsySearch: false });
					return;
				} else {
					await this.apiClientSellsyService.updateCompanyAddress(
						company,
						(response as SellsyCreateCompanyFromLegalNoticeInterface).clientSellsyId!,
						(response as SellsyCreateCompanyFromLegalNoticeInterface).addressSellsyId!,
						prepareCompanyData,
					);

					this.setState({
						createSellsyResponse: (response as SellsyCreateCompanyFromLegalNoticeInterface)
							? response as SellsyCreateCompanyFromLegalNoticeInterface
							: null,
					}, () =>
					{
						if (searchCompanyNeeded) {
							this.searchCompanySellsy(
								null,
								(this.state.searchTerm !== companyToCreate.name) ? this.state.searchTerm : companyToCreate.name,
								company
							);
						}
					});
				}
			})
			.catch((error) =>
			{
				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(true);
				console.error(error);
			})
		;
	}

	private isClient(company: any): company is ClientInterface
	{
		return company && 'options' in company;
	}
}
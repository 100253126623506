import React, { Component, ReactElement } from 'react';
import { SearchProps, SearchState } from './Search.interface';
import { SearchService } from './Search.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import IsLoading from '@/Modules/App/Components/Loader/IsLoading/IsLoading';
import { SearchStyles } from '@/Modules/App/Components/Library/Search/Search.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class Search extends Component<SearchProps, SearchState>
{
	private searchService = new SearchService();

	constructor(props: SearchProps) {
		super(props);

		// Config service
		this.searchService.setProps(this.props);
		this.searchService.subscribeState(this);

		// State
		this.state = this.searchService.getState();
	}

	async componentDidMount(): Promise<void> {
		await this.searchService.init();
	}

	componentWillUnmount(): void {
		this.searchService.willUnMountInit();
	}

	render(): ReactElement
	{
		const { formData } = this.state;
		const hasResults = Array.isArray(formData.results) && formData.results.length > 0;
		const showNotFound = formData.searchTerm.trim() !== "" && !hasResults && this.props.withResultList;

		return (
			<div style={ SearchStyles.container }>
				<ToggleOpenClosed isClickOutside={ true }>
					{({ isOpen, toggle, close, open, menuRef, setIsOpen }) => (
						<>
							<Input
								type="search"
								name="searchTerm"
								label={ this.props.inputLabel }
								textHelp={ this.props.inputTextHelp }
								value={ formData.searchTerm || '' }
								onChange={ this.searchService.handleInputChange.bind(this) }
								autocomplete="off"
								onClick={() => {
									if (formData.searchTerm.trim().length > 0) {
										this.searchService.search(formData.searchTerm);
									}

									setIsOpen(true);
								}}
								isLoadingSearch={ this.state.isLoading }
								required={ this.props.isRequired }
							/>

							{ formData.searchTerm.trim() !== "" && (hasResults || showNotFound) && (
								<div ref={ menuRef } style={ SearchStyles.openList(isOpen, this.props.css?.searchList?.top!) }>
									<IsLoading isLoading={ this.state.isLoading }>
										{ hasResults ? (
											<ul>
												{ formData.results?.map((result) => (
													<Hovered key={ result.email }>
														{({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
															<li
																key={ result.email }
																ref={ ref }
																style={ SearchStyles.item(isHovered) }
																onMouseEnter={ onMouseEnter }
																onMouseLeave={ onMouseLeave }
																onClick={(event: any) => {
																	event.preventDefault();
																	event.stopPropagation();
																	this.props.onSelectElement(result);
																}}
															>
																{this.props.renderResult(result)}
															</li>
														)}
													</Hovered>
												))}
											</ul>
										) : ( showNotFound && (
												<div style={ SearchStyles.notFound }>
													<div style={ FontStyles.h4 }>Aucun résultat trouvé</div>
													<div style={ FontStyles.textHelp }>Veuillez essayer une autre requête</div>
													{ this.props.notFoundButtonAction && (
														<Button
															label={ this.props.notFoundButtonAction.label }
															variant="inline"
															onClick={ this.props.notFoundButtonAction.onClick }
														/>
													)}
												</div>
											)
										)}
									</IsLoading>
								</div>
							)}
						</>
					)}
				</ToggleOpenClosed>
			</div>
		);
	}

}


export default Search;

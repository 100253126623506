import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AssociateCollaboratorProps, AssociateCollaboratorState } from './AssociateCollaborator.interface';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';

const initState: AssociateCollaboratorState = {
	collaborators: [],
	selectedCollaborator: null,
};

export class AssociateCollaboratorService extends BaseComponentService<AssociateCollaboratorProps, AssociateCollaboratorState>
{
	private apiAdminCollaboratorService = new ApiAdminCollaboratorService();

	constructor()
	{
		super({} as AssociateCollaboratorProps, initState);

		// Bind
		this.setCollaborators = this.setCollaborators.bind(this);
		this.setCollaborator = this.setCollaborator.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setCollaborators(this.props.client);

		if (this.props.collaborator) {
			this.setCollaborator( this.props.collaborator! );
		}
	}

	/**
	 * Set Collaborator List
	 * @param client
	 */
	setCollaborators(client: ClientInterface | null): void
	{
		this.setState({ collaborators: (client) ? client.collaborators : [], },
			() =>
			{
				if (client?.collaborators && this.state.collaborators.length === 1) {
					this.setCollaborator(this.state.collaborators[0]);
				}
			});
	}

	/**
	 * Set Collaborator from Select
	 * @param collaborator
	 */
	setCollaborator(collaborator: Partial<CollaboratorInterface> | null): void
	{
		this.setState({
			selectedCollaborator: collaborator
		}, () => this.props.callbackState!(this.state));
	}
}
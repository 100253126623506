import React, { ReactElement } from 'react';
import {
  CommercialClientsAdminProps, CommercialClientsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/List/CommercialClientsAdmin.interface';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
  CommercialClientsAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/List/CommercialClientsAdmin.service';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import CommercialClientAdmin from '@/Modules/Commercial/Admin/Components/CommercialClientList/Show/CommercialClientAdmin';
import { ApiAdminCommercialService } from '@/Service/Admin/ApiAdminCommercialService';

class CommercialClientsAdmin extends React.Component<CommercialClientsAdminProps, CommercialClientsAdminState>
{
  private componentService: CommercialClientsAdminService = new CommercialClientsAdminService();
  private apiCommercialService: ApiAdminCommercialService = new ApiAdminCommercialService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <OffcanvaContext.Consumer>
          { (offcanvaContext) => (
            <ListTableManager
              isTableControls={ true }
              key={ this.state.refreshKey }
              tag={ 'commercial-client' }
              refreshList={ this.state.clientList }
              tableColumns={ this.componentService.buildColumnHeader() }
              filters={ this.componentService.buildFilters() }
              serviceList={ this.apiCommercialService.list.bind(this.apiCommercialService) }
              onRowClick={ async (event: any, client: ClientInterface) => {
                event.stopPropagation();
                event.preventDefault();

                const refreshClient = async () => {
                  const updatedClient = await this.apiCommercialService.show(client.id);
                  await this.componentService.init();
                  offcanvaContext?.content(
                    null,
                    <GlobalContextProvider>
                      <CommercialClientAdmin
                        client={ updatedClient }
                        refreshClient={ refreshClient }
                        modalContext={ this.props.modalContext! }
                        flashMessageContext={ this.props.flashMessageContext! }
                      />
                    </GlobalContextProvider>
                  );
                };

                offcanvaContext?.content(
                  null,
                  <GlobalContextProvider>
                    <CommercialClientAdmin
                      client={ client }
                      refreshClient={ refreshClient }
                      modalContext={ this.props.modalContext! }
                      flashMessageContext={ this.props.flashMessageContext! }
                    />
                  </GlobalContextProvider>
                );

                offcanvaContext?.isOpen(true);
              }}
            />
          )}
        </OffcanvaContext.Consumer>
      </>
    );
  }
}

export default withGlobalContext(CommercialClientsAdmin);
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminAppService extends BaseService {
  adminAppUrl: string = `${ BaseService.API_URL }/v1/admin/app`;

  //<editor-fold desc="Counters / Charts methods" defaultstate="collapsed">

  async counters(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/counters',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async chartLegalNotice(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/legal-notice/chart',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Commercial Gift methods" defaultstate="collapsed">

  async commercialGiftList(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-gift',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialGiftCreate(commercialGiftData: any): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-gift',
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(commercialGiftData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialGiftEdit(commercialGiftId: number, commercialGiftData: any): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-gift/' + commercialGiftId,
        {
          method: HttpVerbEnum.PUT,
          headers: this.buildHeaders(),
          body: JSON.stringify(commercialGiftData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialGiftDelete(commercialGiftId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-gift/' + commercialGiftId,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Commercial Sector methods" defaultstate="collapsed">

  async commercialSectorList(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-sector',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialSectorCreate(commercialSectorData: any): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-sector',
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(commercialSectorData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialSectorEdit(commercialSectorId: number, commercialSectorData: any): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-sector/' + commercialSectorId,
        {
          method: HttpVerbEnum.PUT,
          headers: this.buildHeaders(),
          body: JSON.stringify(commercialSectorData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async commercialSectorDelete(commercialSectorId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/commercial-sector/' + commercialSectorId,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  async exportClosureManagementXml(newspaperClosureManagementId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/export-xml/' + newspaperClosureManagementId,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildZipHeaders(),
        }
      );

      // Response data
      return await response;
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
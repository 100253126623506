import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientLogoProps, ClientLogoState } from './ClientLogo.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ClientLogosInterface } from '@/Modules/Client/Interface/ClientLogosInterface';
import { ModalContextType } from '@/Provider/ModalProvider';

const initState: ClientLogoState = {
	legalNotices: [],
	isLoading: true,
	selectedLogo: null,
};

export class ClientLogoService extends BaseComponentService<ClientLogoProps, ClientLogoState>
{
	private apiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	apiClientService: ApiAdminClientService | ApiClientService = (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin())
		? new ApiAdminClientService()
		: new ApiClientService()
	;

	constructor()
	{
		super({} as ClientLogoProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.getLegalNoticesAssociate(this.props.logo.id);
	}

	/**
	 * Get LegalNotices by client Logo
	 * @param clientLogoId
	 */
	async getLegalNoticesAssociate(clientLogoId: number): Promise<boolean>
	{
		try {
			const response = await this.apiAdminLegalNoticeService.getLegalNoticeByLogo(clientLogoId);
			if (response.length === 0) {
				this.setState({ isLoading: false });
				return false;
			}
			this.setState({
				legalNotices: response,
				isLoading: false,
			});
			return true;
		} catch (error) {
			this.setState({ isLoading: false });
			console.error(error);
			return false;
		}
	}

	async editClientLogoIdOnLegalNotices(clientLogoId: number, legalNotices: LegalNoticeInterface[], modalContext?: ModalContextType): Promise<void>
	{
		modalContext?.isLoadingOverlay(true);

		await Promise.all(
			legalNotices.map(legalNotice =>
				this.apiAdminLegalNoticeService.editClientLogo(clientLogoId, legalNotice.id)
			)
		)
			.then(() =>
			{
				modalContext?.isLoadingOverlay(false);
				modalContext?.isOpen(false);

				this.props.flashMessageContext?.flashMessage(
					'Mis à jour réussie',
					'Logo bien modifié dans les annonces légales',
					'success'
				);

			})
			.catch((error) =>
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					'Une erreur est survenue lors du changement des logos',
					'error'
				)
			);
	}

	/**
	 * Set Client logo State
	 * @param clientLogo
	 */
	setSelectedLogoState(clientLogo: ClientLogosInterface): void
	{
		this.setState({
			selectedLogo: clientLogo,
		});
	}

	/**
	 * Download Logo Image
	 * @param logo
	 */
	downloadImage(logo: any): void
	{
		const link = document.createElement('a');
		link.href = logo.logoUrl;

		link.download = logo.name;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	/**
	 * Handle Delete logo
	 */
	async delete(): Promise<void>
	{
		try {
			this.props.modalContext?.isLoadingOverlay(true);
			const response = await this.apiClientService.deleteLogo(this.props.clientId, this.props.logo.id);

			if (response.error) {
				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(true);
				// FlashMessage
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					'Une erreur est survenue sur la suppression du logo',
					'error'
				);
			}

			this.props.modalContext?.isLoadingOverlay(false);
			this.props.modalContext?.isOpen(false);

			this.props.getComponentState();

			// FlashMessage
			this.props.flashMessageContext?.flashMessage(
				'Supprimé',
				'Logo supprimé avec succès',
				'success'
			);

		} catch (e: any) {
			console.error(` Error occurred on ${ this.constructor.name } - delete Logo`, e);
		}
	}

}
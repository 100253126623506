import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { CSSProperties } from 'react';

export const CardStyles = {
	cardContainer: (isHovered: boolean = false) => ({
		cursor: 'pointer',
		padding: '20px 15px',
		borderRadius: 15,
		color: (isHovered) ? colors.blueRibbon500 : colors.primary,
		border: borderStyles.border,
		borderColor: (isHovered) ? colors.blueRibbon200 : colors.gray200,
		backgroundColor: (isHovered) ? colors.blueRibbon50 : colors.white,
		transition: 'background-color 270ms ease-out',
	}),

	simpleCardGrayContainer: {
		position: 'relative',
		...borderStyles,
		borderColor: colors.gray200,
		borderRadius: 6,
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,
} as const;



import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeGuideRenderProps, LegalNoticeGuideRenderState } from './LegalNoticeGuideRender.interface';
import React from 'react';

const initState: LegalNoticeGuideRenderState = {
	containerMaxHeight: 0,
	formBuilderContents: [],
};

export class LegalNoticeGuideRenderService extends BaseComponentService<LegalNoticeGuideRenderProps, LegalNoticeGuideRenderState>
{
	constructor()
	{
		super({} as LegalNoticeGuideRenderProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{}

	updateContainerMaxHeight = (containerRef: React.RefObject<HTMLDivElement>): void =>
	{
		if (containerRef.current) {
			const containerMaxHeight: number = containerRef.current.offsetHeight;
			if (this.state.containerMaxHeight !== containerMaxHeight) {
				this.setState({
					containerMaxHeight,
				});
			}
		}
	};

}
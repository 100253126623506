import AddressModel from '../../Client/Model/AddressModel';

export interface ExtendedClientModel extends ClientModel {
	userId?: number;
}

export class ClientModel
{
	name: string = '';
	siren: string = '';
	siret?: string | null = null;
	apeCode: string = '';
	phone: string = '';
	address: AddressModel = new AddressModel();
	emailCollaboratorOwner?: string|null = '';
	extSellsyId: string = '';
	isSellsyCreated: boolean = false;
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { UpdateSellsyAddressProps, UpdateSellsyAddressState } from './UpdateSellsyAddress.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';

const initState: UpdateSellsyAddressState = {
	client: null,
	sellsyAddress: null,
	selectedAddressToUpdate: {} as UpdateSellsyAddressState['selectedAddressToUpdate'],
};

export class UpdateSellsyAddressService extends BaseComponentService<UpdateSellsyAddressProps, UpdateSellsyAddressState>
{
	private apiAdminSellsyService: ApiAdminSellsyService = new ApiAdminSellsyService();
	private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as UpdateSellsyAddressProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.client) {
			this.setState({
				client: this.props.client,
				selectedAddressToUpdate: {
					isFromSellsy: false,
					extSellsyId: this.props.client.address?.extSellsyId!,
				},
			});
		}

		if (this.props.clientSellsyAddress) {
			this.setState({ sellsyAddress: this.props.clientSellsyAddress });
		}
	}

	/**
	 * Handle create Sellsy Address
	 */
	async handleCreateSellsyAddress(): Promise<void> {
		this.props.modalContext?.isLoadingOverlay(true);

		this.apiAdminSellsyService
			.createClientSellsyAddress(
				this.props.client?.company?.id!,
				Number(this.props.client?.extSellsyId!),
				this.props.client?.id!
			)
			.then((newSellsyAddress) => {
				if (newSellsyAddress === undefined) {
					this.props.flashMessageContext?.flashMessage(
						'Erreur',
						`Une erreur est survenue lors de la création de l'adresse sellsy`,
						'error'
					);
					return Promise.reject(new Error('New Sellsy Address is undefined'));
				}
				return this.apiAdminClientService.edit(
					{
						address: {
							...this.props.client?.address,
							extSellsyId: newSellsyAddress.id,
						},
					},
					this.props.client?.id!
				);
			})
			.then((updateClient) => {
				this.setState(
					{
						client: updateClient,
					},
					() => {
						this.props.callbackState!(this.state);
					}
				);

				this.props.modalContext?.isOpen(false);
				this.props.flashMessageContext?.flashMessage(
					'Nouvelle adresse Sellsy',
					`La création de votre nouvelle adresse a bien été effectuée`,
					'success'
				);
			})
			.catch((error: any) => {
				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(true);
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					`Une erreure est survenue sur la création de l'adresse`,
					'error'
				);
				console.error(`Error Occurred on ${this.constructor.name}: `, error);
			});
	}

	/**
	 * Handle edit address between SELLSY and PUBLISUR
	 * @param selectedAddressToUpdate
	 */
	async handleEditSellsyAddress(selectedAddressToUpdate: UpdateSellsyAddressState['selectedAddressToUpdate']): Promise<void>
	{
		const { client } = this.state;
		this.props.modalContext?.isLoadingOverlay(true);

		try {
			if (selectedAddressToUpdate.isFromSellsy) {
				/* Update Client if sellsy address selected */
				const updateClient = await this.apiAdminClientService.edit({
						address: this.state.sellsyAddress,
					},
					client?.id!
				);

				this.setState({ client: updateClient },
					() => this.props.callbackState!(this.state)
				);

				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(false);

				this.props.flashMessageContext?.flashMessage(
					'Client mis à jour',
					`Votre client ${ updateClient.name } a bien été mis à jour`,
					'success',
				);

			} else {
				/* Update Sellsy Address if client address selected */
				const updateSellsyAddress = await this.apiAdminSellsyService.updateCompanyAddress(
					client?.company.id!,
					Number(client?.extSellsyId!),
					this.state.sellsyAddress?.extSellsyId!,
					client,
				);

				this.setState({ sellsyAddress: updateSellsyAddress },
					() => this.props.callbackState!(this.state)
				);

				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(false);

				this.props.flashMessageContext?.flashMessage(
					'Sellsy mis à jour',
					`L'adresse Sellsy ${ updateSellsyAddress.name } a bien été mis à jour`,
					'success',
				);
			}

		} catch (error: any) {
			this.props.modalContext?.isLoadingOverlay(false);
			this.props.modalContext?.isOpen(true);

			this.props.flashMessageContext?.flashMessage(
				'Erreur',
				`Une erreure est survenue sur la mise à jour du client`,
				'error',
			);
			console.error(`Error Occurred on ${ this.constructor.name }: `, error);
		}
	}
}
const neutrals = {
	white: '#FFFFFF',
	black: '#000000',
	gray50: '#F9FAFB',
	gray100: '#F3F4F6',
	gray200: '#E5E7EB',
	gray300: '#D1D5DB',
	gray400: '#9CA3AF',
	gray500: '#6B7280',
	gray600: '#4B5563',
	gray700: '#374151',
	gray800: '#1F2937',
	gray900: '#111827',
};

const accents = {
	orange50: '#FFF8E6',
	orange100: '#FFE8B2',
	orange200: '#FFD07D',
	orange300: '#FFB846',
	orange400: '#FF9800',
	orange500: '#FB8C00',
	orange600: '#F57C00',
	orange700: '#EF6C00',
	orange800: '#E65100',
	orange900: '#BF360C',

	purple50: '#F4EAFE',
	purple100: '#E1D1FB',
	purple200: '#C3A9F8',
	purple300: '#A479F3',
	purple400: '#8D5DEF',
	purple500: '#7633EA',
	purple600: '#5D26BF',
	purple700: '#471A8F',
	purple800: '#341268',
	purple900: '#250A47',

	teal50: '#E6FCF7',
	teal100: '#C3F8EB',
	teal200: '#8EF3DC',
	teal300: '#5AECCF',
	teal400: '#26E4C0',
	teal500: '#00D9AE',
	teal600: '#00B08C',
	teal700: '#00896E',
	teal800: '#006751',
	teal900: '#004836',

	pink50: '#FCE4EC',
	pink100: '#F8BBD0',
	pink200: '#F48FB1',
	pink300: '#F06292',
	pink400: '#EC407A',
	pink500: '#E91E63',
	pink600: '#D81B60',
	pink700: '#C2185B',
	pink800: '#AD1457',
	pink900: '#880E4F',
};

const states = {
	success50: '#E6F9F0',
	success100: '#C2F0DD',
	success200: '#8FE6C6',
	success300: '#4ED3A2',
	success400: '#26C88A',
	success500: '#16B377',
	success600: '#119962',
	success700: '#0D8050',
	success800: '#09653E',
	success900: '#054530',

	warning50: '#FFF8E1',
	warning100: '#FFECB3',
	warning200: '#FFE082',
	warning300: '#FFD54F',
	warning400: '#FFCA28',
	warning500: '#FFC107',
	warning600: '#FFB300',
	warning700: '#FFA000',
	warning800: '#FF8F00',
	warning900: '#FF6F00',

	error50: '#FDECEA',
	error100: '#F9BDBB',
	error200: '#F69988',
	error300: '#F36C60',
	error400: '#E53935',
	error500: '#D32F2F',
	error600: '#C62828',
	error700: '#B71C1C',
	error800: '#9A0007',
	error900: '#7F0000',
};

const textColors = {
	primary: neutrals.gray900,
	secondary: neutrals.gray600,
	placeholder: neutrals.gray400,
	inverted: neutrals.white,
};

export const colors = {
	blueRibbon50: '#EEF3FF',
	blueRibbon100: '#D9E3FF',
	blueRibbon200: '#BCCEFF',
	blueRibbon300: '#8EB0FF',
	blueRibbon400: '#5985FF',
	blueRibbon500: '#2D55FF',
	blueRibbon600: '#1B36F5',
	blueRibbon700: '#1423E1',
	blueRibbon800: '#171EB6',
	blueRibbon900: '#19218F',
	blueRibbon950: '#141657',

	...neutrals,
	...accents,
	...states,
	...textColors,
} as const;

/**
 * Handle Dynamics colors
 */

const dynamicNeutrals = {
	white: '#FFFFFF',
	black: '#000000',
	gray: {
		50: '#F9FAFB',
		100: '#F3F4F6',
		200: '#E5E7EB',
		300: '#D1D5DB',
		400: '#9CA3AF',
		500: '#6B7280',
		600: '#4B5563',
		700: '#374151',
		800: '#1F2937',
		900: '#111827',
	}
};

const dynamicAccents = {
	orange: {
		50: '#FFF8E6',
		100: '#FFE8B2',
		200: '#FFD07D',
		300: '#FFB846',
		400: '#FF9800',
		500: '#FB8C00',
		600: '#F57C00',
		700: '#EF6C00',
		800: '#E65100',
		900: '#BF360C',
	},
	purple: {
		50: '#F4EAFE',
		100: '#E1D1FB',
		200: '#C3A9F8',
		300: '#A479F3',
		400: '#8D5DEF',
		500: '#7633EA',
		600: '#5D26BF',
		700: '#471A8F',
		800: '#341268',
		900: '#250A47',
	},
	teal: {
		50: '#E6FCF7',
		100: '#C3F8EB',
		200: '#8EF3DC',
		300: '#5AECCF',
		400: '#26E4C0',
		500: '#00D9AE',
		600: '#00B08C',
		700: '#00896E',
		800: '#006751',
		900: '#004836',
	},
	pink: {
		50: '#FCE4EC',
		100: '#F8BBD0',
		200: '#F48FB1',
		300: '#F06292',
		400: '#EC407A',
		500: '#E91E63',
		600: '#D81B60',
		700: '#C2185B',
		800: '#AD1457',
		900: '#880E4F',
	},
	black: {
		500: '#000'
	}
};

const dynamicStates = {
	success: {
		50: '#E6F9F0',
		100: '#C2F0DD',
		200: '#8FE6C6',
		300: '#4ED3A2',
		400: '#26C88A',
		500: '#16B377',
		600: '#119962',
		700: '#0D8050',
		800: '#09653E',
		900: '#054530',
	},
	warning: {
		50: '#FFF8E1',
		100: '#FFECB3',
		200: '#FFE082',
		300: '#FFD54F',
		400: '#FFCA28',
		500: '#FFC107',
		600: '#FFB300',
		700: '#FFA000',
		800: '#FF8F00',
		900: '#FF6F00',
	},
	error: {
		50: '#FDECEA',
		100: '#F9BDBB',
		200: '#F69988',
		300: '#F36C60',
		400: '#E53935',
		500: '#D32F2F',
		600: '#C62828',
		700: '#B71C1C',
		800: '#9A0007',
		900: '#7F0000',
	},
};

const dynamicTextColors = {
	primary: dynamicNeutrals.gray[900],
	secondary: dynamicNeutrals.gray[600],
	placeholder: dynamicNeutrals.gray[400],
	inverted: dynamicNeutrals.white,
};

export const dynamicColors = {
	blueRibbon: {
		50: '#EEF3FF',
		100: '#D9E3FF',
		200: '#BCCEFF',
		300: '#8EB0FF',
		400: '#5985FF',
		500: '#2D55FF',
		600: '#1B36F5',
		700: '#1423E1',
		800: '#171EB6',
		900: '#19218F',
		950: '#141657',
	},
	...dynamicNeutrals,
	...dynamicAccents,
	...dynamicStates,
	...dynamicTextColors,
} as const;



/**
 * Convert a hex color to rgba with a specified opacity
 * @param hex - The hex color (e.g., "#F9FAFB")
 * @param opacity - The opacity level (from 0 to 1)
 * @returns A string representing the RGBA color
 */
export const hexToRgba = (hex: string, opacity: number): string => {
	// Remove the leading '#' if present
	const sanitizedHex = hex.replace('#', '');

	// Parse the hex values
	const bigint = parseInt(sanitizedHex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	// Return the RGBA color string
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
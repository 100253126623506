export default class LegalNoticeTypeEnum {
  value: string;
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }

  static NONE = new LegalNoticeTypeEnum('NONE', 'Choisir une categorie');
  static LEASE_MANAGEMENT = new LegalNoticeTypeEnum('LEASE_MANAGEMENT', 'Gestion de bail');
  static END_MANAGEMENT = new LegalNoticeTypeEnum('END_MANAGEMENT', 'Fin de gestion');
  static NAME_CHANGE  = new LegalNoticeTypeEnum('NAME_CHANGE', 'Changement de nom');
  static CONSTITUTION  = new LegalNoticeTypeEnum('CONSTITUTION', 'Constitution');
  static DISSOLUTION  = new LegalNoticeTypeEnum('DISSOLUTION', 'Avis de dissolution');
  static NON_DISSOLUTION  = new LegalNoticeTypeEnum('NON_DISSOLUTION', 'Avis de non dissolution');
  static CLOSURE_LIQUIDATION  = new LegalNoticeTypeEnum('CLOSURE_LIQUIDATION', 'Avis de clôture et liquidation');
  static STATUTORY_MODIFICATIONS  = new LegalNoticeTypeEnum('STATUTORY_MODIFICATIONS', 'Modifications statutaires');
  static ABSENCE_DECLARATIONS  = new LegalNoticeTypeEnum('ABSENCE_DECLARATIONS', 'Déclarations d\'absence');
  static CAPITAL_CONTRIBUTION  = new LegalNoticeTypeEnum('CAPITAL_CONTRIBUTION', 'Apport de fonds');
  static DECLARATION_UNSUITABILITY  = new LegalNoticeTypeEnum('DECLARATION_UNSUITABILITY', 'Déclaration d\'insaisissabilité');
  static MODIFICATION  = new LegalNoticeTypeEnum('MODIFICATION', 'Modification');
  static SHARE_CAPITAL  = new LegalNoticeTypeEnum('SHARE_CAPITAL', 'Capital social');
  static PARTIAL_ASSET_CONTRIBUTION  = new LegalNoticeTypeEnum('PARTIAL_ASSET_CONTRIBUTION', 'Apport partiel d\'actifs');
  static FUND_TRANSFER  = new LegalNoticeTypeEnum('FUND_TRANSFER', 'Cession de fonds');
  static BUSINESS_ASSETS  = new LegalNoticeTypeEnum('BUSINESS_ASSETS', 'Fonds de commerce');
  static MATRIMONIAL_REGIME  = new LegalNoticeTypeEnum('MATRIMONIAL_REGIME', 'Régime matrimonial');
  static HOLOGRAPHIC_TESTAMENT  = new LegalNoticeTypeEnum('HOLOGRAPHIC_TESTAMENT', 'Testament olographe');
  static ACCEPTANCE_NET_ASSETS  = new LegalNoticeTypeEnum('ACCEPTANCE_NET_ASSETS', 'Acceptation de l\'actif net');
  static JUDICIAL_COURT  = new LegalNoticeTypeEnum('JUDICIAL_COURT', 'Tribunal judiciaire');
  static COMMERCIAL_COURT  = new LegalNoticeTypeEnum('COMMERCIAL_COURT', 'Tribunal de commerce');
  static TRANSFORMATION_NOTICE  = new LegalNoticeTypeEnum('TRANSFORMATION_NOTICE', 'Avis de transformation');
  static ADDENDUM_RECTIFICATION  = new LegalNoticeTypeEnum('ADDENDUM_RECTIFICATION', 'Additif / Rectificatif');
  static PUBLIC_NOTICE = new LegalNoticeTypeEnum('PUBLIC_NOTICE', 'Avis');

  static typeList: LegalNoticeTypeEnum[] = [
    LegalNoticeTypeEnum.NONE,
    LegalNoticeTypeEnum.CONSTITUTION,
    LegalNoticeTypeEnum.MODIFICATION,
    LegalNoticeTypeEnum.BUSINESS_ASSETS,
    LegalNoticeTypeEnum.NAME_CHANGE,
    LegalNoticeTypeEnum.ABSENCE_DECLARATIONS,
    LegalNoticeTypeEnum.DECLARATION_UNSUITABILITY,
    LegalNoticeTypeEnum.MATRIMONIAL_REGIME,
    LegalNoticeTypeEnum.HOLOGRAPHIC_TESTAMENT,
    LegalNoticeTypeEnum.ACCEPTANCE_NET_ASSETS,
    LegalNoticeTypeEnum.JUDICIAL_COURT,
    LegalNoticeTypeEnum.COMMERCIAL_COURT,
    LegalNoticeTypeEnum.TRANSFORMATION_NOTICE,
    LegalNoticeTypeEnum.ADDENDUM_RECTIFICATION,
    LegalNoticeTypeEnum.DISSOLUTION,
    LegalNoticeTypeEnum.NON_DISSOLUTION,
    LegalNoticeTypeEnum.CLOSURE_LIQUIDATION,
    LegalNoticeTypeEnum.STATUTORY_MODIFICATIONS,
    LegalNoticeTypeEnum.CAPITAL_CONTRIBUTION,
    LegalNoticeTypeEnum.FUND_TRANSFER,
    LegalNoticeTypeEnum.PARTIAL_ASSET_CONTRIBUTION,
    LegalNoticeTypeEnum.SHARE_CAPITAL,
    LegalNoticeTypeEnum.PUBLIC_NOTICE,
  ];

  static filterWithoutSiren: LegalNoticeTypeEnum[] = [
    LegalNoticeTypeEnum.CONSTITUTION,
    LegalNoticeTypeEnum.LEASE_MANAGEMENT,
    LegalNoticeTypeEnum.END_MANAGEMENT,
    LegalNoticeTypeEnum.MATRIMONIAL_REGIME,
    LegalNoticeTypeEnum.HOLOGRAPHIC_TESTAMENT,
    LegalNoticeTypeEnum.DECLARATION_UNSUITABILITY,
    LegalNoticeTypeEnum.JUDICIAL_COURT,
    LegalNoticeTypeEnum.COMMERCIAL_COURT,
    LegalNoticeTypeEnum.BUSINESS_ASSETS,
    LegalNoticeTypeEnum.ADDENDUM_RECTIFICATION,
    LegalNoticeTypeEnum.NAME_CHANGE,
    LegalNoticeTypeEnum.PUBLIC_NOTICE,
  ];

  static filterLegalNoticeGuide: LegalNoticeTypeEnum[] = [
    LegalNoticeTypeEnum.CONSTITUTION,
    LegalNoticeTypeEnum.BUSINESS_ASSETS,
    LegalNoticeTypeEnum.FUND_TRANSFER,
    LegalNoticeTypeEnum.SHARE_CAPITAL,
    LegalNoticeTypeEnum.MODIFICATION,
    LegalNoticeTypeEnum.CAPITAL_CONTRIBUTION,
    LegalNoticeTypeEnum.STATUTORY_MODIFICATIONS,
    LegalNoticeTypeEnum.CLOSURE_LIQUIDATION,
    LegalNoticeTypeEnum.NON_DISSOLUTION,
    LegalNoticeTypeEnum.DISSOLUTION,
    LegalNoticeTypeEnum.NAME_CHANGE,
  ];

  static filterWithBodacc: LegalNoticeTypeEnum[] = [
    LegalNoticeTypeEnum.FUND_TRANSFER,
  ];

  static findByValue(value: string): LegalNoticeTypeEnum|undefined
  {
    return this.typeList.find(option => option.value === value);
  }
}

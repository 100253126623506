import { LegalNoticesProps, LegalNoticesState } from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import { FiltersInterface, TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiLegalNoticeDeveloperService } from '@/Service/Developer/ApiLegalNoticeDeveloperService';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';

const initState: LegalNoticesState = {
  isLoading: false,
  errorMessage: null,
  legalNoticeList: {} as PaginateInterface,
  filters: {},
  refreshKey: 0,
  filterParams: {
    page: 1,
    itemsPerPage: 15,
    search: null,
    filters: null,
    orderBy: null,
    sort: null,
  },
};

export class LegalNoticesDeveloperService extends BaseComponentService<LegalNoticesProps, LegalNoticesState>
{
  private apiLegalNotice = new ApiLegalNoticeDeveloperService();

  constructor()
  {
    super({} as LegalNoticesProps, initState);
  }

  async init(): Promise<void>
  {}

  async refresh(): Promise<any>
  {
    await this.list();
  }

  async list(): Promise<PaginateInterface | undefined>
  {
    try {
      const legalNoticeList = await this.apiLegalNotice.list(paramsToString(this.state.filterParams));
      this.setState({ legalNoticeList });
      return legalNoticeList;
    } catch (error: any) {
      console.log('Failed to fetch user List', error.message);
      return undefined;
    }
  }

  buildColumnHeader(): TableHeadColumnsInterface[]
  {
    return [
      {
        title: 'Donneur d\'ordre',
        tag: 'clientName',
        columnWidth: 300,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'client.name'
      },
      {
        title: 'Entreprise',
        tag: 'company.name',
        columnWidth: 150,
        textAlign: 'left',
        fontWeight: 500,
        keyType: 'string',
        keyTag: 'company.name'
      },
      {
        title: 'N° d\'annonce',
        tag: 'tag',
        columnWidth: 70,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['tag']
      },
      {
        title: 'Publication au ...',
        tag: 'publishDate',
        columnWidth: 100,
        textAlign: 'right',
        fontWeight: 400,
        keyType: 'date',
        keyTag: 'publishDate'
      },
      {
        title: 'Journal',
        tag: 'newspaper',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: 'newspaper.name'
      },
      {
        title: 'Client final',
        tag: 'consumer',
        columnWidth: 300,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'consumer.name'
      },
      {
        title: 'Statut',
        tag: 'status',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'PublishStateEnum',
        keyTag: 'status'
      },
      {
        title: 'Paiement',
        tag: 'paymentStatus',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'PaymentStateEnum',
        keyTag: 'paymentStatus'
      },
      {
        title: 'Devis',
        tag: 'quoteStatus',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'QuoteStatusEnum',
        keyTag: 'quoteStatus'
      },
      {
        title: 'Auteur',
        tag: 'author',
        columnWidth: 300,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['author.lastname', 'author.firstname']
      },
      {
        title: 'Collaborateur',
        tag: 'collaborator',
        columnWidth: 300,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['collaborator.user.lastname', 'collaborator.user.firstname']
      },
    ];
  }

  buildFilters(): FiltersInterface[]
  {
    return [
      {
        title: 'Statut',
        tag: 'status',
        type: 'selector',
        options: PublishStateEnum.optionsFilter
      },
      {
        title: 'Statut de paiement',
        tag: 'paymentStatus',
        type: 'selector',
        options: PaymentStateEnum.optionsFilter
      },
      {
        title: 'Département (code)',
        tag: 'department',
        type: 'search',
      },
      {
        title: 'Journal',
        tag: 'newspaper',
        type: 'search',
      },
      {
        title: 'date',
        tag: 'publishDate',
        type: 'date',
      }
    ];
  }
}
import React, { ReactElement } from 'react';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import NotificationView from '@/Modules/Notifications/View/NotificationView';
import SidebarMenu from '@/Modules/App/Components/Menu/SidebarMenu/SidebarMenu';
import { LayoutProps, LayoutState } from '@/Modules/App/Components/Layout/Layout.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { LayoutStyles } from '@/Modules/App/Components/Layout/Layout.styles';
import TopBar from '@/Modules/App/Components/Menu/TopBarMenu/TopBar';
import AppService from '@/Service/Common/AppService';
import Sandbox from '@/Modules/App/Components/Sandbox/Sandbox';
import Content from '@/Modules/App/Components/Content/Content';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

class CompanyHelpData
{
	id: number;
	name: string;
	email: string;
	phone: string;

	constructor(id: number, name: string, email: string, phone: string)
	{
		this.id = id;
		this.name = name;
		this.email = email;
		this.phone = phone;
	}

	static getHelpDataByCompanyId(companyId: number): CompanyHelpData
	{
		const helpDataList: CompanyHelpData[] = [
			new CompanyHelpData(1, "Les petites affiches", "annonces.legales@heraultjuridique.com", "04.99.58.35.55"),
			new CompanyHelpData(2, "L'annoncier légales", "annonces@heraultjuridique.com", "04.67.28.09.22"),
			new CompanyHelpData(4, "L'echo du Languedoc", "annoncelegale@echo-languedoc.fr", "04.68.90.71.09"),
		];

		return helpDataList.find(data => data.id === companyId) || new CompanyHelpData(0, "Support Générique", "support@general.com", "09.09.09.09.09");
	}
}

class LayoutAdmin extends React.Component<LayoutProps, LayoutState>
{

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();
	}

	render(): ReactElement
	{
		if (!this.props.authContext?.user) {
			return <LoaderComponent height={ '100vh' }/>;
		}

		const companyHelp = CompanyHelpData.getHelpDataByCompanyId(this.props.authCompanyContext?.authCompany?.id || 0);

		return (
			<>
				<div style={ LayoutStyles.container }>
					{ this.state.isSandBoxActive &&
            <Sandbox
              title={ 'Mode develop' }
              content={ 'Vous êtes actuellement sur un environnement local' }
              action={ {
								label: 'Allez sur Preprod',
								path: 'https://www.publisur.dev'
							} }
              handleActive={ this.handleActiveSandBox.bind(this) }
            />
					}
					<div style={ LayoutStyles.topBar }>
						<TopBar/>
					</div>
					<div style={ {
						...LayoutStyles.mainContainer,
						height: `calc(100% - 50px - ${ this.state.isSandBoxActive ? 44 : 0 }px)`
					} }>
						<SidebarMenu/>

						{/* Version */}
						<div style={{ position: 'absolute', bottom: 2, left: 250, zIndex: 4, fontSize: 11 }}>V1.5</div>

						{/* NEED HELP */}
						<div style={{ position: 'absolute', bottom: 2, right: 30, zIndex: 4, fontSize: 11 }}>
							<Button
								iconStyle={{ fontSize: 30 }}
								variant={ 'inline' }
								iconName={ 'LuHelpCircle' }
								onClick={ () => { this.setState({ isHelpVisible: true }) } }
							/>

							{ this.state.isHelpVisible && (
								<div style={ LayoutStyles.tooltip }>
									<div style={ LayoutStyles.arrow } />
									<div style={ LayoutStyles.content }>
										<h4 style={ LayoutStyles.title }>Besoin d'aide ?</h4>
										<hr/>
										<p style={{ textAlign: 'center' }}>Vous rencontrez un problème ? </p>
										<br/>
										<p>
											Vous pouvez nous contacter a l'adresse
											<a style={{ color: colors.blueRibbon400 }} href={"mailto:" + companyHelp.email}> { companyHelp.email }</a>
											<br/>
											ou par téléphone { companyHelp.phone }
										</p>
										<button style={ LayoutStyles.closeButton } onClick={ () => this.setState({ isHelpVisible: false }) }>
											Fermer
										</button>
									</div>
								</div>
							)}
						</div>

						{/* CONTENT OUTLET */ }
						<Content>
							{ this.props.children }
						</Content>
					</div>
				</div>
				{/* Notification Create */ }
				<NotificationView
					isOpen={ this.state.isOpenNotificationView }
					handleOpen={ this.handleOpenNotificationView.bind(this) }
					notificationContext={ this.props.notificationContext! }
					navigation={ this.props.navigation }
				/>
			</>
		);
	}

	//<editor-fold desc="Create (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		const { notificationContext } = this.props;
		const notifications = this.props.notificationContext?.getNotifications();

		if (notifications && notifications.length > 0) {
			const hasUnreadNotifications: boolean = notificationContext?.getNotifications()?.some(notification => !notification.isRead) ?? false;
			if (hasUnreadNotifications !== this.state.isNotification) {
				this.setState({ isNotification: hasUnreadNotifications });
			}
		}
	}

	componentDidUpdate(prevProps: LayoutProps): void
	{
		const notifications = this.props.notificationContext?.getNotifications();

		if ((notifications && notifications.length > 0)
			&& (prevProps.notificationContext?.getNotifications() !== notifications)) {
			const hasUnreadNotifications: boolean = notifications?.some(notification => !notification.isRead) ?? false;

			if (hasUnreadNotifications !== this.state.isNotification) {
				this.setState({ isNotification: hasUnreadNotifications });
			}
		}
	}

	private initState(): LayoutState
	{
		return {
			isAvatarCardOpen: false,
			isModalOpen: false,
			isThemeDark: true,
			isOpenNotificationView: false,
			isNotification: false,
			isHelpVisible: false,
			isSandBoxActive: AppService.getEnv('dev')
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private handleOpenNotificationView(): void
	{
		this.setState(prevState => ({
			isOpenNotificationView: !prevState.isOpenNotificationView,
		}));
	}

	private handleActiveSandBox(isOpen: Boolean): void
	{
		this.setState({ isSandBoxActive: false });
	}

	//</editor-fold>
}

export default withGlobalContext(LayoutAdmin);
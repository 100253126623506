import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AddressFormInterface, AddressFormProps, AddressFormState } from './AddressForm.interface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';

const initState: AddressFormState = {
	address: {} as AddressInterface,
};

export class AddressFormService extends BaseComponentService<AddressFormProps, AddressFormState>
{
	private debounceTimeoutId: NodeJS.Timeout | null = null;

	constructor()
	{
		super({} as AddressFormProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.consumerAddress) {
			this.setAddressState(this.props.consumerAddress);
		}
	}

	/**
	 * Set Address State
	 * @param addressData
	 * @param isCallbackState
	 */
	setAddressState(addressData: Partial<AddressInterface>, isCallbackState?: boolean): void {
		this.setState({
			address: {
				...this.state.address,
				name: null,
				...addressData,
			}
		}, () => {
			if(!isCallbackState) {
				if (this.debounceTimeoutId) {
					clearTimeout(this.debounceTimeoutId);
				}
				this.debounceTimeoutId = setTimeout(() => {
					this.props.callbackState!(this.state);
				}, 1000);
			} else {
				this.props.callbackState!(this.state);
			}
		});
	}


	/**
	 * Validate Address Form
	 * @param address
	 */
	static validateAddressForm(address: AddressFormInterface): { [key: string]: string | null }
	{
		const errors: { [key: string]: string | null } = {};

		if (!address?.street || address?.street.trim() === '') {
			errors.street = 'La rue est obligatoire.';
		}
		if (!address?.zipCode || address?.zipCode.trim() === '') {
			errors.zipCode = 'Le code postal est obligatoire.';
		}
		if (!address?.city || address?.city.trim() === '') {
			errors.city = 'La ville est obligatoire.';
		}

		return errors;
	}

	/**
	 * Transform to lowercase or Uppercase
	 * @param field
	 */
	toggleCaseForField<K extends keyof AddressInterface>(field: K): void {
		const currentValue = this.state.address[field] as string || '';
		const newValue = currentValue === currentValue.toUpperCase()
			? currentValue.toLowerCase()
			: currentValue.toUpperCase();

		this.setAddressState({ [field]: newValue } as Partial<AddressInterface>, true);
	}
}
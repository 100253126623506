import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeOptionsHeaderProps, LegalNoticeOptionsHeaderState } from './LegalNoticeOptionsHeader.interface';
import { ClientLogosInterface } from '@/Modules/Client/Interface/ClientLogosInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';

const initState: LegalNoticeOptionsHeaderState = {
	logos: [],
	selectedLogo: null,
	isHeader: false,
	isConsumerFormLight: false,
};

export class LegalNoticeOptionsHeaderService extends BaseComponentService<LegalNoticeOptionsHeaderProps, LegalNoticeOptionsHeaderState>
{
	private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as LegalNoticeOptionsHeaderProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.client) {
			this.setLogosState(this.props.client?.logos!);
		}

		if(this.props.stateUpdater !== null && Object.keys(this.props.stateUpdater!).length > 0) {
			this.setIsHeaderState(this.props?.stateUpdater?.isHeader!);
			this.setSelectedLogo(this.props?.stateUpdater?.selectedLogo!);
		}
	}

	/**
	 * Set Logos State
	 * @param logos
	 */
	setLogosState(logos: ClientLogosInterface[]): void
	{
		this.setState({
			logos
		});
	}

	/**
	 * Selected logo
	 * @param logo
	 */
	setSelectedLogo(logo: ClientLogosInterface | null): void
	{
		this.setState({
			selectedLogo: logo
		}, () => this.props.callbackState!(this.state));
	}

	/**
	 * Set isHeader State
	 * @param isHeader
	 */
	setIsHeaderState(isHeader: boolean): void
	{
		this.setState({
			isHeader
		}, () => this.props.callbackState!(this.state));
	}

	/**
	 * Set is Consumer Form light State
	 * @param isConsumerFormLight
	 */
	setIsConsumerFormLightState(isConsumerFormLight: boolean): void
	{
		this.setState({
			isConsumerFormLight
		});
	}

	/**
	 * Create Logo from legal Notice
	 * @param logoData
	 */
	async onCreateLogo(logoData: any): Promise<void>
	{
		if (this.props.client) {
			await this.apiAdminClientService.addLogo(logoData, this.props.client?.id)
			.then(async () =>
			{
				await this.refreshLogo();
				this.props.callbackState!(this.state);
			})
			.catch((error) => console.error(error));

		}
	}

	/**
	 * Refresh Logo
	 */
	async refreshLogo(): Promise<void>
	{
		await this.apiAdminClientService.show(this.props.client?.id!)
			.then((response: ClientInterface) => this.setLogosState(response.logos));
	}
}
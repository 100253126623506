import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeWritingContentProps, LegalNoticeWritingContentState } from './LegalNoticeWritingContent.interface';
import { createRef, RefObject } from 'react';

const initState: LegalNoticeWritingContentState = {
	content: '',
	charCount: 0,
};

export class LegalNoticeWritingContentService extends BaseComponentService<LegalNoticeWritingContentProps, LegalNoticeWritingContentState>
{
	contentEditableRef: RefObject<HTMLDivElement> = createRef();

	constructor()
	{
		super({} as LegalNoticeWritingContentProps, initState);

		// Bind methods
		this.setContentState = this.setContentState.bind(this);
		this.handleTextSelect = this.handleTextSelect.bind(this);
		this.applyBoldCustom = this.applyBoldCustom.bind(this);
		this.handleApplyItalic = this.handleApplyItalic.bind(this);
		this.handleApplyUppercase = this.handleApplyUppercase.bind(this);
		this.handleApplyLowercase = this.handleApplyLowercase.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.stateUpdater.content) {
			this.setContentState(null, this.props.stateUpdater.content);
		}
	}

	/**
	 * Set Content state
	 * @param event
	 * @param content
	 */
	setContentState(event: React.FormEvent<HTMLDivElement> | null, content?: string): void {
		// Récupère le contenu depuis l'événement ou la valeur passée
		let updatedContent: string = event
			? (event.target as HTMLDivElement).innerHTML.trim()
			: (content || "").trim();

		const plainText: string = updatedContent.replace(/<\/?[^>]+(>|$)/g, "");
		const charCount: number = plainText.length;

		const selection = window.getSelection();
		const range = selection?.rangeCount ? selection.getRangeAt(0) : null;

		this.setState(
			(prevState) => ({
				content: updatedContent,
				charCount,
			}),
			() => {
				if (range && selection) {
					const newRange = document.createRange();
					newRange.setStart(range.startContainer, range.startOffset);
					newRange.collapse(true);

					selection.removeAllRanges();
					selection.addRange(newRange);
				}
				this.props.callbackState(this.state);
			}
		);
	}

	/**
	 * Applies a styling command (bold, italic, etc.).
	 * @param command - Command to execute (e.g., 'bold', 'italic', 'uppercase', etc.).
	 */
	applyStyle(command: string): void
	{
		document.execCommand(command, false, undefined);
	}

	/**
	 * Converts the selected text to uppercase or lowercase.
	 * @param caseType - 'uppercase' or 'lowercase'.
	 */
	applyCaseTransformation(caseType: 'uppercase' | 'lowercase'): void
	{
		const selection = window.getSelection();
		if (!selection || selection.toString().trim().length === 0) return;

		const selectedText = selection.toString();
		const transformedText =
			caseType === 'uppercase' ? selectedText.toUpperCase() : selectedText.toLowerCase();

		// Remplace la sélection par le texte transformé
		document.execCommand('insertText', false, transformedText);
	}

	/**
	 * handle text selected
	 * @param event
	 */
	handleTextSelect(event: React.MouseEvent<HTMLDivElement>): void
	{
		const selection = window.getSelection();
		if (selection && selection.toString().trim().length > 0) {
			selection.getRangeAt(0).getBoundingClientRect();
		}
	}

	/**
	 * Apply bold to selected text
	 */
	applyBoldCustom(): void {
		const selection = window.getSelection();
		if (!selection || selection.isCollapsed) return;

		const range = selection.getRangeAt(0);

		const startNode = range.startContainer.nodeType === Node.TEXT_NODE
			? (range.startContainer as Text).parentElement
			: (range.startContainer as HTMLElement);

		if (startNode && startNode.tagName === 'SPAN' && startNode.style.fontWeight === '800') {
			const parent = startNode.parentNode;
			while (startNode.firstChild) {
				parent!.insertBefore(startNode.firstChild, startNode);
			}
			parent!.removeChild(startNode);
			selection.removeAllRanges();
			return;
		}

		const selectedContent = range.extractContents();
		const span = document.createElement('span');
		span.style.fontWeight = '800';
		span.appendChild(selectedContent);
		range.insertNode(span);

		selection.removeAllRanges();

		if (this.contentEditableRef.current) {
			this.setContentState(null, this.contentEditableRef.current.innerHTML);
		}
	}

	/**
	 * Apply italic to selected text
	 */
	handleApplyItalic(): void
	{
		this.applyStyleToSelection('<span style="font-style: italic;">', '</i>');
	}

	/**
	 * Convert selected text to uppercase
	 */
	handleApplyUppercase(): void
	{
		this.applyCaseToSelection('uppercase');
	}

	/**
	 * Convert selected text to lowercase
	 */
	handleApplyLowercase(): void
	{
		this.applyCaseToSelection('lowercase');
	}

	/**
	 * Apply HTML tags to the selected text
	 * @param openTag
	 * @param closeTag
	 */
	private applyStyleToSelection(openTag: string, closeTag: string): void
	{
		const textarea = document.querySelector('textarea')!;
		const selectionStart = textarea.selectionStart;
		const selectionEnd = textarea.selectionEnd;
		const { content } = this.state;

		const updatedContent =
			content.substring(0, selectionStart) +
			`${ openTag }${ content.substring(selectionStart, selectionEnd) }${ closeTag }` +
			content.substring(selectionEnd);

		this.setState({ content: updatedContent });
	}

	/**
	 * Condense the content by cleaning up extra spaces and line breaks
	 * in the text nodes while preserving the HTML structure.
	 */
	HandleCondensedContent(): void {
		const { content } = this.state;

		let plainText = content.replace(/<\/?p>/gi, "");

		plainText = plainText
			.replace(/(\r\n|\n|\r)/gm, " ")
			.replace(/>\s+</g, "> <")
			.replace(/\s+/g, " ")
			.trim();

		const condensedContent = `<p>${plainText}</p>`;

		this.setState({ content: condensedContent }, () => {
			this.props.callbackState(this.state);
		});
	}

	/**
	 * Apply case transformation to the selected text
	 * @param caseType
	 */
	private applyCaseToSelection(caseType: 'uppercase' | 'lowercase'): void
	{
		const textarea = document.querySelector('textarea')!;
		const selectionStart = textarea.selectionStart;
		const selectionEnd = textarea.selectionEnd;
		const { content } = this.state;

		const selectedText = content.substring(selectionStart, selectionEnd);
		const transformedText =
			caseType === 'uppercase' ? selectedText.toUpperCase() : selectedText.toLowerCase();

		const updatedContent =
			content.substring(0, selectionStart) + transformedText + content.substring(selectionEnd);

		this.setState({ content: updatedContent });
	}
}
import { ReactElement } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { border } from '@/Enum/CssVariableEnum';

export default class CommercialGiftStatusEnum
{
	value: string;
	label: string;
	css: {
		backgroundColor: string,
		color: string,
		border?: string,
		icon?: ReactElement | null,
	};

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, border?: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static DELIVERY = new CommercialGiftStatusEnum(
		'DELIVERY',
		'Délivré',
		{
			backgroundColor: colors.success50,
			color: colors.success500,
			border: `1px solid ${ colors.success100 }`
		}
	);

	static TO_DELIVER = new CommercialGiftStatusEnum(
		'TO_DELIVER',
		'A livré',
		{
			backgroundColor: colors.error50,
			color: colors.error500,
			border: border(colors.error100)
		}
	);

	static roleOptions: CommercialGiftStatusEnum[] = [
		CommercialGiftStatusEnum.DELIVERY,
		CommercialGiftStatusEnum.TO_DELIVER,
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: CommercialGiftStatusEnum.DELIVERY.label, tag: CommercialGiftStatusEnum.DELIVERY.value },
		{ label: CommercialGiftStatusEnum.TO_DELIVER.label, tag: CommercialGiftStatusEnum.TO_DELIVER.value }
	];

	static findByValue(value: string): CommercialGiftStatusEnum | undefined
	{
		return this.roleOptions.find(option => option.value === value);
	}
}

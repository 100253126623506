import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeConfigProps, LegalNoticeConfigState } from './LegalNoticeConfig.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { LegalNoticeService } from '@/Service/LegalNoticeService';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: LegalNoticeConfigState = {
	shouldRender: false,
	client: null,
	departments: null,
	newspapers: null,
	selectedDepartment: null,
	selectedNewspaper: null,
	selectedNewspaperType: null,
	numberOfCopies: 0,
	legalNoticeStatus: null,
	publishDate: null,
	isLoading: true,
	isCheckNewspaperLoading: false,
	journalConfigurations: {},
};

export class LegalNoticeConfigService extends BaseComponentService<LegalNoticeConfigProps, LegalNoticeConfigState>
{
	private legalNoticeService: LegalNoticeService = new LegalNoticeService();
	private apiPublicService: ApiPublicService = new ApiPublicService();

	constructor()
	{
		super({} as LegalNoticeConfigProps, initState);

		// Bind
		this.setClientState = this.setClientState.bind(this);
		this.setShouldRenderState = this.setShouldRenderState.bind(this);
		this.setNumberOfCopiesState = this.setNumberOfCopiesState.bind(this);
		this.handleNumberOfCopiesChange = this.handleNumberOfCopiesChange.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		/* is DisplayBlock */
		this.setShouldRenderState(this.props.isDisplayBlock);

		if (this.props.stateUpdater.client) {
			this.handleProcessInit(this.props.stateUpdater!);
			await this.setDepartmentsState();
		}
	}

	handleProcessInit(state: Partial<LegalNoticeConfigState>): void
	{
		this.setClientState(state.client!,
			() =>
			{

				/* Set Newspaper Type */
				this.setState({
					legalNoticeStatus: state.legalNoticeStatus,
					selectedNewspaperType: NewspaperTypeEnum.findByValue(
						(state.selectedNewspaperType && state.selectedNewspaperType !== undefined)
							? state.selectedNewspaperType!
							: this.state.client?.options.newspaperType!
					)
				});

				/* Set Newspaper */
				this.setSelectedNewspaperState(
					(state.selectedNewspaper!)
						? state.selectedNewspaper
						: this.state.client?.options?.newspaper!
				);

				/* Set Department */
				this.setSelectedDepartmentState(
					(state.selectedDepartment!)
						? state.selectedDepartment
						: this.state.client?.options?.department!
				);

				/* Set NumberOfCopies */
				this.setNumberOfCopiesState(
					(state.numberOfCopies!)
						? state.numberOfCopies
						: this.state.client?.options?.numberOfCopies!
				);

				/* Set publishDate */
				if (state.publishDate) {
					this.setPublishDateState(state.publishDate);
				}
			}
		);
	}

	/**
	 * set department State
	 */
	async setDepartmentsState(): Promise<void>
	{
		try {
			const departments = await this.legalNoticeService.departmentList();

			this.setState({ departments },
				() =>
				{
					this.setNewspapersState(this.state);
				}
			);

		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }`, error);

			this.props.flashMessageContext?.flashMessage(
				'Une erreur est survenue',
				'La liste des département n’a pas pu être chargée',
				'error',
			);
		}
	}

	/**
	 * Set Newspaper State
	 */
	async setNewspapersState(state: Partial<LegalNoticeConfigState>): Promise<void>
	{
		try {
			const newspapers: NewspaperInterface[] = await this.legalNoticeService.newspaperList(
				state.client?.company?.id!,
				state?.selectedDepartment?.id!,
				state.selectedNewspaperType!,
			);

			this.setState({ newspapers, isLoading: false });

		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }`, error);

			this.props.flashMessageContext?.flashMessage(
				'Une erreur est survenue',
				'La liste des journaux n’a pas pu être chargée',
				'error',
			);
		}
	}

	/**
	 * Set Client State
	 * @param client
	 * @param callback
	 */
	setClientState(client: ClientInterface | null, callback?: () => void): void
	{
		this.setState(
			{ client },
			callback
		);
	}

	/**
	 * Handle Selected NewspaperType State
	 * @param selectedNewspaperType
	 */
	setSelectedNewspaperTypeState(selectedNewspaperType: NewspaperTypeEnum): void
	{
		this.setState({
				isLoading: true,
				selectedNewspaperType,
			}, async () =>
			{
				if (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin()) {
					await this.setConfigWithInternalNewspaper(this.state.selectedNewspaperType!);
				} else {
					await this.setUserConfigWithInternalNewspaper(this.state.selectedNewspaperType!);
				}
			}
		);
	}

	/**
	 * Handle Selected Department State
	 * @param selectedDepartment
	 */
	setSelectedDepartmentState(selectedDepartment: Partial<DepartmentInterface>): void
	{
		this.setState({
			selectedDepartment,
		}, () => this.setNewspapersState(this.state));
	}

	/**
	 * Handle Selected Newspaper state
	 * @param selectedNewspaper
	 */
	async setSelectedNewspaperState(selectedNewspaper: Partial<NewspaperInterface> | null): Promise<void>
	{
		this.setState({
			journalConfigurations: {},
			selectedNewspaper,
		}, () =>
		{
			if (this.state.selectedNewspaper) {
				this.checkJournalConfiguration(selectedNewspaper!);
			}
			this.setState({ isLoading: false });
		});
	}

	/**
	 * Set Number of copies state
	 * @param numberOfCopies
	 */
	setNumberOfCopiesState(numberOfCopies: number): void
	{
		this.setState((prevState) => ({
			numberOfCopies: numberOfCopies !== prevState.numberOfCopies ? numberOfCopies : prevState.numberOfCopies,
		}), () => this.props.callbackState(this.state));
	}

	/**
	 * Handle Change on Number Of copies input
	 * @param event
	 */
	handleNumberOfCopiesChange(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const value: string = event.target.value;
		this.setNumberOfCopiesState(Number(value));
	};

	/**
	 * Is block should render
	 * @param isRender
	 */
	setShouldRenderState(isRender: boolean): void
	{
		this.setState({
			shouldRender: isRender
		});
	}

	/**
	 * Check the configuration of the selected newspaper and return specific error messages.
	 * @param selectedNewspaper - The newspaper selected by the user.
	 * @returns A string with error messages or null if there are no errors.
	 */
	async checkJournalConfiguration(selectedNewspaper: Partial<NewspaperInterface>): Promise<void>
	{
		if (selectedNewspaper.fromSource === 'REGIEPRO') {
			// Initialize an object to hold configuration errors
			const configurations: { [key: string]: string } = {};

			// Check if the newspaper does not allow logos
			if (!selectedNewspaper.isAllowLogo) {
				configurations['isAllowLogo'] = 'Ce journal n‘accepte pas les logos.';
			}

			// Check if the newspaper does not allow headers
			if (!selectedNewspaper.isAllowHeader) {
				configurations['isAllowHeader'] = 'Ce journal n‘accepte pas les en-têtes.';
			}

			// Check if the newspaper bills for logos
			if (selectedNewspaper.isAllowLogo && selectedNewspaper.isBillingLogo) {
				configurations['isBillingLogo'] = `L'ajout d'un logo dans ce journal entraîne un coût supplémentaire de ${ selectedNewspaper.billingLogoPrice }€.`;
			}

			// Update the state with the configurations
			this.setState({
				journalConfigurations: configurations,
			});
		} else if (selectedNewspaper.fromSource === 'LEGAL2DIGITAL') {
			this.setState({ isCheckNewspaperLoading: true });

			const isNewspaper = await this.checkSupplierL2DNewspaper(
				LocalStorageService.getAuthCompanyId(),
				this.state.selectedDepartment?.code as string,
				selectedNewspaper?.sourceId as string
			);

			this.setState({ isCheckNewspaperLoading: false });

			if (!isNewspaper) {
				// Initialize an object to hold configuration errors
				const configurations: { [key: string]: string } = {};
				configurations['notAllow'] = 'Journal non habilité dans ce departement';

				// Update the state with the configurations
				this.setState({
					journalConfigurations: configurations,
				});
			}
		}
	}

	/**
	 * PublishDate
	 * @param publishDate
	 */
	setPublishDateState(publishDate: Date): void
	{
		this.setState({
			publishDate: publishDate,
		}, () => this.props.callbackState(this.state, false));
	}

	async setConfigWithInternalNewspaper(selectedNewspaperType: NewspaperTypeEnum): Promise<void>
	{
		// reset Client Option or force internal Newspaper
		if (NewspaperTypeEnum.findByValue(this.props.stateUpdater.client?.options?.newspaperType!) === selectedNewspaperType) {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);
			this.setSelectedNewspaperState(this.props.stateUpdater.client?.options?.newspaper!);
		} else if (this.props.stateUpdater.client?.company.id !== 4) {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);

			if (this.props.authCompanyContext?.authCompany?.supplier?.tag === 'REGIEPRO') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? 47 : 98;
				const selectedNewspaper = await this.apiPublicService.getNewspaper(newspaperId);
				this.setSelectedNewspaperState(selectedNewspaper);
			} else if (this.props.authCompanyContext?.authCompany?.supplier?.tag === 'LEGAL2DIGITAL') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? 621 : 629;
				const selectedNewspaper = await this.apiPublicService.getNewspaper(newspaperId);
				this.setSelectedNewspaperState(selectedNewspaper);
			}

		} else {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);
			if (this.props.authCompanyContext?.authCompany?.supplier?.tag === 'REGIEPRO') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? null : 76;
				const selectedNewspaper = (newspaperId) ? await this.apiPublicService.getNewspaper(newspaperId) : null;
				this.setSelectedNewspaperState(selectedNewspaper);
			} else if (this.props.authCompanyContext?.authCompany?.supplier?.tag === 'LEGAL2DIGITAL') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? null : 447;
				const selectedNewspaper = (newspaperId) ? await this.apiPublicService.getNewspaper(newspaperId) : null;
				this.setSelectedNewspaperState(selectedNewspaper);
			}
		}
		;
	}

	async setUserConfigWithInternalNewspaper(selectedNewspaperType: NewspaperTypeEnum): Promise<void>
	{
		const supplierTag = this.props.authClientContext?.authClient?.company.supplier?.tag;

		// reset Client Option or force internal Newspaper
		if (NewspaperTypeEnum.findByValue(this.props.stateUpdater.client?.options?.newspaperType!) === selectedNewspaperType) {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);
			this.setSelectedNewspaperState(this.props.stateUpdater.client?.options?.newspaper!);
		} else if (this.props.stateUpdater.client?.company.id !== 4) {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);

			if (supplierTag === 'REGIEPRO') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? 47 : 98;
				const selectedNewspaper = await this.apiPublicService.getNewspaper(newspaperId);
				this.setSelectedNewspaperState(selectedNewspaper);
			} else if (supplierTag === 'LEGAL2DIGITAL') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? 621 : 629;
				const selectedNewspaper = await this.apiPublicService.getNewspaper(newspaperId);
				this.setSelectedNewspaperState(selectedNewspaper);
			}

		} else {
			this.setSelectedDepartmentState(this.props.stateUpdater.client?.options?.department!);
			if (supplierTag === 'REGIEPRO') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? null : 76;
				const selectedNewspaper = (newspaperId) ? await this.apiPublicService.getNewspaper(newspaperId) : null;
				this.setSelectedNewspaperState(selectedNewspaper);
			} else if (supplierTag === 'LEGAL2DIGITAL') {
				const newspaperId = (selectedNewspaperType === NewspaperTypeEnum.WEB) ? null : 447;
				const selectedNewspaper = (newspaperId) ? await this.apiPublicService.getNewspaper(newspaperId) : null;
				this.setSelectedNewspaperState(selectedNewspaper);
			}
		}
		;
	}

	/**
	 * Is External or Internal Newspaper
	 */
	isExternalDepartment(): boolean
	{
		if (this.state.client!.company.id !== 4 && this.state.selectedDepartment?.code !== '34') {
			return true;
		} else if (this.state.client!.company.id === 4 && this.state.selectedDepartment?.code !== '11') {
			return true;
		}

		return false;
	}

	private async checkSupplierL2DNewspaper(companyId: number, departmentCode: string, sourceId:string): Promise<boolean>
	{
		const newspaperList = await this.apiPublicService.checkListNewspaperSupplierL2D(companyId, departmentCode);
		return newspaperList.results.some((newspaper: { id: string }) => newspaper.id === sourceId);
	}
}
import { ConsumerFormInterface } from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm.interface';
import { AddressFormModel } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.model';
import { AddressFormInterface } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.interface';

export class ConsumerFormModel implements ConsumerFormInterface {
	siren: string = '';
	name: string = '';
	apeCode: string | null = null;
	capital: string | null = null;
	legalStatus: string = '';
	rcs: string = '';
	extSellsyId?: number | null = null;
	address: AddressFormInterface = AddressFormModel.prepare({});

	/**
	 * Static method to create a new instance of the model
	 * @param data - Input data
	 * @returns A new instance of ConsumerFormModel
	 */
	static prepare(data: Partial<ConsumerFormInterface>): ConsumerFormModel {
		const model = new ConsumerFormModel();

		model.siren = data.siren ?? '';
		model.name = data.name ?? '';
		model.apeCode = data.apeCode ?? null;
		model.capital = data.capital ?? null;
		model.legalStatus = data.legalStatus ?? '';
		model.rcs = data.rcs ?? '';
		model.extSellsyId = data.extSellsyId ?? null;
		model.address = AddressFormModel.prepare(data.address ?? {});

		return model;
	}
}

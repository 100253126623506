import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiPappersService
{
	// Properties
	private BASE_URL: string = 'https://api.pappers.fr/v2';
	private API_SECRET_TOKEN: string = '750a8fe447dc119cbf95c8d120c6c36ec194efe093ac7a0c';

	async getCompanyBySiren(siren: string): Promise<any>
	{
		try {
			const url: string = `${ this.BASE_URL }/entreprise?siren=${ siren }`;

			const response: Response = await fetch(url, {
				method: HttpVerbEnum.GET,
				headers: {
					'api-key': this.API_SECRET_TOKEN,
				},
			});

			return await response.json();

		} catch (error: any) {
			console.error(`Error occurred in ${ this.constructor.name }:`, error);
			throw error; // Relancer l'erreur pour la gestion en amont
		}
	}
}

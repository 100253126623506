import React, { Component, ReactElement } from 'react';
import { SelectorCategoriesProps, SelectorCategoriesState } from './SelectorCategories.interface';
import { SelectorCategoriesService } from './SelectorCategories.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Select from '@/Modules/App/Components/Library/Select/Select';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import {
	SelectorCategoriesStyles
} from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories.styles';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class SelectorCategories extends Component<SelectorCategoriesProps, SelectorCategoriesState>
{
	private selectorCategoriesService = new SelectorCategoriesService();

	constructor(props: SelectorCategoriesProps)
	{
		super(props);

		// Config service
		this.selectorCategoriesService.setProps(this.props);
		this.selectorCategoriesService.subscribeState(this);

		// State
		this.state = this.selectorCategoriesService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.selectorCategoriesService.init();
	}

	componentWillUnmount(): void
	{
		this.selectorCategoriesService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { isLoading } = this.state;

		if (isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<div style={ SelectorCategoriesStyles.container(this.props.isDisplayBlock!) }>
				<div style={ CardStyles.simpleCardGrayContainer }>
					<Select
						label={ 'Catégorie' }
						textHelp={
							(!UserConnectedService.isAdmin() && !UserConnectedService.isSuperAdmin() && window.location.pathname !== '/legal-notices/free-writing/create')
								? (
									<span>
										Pour information, la version guidée ne vous permet pas d’accéder à toutes les catégories.
										Vous pouvez toutefois passer en mode&nbsp;
										<a href="/legal-notices/free-writing/create" style={ { textDecoration: 'underline' } }>
          					rédaction libre
										</a>.
									</span>
								) : ''
						}
						listOptions={ this.state.categories }
						onSelect={ (category: FormBuilderCategoryInterface) =>
							this.selectorCategoriesService.setSelectedCategory(category)
						}
						displayKey={ 'label' }
						value={ this.state?.selectedCategory?.label! || '' }
						isRequired={ true }
					/>

					<Select
						key={ this.state.selectedCategory?.label }
						label={ 'Sous-catégorie' }
						listOptions={ this.state.childCategories }
						onSelect={ (childCategory) =>
							this.selectorCategoriesService.setSelectedChildCategory(childCategory)
						}
						value={ this.state.selectedChildCategory?.label || '' }
						isRequired={ true }
						isDisabled={ !this.state.selectedCategory }
					/>

					{ this.state.selectedChildCategory?.label?.includes!('Cession de fonds') && (
						<div>
							<div style={ { display: 'flex', gap: 5, alignItems: 'center', marginBottom: 4, marginTop: 10 } }>
								<input
									type="checkbox"
									checked={ this.state.isBodacc }
									onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
										this.selectorCategoriesService.setIsBodaccState(event.target.checked)
									}
								/>
								<Label label={ 'Bodacc' } isRequired={ false }/>
							</div>
							<div style={ { ...FontStyles.textHelp, marginLeft: 18 } }>
								Parution au Bulletin Officiel des Annonces Civiles et Commerciales.
							</div>
						</div>
					) }
				</div>
			</div>
		);
	}
}

export default withGlobalContext(SelectorCategories);
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeControlsProps, LegalNoticeControlsState } from './LegalNoticeControls.interface';
import { LegalNoticeAdminService } from '@/Modules/LegalNotice/Admin/Show/LegalNoticeAdmin.service';
import { LegalNoticeFlagsInterface } from '@/Modules/LegalNotice/Common/LegalNotice.interface';
import { isPast, parseISO } from 'date-fns';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { formatDateForInputDate } from '@/Utils/DateUtils';
import {
	LegalNoticeHandleEstimateState
} from '@/Modules/LegalNotice/ComponentsOld/LegalNoticeHandleEstimate/LegalNoticeHandleEstimate.interface';

const initState: LegalNoticeControlsState = {
	legalNotice: {} as LegalNoticeInterface,
	flags: {} as LegalNoticeFlagsInterface,
	isLoadingDeleted: false,
	isDeleted: false,
	isDuplicate: false,
	isQuoteResponse: false,
	isLoadingDocument: false,
	isLoading: false,
	publishDate: '',
};

export class LegalNoticeControlsService extends BaseComponentService<LegalNoticeControlsProps, LegalNoticeControlsState>
{
	private legalNoticeAdminService: LegalNoticeAdminService = new LegalNoticeAdminService();
	private apiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	private apiAdminLegalNoticeFilesService = new ApiAdminLegalNoticeFileService();
	private legalNoticePriceService: LegalNoticePriceService;

	constructor()
	{
		super({} as LegalNoticeControlsProps, initState);

		// Services
		this.legalNoticePriceService = new LegalNoticePriceService(UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin());
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 * @public
	 */
	async init(): Promise<void>
	{
		// Set Publish Date
		this.setState({
			publishDate: formatDateForInputDate(this.props.legalNotice.publishDate),
			legalNotice: this.props.legalNotice,
		});

		this.handleFlagsState(this.props.legalNotice);
	}

	/**
	 * Set Flags state
	 * @return void
	 * @public
	 */
	handleFlagsState(legalNotice: LegalNoticeInterface): void
	{
		this.setState({
			flags: this.getLegalNoticeFlags(legalNotice)
		});
	}

	/**
	 * Duplicate LegalNotice
	 */
	async onDuplicate(): Promise<void>
	{
		try {
			if (this.props.legalNotice) {

				const duplicateLegalNotice = await this.apiAdminLegalNoticeService.duplicate(this.props.legalNotice.id);

				if (duplicateLegalNotice?.id) {
					// Redirection simple avec window.location.href
					window.location.href = `/admin/legal-notices/${duplicateLegalNotice.id}`;
				}

				this.setState({ isDuplicate: true }, () => {
					this.props.getComponentState(this.state);
				});
			}
		} catch (error: any) {
			console.error(`Error occurred on ${ this.constructor.name }: duplicate LegalNotice`, error);
		}
	}

	/**
	 * Handle Delete LegalNotice
	 */
	async onDeleted(legalNotice: LegalNoticeInterface): Promise<void> {
		try {
			const userConfirmed = window.confirm(
				`Êtes-vous sûr de vouloir supprimer l'annonce ${legalNotice.tag} ? Cette action est irréversible.`
			);

			if (!userConfirmed) {
				return;
			}

			this.setState({ isLoadingDeleted: true }, () => this.props.getComponentState(this.state));

			const nbCharacters = await this.legalNoticePriceService.nbCharacters(
				legalNotice.publishDepartment,
				legalNotice.formBuilderCategory,
				legalNotice,
				(legalNotice.option.isHeader)
					? this.legalNoticePriceService.countTempHeaderContent(legalNotice.consumer)
					: 0
			);

			const legalNoticeDeleted = await this.apiAdminLegalNoticeService.delete(legalNotice.id, nbCharacters);

			if (legalNoticeDeleted.statusCode !== 200) {
				throw new Error(legalNoticeDeleted.message);
			} else {
				this.setState({ isLoadingDeleted: false, isDeleted: true },
					() => this.props.getComponentState(this.state)
				);

				this.props.flashMessageContext?.flashMessage(
					'Succès',
					`L'annonce ${legalNotice.tag} a été supprimée avec succès.`,
					'success'
				);
			}
		} catch (error) {
			console.error(`Error occurred on ${this.constructor.name}: Delete LegalNotice`, error);
			this.setState({ isLoadingDeleted: false, isDeleted: true }, () => this.props.getComponentState(this.state));

			this.props.flashMessageContext?.flashMessage(
				'Erreur',
				'Une erreur est survenue lors de la suppression de l’annonce',
				'error'
			);
		}
	}

	async handleRespondQuote(childState: LegalNoticeHandleEstimateState): Promise<void>
	{
		this.getLegalNotice();

		if (childState.respond === 'ACCEPTED') {
			await this.callApiFiles(childState.legalNotice, childState.nbCharacters);
		} else if (childState.respond === 'REFUSED') {
			await this.onDeleted(this.props.legalNotice);
		}
	}

	private async getLegalNotice(): Promise<void>
	{
		try {
			const legalNotice = await this.apiAdminLegalNoticeService.show(this.props.legalNotice.id);

			if (legalNotice) {
				this.setState({
					legalNotice: legalNotice,
					flags: this.getLegalNoticeFlags(legalNotice)!
				}, () => this.props.getComponentState!(this.state));
			}

		} catch (error: any) {
			console.error('ERROR Occurred on get LegalNotice', error);
		}
	}

	/**
	 * Get LegalNotice Flags to handle rights controls
	 * @return LegalNoticeFlagsInterface
	 * @private
	 */
	private getLegalNoticeFlags(legalNotice: LegalNoticeInterface): LegalNoticeFlagsInterface
	{
		const askUserValidate = typeof this.props.legalNotice.status === 'string'
			? PublishStateEnum.findByValue(this.props.legalNotice.status)
			: typeof this.props.legalNotice.status;

		// Verify if publishDate exists before using parseISO
		const isPublicationDatePast = legalNotice?.publishDate ? isPast(parseISO(legalNotice.publishDate)) : false;
		const isSupplier = !!legalNotice?.extSupplierUid!;
		const isIntern = legalNotice?.extSupplierUid?.source === 'INTERN';
		const isDraft = legalNotice?.status?.toString()! === PublishStateEnum.DRAFT.value;
		const isCancelled = legalNotice?.status?.toString()! === PublishStateEnum.CANCEL.value;
		const isQuote = legalNotice?.status?.toString()! === PublishStateEnum.QUOTE.value;
		const isQuotePending = legalNotice?.quoteStatus?.toString()! === QuoteStatusEnum.PENDING.value;
		const isQuoteAnswerRefused = legalNotice?.quoteStatus?.toString()! === QuoteStatusEnum.REFUSED.value;
		const isQuoteAnswerAccepted = legalNotice?.quoteStatus?.toString()! === QuoteStatusEnum.ACCEPTED.value;
		const isPaid = legalNotice?.paymentStatus?.toString()! === PaymentStateEnum.PAID.value;
		const isPublished = legalNotice?.status?.toString()! === PublishStateEnum.PUBLISH.value;
		const isError = legalNotice?.status?.toString()! === PublishStateEnum.ERROR.value;

		const canEdit = !isPublished && !isError && (isDraft || isQuote || (!isCancelled && !isPublicationDatePast) || askUserValidate === PublishStateEnum.ASK_USER_VALIDATE);

		return {
			isPublicationDatePast,
			isSupplier,
			isIntern,
			isDraft,
			isCancelled,
			isQuote,
			isQuotePending,
			isQuoteAnswerRefused,
			isQuoteAnswerAccepted,
			isPaid,
			isPublished,
			isError,
			canEdit,
		} as const;
	}

	/**
	 * Handle Files service
	 * @param legalNotice
	 * @param nbCharacters
	 * @private
	 */
	private async callApiFiles(legalNotice: LegalNoticeInterface, nbCharacters: number): Promise<void>
	{
		const prepareDataForCertificate = {
			legalNotice: {
				title: legalNotice.title,
				content: legalNotice.content,
				signature: legalNotice.signature,
				price: legalNotice.price,
			},
		};

		this.setState({ isLoadingDocument: true }, () => this.props.getComponentState(this.state));

		try {

			try {
				// CreateOld billing file and handle result
				await this.apiAdminLegalNoticeFilesService.createBillingFile(
					this.props.legalNotice?.id as number,
					{ legalNotice: { nbCharacters } }
				);
				this.props.flashMessageContext?.flashMessage(
					`Facture créée avec succès`,
					`Votre facture pour l'annonce légale ${ legalNotice.tag } a été créée avec succès.`,
					'success'
				);
			} catch (error) {
				console.error('Erreur lors de la création de l’attestation :', error);
				this.props.flashMessageContext?.flashMessage(
					`Erreur lors de la création de la facture`,
					`Impossible de créer la facture pour l'annonce légale ${ legalNotice.tag }.`,
					'error'
				);
			}

			// If discount, create credit note file
			if (legalNotice.discount > 0) {
				try {
					await this.apiAdminLegalNoticeFilesService.createCreditNoteFile(
						legalNotice.id as number,
						prepareDataForCertificate
					);
					this.props.flashMessageContext?.flashMessage(
						`Avoir créé avec succès`,
						`Un avoir pour l'annonce légale ${ legalNotice.tag } a été créé.`,
						'success'
					);
				} catch (error) {
					console.error('Erreur lors de la création de l’avoir :', error);
					this.props.flashMessageContext?.flashMessage(
						`Erreur lors de la création de l'avoir`,
						`Impossible de créer un avoir pour l'annonce légale ${ legalNotice.tag }.`,
						'error'
					);
				}
			}

			// CreateOld certificate file
			try {
				await this.apiAdminLegalNoticeFilesService.createCertificateFile(
					legalNotice.id as number,
					prepareDataForCertificate
				);
				this.props.flashMessageContext?.flashMessage(
					`Attestation créée avec succès`,
					`L'attestation pour l'annonce légale ${ legalNotice.tag } a été créée.`,
					'success'
				);
			} catch (error) {
				console.error('Erreur lors de la création de l’attestation :', error);
				this.props.flashMessageContext?.flashMessage(
					`Erreur lors de la création de l'attestation`,
					`Impossible de créer l'attestation pour l'annonce légale ${ legalNotice.tag }.`,
					'error'
				);
			}
		} catch (error) {
			console.error('Erreur générale lors de la gestion des fichiers :', error);
			this.props.flashMessageContext?.flashMessage(
				`Erreur générale`,
				`Une erreur est survenue lors de la gestion des fichiers pour l'annonce légale ${ legalNotice.tag }.`,
				'error'
			);
		} finally {
			// Set loading to false
			this.setState(
				{
					isLoadingDocument: false,
					isQuoteResponse: true,
				},
				() => this.props.getComponentState(this.state)
			);
		}
	}

}
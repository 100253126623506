import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientGiftInterface } from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/ClientGift/ClientGiftAdmin.interface';

export class ApiAdminClientGiftService extends BaseService
{
  clientGiftUrl: string = `${ BaseService.API_URL }/v1/admin/clients-gifts`;

  async list(clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientGiftUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(clientId: number, clientGiftData: ClientGiftInterface): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientGiftUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientGiftData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(clientId: number, clientGiftId: number, clientGiftData: ClientGiftInterface): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientGiftUrl }/${ clientId }/${ clientGiftId }`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientGiftData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editStatus(clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientGiftUrl }-status/${ clientId }`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async delete(clientId: number, clientGiftId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientGiftUrl }/${ clientId }/${ clientGiftId }`,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
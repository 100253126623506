import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { CommercialAdminProps, CommercialAdminState } from '@/Modules/Commercial/Admin/Page/CommercialAdmin.interface';

const initState: CommercialAdminState = {
}

export class CommercialAdminService extends BaseComponentService<CommercialAdminProps, CommercialAdminState>
{
  constructor()
  {
    super({} as CommercialAdminProps, initState);
  }

  async init(): Promise<void>
  {}
}
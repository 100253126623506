import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState } from './LegalNoticeHandleEstimate.interface';
import { formatDateForInputDate } from '@/Utils/DateUtils';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { LegalNoticeHelper } from '@/Modules/LegalNotice/Common/LegalNoticeHelper';

const initState: LegalNoticeHandleEstimateState = {
	isLoading: true,
	publishDate: '',
	respond: null,
	legalNotice: {} as LegalNoticeInterface,
	nbCharacters: 0,
};

export class LegalNoticeHandleEstimateService extends BaseComponentService<LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState>
{
	private legalNoticePriceService: LegalNoticePriceService = new LegalNoticePriceService(true);
	private apiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeHandleEstimateProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({
			publishDate: formatDateForInputDate(this.props?.legalNotice?.publishDate!),
			isLoading: false,
		});
	}

	/**
	 * On Change publish Date
	 * @param publishDate
	 */
	onChangePublishDate(publishDate?: Date): void
	{
		const newDate: string = formatDateForInputDate(publishDate!);
		this.setState({ publishDate: newDate });
	}

	/**
	 * Handle Respond Quote
	 */
	async onValidateQuote(): Promise<void>
	{
		const { legalNotice } = this.props;

		if (!legalNotice) {
			console.error('No legal notice provided.');
			return;
		}

		// Handle Loading
		this.setState({ isLoading: true });
		this.props.modalContext?.isOpen(true);
		this.props.modalContext?.isLoadingOverlay(true);

		const hasPublishDateChanged: boolean = formatDateForInputDate(legalNotice.publishDate) !== this.state.publishDate;

		// Step 1: Update the legal notice if the publishing date has changed
		await this.editLegalNoticeOnRespondQuote(
				legalNotice,
				{
					publishDate: (hasPublishDateChanged) ? this.state.publishDate : this.props.legalNotice.publishDate,
					status: PublishStateEnum.PLANNED,
				}
			)
				.then(async (response) => {
					if (!response) {
						console.error('Failed to update the legal notice.');
						return;
					}

					// Step 2: Calculate the number of characters
					const nbCharacters: number = await this.calculateNbCharacters(response);

					// Step 3: Respond to the quote
					await this.apiAdminLegalNoticeService.respondQuote(response.id, {
						respond: 'ACCEPTED',
						legalNotice: { nbCharacters },
					});

					// Set LegalNotice State
					this.setState({
						legalNotice: response,
						nbCharacters,
						respond: 'ACCEPTED',
						isLoading: false,
					}, () => this.props.getComponentState?.(this.state));
				})
				.catch((error: any) => {
					console.error('Error handling quote response:', error);
					let errorMessage = 'Une erreur est survenue. Veuillez réessayer.';

					if (error instanceof Error) {
						errorMessage = error.message;
					}

					if (typeof errorMessage === 'string' && errorMessage.includes('[REGIE-PRO]')) {
						const parts = errorMessage.split(':');
						errorMessage = parts[parts.length - 1].trim();
					}

					this.props.flashMessageContext?.flashMessage(
						'Une erreur est survenue.',
						errorMessage,
						'error'
					);
				})
				.finally(() => {
					this.props.modalContext?.isLoadingOverlay(false);
					this.props.modalContext?.isOpen(false);
				})
		;
	}

	/**
	 * Handle refused estimate
	 */
	async onRefusedQuote(): Promise<void>
	{
		try {
			// Handle Loading
			this.setState({ isLoading: true });
			this.props.modalContext?.isOpen(true);
			this.props.modalContext?.isLoadingOverlay(true);

			await this.apiAdminLegalNoticeService.respondQuote(
				this.props.legalNotice?.id as number,
				{ respond: 'REFUSED', legalNotice: { nbCharacters: 0 } }
			);

		} catch (error: any) {
			console.error('Error occurred on Refused Quote :', error);
			// Handle Loading
			this.setState({ isLoading: false });
			this.props.modalContext?.isOpen(true);
			this.props.modalContext?.isLoadingOverlay(false);
		} finally {
			this.setState({
				respond: 'REFUSED',
			}, () => this.props.getComponentState!(this.state));
			this.props.modalContext?.isLoadingOverlay(false);
			this.props.modalContext?.isOpen(false);
		}
	}

	getTodayDate(): string
	{
		const today = new Date();
		return today.toISOString().split('T')[0];
	}

	/**
	 * Updates the legal notice with a new publishing date.
	 */
	private async editLegalNoticeOnRespondQuote(legalNotice: any, legalNoticeData: Partial<LegalNoticeInterface>): Promise<any>
	{
		try {
			const updatedData = LegalNoticeHelper.prepareLegalNoticeForEdit(
				legalNotice,
				legalNoticeData,
				this.state.nbCharacters
			);

			const updatedLegalNotice = await this.apiAdminLegalNoticeService.edit(legalNotice.id, updatedData);

			if (updatedLegalNotice.errorMessage) {
				throw new Error(`Error occurred while editing legal notice: ${ updatedLegalNotice.errorMessage }`);
			}

			return updatedLegalNotice;
		} catch (error) {
			console.error('Failed to update legal notice:', error);
			throw new Error(`Error occurred while editing legal notice: ${ error }`);
		}
	}

	/**
	 * Calculates the number of characters for the legal notice.
	 */
	private async calculateNbCharacters(legalNotice: any): Promise<number>
	{
		const isHeaderIncluded = legalNotice.option.isHeader;
		const headerContentLength = isHeaderIncluded
			? this.legalNoticePriceService.countTempHeaderContent(legalNotice.consumer)
			: 0;

		try {
			return await this.legalNoticePriceService.nbCharacters(
				legalNotice.publishDepartment,
				legalNotice.formBuilderCategory,
				legalNotice,
				headerContentLength
			);
		} catch (error) {
			console.error('Failed to calculate number of characters:', error);
			throw error;
		}
	}

	/**
	 * Check if publishDate is passed
	 * @return boolean
	 */
	public isPublishDateExceeded(): boolean
	{
		if (!this.state.publishDate) {
			return false;
		}

		const publishDate = new Date(this.state.publishDate);

		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		publishDate.setHours(0, 0, 0, 0);

		return publishDate <= currentDate;
	}

}
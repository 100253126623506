import React, { Component, ReactElement } from 'react';
import { AssociateCollaboratorProps, AssociateCollaboratorState } from './AssociateCollaborator.interface';
import { AssociateCollaboratorService } from './AssociateCollaborator.service';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { AssociateUserStyles } from '@/Modules/User/Components/AssociateUser/AssociateUser.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

class AssociateCollaborator extends Component<AssociateCollaboratorProps, AssociateCollaboratorState>
{
	private associateCollaboratorService = new AssociateCollaboratorService();

	constructor(props: AssociateCollaboratorProps)
	{
		super(props);

		// Config service
		this.associateCollaboratorService.setProps(this.props);
		this.associateCollaboratorService.subscribeState(this);

		// State
		this.state = this.associateCollaboratorService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.associateCollaboratorService.init();
	}

	componentWillUnmount(): void
	{
		this.associateCollaboratorService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { collaborators, selectedCollaborator } = this.state;

		return (
			<>
				{ !selectedCollaborator &&
          <Select
            label={ 'Associer un collaborateur' }
            listOptions={ collaborators }
            disabledResultKey={ 'extSellsyId' }
            disabledResultText={ 'Collaborateur non associé à sellsy' }
            isRequired={ true }
            onSelect={ (collaborator) => this.associateCollaboratorService.setCollaborator(collaborator) }
            displayKey={ 'email' }
            isDisabled={ this.props.isDisabled }
          />
				}

				{ selectedCollaborator &&
          <div style={ AssociateUserStyles.selectedUserContainer }>
            <div>
              <div style={ AssociateUserStyles.label }>Collaborateur associé</div>
              <div style={ FontStyles.textHelp }>
                Ce collaborateur sera lié à cette annonce légale.
              </div>
            </div>
            <div style={ AssociateUserStyles.selectedUser }>
              <div style={ AssociateUserStyles.searchList }>
                <div style={ AssociateUserStyles.left }>
                  <span
                    style={ AssociateUserStyles.lastName }>{ selectedCollaborator?.user?.lastname.toUpperCase() }</span>
                  <span>{ selectedCollaborator?.user?.firstname.toLowerCase() }</span>
                </div>
                <div style={ AssociateUserStyles.right }>
                  <span> { selectedCollaborator.email }</span>
                </div>
              </div>
              <div style={ { display: 'inline-flex', marginLeft: 15 } }>
                <Button
                  variant={ 'smallDark' }
                  label={ 'Annuler' }
                  onClick={ () => this.associateCollaboratorService.setCollaborator(null) }
                />
              </div>
            </div>
          </div>
				}

				{ selectedCollaborator && !selectedCollaborator.extSellsyId &&
          <div style={ {
						backgroundColor: colors.error50,
						...borderStyles,
						borderColor: colors.error100,
						borderRadius: 6,
						color: colors.error600,
						padding: '5px 10px',
						height: 38,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					} }>
            <span>Merci de lier ce collaborateur à Sellsy pour continuer</span>
            <Button
              label={ 'Voir la fiche' }
              variant={ 'small' }
              onClick={ () => this.props.navigation(`/admin/clients/${ this.props.client.id }`) }
            />
          </div>
				}
			</>
		);
	}
}

export default withGlobalContext(AssociateCollaborator);
import React, { Component, ReactElement } from 'react';
import { SearchClientProps, SearchClientState } from './SearchClient.interface';
import { SearchClientService } from './SearchClient.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Search from '@/Modules/App/Components/Library/Search/Search';
import { AssociateUserStyles } from '@/Modules/User/Components/AssociateUser/AssociateUser.styles';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { SellsyCompanyStyles } from '@/Modules/App/Components/Sellsy/SellsyCompany/SellsyCompany.styles';
import AssociateSellsyCompany from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';

class SearchClient extends Component<SearchClientProps, SearchClientState>
{
	private searchClientService = new SearchClientService();
	private apiAdminClientService = new ApiAdminClientService();
	constructor(props: SearchClientProps)
	{
		super(props);

		// Config service
		this.searchClientService.setProps(this.props);
		this.searchClientService.subscribeState(this);

		// State
		this.state = this.searchClientService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.searchClientService.init();
	}

	componentWillUnmount(): void
	{
		this.searchClientService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedClient, isLoading } = this.state;

		if (isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div>
					{ !selectedClient &&
            <Search
              inputName={ 'search' }
              css={ {
								searchList: {
									top: 55,
								}
							} }
              inputLabel={ 'Associer un client' }
              searchService={ (params: any) => this.apiAdminClientService.search(params) }
              withResultList={ true }
              onSelectElement={ (client) => this.searchClientService.handleSelectedClient(client) }
              renderResult={ (result) =>
								<>
									<div style={ AssociateUserStyles.searchList }>
										<div style={ AssociateUserStyles.left }>
											<span>{ result.name }</span>
										</div>
									</div>
								</>
							}
            />
					}

					{ selectedClient &&
            <div style={ AssociateUserStyles.selectedUserContainer }>
              <div>
                <div style={ AssociateUserStyles.label }>Client associé</div>
              </div>
              <div style={ AssociateUserStyles.selectedUser }>
                <div style={ AssociateUserStyles.searchList }>
                  <div style={ AssociateUserStyles.left }>
                    <span style={ AssociateUserStyles.lastName }>{ selectedClient.name }</span>
                  </div>
                </div>
                <div style={ { display: 'inline-flex', marginLeft: 15 } }>
                  <Button
                    variant={ 'smallDark' }
                    label={ 'Annuler' }
                    onClick={ (event: any) => this.searchClientService.handleCancel(event) }
                  />
                </div>
              </div>
            </div>
					}

					{ (this.state.isSellsyCompanyExist === false && this.state.selectedClient) &&
            <div style={ {
							marginTop: 15,
							backgroundColor: colors.error50,
							...borderStyles,
							borderColor: colors.error100,
							borderRadius: 6,
							color: colors.error600,
							padding: '5px 10px',
							height: 38,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						} }>
              <span>Merci de créer le client sur Sellsy</span>
              <Button
                label={ 'Associer' }
                variant={ 'sellsy' }
                onClick={ (event: any) =>
								{
									event.stopPropagation();
									this.props.modalContext?.content(
										'Associé un compte Sellsy',
										<GlobalContextProvider>
											<div style={ SellsyCompanyStyles.modal }>
												<AssociateSellsyCompany
													key={ this.state.selectedClient?.id! }
													client={ this.state.selectedClient! }
													associateCompany={ this.state.selectedClient! as ClientInterface }
													isWaitingCreate={ false }
													getComponentState={
														(associateState: AssociateSellsyCompanyState) =>
															this.searchClientService.handleAssociateSellsyCompany(associateState, this.props.modalContext!)
													}
												/>
											</div>
										</GlobalContextProvider>
									);
								}}
              />
            </div>
					}

					{ this.state.isMissedSellsyAddress &&
            <div style={ {
							marginTop: 15,
							backgroundColor: colors.error50,
							...borderStyles,
							borderColor: colors.error100,
							borderRadius: 6,
							color: colors.error600,
							padding: '5px 10px',
							height: 38,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						} }>
              <span>Merci de valider l'adresse de facturation</span>
              <Button
                label={ 'Voir la fiche' }
                variant={ 'smallError' }
                onClick={ () => this.props.navigation(`/admin/clients/${ this.state?.selectedClient?.id }`)}
              />
            </div>
					}

					{ this.state.isMissedClientOption &&
            <div style={ {
							marginTop: 15,
							backgroundColor: colors.error50,
							...borderStyles,
							borderColor: colors.error100,
							borderRadius: 6,
							color: colors.error600,
							padding: '5px 10px',
							height: 38,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						} }>
              <span>Merci de remplir toutes les préférences de ce client pour continuer</span>
              <Button
                label={ 'Voir la fiche' }
                variant={ 'small' }
                onClick={ () => this.props.navigation(`/admin/clients/${ this.state?.selectedClient?.id }`) }
              />
            </div>
					}
				</div>
			</>
		);
	}
}

export default withGlobalContext(SearchClient)
;
import React, { ReactElement } from 'react';
import {
  AppConfigAdminSupplierProps,
  AppConfigAdminSupplierState
} from '@/Modules/Config/Admin/Component/Supplier/ConfigAdminSupplier.interface';
import { ConfigAdminSupplierService } from '@/Modules/Config/Admin/Component/Supplier/ConfigAdminSupplier.service';
import Card from '@/Modules/App/Components/Atom/Card/Card';
import { AppConfigAdminStyles } from '@/Modules/Config/Admin/AppConfigAdmin/AppConfigAdmin.styles';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { SupplierInterface } from '@/Modules/Interface/SupplierInterface';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';

export default class ConfigAdminSupplier extends React.Component<AppConfigAdminSupplierProps, AppConfigAdminSupplierState>
{
  private configAdminSupplierService: ConfigAdminSupplierService = new ConfigAdminSupplierService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.configAdminSupplierService.setProps(this.props);
    this.configAdminSupplierService.subscribeState(this);

    // State
    this.state = this.configAdminSupplierService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.configAdminSupplierService.init();
  }

  componentWillUnmount(): void
  {
    this.configAdminSupplierService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <Title type="h5">Gestion des Fournisseurs</Title>
        <Card
          title={ '' }
          text={ '' }
          renderContent={() => this.supplierSettingsRender() }
        />

        { this.state.isEdit &&
          <>
            <div style={ AppConfigAdminStyles.cardContainer }>
              <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                <Title type={ 'h6' } style={ { marginTop: 0 } }>Changer de Fournisseurs</Title>
                <div style={{ display: 'flex', gap: 10 }}>
                  <Button
                    label={ 'Annuler' }
                    variant={ 'warning' }
                    onClick={ (event: any) => {
                      event.preventDefault();
                      event.stopPropagation();

                      this.setState({ isEdit: false });
                    }}
                  />

                  <Button
                    label={ 'Sauvegarder' }
                    variant={ 'primary' }
                    onClick={ (event: any) => {
                      event.preventDefault();
                      event.stopPropagation();

                      this.configAdminSupplierService.edit(this.state);
                    }}
                    disabled={ Boolean(!this.state.selectedSupplier) }
                  />
                </div>
              </div>

              <Select
                label={ 'Choisir le nouveau fournisser' }
                textHelp={ 'ATTENTION: Le changement prendra effet sur les nouvelles annonces légales' }
                text={ 'Choisir un nouveau fournisseur' }
                listOptions={
                  this.state.supplierList.map((supplier: SupplierInterface) => ({
                    label: `${ supplier.name }`,
                    tag: supplier.tag,
                    id: supplier.id
                  })) as OptionFilterInterface[]
                }
                isRequired={ false }
                onSelect={ (supplier: SupplierInterface) => {
                  this.configAdminSupplierService.onSelectedSupplier(supplier)
                }}
              />
            </div>
          </>
        }
      </>
    );
  }

  private supplierSettingsRender(): ReactElement
  {
    if (!this.props.company) {
      return (<>Il y a un probléme sur l'entreprise !</>);
    }

    const supplier: SupplierInterface = this.props.company.supplier;

    return (
      <>
        <Title type={ 'h6' } style={{ marginTop: 0 }}>Fournisseurs courrant</Title>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <div style={{ display: 'flex', gap: 20 }}>
            <div>{ (supplier) ? supplier.name : 'Aucun Fournisseur en cours' }</div>
            { supplier &&
              <Tag value={ supplier.status } enumName={ 'ActiveStatusEnum' } />
            }
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            label={ (supplier) ? 'Changer de fournisseur ?' : 'Merci de choisir un fournisseur' }
            iconName={ 'LuTruck' }
            onClick={ (event: any) => {
              event.preventDefault();
              event.stopPropagation();

              this.setState({ isEdit: true });
            }}
          />
        </div>
      </>
    );
  }
}
import React, { ReactElement } from 'react';
import {
  CommercialSectorsAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialSectors/CommercialSectorsAdmin.service';
import {
  CommercialSectorsAdminProps, CommercialSectorsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialSectors/CommercialSectorsAdmin.interface';
import { ClipLoader } from 'react-spinners';
import {
  CommercialTableStatsAdminStyles
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin.styles';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { CommercialSectorInterface } from '@/Modules/Interface/CommercialSectorInterface';

export default class CommercialSectorsAdmin extends React.Component<CommercialSectorsAdminProps, CommercialSectorsAdminState>
{
  private componentService: CommercialSectorsAdminService = new CommercialSectorsAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <ClipLoader/>;
    }

    const { clientsBySectorList, selectedSector } = this.state;

    return (
      <>
        { this.state.sectorList&&
          <>
            <div style={ { width: '50%' } }>
              <h5>Merci de choisir un secteur</h5>
              <Select
                listOptions={ this.state.sectorList }
                isRequired={ true }
                onSelect={ (sector: CommercialSectorInterface) =>
                {
                  this.componentService.onSelectedSector(sector);
                } }
              />
            </div>
          </>
        }

        { selectedSector && (
          <>
            <h6 style={ { marginTop: '20px', color: '#333' } }>📍 { selectedSector.label }</h6>

            { this.state.isClientsLoading ? (
              <ClipLoader />
            ) : (
              <table style={ CommercialTableStatsAdminStyles.table }>
                <thead>
                <tr style={ { background: '#f8f9fa', border: '1px solid #ddd' } }>
                  <th style={ CommercialTableStatsAdminStyles.tableTh }>Client</th>
                  <th style={ CommercialTableStatsAdminStyles.tableTh }>Adresse</th>
                  <th style={ CommercialTableStatsAdminStyles.tableTh }>Cadeaux</th>
                </tr>
                </thead>
                <tbody style={ CommercialTableStatsAdminStyles.tableBodyRow }>
                { (clientsBySectorList || []).length > 0 ? (
                  (clientsBySectorList || []).map((clientData: any) => (
                    <tr key={ clientData.client } style={ CommercialTableStatsAdminStyles.tableBodyRow }>
                      <td style={{ padding: '10px', fontWeight: 'bold' }}>{ clientData.name }</td>
                      <td style={{ padding: '10px', fontWeight: 'bold' }}>
                        <div style={{ lineHeight: '1.5', whiteSpace: 'nowrap' }}>
                          <strong>{ clientData.address.city }</strong>
                          <br />
                          <div style={{ fontWeight: 'initial' }}>
                            { clientData.address.number ? `${clientData.address.number }, ` : '' }
                            { clientData.address.street || 'Rue non renseignée' }
                          </div>
                        </div>
                      </td>
                      <td style={{ padding: '10px' }}>
                        { (clientData.clientGifts || []).length > 0 ? (
                          <ul style={ { margin: 0 } }>
                            { (clientData.clientGifts || []).map((gift: any, giftIndex: number) => (
                              <li key={ `gift_${ clientData.client }_${ giftIndex }` }>
                                { gift.commercialGift.label } - <strong>{ gift.quantity }</strong>
                              </li>
                            )) }
                          </ul>
                        ) : (
                          <span style={ { fontStyle: 'italic', color: '#888' } }>Aucun cadeau</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={ 2 }
                        style={ { textAlign: 'center', padding: '20px', fontStyle: 'italic', color: '#888' } }>
                      Aucun client dans ce secteur
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            )}
          </>
        )}
      </>
    );
  }
}
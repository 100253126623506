import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SearchClientProps, SearchClientState } from './SearchClient.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import { SellsyCompanyResponseInterface } from '@/Interface/Sellsy/SellsyCompanyResponse.interface';
import { SellsyErrorResponseInterface } from '@/Interface/Sellsy/SellsyErrorResponse.interface';
import { SellsyCompanyAddressesResponseInterface } from '@/Interface/Sellsy/SellsyCompanyAddressesResponse.interface';
import {
	UpdateSellsyAddressState
} from '@/Modules/App/Components/Sellsy/UpdateSellsyAddress/UpdateSellsyAddress.interface';
import { SellsyCompanyService } from '@/Modules/App/Components/Sellsy/SellsyCompany/SellsyCompany.service';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { ModalContextType } from '@/Provider/ModalProvider';

const initState: SearchClientState = {
	isLoadingSearch: false,
	selectedClient: null,
	isSellsyCompanyExist: null,
	isMissedClientOption: false,
	isMissedSellsyAddress: false,
	clientSellsyAddress: {} as AddressInterface,
	isLoading: true,
	missingAddressMessage: '',
};

export class SearchClientService extends BaseComponentService<SearchClientProps, SearchClientState>
{
	private apiAdminSellsyService: ApiAdminSellsyService = new ApiAdminSellsyService();
	private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();
	private sellsyCompanyService: SellsyCompanyService = new SellsyCompanyService();

	constructor()
	{
		super({} as SearchClientProps, initState);

		// Bind
		this.handleSelectedClient = this.handleSelectedClient.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ isLoading: false });
		if (this.props.client) {
			this.handleSelectedClient(this.props.client);
		}
	}

	/**
	 * Handle selected Client on research
	 * @param client
	 */
	handleSelectedClient(client: ClientInterface): void
	{
		this.setState(
			{ selectedClient: client },
			async () =>
			{
				this.setState({ isLoading: true });
				this.isMissedClientOption();
				await this.isMissedSellsyAddress();
				this.setState({ isLoading: false }, () =>
				{
					if (
						this.state.isSellsyCompanyExist === true
						&& !this.state.isMissedClientOption
						&& !this.state.isMissedSellsyAddress
					) {
						this.props.callbackState!(this.state);
					}
				});
			}
		);
	}

	/**
	 * Handle Cancel Selected Client
	 */
	handleCancel(event: any): void
	{
		event.stopPropagation();

		this.setState({ selectedClient: null },
			() =>
			{
				this.setState({ isMissedClientOption: false });
				this.setState({ isMissedSellsyAddress: false });
				this.props.callbackState!(this.state);
			}
		);
	}

	/**
	 * Handle callback id updated Sellsy address
	 * @param state
	 */
	handleUpdateSellsyAddress(state: UpdateSellsyAddressState): void
	{
		if (state.client) {
			this.handleSelectedClient(state.client);
		}
	}

	/**
	 * Handle Associate Sellsy Company
	 * @param state
	 * @param modalContext
	 */
	async handleAssociateSellsyCompany(state: AssociateSellsyCompanyState, modalContext: ModalContextType): Promise<void> {
		this.setState({ isLoading: true });
		await this.sellsyCompanyService.handleAssociateSellsy(state, this.state?.selectedClient!, modalContext)
			.then(async () => {
				const updateClient = await this.apiAdminClientService.show(this.state.selectedClient?.id!);

				this.setState({ isSellsyCompanyExist: true },
					() => this.handleSelectedClient(updateClient)
				);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					'Une erreur est survenue lors de l‘association de votre client à Sellsy',
					'error'
				);
				console.error('An error occurred:', error);
			});
	}

	/**
	 * Check if Client Exist in Sellsy DataBase
	 */
	async sellsyClientExist(): Promise<SellsyCompanyResponseInterface | undefined>
	{
		const { selectedClient } = this.state;

		try {

			/* Get Client From Sellsy */
			const getSellsyClient = await this.apiAdminSellsyService.getCompany(
				selectedClient?.company.id!,
				Number(selectedClient?.extSellsyId!)
			);

			if ((getSellsyClient as SellsyErrorResponseInterface).errorMessage) {
				this.setState({ isSellsyCompanyExist: false });
				console.error((getSellsyClient as SellsyErrorResponseInterface).errorMessage);
				return undefined;
			}

			this.setState({ isSellsyCompanyExist: true });
			return getSellsyClient as SellsyCompanyResponseInterface;

		} catch (error: any) {
			console.error(`Error Occurred on ${ this.constructor.name }: `, error);
			return undefined;
		}
	}

	/**
	 * Check if client has all options
	 */
	isMissedClientOption(): void
	{
		const { selectedClient } = this.state;

		const clientType: boolean = Boolean(selectedClient?.options.type);
		const newspaperType: boolean = Boolean(selectedClient?.options.newspaperType);
		const department: boolean = Boolean(selectedClient?.options.department);
		const newspaper: boolean = Boolean(selectedClient?.options.newspaper);
		const paymentMethod: boolean = Boolean(selectedClient?.options.paymentMethod);
		const discountPreference: boolean = Boolean(selectedClient?.options.discountPreference);
		const billedTo: boolean = Boolean(selectedClient?.options.billedTo);

		this.setState({
			isMissedClientOption: !Boolean(
				clientType
				&& newspaperType
				&& department
				&& newspaper
				&& paymentMethod
				&& discountPreference
				&& billedTo
			)
		});
	}

	/**
	 * Check if Missed Address Sellsy or not the right ID
	 */
	async isMissedSellsyAddress(): Promise<void>
	{
		const { selectedClient } = this.state;

		try {
			const clientExistInSellsy: SellsyCompanyResponseInterface | undefined = await this.sellsyClientExist();

			if (clientExistInSellsy === undefined) {
				return;
			}

			/* Get Address From Sellsy */
			const getAddresses = await this.apiAdminSellsyService.getCompanyAddressList(
				selectedClient?.company.id!,
				Number(selectedClient?.extSellsyId!),
			);

			if (
				(selectedClient?.address.extSellsyId! !== (clientExistInSellsy as SellsyCompanyResponseInterface).invoicing_address_id) ||
				selectedClient?.address.extSellsyId === null
			) {
				/* If Address list is empty ! */
				if (!(getAddresses as SellsyCompanyAddressesResponseInterface).data || (getAddresses as SellsyCompanyAddressesResponseInterface).data.length === 0) {
					this.setState({
						isMissedSellsyAddress: true,
						clientSellsyAddress: null,
						missingAddressMessage: 'Créer une nouvelle adresse',
					});
					return;
				}

				/* Get Address */
				const getAddress = (getAddresses as SellsyCompanyAddressesResponseInterface).data.find(
					(address: any) => address.id === (clientExistInSellsy as SellsyCompanyResponseInterface).invoicing_address_id
				);

				if (!getAddress) {
					this.setState({
						isMissedSellsyAddress: true,
						clientSellsyAddress: null,
						missingAddressMessage: 'Créer une nouvelle adresse',
					});
					return;
				}

				this.setState({
					isMissedSellsyAddress: true,
					clientSellsyAddress: {
						name: getAddress.name! || '',
						street: getAddress.address_line_1! || '',
						extSellsyId: Number(getAddress.id!) || null,
						isBillingAddress: true,
						zipCode: getAddress.postal_code! || '',
						city: getAddress.city! || '',
						country: getAddress.country! || '',
					},
				});
				return;
			}

			if ((selectedClient?.address.extSellsyId! === (clientExistInSellsy as SellsyCompanyResponseInterface).invoicing_address_id)) {
				this.setState({ isMissedSellsyAddress: false });
			}

		} catch (error: any) {
			console.error(`Error Occurred on ${ this.constructor.name }: `, error);
		}
	}

}
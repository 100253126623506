import React, { ReactElement } from 'react';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import { dateFormat } from '@/Utils/DateUtils';
import { mapperEnumUtils } from '@/Utils/MapperEnumUtils';
import TagTableEnum from '@/Modules/App/Components/Atom/Tags/TagTableEnum';
import { stringToCapitalize } from '@/Utils/StringToCapitalizeUtils';
import { LuArrowDown, LuArrowUp } from 'react-icons/lu';
import { TableComponentStyle } from '@/Modules/App/Style/TableComponentStyle';
import Skeleton from 'react-loading-skeleton';

type TableHeaderJustifyContentType = 'left' | 'center' | 'right';
type TableDataType =
	'string'
	| 'string[]'
	| 'uppercaseString'
	| 'toCapitalizeString'
	| 'lowercaseString'
	| 'date'
	| 'tag'
	| 'price'
	| 'user'
	| 'twoLinesString'
	| 'company'
	| 'sellsy'
	;
type TableDataTag = string | string[] | { label: string, enumClass: any };

export interface TableHeaderListInterface
{
	columnTitle: string,
	orderTag: string | null,
	minWidth: number,
	width: number | string,
	justifyContent: TableHeaderJustifyContentType,
	fontWeight: number,
	type: TableDataType,
	dataTag: TableDataTag,
}

interface ComponentProps
{
	paginateList: any[],
	tableHeaderList: TableHeaderListInterface[],
	params: (orderBy: string, sort: string, event?: any) => void | null,
	onClickRow: ((event: any, item: any) => void) | ((event?: any, item?: any) => void),
	notFoundValuesText: string | null,
	onRefreshList?: () => Promise<void>
	isStickyHeader?: boolean,
	isResizeColumns?: boolean,
	isLoading?: boolean,
}

interface ComponentState
{
	tableHeaderList: TableHeaderListInterface[],
	tableKey: string,
	paginateList: any[],
	resizeHovered: number | null,
	clickedColumnIndex: number | null,
	isAscendingOrder: boolean,
	tableTotalWidth: number | null,
	params: {
		orderBy: string,
		sort: string,
	},
}

export default class TableComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);
		this.state = this.initState();
	}

	render(): ReactElement
	{
		const { tableHeaderList, paginateList, clickedColumnIndex, isAscendingOrder } = this.state;
		const { isStickyHeader, notFoundValuesText, isLoading } = this.props;

		return (
			<div key={ Math.random().toString(36).substr(2, 9) }
					 style={ { maxWidth: 'calc(100vw - 300px)', overflowX: 'scroll', width: '100%' } }>
				<TableOld
					style={ {
						minWidth: 'max-content',
						width: '100%',
						backgroundColor: CssVariableEnum['--color-white'],
						height: '100%',
						marginTop: (isStickyHeader) ? -2 : 0,
						borderRadius: 15
					} } id={ 'table-container' }>
					<div style={ (isStickyHeader) ? TableComponentStyle.containerHeaderSticky() : {} }>
						<div style={ TableComponentStyle.containerHeader(this.props) }>
							<div style={ {
								backgroundColor: CssVariableEnum['--color-grey-100'],
								border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
								borderRadius: 8,
								width: '100%'
							} }>
								<TableRow isCheckboxInput={ false }>
									{ tableHeaderList.map((header: TableHeaderListInterface, index: number) => (
										<div key={ Math.random().toString(36).substr(2, 9) }
												 style={ TableComponentStyle.headerStyle(this.colSize(header.width, index)) }>
											<TableCell
												style={ TableComponentStyle.cellStyle(header) }
												onClick={ header.orderTag ? (event: any) => this.handleOrderColumnClick(index, header.orderTag, event) : undefined }
											>
												{ header.columnTitle }
												{ clickedColumnIndex === index ? (
													isAscendingOrder
														? <span style={ { marginLeft: '4px' } }><LuArrowUp fontSize={ 14 }/></span>
														: <span style={ { marginLeft: '4px' } }><LuArrowDown fontSize={ 14 }/></span>
												) : null }
											</TableCell>
											<div
												style={ TableComponentStyle.tableSizePaginate(this.props, this.state, index) }
												onMouseDown={ (event: any) => (this.props.isResizeColumns) ? this.handleMouseDownResizeColumn(index, event) : null }
												onMouseEnter={ () => (this.props.isResizeColumns) ? this.handleMouseEnterResizeColumn(index) : null }
												onMouseLeave={ () => (this.props.isResizeColumns) ? this.handleMouseLeaveResizeColumn() : null }
											/>
										</div>
									))
									}
								</TableRow>
							</div>
						</div>
					</div>
					{ (!isLoading && paginateList.length <= 0) && (
						<div style={ { fontSize: 14, fontWeight: 500, marginLeft: 15, marginTop: 15, marginBottom: 15 } }>
							{ notFoundValuesText }
						</div>
					) }
					{ isLoading ? (
						Array.from({ length: 10 }).map((_, rowIndex) => (
							<TableRow key={rowIndex} isCheckboxInput={false} borderBottom={ rowIndex !== 4 }>
								{ tableHeaderList.map((tableCol: TableHeaderListInterface, colIndex: number) => (
									<TableCol key={colIndex} style={{ width: this.colSize(tableCol.width, colIndex) }}>
										<TableCell key={colIndex}>
											<Skeleton style={{ width: '100%', height: 32 }} baseColor={ CssVariableEnum['--color-grey-100'] } />
										</TableCell>
									</TableCol>
								)) }
							</TableRow>
						))
					)  : (
						paginateList.map((item, index: number) => (
							<div key={ Math.random().toString(36).substr(2, 9) }
									 style={ (isStickyHeader) ? { padding: '0 10px' } : {} }>
								<TableRow
									key={ Math.random().toString(36).substr(2, 9) }
									isCheckboxInput={ false }
									borderBottom={ index !== paginateList.length - 1 }
									onClick={ this.props.onClickRow }
									rowItem={ item }
								>
									{ tableHeaderList.map((tableCol: TableHeaderListInterface, index: number) => (
										<TableCol key={ index } style={ { width: this.colSize(tableCol.width, index) } }>
											<TableCell style={ { fontWeight: tableCol.fontWeight } }>
												{ this.buildDataByType(tableCol, item) }
											</TableCell>
										</TableCol>
									)) }
								</TableRow>
							</div>
						))
					) }
				</TableOld>
			</div>
		);
	}

	//<editor-fold desc="CreateOld (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.setState({
			tableHeaderList: this.props.tableHeaderList,
			paginateList: this.props.paginateList
		});

		const tableContainer = document.getElementById('table-container');
		const tableTotalWidth = (tableContainer) ? tableContainer.clientWidth : null;
		this.setState({ tableTotalWidth });
	}

	componentDidUpdate(prevProps: any): void
	{
		if (prevProps.paginateList !== this.state.paginateList) {
			this.setState({
				paginateList: this.props.paginateList,
			});
		}
	}

	private initState(): ComponentState
	{
		return {
			tableKey: `table-${ Math.random().toString(36).substr(2, 9) }`,
			tableHeaderList: [],
			paginateList: [],
			resizeHovered: null,
			clickedColumnIndex: null,
			isAscendingOrder: true,
			tableTotalWidth: null,
			params: {
				orderBy: '',
				sort: '',
			}
		};
	}

	//</editor-fold>

	//<editor-fold desc="Handle Row Resize" defaultstate="collapsed">

	private colSize(width: number | string, index: number): number
	{
		let colWidth: number | string;

		if (typeof width === 'string' && width === '100%' && this.state.tableTotalWidth) {
			const otherWidths = this.state.tableHeaderList
				.filter((_, i) => i !== index)
				.reduce((acc, col) => acc + (typeof col.width === 'number' ? col.width : 0), 0);
			colWidth = this.state.tableTotalWidth - otherWidths;
		} else {
			colWidth = width as number;
		}
		return colWidth;
	}

	handleMouseDownResizeColumn = (index: number, event: any) =>
	{
		let startWidthNumber: number;
		const startX = event.pageX;

		if (this.state.tableHeaderList[index].width === '100%' && this.state.tableTotalWidth) {
			// Calculate the width in pixels for the 100% case
			const otherWidths = this.state.tableHeaderList
				.filter((_, i) => i !== index)
				.reduce((acc, col) => acc + (typeof col.width === 'number' ? col.width : 0), 0)
			;

			startWidthNumber = this.state.tableTotalWidth - otherWidths;
		} else {
			startWidthNumber = this.state.tableHeaderList[index].width as number;
		}

		const onMouseMove = (moveEvent: any) =>
		{
			const newWidth: number = Math.max(100, startWidthNumber + moveEvent.pageX - startX);

			this.setState(prevState =>
			{
				const newTableHeaderList: TableHeaderListInterface[] = [...prevState.tableHeaderList];
				newTableHeaderList[index].width = newWidth;
				return { tableHeaderList: newTableHeaderList };
			});
		};

		const onMouseUp = (): void =>
		{
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	};

	handleMouseEnterResizeColumn = (index: number): void =>
	{
		this.setState({ resizeHovered: index });
	};

	handleMouseLeaveResizeColumn = (): void =>
	{
		this.setState({ resizeHovered: null });
	};

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private resolveDataTag(dataTag: TableDataTag, item: any): any
	{
		if (typeof dataTag === 'string') {
			return dataTag.split('.').reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, item);
		} else if (Array.isArray(dataTag)) {
			return dataTag.map((dataPath: string) =>
			{
				return dataPath.split('.').reduce((obj, key) =>
					(obj && obj[key] !== undefined) ? obj[key] : null, item);
			}).filter(value => value !== null);
		} else if (typeof dataTag === 'object' && 'label' in dataTag) {
			return dataTag.label.split('.').reduce((obj, key) => obj && obj[key], item);
		}
		return null;
	}

	private buildDataByType(data: TableHeaderListInterface, item: any): string | ReactElement
	{
		const { dataTag, type } = data;
		let dataTagMapper = this.resolveDataTag(dataTag, item);

		switch (type) {
			case 'string':
				return dataTagMapper;
			case 'price':
				return `€ ${ dataTagMapper }`;
			case 'lowercaseString':
				return dataTagMapper.toLowerCase();
			case 'uppercaseString':
				return dataTagMapper.toUpperCase();
			case 'string[]':
				return this.arrayStringHtmlElement(dataTagMapper);
			case 'date':
				return dateFormat(dataTagMapper);
			case 'twoLinesString':
				return this.twoLinesHtmlElement(data, dataTagMapper);
			case 'user':
				return this.userHtmlElement(data, dataTagMapper);
			case 'tag':
				return this.tagHtmlElement(data, dataTagMapper);
			case 'company':
				return this.companyHtmlElement(data, dataTagMapper);
			case 'sellsy':
				return this.sellsyLogoHtmlElement(dataTagMapper);
			default:
				return '';
		}
	}

	private handleOrderColumnClick(index: number, orderTag: string | null, event?: any): void
	{
		event.stopPropagation();

		if (orderTag === null) {
			return;
		}

		if (this.state.clickedColumnIndex === index) {
			this.setState((prevState: any) => ({
				isAscendingOrder: !prevState.isAscendingOrder,
				params: {
					...prevState.params,
					sort: prevState.isAscendingOrder ? 'desc' : 'asc',
					orderBy: orderTag
				}
			}), () =>
			{
				if (this.props.params) this.props.params(this.state.params.orderBy, this.state.params.sort);
			});
		} else {
			this.setState({
				clickedColumnIndex: index,
				isAscendingOrder: true,
				params: {
					...this.state.params,
					orderBy: orderTag,
					sort: 'asc'
				}
			}, () =>
			{
				if (this.props.params) this.props.params(this.state.params.orderBy, this.state.params.sort);
			});
		}
	}

	private tagHtmlElement(data: TableHeaderListInterface, dataTagMapper: any): ReactElement | string
	{
		if (typeof data.dataTag === 'object' && 'enumClass' in data.dataTag) {
			const enumTag = mapperEnumUtils(data.dataTag.enumClass, dataTagMapper);
			return (
				<TagTableEnum enumTag={ enumTag }/>
			);
		}
		return '-';
	}

	private companyHtmlElement(data: TableHeaderListInterface, dataTagMapper: any): ReactElement | string
	{
		if (dataTagMapper.length === 0) {
			return '-';
		} else {
			return (
				<>
					<div style={ {
						fontWeight: data.fontWeight
					} }>
						{ stringToCapitalize(dataTagMapper[0]) }
					</div>
					<div style={ {
						color: CssVariableEnum['--color-grey-400'],
						fontWeight: 400
					} }>
						{ `(${ stringToCapitalize(dataTagMapper[1]) })` }
					</div>
				</>
			);
		}
	}

	private sellsyLogoHtmlElement(dataTagMapper: any): ReactElement
	{
		return (
			<>
				<div>
					{ dataTagMapper.length > 0 ? (
						<>
							<img
								style={ {
									width: '35px',
									padding: '5px',
									borderRadius: '5px',
									backgroundColor: CssVariableEnum['--color-purple-200']
								} }
								src="/img/logo-sellsy.png"
								alt="Sellsy Logo"
							/>
						</>
					) : (
						<span style={ { fontSize: '11px' } }>Collaborateur non associé</span>
					) }
				</div>
			</>
		);
	}

	private arrayStringHtmlElement(dataTagMapper: any): ReactElement | string
	{
		if (dataTagMapper.length === 2) {
			return `${ stringToCapitalize(dataTagMapper[0]) } ${ stringToCapitalize(dataTagMapper[1]) }`;
		} else {
			return '-';
		}
	}

	private twoLinesHtmlElement(data: TableHeaderListInterface, dataTagMapper: any): ReactElement | string
	{
		if (dataTagMapper.length === 2) {
			return (
				<>
					<div style={ {
						fontWeight: data.fontWeight
					} }>
						{ dataTagMapper[0] }
					</div>
					<div style={ {
						color: CssVariableEnum['--color-grey-400'],
						fontWeight: 400
					} }>
						{ `(${ stringToCapitalize(dataTagMapper[1]) })` }
					</div>
				</>
			);
		} else {
			return '-';
		}
	}

	private userHtmlElement(data: TableHeaderListInterface, dataTagMapper: any): ReactElement | string
	{
		if (dataTagMapper.length === 3) {
			return (
				<>
					<div style={ {
						fontWeight: data.fontWeight
					} }>
						{ dataTagMapper[0] } { dataTagMapper[1] }
					</div>
					<div style={ {
						color: CssVariableEnum['--color-grey-400'],
						fontWeight: 400
					} }>
						{ `${ dataTagMapper[2].toLowerCase() }` }
					</div>
				</>
			);
		} else {
			return '-';
		}
	}

	//</editor-fold>

}

import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export const AppConfigAdminStyles = {
  container: {
    position: 'relative',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    height: 800,
    overflow: 'hidden'
  } as CSSProperties,
  header: {
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  menuContainer: {
    backgroundColor: '#f9f9f9',
    padding: '10px 0',
    borderRight: '1px solid #E6E6E6'
  },
  menuItem: (isActive: boolean) => ({
    padding: '10px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: isActive ? '#E3E8FF' : 'transparent',
    fontWeight: isActive ? 'bold' : 'normal',
    color: isActive ? '#333' : '#666',
    borderLeft: isActive ? '4px solid #8884d8' : '4px solid transparent',
    transition: 'all 0.2s ease-in-out',
  }),
  contentContainer: {
    padding: '10px 20px 20px 10px',
  },
  cardContainer: {
    backgroundColor: CssVariableEnum['--color-white'],
    borderRadius: '3px',
    padding: '20px 10px',
    marginTop: 10,
    border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
  } as CSSProperties
} as const;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  CommercialClientAdminProps, CommercialClientAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Show/CommercialClientAdmin.interface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { ApiAdminCommercialService } from '@/Service/Admin/ApiAdminCommercialService';

const initState: CommercialClientAdminState = {
  isLoading: false,
  commercialSectorList: [],
  selectedSelector: null
}

export class CommercialClientAdminService extends BaseComponentService<CommercialClientAdminProps, CommercialClientAdminState>
{
  private apiCommercialService: ApiAdminCommercialService = new ApiAdminCommercialService();
  appService: ApiAdminAppService = new ApiAdminAppService();

  constructor()
  {
    super({} as CommercialClientAdminProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.appService.commercialSectorList()
      .then((response) => { this.setState({ commercialSectorList: response, selectedSelector: this.props.client?.sector, isLoading: false }); })
    ;
  }

  async editSector(clientId: number, commercialSectorId: number): Promise<any>
  {
    await this.apiCommercialService.addSector(clientId, commercialSectorId)
      .then(async () => {
        this.props.refreshClient()
      })
    ;
  }
}
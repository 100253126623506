import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import BaseService from '@/Service/Common/BaseService';

export class ApiUserDeveloperService extends BaseService
{
  userUrl: string = `${ BaseService.API_URL }/v1/developer/users`;

  async list(params?: string): Promise<any>
  {
    try {
      const url: string = params ? this.userUrl + '?' + params : this.userUrl;
      const response: Response = await fetch(
        url,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user list');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching user list: ', error);
      throw error;
    }
  }

  async show(userId: number): Promise<any> {
      try {
          const response = await fetch(
            `${ this.userUrl }/${ userId }`,
            {
                method: HttpVerbEnum.GET,
                headers: this.buildHeaders()
            }
          );

          return await response.json();
      } catch (error: any) {
          console.error(error.message);
      }
  }

  async create(formData: UserInterface): Promise<any>
  {
    try {
      const response: Response = await fetch(
        this.userUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(formData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
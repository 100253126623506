import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ClientDeveloperProps, ClientDeveloperState } from '@/Modules/Client/Developer/Show/ClientDeveloper.interface';
import { ApiClientDeveloperService } from '@/Service/Developer/ApiClientDeveloperService';

const initState: ClientDeveloperState = {
	client: {} as ClientInterface,
	formData: {} as ClientInterface,
	isLoading: true,
	isEdit: false
};

export class ClientDeveloperService extends BaseComponentService<ClientDeveloperProps, ClientDeveloperState>
{
	private apiClientService: ApiClientDeveloperService = new ApiClientDeveloperService();

	constructor()
	{
		super({} as ClientDeveloperProps, initState);

		// Bind
		this.handleEdit = this.handleEdit.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: this.props.client },
			() => this.setState({ isLoading: false })
		);
	}

	async handleEdit(currentState: ClientDeveloperState): Promise<void>
	{
		this.setState({ isLoading: true });
		try {

			const updatedClient = await this.apiClientService.edit(currentState.formData, currentState.formData.id);

			if(updatedClient.errorMessage) {
				console.error('Something wrong with update Client', updatedClient.errorMessage);
				return;
			}

			this.setState({ formData: currentState.formData });
			this.props.getComponentState!(this.state.formData);

			this.setState({ isLoading: false });

			this.props.flashMessageContext?.flashMessage(
				'Mise à jour réussie',
				'Les nouvelles informations du donneur d\'ordre ont été enregistrées',
			);

		} catch (error) {
			console.error('Something wrong with update Client', error);
			this.setState({ isLoading: false });
		}
	}
}

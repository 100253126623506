import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  AppConfigAdminSectorProps,
  AppConfigAdminSectorState
} from '@/Modules/Config/Admin/Component/Sector/ConfigAdminSector.interface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { CommercialSectorInterface } from '@/Modules/Interface/CommercialSectorInterface';

const initState: AppConfigAdminSectorState = {
  isLoading: false,
  editSectorId: null,
  sectorList: [],
  newSector: null
}

export class ConfigAdminSectorService extends BaseComponentService<AppConfigAdminSectorProps, AppConfigAdminSectorState>
{
  apiAdminAppService: ApiAdminAppService = new ApiAdminAppService();

  constructor()
  {
    super({} as AppConfigAdminSectorProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.apiAdminAppService.commercialSectorList()
      .then((response)  => {
        this.setState({ isLoading: false, sectorList: response })
      })
    ;
  }

  async createCommercialSector(label :string): Promise<void>
  {
    await this.apiAdminAppService.commercialSectorCreate({ label })
      .then(async () => { await this.init(); })
    ;
  }

  async updateCommercialSector(commercialSectorData: CommercialSectorInterface): Promise<void>
  {
    await this.apiAdminAppService.commercialSectorEdit(commercialSectorData.id as number, commercialSectorData)
      .then(async () => { await this.init(); })
    ;
  }

  async deleteCommercialSector(commercialSectorId :number): Promise<void>
  {
    await this.apiAdminAppService.commercialSectorDelete(commercialSectorId)
      .then(async () => { await this.init(); })
    ;
  }
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	NavigationProps,
	NavigationState
} from '@/Modules/App/Components/Menu/Navigation/Navigation.interface';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { MenuItemInterface } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.interface';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

enum Paths {
	DASHBOARD = '',
	ADMIN = '/admin',
	USERS = '/users',
	CLIENTS = '/clients',
	LEGAL_NOTICE = '/legal-notices',
	LEGAL_NOTICE_VALIDATE = '/legal-notices/validate',
	LEGAL_NOTICE_DRAFT = '/legal-notices/draft',
	LEGAL_NOTICE_QUOTE = '/legal-notices/quote',
	NEWSPAPER_MANAGEMENT = '/newspaper-management',
	PRICING = '/settings/pricing',
	FORM_BUILDER = '/settings/form-builder',
	COMMERCIAL = '/commercial'
}


const initState: NavigationState = {
	navigationMenu: [],
};

export class NavigationService extends BaseComponentService<NavigationProps, NavigationState>
{
	constructor()
	{
		super({} as NavigationProps, initState);
	}

	/**
	 * Init componentDidMount
	 * @return void
	 */
	init(): void
	{
		this.setNavigationMenu();
	}

	/**
	 * Set navigation menu with the right authorizations
	 * @return void
	 */
	setNavigationMenu(): void {
		const userRole: string = LocalStorageService.getUser().userRole;
		const navigationMenu: MenuItemInterface[] = this.buildMainNavigationMenu(userRole).filter(item =>
			this.isAuthorized(item.authorization, userRole)
		);

		this.setState({ navigationMenu });
	}

	/**
	 * Build the navigation menu sidebar
	 * @return MenuItemInterface[]
	 * @private
	 */
	private buildMainNavigationMenu(userRole: string): MenuItemInterface[]
	{
		const isAdmin = UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin();
		const isDeveloper = UserConnectedService.isDeveloper();

		const addAdminPrefix = (path: string) => {
			if (isAdmin) {
				return `/admin${path}`;
			} else if (isDeveloper) {
				return `/developer${path}`;
			}
			return path;
		};

		return [
			{
				label: 'Tableau de bord',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.DASHBOARD),
				iconName: 'LuLayoutDashboard',
				authorization: UserRoleEnum.fullGroup,
			},
			{
				label: 'Utilisateurs',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.USERS),
				iconName: 'LuUsers',
				authorization: UserRoleEnum.fullAdminGroup,
			},
			{
				label: 'Mon entreprise',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.CLIENTS + '/' + LocalStorageService.getAuthClient()),
				iconName: 'LuBuilding2',
				authorization: UserRoleEnum.userGroup,
			},
			{
				label: 'Donneur d\'ordre',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.CLIENTS),
				iconName: 'LuLandmark',
				authorization: UserRoleEnum.fullAdminGroup,
			},
			{
				label: 'Annonces Légales',
				type: 'separatorItem',
				urlPath: null,
				iconName: null,
				authorization: UserRoleEnum.fullGroup,
			},
			{
				label: 'Annonces Légales',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.LEGAL_NOTICE),
				iconName: 'LuFiles',
				authorization: UserRoleEnum.fullGroup,
			},
			{
				label: 'À valider',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.LEGAL_NOTICE_VALIDATE),
				iconName: 'LuStamp',
				authorization: UserRoleEnum.adminGroup,
			},
			{
				label: 'Devis',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.LEGAL_NOTICE_QUOTE),
				iconName: 'LuPenLine',
				authorization: UserRoleEnum.adminGroup,
			},
			{
				label: 'Brouillons',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.LEGAL_NOTICE_DRAFT),
				iconName: 'LuClipboard',
				authorization: UserRoleEnum.adminGroup,
			},
			{
				label: 'Bouclage',
				type: 'separatorItem',
				urlPath: null,
				iconName: null,
				authorization: UserRoleEnum.adminGroup,
			},
			{
				label: 'Journal',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.NEWSPAPER_MANAGEMENT),
				iconName: 'LuNewspaper',
				authorization: UserRoleEnum.adminGroup,
			},
			{
				label: 'Commercial',
				type: 'separatorItem',
				urlPath: '!#',
				iconName: null,
				authorization: UserRoleEnum.superAdminGroup,
			},
			{
				label: 'Commercial',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.COMMERCIAL),
				iconName: 'LuLuggage',
				authorization: UserRoleEnum.superAdminGroup,
			},
			{
				label: 'Configurations',
				type: 'separatorItem',
				urlPath: '!#',
				iconName: null,
				authorization: UserRoleEnum.superAdminGroup,
			},
			{
				label: 'Tarifications',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.PRICING),
				iconName: 'LuCircleDollarSign',
				authorization: UserRoleEnum.superAdminGroup,
			},
			{
				label: 'Formulaires',
				type: 'menuItem',
				urlPath: addAdminPrefix(Paths.FORM_BUILDER),
				iconName: 'LuListTodo',
				authorization: UserRoleEnum.superAdminGroup,
			}
		];
	}

	/**
	 * Authorizations to items menu access
	 * @param authorizationGroup
	 * @param userRole
	 * @private
	 */
	private isAuthorized(authorizationGroup: UserRoleEnum[], userRole: string): boolean
	{
		const userRoleEnum = UserRoleEnum.findByValue(userRole);
		return authorizationGroup.includes(userRoleEnum);
	}
}
import React, { Component, ReactElement } from 'react';
import { LegalNoticeCheckoutProps, LegalNoticeCheckoutState } from './LegalNoticeCheckout.interface';
import { LegalNoticeCheckoutService } from './LegalNoticeCheckout.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import CreateLegalNoticeFreeWriting from '@/Modules/LegalNotice/User/Create/CreateLegalNoticeFreeWriting';
import CreateLegalNoticeGuide from '@/Modules/LegalNotice/User/Create/Guide/CreateLegalNoticeGuide';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';

class LegalNoticeCheckout extends Component<LegalNoticeCheckoutProps, LegalNoticeCheckoutState>
{
	private legalNoticeCheckoutService = new LegalNoticeCheckoutService();

	constructor(props: LegalNoticeCheckoutProps)
	{
		super(props);

		// Config service
		this.legalNoticeCheckoutService.setProps(this.props);
		this.legalNoticeCheckoutService.subscribeState(this);

		// State
		this.state = this.legalNoticeCheckoutService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeCheckoutService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeCheckoutService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderFullPage/>;
		}

		const isLegalNoticeGuide: boolean = window.location.pathname === '/legal-notices/create';

		return (
			<>
				<HeroSection
					title={ (isLegalNoticeGuide) ? 'Créer une nouvelle annonce légale guidée' : 'Rédaction libre' }
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowUpLeft',
						onClick: () => null,
					} }
				/>

				{ isLegalNoticeGuide ? (
					<CreateLegalNoticeGuide />
				) : (
					<CreateLegalNoticeFreeWriting />
				) }
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeCheckout);
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SellsyCompanyProps, SellsyCompanyState } from './SellsyCompany.interface';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ModalContextType } from '@/Provider/ModalProvider';
import { SellsyErrorResponseInterface } from '@/Interface/Sellsy/SellsyErrorResponse.interface';
import { SellsyCompanyResponseInterface } from '@/Interface/Sellsy/SellsyCompanyResponse.interface';
import { SellsyCompanyAddressesResponseInterface } from '@/Interface/Sellsy/SellsyCompanyAddressesResponse.interface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';

const initState: SellsyCompanyState = {
	isLoading: true,
	sellsyDetails: {} as SellsyCompanyResponseInterface,
	sellsyCompanyAddress: {} as AddressInterface,
	addressIdIssue: false,
	client: {} as ClientInterface,
};

export class SellsyCompanyService extends BaseComponentService<SellsyCompanyProps, SellsyCompanyState>
{
	apiAdminSellsyService: ApiAdminSellsyService = new ApiAdminSellsyService();
	apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as SellsyCompanyProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.client) {
			this.setState({ client: this.props.client });
		}
		await this.getSellsyCompany(Number(this.props.client.extSellsyId));
	}

	/**
	 * Handle Refresh
	 * @param trigger
	 */
	async handleRefresh(trigger: any): Promise<void>
	{
		if (trigger) {
			this.setState({ isLoading: true });
			await this.getSellsyCompany(Number(this.props.client.extSellsyId))
				.then(() => this.setState({ isLoading: false }))
				.catch((reason) => console.error(reason.error));
		}
	}

	/**
	 * Handle Associate Company Sellsy
	 * @param state
	 * @param selectedCompany
	 * @param modalContext
	 */
	async handleAssociateSellsy(state: AssociateSellsyCompanyState, selectedCompany?: ClientInterface | null, modalContext?: ModalContextType): Promise<void>
	{
		const selectedCompanyToAssociate = (selectedCompany) ? selectedCompany : this.props.client!;

		try {
			modalContext?.isLoadingOverlay(true);

			let associateCompany: any;
			if (state.createSellsyResponse?.clientSellsyId) {

				associateCompany = await this.apiAdminClientService.edit(
					{
						extSellsyId: state.createSellsyResponse?.clientSellsyId,
						address: {
							...selectedCompanyToAssociate.address!,
							extSellsyId: state.createSellsyResponse.addressSellsyId,
						}
					},
					selectedCompanyToAssociate.id
				);
			} else {
				associateCompany = await this.apiAdminClientService.edit(
					{
						extSellsyId: state.selectedCompanySellsy?.object?.id,
						address: {
							...selectedCompanyToAssociate.address!,
							extSellsyId: Number(state.selectedCompanySellsy?.delivery_address_id),
						}
					},
					selectedCompanyToAssociate.id
				);
			}

			if (associateCompany.errorMessage) {
				modalContext?.errorMessage('Une erreur est survenue lors de l\'association du client, veuillez réessayer');
				modalContext?.isLoadingOverlay(false);
				return;
			}

			await this.getSellsyCompany(associateCompany.extSellsyId, selectedCompanyToAssociate);

			this.props.flashMessageContext?.flashMessage(
				'Associé avec succès',
				'Ce donneur d’ordre a bien été associé dans Sellsy',
				'success'
			);

			modalContext?.isLoadingOverlay(false);
			modalContext?.isOpen(false);

		} catch (error) {
			console.error(`Error occurred in ${ this.constructor.name }: AssociateSellsy`, error);
			this.setState({ isLoading: false });
			modalContext?.isLoadingOverlay(false);
			throw new Error(`Error occurred in ${ this.constructor.name }: AssociateSellsy`, error!);
		}
	}

	/**
	 * Update Address Sellsy on Company
	 * @param clientId
	 * @param sellsyAddressId
	 */
	async updateAddressExtSellsyId(clientId: number, sellsyAddressId: number): Promise<void>
	{
		this.setState({ isLoading: true });
		const updateExtSellsyId = await this.apiAdminClientService.updateCompanyAddressId(clientId, sellsyAddressId)
			.then(() =>
			{
				this.setState({
					isLoading: false,
					addressIdIssue: false,
				});
				this.props.callbackState!(this.state);
			})
			.catch((reason) => console.error(reason.error));

		this.props.flashMessageContext?.flashMessage(
			'Mise à jour',
			'Association de l\'address sellsy / CRM, OK !',
			'success'
		);

		return updateExtSellsyId;
	}

	/**
	 * @param extSellsyId
	 * @param selectedCompany
	 * @private
	 */
	private async getSellsyCompany(extSellsyId: number, selectedCompany?: ClientInterface | null): Promise<void>
	{
		const selectedCompanyToAssociate = (selectedCompany) ? selectedCompany : this.props.client!;
		try {

			const getSellsyClient = await this.apiAdminSellsyService.getCompany(
				selectedCompanyToAssociate?.company?.id!,
				extSellsyId
			);

			if ((getSellsyClient as SellsyErrorResponseInterface).errorMessage) {
				console.error((getSellsyClient as SellsyErrorResponseInterface).errorMessage);
				this.setState({ isLoading: false });
				return;
			}

			const getAddresses = await this.apiAdminSellsyService.getCompanyAddressList(
				selectedCompanyToAssociate?.company?.id!,
				Number(selectedCompanyToAssociate?.extSellsyId!)
			);

			const getAddress = (getAddresses as SellsyCompanyAddressesResponseInterface).data.find(
				(address: any) => address.id === (getSellsyClient as SellsyCompanyResponseInterface).invoicing_address_id
			);

			if (getAddress?.id! !== selectedCompanyToAssociate.address.extSellsyId) {
				this.setState({
					addressIdIssue: true,
				});
			}

			this.setState({
				sellsyDetails: getSellsyClient as SellsyCompanyResponseInterface,
				sellsyCompanyAddress: {
					name: null,
					extSellsyId: getAddress?.id,
					number: null,
					street: getAddress?.address_line_1!,
					zipCode: getAddress?.postal_code!,
					city: getAddress?.city!,
					additionalData: getAddress?.address_line_2!,
					country: getAddress?.country!,
					phone: null,
					isBillingAddress: true,
				},
			}, () => this.setState({ isLoading: false }));

		} catch (error) {
			console.error(`Error occurred in ${ this.constructor.name }: getCompanySellsy`, error);
			this.setState({ isLoading: false });
		}
	}
}
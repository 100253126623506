import { LegalNoticesProps } from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import { BaseLegalNoticesService } from '@/Modules/LegalNotice/Common/BaseLegalNotices.service';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';

export class LegalNoticesAdminService extends BaseLegalNoticesService
{
  constructor()
  {
    super({} as LegalNoticesProps, BaseLegalNoticesService.baseInitState);
  }

  buildColumnHeader(): TableHeadColumnsInterface[]
  {
    return [
      {
        title: 'Donneur d\'ordre',
        tag: 'clientName',
        columnWidth: 250,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'client.name'
      },
      {
        title: 'N° d\'annonce',
        tag: 'tag',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['tag']
      },
      {
        title: 'Référence',
        tag: 'reference',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: 'reference'
      },
      {
        title: 'Publication au ...',
        tag: 'publishDate',
        columnWidth: 100,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'date',
        keyTag: 'publishDate'
      },
      {
        title: 'Journal',
        tag: 'newspaper',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: 'newspaper.name'
      },
      {
        title: 'Département',
        tag: 'department',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: 'publishDepartment.name'
      },
      {
        title: 'Client final',
        tag: 'consumer',
        columnWidth: 250,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'consumer.name'
      },
      {
        title: 'Statut',
        tag: 'status',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'PublishStateEnum',
        keyTag: 'status'
      },
      {
        title: 'Paiement',
        tag: 'paymentStatus',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'PaymentStateEnum',
        keyTag: 'paymentStatus'
      },
      {
        title: 'Devis',
        tag: 'quoteStatus',
        columnWidth: 100,
        textAlign: 'center',
        fontWeight: 400,
        keyType: 'tag',
        enumClass: 'QuoteStatusEnum',
        keyTag: 'quoteStatus'
      },
      {
        title: 'Auteur',
        tag: 'author',
        columnWidth: 150,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['author.lastname', 'author.firstname']
      },
      {
        title: 'Collaborateur',
        tag: 'collaborator',
        columnWidth: 150,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: ['collaborator.user.lastname', 'collaborator.user.firstname']
      },
    ];
  }
}
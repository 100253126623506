import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SirenConsumerAutoFillProps, SirenConsumerAutoFillState } from './SirenConsumerAutoFill.interface';
import React from 'react';
import { ConsumerFormModel } from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm.model';
import { AddressFormModel } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.model';
import { ApiPappersService } from '@/Service/Pappers/ApiPappers.service';

const initState: SirenConsumerAutoFillState = {
	isLoading: true,
	siren: null,
	errorMessage: null,
};

export class SirenConsumerAutoFillService extends BaseComponentService<SirenConsumerAutoFillProps, SirenConsumerAutoFillState>
{
	private apiPappers: ApiPappersService = new ApiPappersService();

	constructor()
	{
		super({} as SirenConsumerAutoFillProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ siren: this.props.sirenValue },
			() => this.setState({ isLoading: false })
		);
	}

	/**
	 * Set Siren State
	 * @param event
	 */
	setSirenState(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const updateSiren: string = event.target.value.trim();

		if (updateSiren.length === 0) {
			this.setState({
				siren: null,
				errorMessage: null,
			}, () => this.props.callbackState!(this.state));
		} else if (/^\d{9}$/.test(updateSiren)) {
			this.setState({
				siren: updateSiren,
				errorMessage: null,
			}, () => this.props.callbackState!(this.state));
		} else {
			this.setState({
				siren: updateSiren || null,
				errorMessage: 'Le numéro SIREN doit contenir exactement 9 chiffres.',
			}, () => this.props.callbackState!(this.state));
		}
	}

	/**
	 * Get Response API siren
	 * @param siren
	 */
	async callApiSiren(siren: number): Promise<void>
	{
		this.setState({ isLoading: true });

		try {
			// Call Api
			const responseSiren = await this.apiPappers.getCompanyBySiren(String(siren));

			// Check and return Errors
			if (responseSiren.statusCode === 404) {
				this.setState({
					isLoading: false,
					errorMessage: `${responseSiren.error} (${responseSiren.message})`
				});
				console.error('Erreur API SIREN:', responseSiren.message);
				return;
			}

			// Prepare Consumer Data
			const preparedConsumerData: ConsumerFormModel = ConsumerFormModel.prepare({
				name: responseSiren.nom_entreprise,
				apeCode: responseSiren.code_naf,
				siren: responseSiren.siren,
				capital: String(responseSiren.capital),
				rcs: responseSiren.greffe,
				legalStatus: responseSiren.forme_juridique,
				address: AddressFormModel.prepare({
					number: responseSiren.siege.numero_voie,
					street: `${ responseSiren.siege.type_voie } ${ responseSiren.siege.libelle_voie }`,
					additionalData: responseSiren.siege.complement_adresse,
					zipCode: responseSiren.siege.code_postal,
					city: responseSiren.siege.ville,
				})
			});

			this.setState(
				{
					isLoading: false,
					errorMessage: (responseSiren.statut_rcs === "Radié") ? "Attention: cette entreprise a été radiée" : null
				}, // Clear error message on success
				() => this.props.callbackResponseApi(preparedConsumerData)
			);
		} catch (error: any) {
			// Return error
			console.error(`Erreur inattendue:`, error);
			this.setState({ isLoading: false, errorMessage: 'Une erreur inattendue est survenue.' });
		}
	}

}
import { CSSProperties } from 'react';

export const CommercialTableStatsAdminStyles = {
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  } as CSSProperties,
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
    borderTop: 0,
    borderLeft: 0,
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd'
  } as CSSProperties,
  tableTh: {
    padding: '10px',
    minWidth: 200,
    border: 'none',
    background: 'transparent'
  } as CSSProperties,
  emptyTableRow: (index: number) => ({
    padding: '10px',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
    borderTop: '1px solid #ddd',
    borderLeft: index === 0 ? '1px solid #ddd' : 'none',
    background: '#f8f9fa'
  }) as CSSProperties,
  yearsRow: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
    borderTop: '1px solid #ddd',
    background: '#f8f9fa'
  } as CSSProperties,
  firstTh: {
    padding: '10px',
    minWidth: 355,
    textAlign: 'left',
    borderTop: '1px solid #ddd',
    borderLeft: '1px solid #ddd'
  } as CSSProperties,
  tableBodyRow: {
    borderBottom: '1px solid #ddd',
    borderLeft: '1px solid #ddd',
    cursor: 'pointer'
  } as CSSProperties
};


import { CSSProperties } from 'react';

export const CreateLegalNoticeStyles = {
	container: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: 'auto 600px',
		columnGap: 40,
		height: 'calc(100% - 100px)',
		overflow: 'hidden'
	} as CSSProperties,

	scrollContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		height: '100%',
		overflow: 'scroll',
		padding: '0 10px 10px 10px'
	} as CSSProperties,

} as const;
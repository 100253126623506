import React, { Component, createRef, ReactElement, RefObject } from 'react';
import { HoveredProps, HoveredState } from './Hovered.interface';
import { HoveredStyles } from '@/Modules/App/Components/Library/Hovered/Hovered.styles';
import { HoveredService } from '@/Modules/App/Components/Library/Hovered/Hovered.service';

class Hovered<T extends HTMLElement> extends Component<HoveredProps<T>, HoveredState>
{
	private hoveredService = new HoveredService();
	private containerRef: RefObject<T>;

	constructor(props: HoveredProps<T>)
	{
		super(props);
		// Config Service
		this.hoveredService.setProps(this.props);
		this.hoveredService.subscribeState(this);

		// State
		this.state = this.hoveredService.getState();
		this.containerRef = createRef<T>();
	}

	render(): ReactElement
	{
		const { children } = this.props;
		const { isHovered } = this.state;

		const renderProps = {
			isHovered,
			ref: this.containerRef,
			onMouseEnter: this.hoveredService.handleMouseEnter,
			onMouseLeave: this.hoveredService.handleMouseLeave,
			style: HoveredStyles.hover(isHovered, this.props),
		};

		return <>{typeof children === 'function' ? children(renderProps) : children}</>;
	}
}

export default Hovered;

import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { CollaboratorsAdminProps, CollaboratorsAdminState } from './CollaboratorsAdmin.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { CollaboratorService } from '@/Service/Collaborator.service';

const initState: CollaboratorsAdminState = {
	isLoading: true,
	list: {} as PaginateInterface,
	isRoleOwner: false,
};

export class CollaboratorsAdminService extends BaseComponentService<CollaboratorsAdminProps, CollaboratorsAdminState>
{
	private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as CollaboratorsAdminProps, initState);

		// Bind
		this.list = this.list.bind(this);
		this.refresh = this.refresh.bind(this);
		this.handleIsRoleOwner = this.handleIsRoleOwner.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		try {
			this.list();
			await this.handleIsRoleOwner();
		} catch (error) {
			console.error('Erreur pendant l\'initialisation: ', error);
		}
	}

	/**
	 * Format list for Collaborators to PaginateInterface
	 */
	list(): void
	{
		this.setState({
			list: {
				items: this.props.client.collaborators,
				itemsPerPage: 10,
				totalCount: this.props.client.collaborators.length,
				totalPages: 1,
			}
		}, () => this.setState({ isLoading: false }));
	}

	/**
	 * Handle Collaborator is Role Owner
	 */
	async handleIsRoleOwner(): Promise<void> {
		try {
			if (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()) {
				this.setState({ isRoleOwner: true });
				return;
			}

			const isRoleOwner: boolean = await CollaboratorService.isRoleOwner();
			this.setState({ isRoleOwner });

		} catch (error) {
			console.error(`Error occurred in ${this.constructor.name}: handleIsRoleOwner`, error);
		}
	}

	/**
	 * Refresh Client to get new Collaborators
	 * @return Promise<void>
	 */
	async refresh(): Promise<void>
	{
		this.setState({ isLoading: true });

		try {
			const client = await this.apiAdminClientService.show!(this.props.client?.id);
			const collaborators: CollaboratorInterface[] = client?.collaborators;

			if (client.response) {
				console.error('Error on get client COLLABORATORADMINSERVICE');
				this.setState({ isLoading: false });
			}

			this.setState({
				list: {
					items: collaborators,
					itemsPerPage: 10,
					totalCount: this.props.client.collaborators.length,
					totalPages: 1,
				}
			}, () => this.setState({ isLoading: false }));

		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }: getClient`, error);
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Build table columns
	 */
	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		const baseColumns: TableHeadColumnsInterface[] = [
			{
				title: 'Nom',
				tag: 'lastname',
				columnWidth: 150,
				fontWeight: 600,
				textAlign: 'left',
				keyType: 'string',
				keyTag: 'user.lastname',
			},
			{
				title: 'Prénom',
				tag: 'firstname',
				columnWidth: 150,
				fontWeight: 600,
				textAlign: 'left',
				keyType: 'string',
				keyTag: 'user.firstname',
			},
			{
				title: 'Email',
				tag: 'email',
				columnWidth: 400,
				fontWeight: 600,
				textAlign: 'left',
				keyType: 'string',
				keyTag: 'email',
			},
			{
				title: 'Rôle',
				tag: 'role',
				columnWidth: 100,
				fontWeight: 500,
				textAlign: 'left',
				keyType: 'tag',
				enumClass: 'CollaboratorRoleEnum',
				keyTag: 'role',
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				fontWeight: 500,
				textAlign: 'left',
				keyType: 'tag',
				enumClass: 'ActiveStatusEnum',
				keyTag: 'status',
			},
		];

		// Add Sellsy column for super admin or admin users
		if (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin()) {
			return [
				{
					title: 'Sellsy',
					tag: 'extSellsyId',
					columnWidth: 100,
					fontWeight: 600,
					textAlign: 'left',
					keyType: 'extSellsyId',
					keyTag: 'extSellsyId',
				},
				...baseColumns,
			];
		}

		return baseColumns;
	}

}
import React, { Component, ReactElement } from 'react';
import { LegalNoticeOptionsHeaderProps, LegalNoticeOptionsHeaderState } from './LegalNoticeOptionsHeader.interface';
import { LegalNoticeOptionsHeaderService } from './LegalNoticeOptionsHeader.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Label from '@/Modules/App/Components/Library/Label/Label';
import {
	LegalNoticeOptionsHeaderStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeOptionsHeader/LegalNoticeOptionsHeader.styles';
import Select from '@/Modules/App/Components/Library/Select/Select';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import AddCustomLogoClientFormComponent
	from '@/Modules/Client/Components/ClientLogos/Create/Form/AddCustomLogoClientFormComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';

class LegalNoticeOptionsHeader extends Component<LegalNoticeOptionsHeaderProps, LegalNoticeOptionsHeaderState>
{
	private legalNoticeOptionsHeaderService = new LegalNoticeOptionsHeaderService();

	constructor(props: LegalNoticeOptionsHeaderProps)
	{
		super(props);

		// Config service
		this.legalNoticeOptionsHeaderService.setProps(this.props);
		this.legalNoticeOptionsHeaderService.subscribeState(this);

		// State
		this.state = this.legalNoticeOptionsHeaderService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeOptionsHeaderService.init();
	}

	componentDidUpdate(prevProps: LegalNoticeOptionsHeaderProps): void
	{
		if (prevProps.isConsumerFormLight !== this.props.isConsumerFormLight) {
			this.legalNoticeOptionsHeaderService.setIsConsumerFormLightState(this.props.isConsumerFormLight);
		}
	}

	componentWillUnmount(): void
	{
		this.legalNoticeOptionsHeaderService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedLogo, logos } = this.state;

		return (
			<>
				<div style={ FontStyles.h3 }> Option d'annonce</div>
				{ (this.props.isDisabled) ? (
					<div style={ {
						backgroundColor: colors.blueRibbon50,
						...borderStyles,
						borderColor: colors.blueRibbon100,
						borderRadius: 6,
						color: colors.blueRibbon800,
						fontSize: 12,
						padding: '10px 10px',
						minHeight: 38,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						textWrap: 'wrap',
						fontStyle: 'italic',
					} }>
						<span> Merci de sélectionner un client et de compléter les informations concernant la société afin d'accéder
						à l'en-tête et au logo.</span>
					</div>
				) : (
					<div style={ LegalNoticeOptionsHeaderStyles.container }>
						<div style={ { display: 'flex', alignItems: 'center', gap: 5 } }>
							<input
								type="checkbox"
								checked={ this.state.isHeader }
								onChange={
									(event) =>
									{
										if (this.props.isConsumerFormLight) {
											event.stopPropagation();
											this.props.modalContext?.content!(
												'Informations client incomplètes',
												<div style={ { ...ModalStyles.padding, width: 500 } }>
													<div style={ FontStyles.textHelp }>Pour activer cette option, veuillez d'abord
														renseigner tous les champs du formulaire du client final. Veuillez cliquer sur continuer
														pour débloquer le formulaire complet.
													</div>
													<div style={ {
														width: '100%',
														display: 'flex',
														justifyContent: 'flex-end',
														alignItems: 'flex-end',
														height: 50,
														gap: 5
													} }>
														<Button
															label={ 'Annuler' }
															variant={ 'small' }
															onClick={ () => this.props.modalContext?.isOpen(false) }
														/>
														<Button
															label={ 'Continuer' }
															variant={ 'smallDark' }
															onClick={ () =>
															{
																this.props.modalContext?.isOpen(false);
																this.setState({ isConsumerFormLight: false },
																	() => this.props.callbackState!(this.state)
																);
															} }
														/>
													</div>
												</div>
											);
										} else {
											const checkIsErrors: boolean = this.props.isConsumerFormComplete();
											if (checkIsErrors) {
												return;
											}
											this.legalNoticeOptionsHeaderService.setIsHeaderState(event.target.checked);
										}
									}
								}
								disabled={ !this.props?.newspaper?.isAllowHeader! || this.props.isDisabledHead! }
							/>
							<Label label={ 'Ajouter un en-tête' } isRequired={ false }/>
						</div>

						<div style={ { display: 'flex', alignItems: 'center', gap: 5, height: '100%' } }>
							<Select
								key={ selectedLogo?.name! }
								listOptions={ logos }
								onSelect={ (logo) => this.legalNoticeOptionsHeaderService.setSelectedLogo(logo) }
								displayKey={ 'name' }
								styles={ { width: '100%' } }
								text={ 'Ajouter un logo ...' }
								value={ selectedLogo?.name || '' }
								isRequired={ false }
								isDisabled={ !this.props?.newspaper?.isAllowHeader! }
							/>

							{ !selectedLogo &&
                <Button
                  iconName={ 'LuPlus' }
                  onClick={ (event: React.ChangeEvent<HTMLButtonElement>) =>
									{
										event.stopPropagation();

										this.props.modalContext?.content(
											'Ajouter un logo',
											<AddCustomLogoClientFormComponent
												client={ this.props.client as ClientInterface }
												modalContext={ this.props.modalContext }
												flashMessageContext={ this.props.flashMessageContext! }
												onCreateLogo={ this.legalNoticeOptionsHeaderService.onCreateLogo.bind(this.legalNoticeOptionsHeaderService) }
											/>,
										);
									} }
                />
							}

							{ selectedLogo &&
                <Button
                  variant={ 'close' }
                  iconName={ 'LuX' }
                  onClick={ () => this.legalNoticeOptionsHeaderService.setSelectedLogo(null) }
                />
							}
						</div>
					</div>
				) }

			</>
		);
	}
}

export default withGlobalContext(LegalNoticeOptionsHeader);
import React, { Component, ReactElement } from 'react';
import {
	BillingPreferencesFormProps,
	BillingPreferencesFormState, billingPreferencesListOptions
} from './BillingPreferencesForm.interface';
import { BillingPreferencesFormService } from './BillingPreferencesForm.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
	BillingPreferencesFormStyles
} from '@/Modules/LegalNotice/Components/Form/BillingPreferencesForm/BillingPreferencesForm.styles';
import Select from '@/Modules/App/Components/Library/Select/Select';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class BillingPreferencesForm extends Component<BillingPreferencesFormProps, BillingPreferencesFormState>
{
	private billingPreferencesFormService = new BillingPreferencesFormService();

	constructor(props: BillingPreferencesFormProps)
	{
		super(props);

		// Config service
		this.billingPreferencesFormService.setProps(this.props);
		this.billingPreferencesFormService.subscribeState(this);

		// State
		this.state = this.billingPreferencesFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.billingPreferencesFormService.init();
	}

	componentWillUnmount(): void
	{
		this.billingPreferencesFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const isEdit: boolean = Object.keys(this.props.stateUpdater).length > 0;
		return (
			<>
				<div style={ BillingPreferencesFormStyles.container(this.props.isDisplayBlock!) }>
					<div style={ BillingPreferencesFormStyles.form }>

						<div style={ {
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 1fr)',
							columnGap: 20,
							alignItems: 'flex-end',
						} }>
							<Input
								key={ this.state.isFree.toString() }
								type={ 'text' }
								label={ 'Taux de remise accordé (en %)' }
								name={ 'discount-granted' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
									this.billingPreferencesFormService.setDiscountGrantedState(Number(event.target.value))
							}
								value={ this.state.discountGranted! || 0 }
								allowedCharacters={ 'digits' }
								disabled={ this.state.isFree }
							/>

							<div style={ { display: 'flex', gap: 5, alignItems: 'center' } }>
								<input
									type="checkbox"
									onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
										this.billingPreferencesFormService.setIsFreeState(event.target.checked)
									}
								/>
								<Label label={ 'Appliquer une gratuité' } isRequired={ false }/>
							</div>
						</div>

						<Select
							label={ 'Préférence de remise...' }
							listOptions={ ClientDiscountPreferenceEnum.options }
							onSelect={ (discountPreference) =>
								this.billingPreferencesFormService.setDiscountPreferenceState(discountPreference)
							}
							displayKey={ 'label' }
							value={ this.state.discountPreference?.label || '' }
							isRequired={ true }
							isDisabled={ Object.keys(this.props.stateUpdater).length > 0 }
						/>

						<Select
							label={ 'Facture éditée au...' }
							listOptions={ billingPreferencesListOptions }
							onSelect={ (invoicePreference) =>
								this.billingPreferencesFormService.setInvoiceAffiliationState(invoicePreference)
							}
							displayKey={ 'label' }
							value={ (
								<div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
									<span>{ this.state.invoiceAffiliation.label }</span>
									<span style={ { ...FontStyles.textHelp, fontStyle: 'italic' } }>
										{ this.state.invoiceAffiliation.value === 'CLIENT' && `(${this.props.client.name})` }
									</span>
								</div>
							) || '' }
							isRequired={ true }
							isDisabled={ isEdit }
						/>

						<Select
							key={ this.state.discountPreference?.value + this.state.invoiceAffiliation.value }
							label={ 'Avoir éditée au...' }
							listOptions={ billingPreferencesListOptions }
							onSelect={ (creditNotePreference) =>
								this.billingPreferencesFormService.setCreditNoteAffiliationState(creditNotePreference)
							}
							displayKey={ 'label' }
							value={ (
								<div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
									<span>{ this.state.creditNoteAffiliation.label }</span>
									<span style={ { ...FontStyles.textHelp, fontStyle: 'italic' } }>
										{ this.state.creditNoteAffiliation.value === 'CLIENT' && `(${this.props.client.name})` }
									</span>
								</div>
							) || '' }
							isRequired={ true }
							isDisabled={ this.state.discountPreference === ClientDiscountPreferenceEnum.CREDIT_DEDUCTED || this.state.isFree || isEdit }
						/>
					</div>

					<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: 50}}>
						<Button
							label={ 'Suivant' }
							variant={'small'}
							onClick={
								() => this.props.callbackState(this.state, true)
							}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(BillingPreferencesForm);
import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export class OffCanvasComponentStyle
{
  static containerStyle(props: any): CSSProperties
  {
    const widthStyle: string = props.show ? `${ props.width ?? 850 }px` : '0px';
    const heightStyle: string = `${ props.height ?? 100 }vh`;

    return {
      position: 'fixed',
      width: widthStyle,
      height: `calc(${ heightStyle } - 50px)`,
      top: '50px',
      right: 0,
      backgroundColor: CssVariableEnum['--color-white'],
      borderLeft: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      borderBottom: (props.show) ? `1px solid ${ CssVariableEnum['--color-grey-200'] }` : '',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
      transition: 'width 270ms ease',
      zIndex: 1000,
      overflowX: 'hidden'
    };
  }

  static content(): CSSProperties
  {
    return {
      padding: '0 30px 30px 30px',
      position: 'relative',
      top: 70,
      minHeight: 'calc(100% + 100px)',
      overflowY: 'scroll'
    };
  }

  static headerActionsStyle(): CSSProperties
  {
    return {
      position: 'fixed',
      backgroundColor: CssVariableEnum['--color-white'],
      zIndex: 1200,
      width: '100%',
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px'
    };
  }

  static devHeaderActionsStyle(): CSSProperties
  {
    return {
      position: 'fixed',
      backgroundColor: themeColors.darkBackground,
      zIndex: 1200,
      width: '100%',
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px'
    };
  }

  static tabsStyle(): CSSProperties
  {
    return {
      display: 'inline-flex',
      borderRadius: '6px',
      height: '32px',
      backgroundColor: CssVariableEnum['--color-grey-200'],
      marginTop: 10
    };
  }

  static activeTabStyle(state: any, tab: any): CSSProperties
  {
    return  {
      height: '100%',
      padding: '5px 10px',
      color: 'tab' + tab.id === state.selectedTab ? CssVariableEnum['--color-grey-900'] : CssVariableEnum['--color-grey-400'],
      cursor: 'pointer',
      backgroundColor: 'tab' + tab.id === state.selectedTab ? 'white' : '',
      borderRadius: '6px',
      border: 'tab' + tab.id === state.selectedTab ? `1px solid ${ CssVariableEnum['--color-grey-300'] }` : 'none'
    };
  }
}
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { PricingLineInterface } from '@/Modules/Pricing/Interface/PricingLineInterface';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CreateLegalNoticeInterface } from '@/Modules/LegalNotice/Common/CreateLegalNotice.interface';

export interface LegalNoticePriceDetailsProps
{
	legalNotice: LegalNoticeInterface|CreateLegalNoticeInterface,
	client: ClientInterface,
	selectedDepartment: DepartmentInterface,
	selectedNewspaper: NewspaperInterface,
	selectedCategory: FormBuilderCategoryInterface,
	consumer: ConsumerDataInterface,
	isHeaderCharacterCount?: number,
	callbackState?: (state: LegalNoticePriceDetailsState) => void,
}

export interface LegalNoticePriceDetailsState
{
	isLoading: boolean,
	priceList: PaginateInterface,
	isFixedPrice: boolean,
	annexPrice: number,
	printPrice: PricingLineInterface | null,
	externPrintPrice: number|null,
	shippingCostPrice: PricingLineInterface | null,
	bodaccPrice: PricingLineInterface | null,
	externPrice: PricingLineInterface | null,
	isPriceLoaded: boolean,
	nbCharacters: number,
}

export function LegalNoticePriceDetailsDefault(): LegalNoticePriceDetailsState
{
	return {
		isLoading: true,
		priceList: {} as PaginateInterface,
		isFixedPrice: false,
		annexPrice: 0,
		printPrice: null,
		externPrintPrice: null,
		shippingCostPrice: null,
		bodaccPrice: null,
		externPrice: null,
		isPriceLoaded: false,
		nbCharacters: 0,
	};
}
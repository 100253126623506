import { stringAvatar } from '@/Utils/AvatarUtils';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';

export const AvatarStyles = {
	container: (authContext: AuthContextType) => ({
		width: 30,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: stringAvatar(authContext?.user.firstname, authContext?.user.lastname).color,
		userSelect: 'none' as const,
		MozUserSelect: 'none' as const,
		WebkitUserSelect: 'none' as const,
		msUserSelect: 'none' as const,
		cursor: 'pointer',
	}),
} as const;
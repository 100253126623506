import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiPaymentService } from '@/Service/Api/ApiPaymentService';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { LuCheckCheck, LuXCircle } from 'react-icons/lu';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

interface PaymentCallbackProps
{
	paymentStatus: string;
	navigation: NavigateFunction;
}

export default class PaymentCallback extends React.Component
	<PaymentCallbackProps, any>
{
	paymentService: ApiPaymentService;
	apiPublicService: ApiPublicService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.paymentService = new ApiPaymentService();
		this.apiPublicService = new ApiPublicService();

		// Document title
		document.title = 'Publisur - Merci pour votre paiement';
	}

	async componentDidMount(): Promise<void>
	{
		const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
		// Get the TransID from URL params
		const transId: string | null = urlParams.get('TransID');

		if (transId) {
			// Get LegalNotice
			await this.apiPublicService.getLegalNotice(Number(transId));
		}
	}

	render(): ReactElement
	{
		return (
			<div style={ { width: '100%', display: 'flex', top: 100, position: 'relative', justifyContent: 'center' } }>
				<div style={ { textAlign: 'center' } }>
					{ (this.props.paymentStatus === 'success')
						? (
							this.successRender()
						) : (
							this.failureRender()
						)
					}
				</div>
			</div>
		);
	}

	private successRender(): ReactElement
	{
		return (
			<>
				<div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
					<div style={ {
						width: 50,
						height: 50,
						borderRadius: '50%',
						backgroundColor: CssVariableEnum['--color-green-500'],
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					} }>
						<LuCheckCheck color={ CssVariableEnum['--color-white'] } size={ 25 }/>
					</div>
				</div>
				<div style={ { fontSize: 24, fontWeight: 600, color: CssVariableEnum['--color-green-500'] } }>
					Transaction confirmée
				</div>
				<div style={ { ...FontStyle.littleGrey(), marginBottom: 20 } }>
					Votre paiment a bien été effectué <br/>
					D'ici quelques minutes vous recevrez les documents associés sur l'email renseigné.
				</div>
				<Button
					label={ 'Quitter et fermer cette fenêtre' }
					variant={ 'dark' }
					onClick={ () => window.close() }
				/>
			</>
		);
	}

	private failureRender(): ReactElement
	{
		return (
			<>
				<div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
					<div style={ {
						width: 50,
						height: 50,
						borderRadius: '50%',
						backgroundColor: colors.error300,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					} }>
						<LuXCircle color={ colors.error700 } size={ 25 }/>
					</div>
				</div>
				<div style={ { fontSize: 24, fontWeight: 600, color: colors.error500 } }>
					Erreur sur la transaction
				</div>
				<div style={ { ...FontStyle.littleGrey(), marginBottom: 20 } }>
					Votre paiment a été annulé <br/>
					Nous avons rencontré une erreur lors de votre paiement, merci de fermer cette fenêtre et d'effectuer un nouvel essai.
				</div>
				<div>
					<Button
						label={ 'Quitter et fermer cette fenêtre' }
						variant={ 'dark' }
						onClick={ () => window.close() }
					/>
				</div>

			</>
		);
	}
}

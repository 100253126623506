import React, { Component, ReactElement } from 'react';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { LegalNoticesDeveloperService } from '@/Modules/LegalNotice/Developer/List/LegalNoticesDeveloper.service';
import {
  LegalNoticesDeveloperProps,
  LegalNoticesDeveloperState
} from '@/Modules/LegalNotice/Developer/List/LegalNoticesDeveloper.interface';
import { ApiLegalNoticeDeveloperService } from '@/Service/Developer/ApiLegalNoticeDeveloperService';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import LegalNoticeDeveloper from '@/Modules/LegalNotice/Developer/Show/LegalNoticeDeveloper';

class LegalNoticesDeveloper extends Component<LegalNoticesDeveloperProps, LegalNoticesDeveloperState>
{
  private legalNoticesService: LegalNoticesDeveloperService = new LegalNoticesDeveloperService();
  private apiLegalNotice = new ApiLegalNoticeDeveloperService();

  constructor(props: LegalNoticesDeveloperProps)
  {
    super(props);

    // Config service
    this.legalNoticesService.setProps(this.props);
    this.legalNoticesService.subscribeState(this);

    // State & props
    this.state = this.legalNoticesService.getState();

    // Document title
    document.title = 'Publisur - ADMIN - Legal notice';
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.legalNoticesService.init();
  }

  componentWillUnmount(): void
  {
    this.legalNoticesService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <HeroSection
          title="Annonces légales"
          icon={ null }
        />

        <OffcanvaContext.Consumer>
          { (offcanvaContext) => (
            <ListTableManager
              isTableControls={ true }
              refreshList={ this.state.legalNoticeList }
              tag={ 'legal-notice' }
              tableColumns={ this.legalNoticesService.buildColumnHeader() }
              serviceList={ this.apiLegalNotice.list.bind(this.apiLegalNotice) }
              filters={ this.legalNoticesService.buildFilters() }
              onRowClick={ (event: any, item: LegalNoticeInterface) => {
                event.stopPropagation();

                offcanvaContext?.content(
                  null,
                  <GlobalContextProvider>
                    <LegalNoticeDeveloper
                      legalNotice={ item }
                      getComponentState={ () => this.legalNoticesService.refresh() }
                    />
                  </GlobalContextProvider>
                );

                offcanvaContext?.isOpen(true);
              }}
            />
          )}
        </OffcanvaContext.Consumer>
      </>
    );
  }
}

export default withGlobalContext(LegalNoticesDeveloper);
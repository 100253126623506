import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  ClientLegalNoticeStatsInterface,
  ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats.interface';
import { ApiClientLegalNoticeStatsService } from '@/Service/Api/ApiClientLegalNoticeStatsService';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';

const initState: ClientLegalNoticeStatsState = {
  isLoading: true,
  statsList: [],
  editStatsId: null,
  newStats: null
}

export class ClientLegalNoticeStatsService extends BaseComponentService<ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState>
{
  private apiAdminClientLegalNoticeStats: ApiClientLegalNoticeStatsService = new ApiClientLegalNoticeStatsService();

  constructor()
  {
    super({} as ClientLegalNoticeStatsProps, initState);
  }

  async init(): Promise<any>
  {
    this.setState({ isLoading: true });
    await this.list()
      .then((response) => { this.setState({ statsList: response, isLoading: false }) })
    ;
  }

  async list(): Promise<any>
  {
    try {
      return await this.apiAdminClientLegalNoticeStats.list(this.props.client.id);
    } catch (error) {
      console.error('[CLIENT-LEGAL-NOTICE-STATS] error on get List', error);
      return [];
    }
  }

  async createStat(createStatsData: ClientLegalNoticeStatsInterface): Promise<void>
  {
    await this.apiAdminClientLegalNoticeStats.create(this.props.client?.id, createStatsData)
      .then(async () => { await this.init(); this.props.refreshClient(this.state.statsList); })
    ;
  }

  async editStat(editStatsData: ClientLegalNoticeStatsInterface): Promise<void>
  {
    await this.apiAdminClientLegalNoticeStats.edit(editStatsData.id as number, editStatsData)
      .then(async () => { await this.init(); this.props.refreshClient(this.state.statsList); })
    ;
  }

  buildColumnHeader(): TableHeaderColumnInterface[]
  {
    return [
      { columnTitle: 'Année', columnSize: 6, name: 'Year' },
      { columnTitle: 'Interne', columnSize: 6, name: 'intern' },
      { columnTitle: 'Externe', columnSize: 6, name: 'extern' }
    ];
  }
}
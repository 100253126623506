import React, { Component, ReactElement } from 'react';
import {
	LegalNoticeSendToAdditionalProps, LegalNoticeSendToAdditionalState,
} from './LegalNoticeSendToAdditional.interface';
import { LegalNoticeSendToAdditionalService } from './LegalNoticeSendToAdditional.service';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import AddressFormComponent from '@/Modules/LegalNotice/ComponentsOld/Form/Admin/AddressFormComponent';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

class LegalNoticeSendToAdditional extends Component<LegalNoticeSendToAdditionalProps, LegalNoticeSendToAdditionalState>
{
	private legalNoticeSendToAdditionalService: LegalNoticeSendToAdditionalService = new LegalNoticeSendToAdditionalService();

	constructor(props: LegalNoticeSendToAdditionalProps)
	{
		super(props);

		// Config service
		this.legalNoticeSendToAdditionalService.setProps(this.props);
		this.legalNoticeSendToAdditionalService.subscribeState(this);

		// State
		this.state = this.legalNoticeSendToAdditionalService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeSendToAdditionalService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeSendToAdditionalService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}
		
		return (
			<>
				<div style={ { width: '100%' } }>
					<AddressFormComponent
						formData={ this.state.formData.billingAddress }
						onChange={ (event: any) => this.legalNoticeSendToAdditionalService.onChangeBillingAddress(event) }
						onBlur={ () => {} }
					/>

					<hr />

					<Input
						type={ 'text' }
						name={ 'numberOfCopies' }
						label={ 'Nombre de journaux (Papier)' }
						allowedCharacters={ 'digits' }
						value={ this.state.formData.numberOfCopies || '' }
						onChange={ (event: any) => this.legalNoticeSendToAdditionalService.onChangeNumberOfCopies(event) }
					/>

					<div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
						<Button
							variant={ 'primary' }
							label={ 'Enregistrer un envoi supplémentaire' }
							onClick={ () => { this.legalNoticeSendToAdditionalService.onCreate() } }
						/>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeSendToAdditional);
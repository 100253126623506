import React, { Component, ReactElement, ReactNode } from 'react';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ContentSidebarInterface } from '@/Modules/App/Components/Content/Content.Sidebar.Interface';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class Content extends Component<any, any>
{
	static WithSidebar: typeof WithSidebar;
	static Main: typeof Main;
	static Sidebar: typeof Sidebar;

	render(): ReactElement
	{
		return (
			<div style={ UserConnectedService.isDeveloper() ? ContentStyles.devWrapper : ContentStyles.wrapper }>
				<div style={ ContentStyles.container }>
					{ this.props.children }
				</div>
			</div>
		);
	}
}

class WithSidebar extends Component<{ sidebar: ReactElement, children: ReactNode }, any>
{
	render(): ReactElement
	{
		const { sidebar, children } = this.props;

		return (
			<div style={ ContentStyles.sidebar }>
				<div>
					{ children }
				</div>
				<div style={ ContentStyles.sidebar_block }>
					{ sidebar }
				</div>
			</div>
		);
	}
}

class Main extends Component<any, any>
{
	render(): ReactElement
	{
		return (
			<div>
				{ this.props.children }
			</div>
		);
	}
}

class Sidebar extends Component<{ render: ContentSidebarInterface[] }, any>
{
	static Block: typeof Block;

	render(): ReactElement
	{
		return (
			<>
				{ this.props.render.map((block, index: number) => (
					<div key={ index }>
						<div style={ ContentStyles.sidebar_block_title }>
							<span>{ block.blockTitle }</span>
							{ block.button &&
								<Button
									variant={ block.button.variant! }
									label={ block.button.label! }
									iconName={ block.button.iconName! }
									onClick={ block.button.onClick }
								/>
							}
						</div>
						{ block.items.map((item: any, idx: any) =>
							<Content.Sidebar.Block
								key={ idx } title={ item.title }
								data={ item.data! }
								enumName={ item.enumName }
								isClickable={ item.isClickable }
							/>
						) }
					</div>
				)) }
			</>
		);
	}
}

class Block extends Component<{
	title: string;
	data: string | number | null | undefined;
	enumName?: string;
	isClickable: boolean
}, any>
{
	render(): ReactElement
	{
		const { title, data, enumName } = this.props;

		return (
			<div style={ ContentStyles.sidebar_block_content }>
				<div>
					<div style={ ContentStyles.sidebar_block_content_title }>{ title }</div>
					<div style={ ContentStyles.sidebar_block_content_element(this.props.isClickable) }>
						{ data !== undefined && data !== null && data !== '' ? (
							enumName ? (
								<Tag value={ data as string } enumName={ enumName }/>
							) : (
								data
							)
						) : (
							'—'
						)}
					</div>
				</div>
			</div>
		);
	}
}

Content.WithSidebar = WithSidebar;
Content.Main = Main;
Content.Sidebar = Sidebar;
Content.Sidebar.Block = Block;

export default Content;

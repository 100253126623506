import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const LegalNoticeWritingContentStyles = {
	container: {
		height: '100%',
	} as CSSProperties,

	textarea: {
		height: '100%',
		width: '100%',
		resize: 'none',
		borderColor: colors.gray200,
		borderRadius: 6,
		padding: 10,
	} as CSSProperties,

	menu : {
		width: '100%',
		height: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: 5,
	} as CSSProperties,

	editableDiv: {
		...borderStyles,
		borderColor: colors.gray300,
		backgroundColor: colors.white,
		borderRadius: 6,
		minHeight: 'calc(100% - 30px)',
		maxHeight: '400px',
		padding: 10,
		outline: 'none',
		overflowY: 'auto',
	},

} as const;
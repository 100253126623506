import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';

export class ApiAdminLegalNoticeService extends BaseService
{
	legalNoticeUrl: string = `${ BaseService.API_URL }/v1/admin/legal-notices`;

	async list(params?: string): Promise<any>
	{
		try {
			const url = params ? this.legalNoticeUrl + '?' + params : this.legalNoticeUrl;
			const response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async specificList(term: string, params?: string): Promise<any>
	{
		try {
			const validateUrl: string = `${ this.legalNoticeUrl }/specificList/${ term }`;
			const url: string = params ? validateUrl + '?' + params : validateUrl;
			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async specificListCount(term: string, params?: string): Promise<any>
	{
		try {
			const validateUrl: string = `${ this.legalNoticeUrl }/specificList/${ term }/count`;
			const url: string = params ? validateUrl + '?' + params : validateUrl;
			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getLegalNoticeByLogo(clientLogoId: number): Promise<LegalNoticeInterface[]>
	{
		try {
			const url: string = this.legalNoticeUrl + `/logo/${ clientLogoId }`;
			const response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
			throw new Error(`Error occurred on ${ this.constructor.name }`);
		}
	}

	async clientList(clientId: number, params?: string): Promise<any>
	{
		try {
			const clientListUrl: string = `${ this.legalNoticeUrl }/clientList/${ clientId }`;
			const url: string = params ? clientListUrl + '?' + params : clientListUrl;
			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(legalNoticeId: number): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(legalNoticeData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				this.legalNoticeUrl,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(legalNoticeId: number, legalNoticeData: any): Promise<any>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + '/' + legalNoticeId,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async editContent(legalNoticeId: number, legalNoticeContentData: any): Promise<any>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + '/content/' + legalNoticeId,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeContentData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async editClientLogo(clientLogoId: number, legalNoticeId: number): Promise<LegalNoticeInterface>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + `/logo/${clientLogoId}/${legalNoticeId}`,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
			throw new Error(`Error occurred on ${ this.constructor.name }`);
		}
	}

	async delete(legalNoticeId: number, nbCharacters: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
					body: JSON.stringify({ nbCharacters: nbCharacters }),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async duplicate(legalNoticeId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async respondQuote(legalNoticeId: number, answerData: {
		respond: string,
		legalNotice: { nbCharacters: number }
	}): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/quote/respond`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(answerData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
			throw new Error(`Error occurred in respond quote`, error!);
		}
	}

	/**
	 * Force legalNotice Payment Status
	 * @param legalNoticeId
	 * @param paymentStatus
	 */
	async forceLegalNoticePaymentStatus(legalNoticeId: number, paymentStatus: string): Promise<any>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + `/force/payment/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(paymentStatus),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}


	async sendBackEmail(legalNoticeId: number, sendBackEmailData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/send-back-email`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(sendBackEmailData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async sendToAdditional(legalNoticeId: number, sendToAdditionalData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/send-to-additional`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(sendToAdditionalData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeTableListProps, LegalNoticeTableListState } from './LegalNoticeTableList.interface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: LegalNoticeTableListState = {
	isLoading: true,
	list: {} as PaginateInterface,
	filterParams: {
		page: 1,
		itemsPerPage: 10,
		search: null,
		filters: null,
		orderBy: null,
		sort: null,
	}
};

export class LegalNoticeTableListService extends BaseComponentService<LegalNoticeTableListProps, LegalNoticeTableListState>
{
	private apiLegalNoticeAdminService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	private apiLegalNoticeService: ApiLegalNoticeService = new ApiLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeTableListProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.list();
	}

	/**
	 * Legal notice List
	 * @return Promise<PaginateInterface>
	 */
	async list(): Promise<PaginateInterface | undefined>
	{
		try {

			this.setState({
				filterParams: {
					...this.state.filterParams,
					search: `${ this.props.client?.name }`
				}
			}, async () => {

				let list: PaginateInterface = {} as PaginateInterface;

				if( UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin() ) {
					list = await this.apiLegalNoticeAdminService.list(paramsToString(this.state.filterParams));
				} else {
					list = await this.apiLegalNoticeService.list(LocalStorageService.getAuthClient(), paramsToString(this.state.filterParams));
				}

				this.setState({ list }, () => this.setState({ isLoading: false }));
				return list;
			});

		} catch (error: any) {
			console.log('Failed to fetch legal notice List', error.message);
			this.setState({ isLoading: false });
			return undefined;
		}
	}

	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'N° d\'annonce',
				tag: 'tag',
				columnWidth: 120,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['tag']
			},
			{
				title: 'Publication au ...',
				tag: 'publishDate',
				columnWidth: 100,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'publishDate'
			},
			{
				title: 'Journal',
				tag: 'newspaper',
				columnWidth: 200,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'newspaper.name'
			},
			{
				title: 'Client final',
				tag: 'consumer',
				columnWidth: 300,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'consumer.name'
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'PublishStateEnum',
				keyTag: 'status'
			},
			{
				title: 'Paiement',
				tag: 'paymentStatus',
				columnWidth: 80,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'PaymentStateEnum',
				keyTag: 'paymentStatus'
			},
			{
				title: 'Devis',
				tag: 'quoteStatus',
				columnWidth: 80,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'QuoteStateEnum',
				keyTag: ['quoteStatus']
			},
		];
	}
}
import React, { Component, ReactElement } from 'react';
import { SellsyCompanyProps, SellsyCompanyState } from './SellsyCompany.interface';
import { SellsyCompanyService } from './SellsyCompany.service';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import { SellsyCompanyStyles } from '@/Modules/App/Components/Sellsy/SellsyCompany/SellsyCompany.styles';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import AssociateSellsyCompany from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { Alert } from 'react-bootstrap';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import UpdateSellsyAddress from '@/Modules/App/Components/Sellsy/UpdateSellsyAddress/UpdateSellsyAddress';

class SellsyCompany extends Component<SellsyCompanyProps, SellsyCompanyState>
{
	private sellsyCompanyService = new SellsyCompanyService();

	constructor(props: SellsyCompanyProps)
	{
		super(props);

		// Config service
		this.sellsyCompanyService.setProps(this.props);
		this.sellsyCompanyService.subscribeState(this);

		// State
		this.state = this.sellsyCompanyService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.sellsyCompanyService.init();
	}

	componentWillUnmount(): void
	{
		this.sellsyCompanyService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { sellsyDetails, isLoading, sellsyCompanyAddress } = this.state;
		if (isLoading) {
			return <LoaderComponent/>;
		}

		const colorLine = { colorInfo: colors.purple400, colorData: colors.purple600 };

		return (
			<ModalContext.Consumer>
				{ (modalContext) => (
					<div style={ SellsyCompanyStyles.container }>
						{ sellsyDetails.id ? (
							<>
								<LineDetailsRender
									info={ 'Id Sellsy' }
									formData={ sellsyDetails.id }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Nom' }
									formData={ sellsyDetails.name }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Code Auxiliaire' }
									formData={ sellsyDetails.auxiliary_code }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Email' }
									formData={ sellsyDetails.email }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Numéro de téléphone' }
									formData={ sellsyDetails.phone_number }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Id de l’adresse' }
									formData={ sellsyCompanyAddress.extSellsyId! || null }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Rue' }
									formData={ sellsyCompanyAddress.street }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Code postal' }
									formData={ sellsyCompanyAddress.zipCode }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Ville' }
									formData={ sellsyCompanyAddress.city }
									textColor={ colorLine }
								/>

								{ this.props.client.address.extSellsyId ? (
									<p style={ { color: 'green', fontWeight: 'bold' } }>✅ Adresse liée à Sellsy</p>
								) : (
									<Alert variant={ 'warning' }>
										<div>
											<b>ATTENTION</b>: ce <b>donneur d'ordre</b> n'a pas <b>d'adresse Sellsy renseignée</b> sur
											le <b>CRM</b>. <br/>
											Pour <b>résoudre</b> le souci, merci de <b>vérifier</b> sur <b>Sellsy</b> si le donneur d'ordre a
											bien une adresse. <br/>
											Ensuite, merci de <b>cliquer sur ce bouton</b> pour <b>mettre à jour</b> les informations.
										</div>

										<hr/>
									</Alert>
								) }

								{ this.state.addressIdIssue &&
                  <div style={ {
										marginTop: 15,
										backgroundColor: colors.error50,
										...borderStyles,
										borderColor: colors.error100,
										borderRadius: 6,
										color: colors.error600,
										padding: '5px 10px',
										height: 38,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									} }>
                    <span> Nous avons constaté une erreur dans la liaison de votre adresse sellsy.</span>
                  </div>
								}

								<div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 5 } }>
									<Button
										label={ 'Mettre à jour l\'adresse' }
										variant={ 'outlineSellsy' }
										onClick={ async (event: any) =>
										{
											{
												event.stopPropagation();

												if (this.state.sellsyCompanyAddress?.extSellsyId! && !this.state.client?.address?.extSellsyId!) {
													await this.sellsyCompanyService.updateAddressExtSellsyId(this.props?.client?.id!, this.state.sellsyCompanyAddress?.extSellsyId!);
												} else {
													this.props.modalContext?.content(
														'Associé un compte Sellsy',
														<GlobalContextProvider>
															<div style={ SellsyCompanyStyles.modal }>
																<UpdateSellsyAddress
																	client={ this.props.client }
																	clientSellsyAddress={ this.state.sellsyCompanyAddress! }
																	callbackState={ (state) => this.sellsyCompanyService.handleRefresh(state) }
																/>
															</div>
														</GlobalContextProvider>
													);
												}
											}
										} }
										disabled={ Boolean(!sellsyDetails.delivery_address_id) }
									/>

									<Button
										label={ 'Modifier' }
										variant={ 'sellsy' }
										onClick={ (event: any) =>
										{
											event.stopPropagation();
											modalContext?.content(
												'Associé un compte Sellsy',
												<GlobalContextProvider>
													<div style={ SellsyCompanyStyles.modal }>
														<AssociateSellsyCompany
															key={ this.state.client?.id! }
															client={ this.state.client! }
															associateCompany={ this.state.client!}
															isWaitingCreate={ false }
															getComponentState={
																(associateState: AssociateSellsyCompanyState) =>
																	this.sellsyCompanyService.handleAssociateSellsy(associateState, null, modalContext)
															}
														/>
													</div>
												</GlobalContextProvider>
											);
										}
										}
									/>
								</div>
							</>
						) : (
							<div style={ SellsyCompanyStyles.notFound }>
								<div style={ SellsyCompanyStyles.notFound_title }>
									Ce client n'est associé à aucun compte Sellsy.
								</div>
								<div style={ SellsyCompanyStyles.notFound_actions }>
									<Button
										label={ 'Associer' }
										variant={ 'sellsy' }
										onClick={ (event: any) =>
										{
											event.stopPropagation();
											modalContext?.content(
												'Associé un compte Sellsy',
												<GlobalContextProvider>
													<div style={ SellsyCompanyStyles.modal }>
														<AssociateSellsyCompany
															key={ this.props.client.id! }
															client={ this.props.client! }
															associateCompany={ this.props.client! }
															isWaitingCreate={ false }
															getComponentState={
																(associateState: AssociateSellsyCompanyState) =>
																	this.sellsyCompanyService.handleAssociateSellsy(associateState, null, modalContext)
															}
														/>
													</div>
												</GlobalContextProvider>
											);
										}
										}
									/>
								</div>
							</div>
						) }
					</div>
				) }
			</ModalContext.Consumer>
		);
	}
}

export default withGlobalContext(SellsyCompany);
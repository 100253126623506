import React, { Component, ReactElement } from 'react';
import { LegalNoticePriceRenderProps, LegalNoticePriceRenderState } from './LegalNoticePriceRender.interface';
import { LegalNoticePriceRenderService } from './LegalNoticePriceRender.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import {
	LegalNoticePriceRenderStyles
} from '@/Modules/LegalNotice/Components/LegalNoticePriceRender/LegalNoticePriceRender.styles';

class LegalNoticePriceRender extends Component<LegalNoticePriceRenderProps, LegalNoticePriceRenderState>
{
	private legalNoticePriceRenderService = new LegalNoticePriceRenderService();

	constructor(props: LegalNoticePriceRenderProps)
	{
		super(props);

		// Config service
		this.legalNoticePriceRenderService.setProps(this.props);
		this.legalNoticePriceRenderService.subscribeState(this);

		// State
		this.state = this.legalNoticePriceRenderService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticePriceRenderService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticePriceRenderService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { fixedPrice, totalPrice } = this.state;

		return (
			<>
				<div style={ LegalNoticePriceRenderStyles.container }>
					{ (fixedPrice) &&
            <div style={ LegalNoticePriceRenderStyles.totalPrice }>
							{ (fixedPrice) && `${ fixedPrice }.00 € HT` }
						</div>
					}
					{ (!fixedPrice && totalPrice) && (
						<div>
							<span style={LegalNoticePriceRenderStyles.nbCharacters}>Caractères: { this.state.numberOfCharacters && this.state.numberOfCharacters }</span>
							<span style={LegalNoticePriceRenderStyles.totalPrice}>{ (totalPrice && !fixedPrice) && `${ totalPrice.toFixed(2) }€ HT` }</span>
						</div>
					) }
				</div>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticePriceRender);
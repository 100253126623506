import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	BillingPreferencesFormProps,
	BillingPreferencesFormState,
} from './BillingPreferencesForm.interface';
import { ClientOptionsInterface } from '@/Modules/Client/Interface/ClientOptionsInterface';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';

const initState: BillingPreferencesFormState = {
	clientOptions: {} as ClientOptionsInterface,
	isFree: false,
	discountPreference: null,
	discountGranted: 0,
	invoiceAffiliation: {} as { label: string, value: string },
	creditNoteAffiliation: {} as { label: string, value: string },
};

export class BillingPreferencesFormService extends BaseComponentService<BillingPreferencesFormProps, BillingPreferencesFormState>
{
	constructor()
	{
		super({} as BillingPreferencesFormProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.client) {

			if(Object.keys(this.props.stateUpdater).length > 0) {
				this.setDiscountGrantedState(this.props.stateUpdater?.discountGranted!);
				this.setDiscountPreferenceState(this.props.stateUpdater.discountPreference!);
				this.setInvoiceAffiliationState(this.props.stateUpdater?.invoiceAffiliation!);
				this.setCreditNoteAffiliationState(this.props.stateUpdater?.creditNoteAffiliation!);

			} else {
				this.setDiscountGrantedState(this.props.client.options.discountGranted);
				this.setDiscountPreferenceState(this.props.client.options.discountPreference);

				const invoicePreference: {
					label: string,
					value: string
				} = (this.props?.client?.options?.billedTo! === BilledToTypeEnum.ORDER_GIVER.value)
					? { label: 'Donneur d\'ordre', value: 'CLIENT' }
					: { label: 'Client final', value: 'CONSUMER' };

				this.setInvoiceAffiliationState(invoicePreference);
				this.setCreditNoteAffiliationState({ label: 'Donneur d\'ordre', value: 'CLIENT' });
			}
		}
	}

	/**
	 * Set Discount Granted state
	 * @param discountGranted
	 */
	setDiscountGrantedState(discountGranted: number): void
	{
		this.setState({
			discountGranted,
		}, () => this.props.callbackState!(this.state));
	}

	/**
	 * Set isFree State
	 * @param isFree
	 */
	setIsFreeState(isFree: boolean): void
	{
		this.setState({
			isFree,
		}, () =>
		{
			const discountGranted: number = (this.state.isFree) ? 100 : this.props.client?.options?.discountGranted;
			const discountPreference: ClientDiscountPreferenceEnum = (this.state.isFree)
				? ClientDiscountPreferenceEnum.CREDIT_DEDUCTED
				: this.props.client?.options?.discountPreference!;
			this.setDiscountGrantedState(discountGranted);
			this.setDiscountPreferenceState(discountPreference);
			// callback State
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Set Invoice Affiliation State
	 * @param invoiceAffiliation
	 */
	setInvoiceAffiliationState(invoiceAffiliation: { label: string, value: string }): void
	{
		this.setState({
			invoiceAffiliation,
		}, () =>
		{
			const isCreditDeduced: boolean = this.state.discountPreference === ClientDiscountPreferenceEnum.CREDIT_DEDUCTED;
			if (isCreditDeduced) {
				this.setCreditNoteAffiliationState(this.state.invoiceAffiliation);
			}
			// callback State
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Set Credit Note affiliation State
	 * @param creditNoteAffiliation
	 */
	setCreditNoteAffiliationState(creditNoteAffiliation: { label: string, value: string }): void
	{
		this.setState({
			creditNoteAffiliation,
		}, () => this.props.callbackState!(this.state));
	}

	/**
	 * Set Discount preference State
	 * @param discountPreference
	 */
	setDiscountPreferenceState(discountPreference: ClientDiscountPreferenceEnum | string): void
	{
		const updateDiscountPreference = typeof discountPreference === 'string'
			? ClientDiscountPreferenceEnum.findByValue(discountPreference)
			: discountPreference;

		this.setState({
			discountPreference: updateDiscountPreference,
		}, () =>
		{
			const isCreditDeduced: boolean = this.state.discountPreference === ClientDiscountPreferenceEnum.CREDIT_DEDUCTED;
			if (isCreditDeduced) {
				this.setCreditNoteAffiliationState(this.state.invoiceAffiliation);
			} else {
				this.setCreditNoteAffiliationState({ label: 'Donneur d\'ordre', value: 'CLIENT' });
			}
			// callback State
			this.props.callbackState!(this.state);
		});
	}

}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { FormSectionProps, FormSectionState } from './FormSection.interface';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';

const initState: FormSectionState = {
	isLoading: true,
	formSection: {} as FormBuilderSectionInterface,
	titleNeeded: true,
};

export class FormSectionService extends BaseComponentService<FormSectionProps, FormSectionState>
{
	constructor()
	{
		super({} as FormSectionProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ titleNeeded: (this.props.titleNeeded) ?? true });
		this.setFormSectionState(this.props.formSection,
			() => this.setLoading(false)
			);
	}

	/**
	 * Set Form Section
	 * @param formSection
	 * @param callback
	 */
	setFormSectionState(formSection: FormBuilderSectionInterface, callback?: () => void): void
	{
		this.setState({
			formSection,
		}, callback);
	}

	/**
	 * Set Loading
	 * @param isLoading
	 */
	setLoading(isLoading: boolean): void
	{
		this.setState({
			isLoading
		});
	}
}
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAppService extends BaseService
{

	appUrl: string = `${ BaseService.API_URL }/app`;

	async sirenInsee(siren: string): Promise<any> {
		try {
			const response: Response = await fetch(
				`${this.appUrl}/insee/siren/${siren}`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				return { isError: true, errorMessage: errorData.errorMessage || `Erreur inconnue (${response.status})` };
			}

			return await response.json();
		} catch (error: any) {
			return { isError: true, errorMessage: error.message || 'Erreur réseau inconnue' };
		}
	}

	async sirenInpi(siren: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/inpi/siren/${ siren }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async departmentList(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/departments`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async categoryList(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/form-builder/categories`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async formBuilderSectionList(formBuilderCategoryId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/form-builder/sections/${ formBuilderCategoryId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async formBuilderInputList(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/form-builder/inputs`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async legalNoticeList(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/legal-notices`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createOrderL2D(): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.appUrl }/legal-2-digital/order`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
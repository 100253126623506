import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiClientDeveloperService } from '@/Service/Developer/ApiClientDeveloperService';
import {
	LegalNoticeDeveloperProps,
	LegalNoticeDeveloperState
} from '@/Modules/LegalNotice/Developer/Show/LegalNoticeDeveloper.interface';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';

const initState: LegalNoticeDeveloperState = {
	legalNotice: {} as LegalNoticeInterface,
	formData: {} as LegalNoticeInterface,
	isLoading: true,
	isEdit: false
};

export class LegalNoticeDeveloperService extends BaseComponentService<LegalNoticeDeveloperProps, LegalNoticeDeveloperState>
{
	private apiClientService: ApiClientDeveloperService = new ApiClientDeveloperService();

	constructor()
	{
		super({} as LegalNoticeDeveloperProps, initState);

		// Bind
		this.handleEdit = this.handleEdit.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: this.props.legalNotice },
			() => this.setState({ isLoading: false })
		);
	}

	async handleEdit(currentState: LegalNoticeDeveloperState): Promise<void>
	{
		this.setState({ isLoading: true });
		try {

			const updatedClient = await this.apiClientService.edit(currentState.formData, currentState.formData.id);

			if(updatedClient.errorMessage) {
				console.error('Something wrong with update Client', updatedClient.errorMessage);
				return;
			}

			this.setState({ formData: currentState.formData });
			this.props.getComponentState!(this.state.formData);

			this.setState({ isLoading: false });

			this.props.flashMessageContext?.flashMessage(
				'Mise à jour réussie',
				'Les nouvelles informations de l\'annonce ont été enregistrées',
			);

		} catch (error) {
			console.error('Something wrong with update LEGAL NOTICE', error);
			this.setState({ isLoading: false });
		}
	}
}

import React, { Component, ReactElement } from 'react';
import { LegalNoticePriceDetailsProps, LegalNoticePriceDetailsState } from './LegalNoticePriceDetails.interface';
import { LegalNoticePriceDetailsService } from './LegalNoticePriceDetails.service';
import Table from '@/Modules/App/Components/Library/Table/Table';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import {
	LegalNoticePriceDetailsStyles
} from '@/Modules/LegalNotice/ComponentsOld/LegalNoticePriceDetails/LegalNoticePriceDetails.styles';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';

class LegalNoticePriceDetails extends Component<LegalNoticePriceDetailsProps, LegalNoticePriceDetailsState>
{
	private legalNoticePriceDetailsService = new LegalNoticePriceDetailsService();

	constructor(props: LegalNoticePriceDetailsProps)
	{
		super(props);

		// Config service
		this.legalNoticePriceDetailsService.setProps(this.props);
		this.legalNoticePriceDetailsService.subscribeState(this);

		// State
		this.state = this.legalNoticePriceDetailsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticePriceDetailsService.init();
	}

	async componentDidUpdate(prevProps: LegalNoticePriceDetailsProps): Promise<void>
	{
		if (
			prevProps.legalNotice !== this.props.legalNotice ||
			prevProps.selectedDepartment !== this.props.selectedDepartment ||
			prevProps.selectedCategory !== this.props.selectedCategory
		) {
			if (!this.state.isPriceLoaded) {
				await this.legalNoticePriceDetailsService.updateState();
				await this.legalNoticePriceDetailsService.handlePrintPrice();
			}
		}
	}

	componentWillUnmount(): void
	{
		this.legalNoticePriceDetailsService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading || !this.state.isPriceLoaded) {
			return <LoaderComponent/>;
		}

		// Calculate total HT (priceExclVat) and TTC (priceInclVat)
		const totalExclTax = this.state.priceList.items.reduce((sum, item) =>
		{
			return sum + parseFloat(item.priceExclVat);
		}, 0);

		const totalInclTax = this.state.priceList.items.reduce((sum, item) =>
		{
			return sum + parseFloat(item.priceInclVat);
		}, 0);

		const legalNotice = this.props.legalNotice as LegalNoticeInterface;
		const discountPreference = legalNotice.discountPreference
			? (typeof legalNotice.discountPreference === 'string'
				? legalNotice.discountPreference
				: legalNotice.discountPreference.value)
			: this.props.client.options.discountPreference;

		let discountAmountExclTax = 0;
		let discountAmountInclTax = 0;

		if (discountPreference && discountPreference.toString() === 'CREDIT_DEDUCTED') {
			const legalNoticeItem = this.state.priceList.items.find(item => item.label === 'Annonce légale');
			if (legalNoticeItem) {
				discountAmountExclTax = Math.round((parseFloat(legalNoticeItem.priceExclVat) * this.props.legalNotice.discount / 100) * 100) / 100;
				const vatRate = parseFloat(legalNoticeItem.vatRate) / 100;
				discountAmountInclTax = Math.round((discountAmountExclTax * (1 + vatRate)) * 100) / 100;
			}
		}

		const finalDiscountInclTax = discountAmountInclTax;
		const newTotalInclTax = Math.round((totalInclTax) * 100) / 100;
		const TotalDue = Math.round((totalInclTax - finalDiscountInclTax) * 100) / 100;

		// Each Different VAT and Amount associate
		const vatBreakdown: { [rate: string]: number } = {};
		this.state.priceList.items.forEach(item =>
		{
			const rate = item.vatRate.toString();
			const priceExcl = parseFloat(item.priceExclVat);
			const priceIncl = parseFloat(item.priceInclVat);
			const vatAmount = (priceIncl - priceExcl);
			if (vatBreakdown[rate]) {
				vatBreakdown[rate] += vatAmount;
			} else {
				vatBreakdown[rate] = vatAmount;
			}
		});

		return (
			<>
				<Table
					list={ this.state.priceList }
					columns={ this.legalNoticePriceDetailsService.buildColumnHeader() }
					filterParams={ null }
					onRowClick={ () => null }
					onOrderBy={ () => null }
				/>
				<div style={ LegalNoticePriceDetailsStyles.blockResume }>
					<div style={ LegalNoticePriceDetailsStyles.amountDue }>
						<div>Total net HT</div>
						<div>{ totalExclTax.toFixed(2).replace('.', ',') } €</div>
					</div>

					{ Object.keys(vatBreakdown).map(rate => (
						<div key={ rate } style={ LegalNoticePriceDetailsStyles.infoAmount }>
							<div>TVA { rate } %</div>
							<div>{ vatBreakdown[rate].toFixed(2).replace('.', ',') } €</div>
						</div>
					)) }

					<div style={ LegalNoticePriceDetailsStyles.amountDue }>
						<div>Montant total TTC</div>
						<div style={ { fontWeight: 700 } }>{ newTotalInclTax.toFixed(2).replace('.', ',') } €</div>
					</div>

					{/* Display discount if applicable */ }
					{ finalDiscountInclTax > 0 && (
						<div style={ { ...LegalNoticePriceDetailsStyles.infoAmount, marginTop: 7, marginBottom: 7 }}>
							<div>Avoir ({ this.props.legalNotice?.discount! }%)</div>
							<div>{ finalDiscountInclTax.toFixed(2).replace('.', ',') } €</div>
						</div>
					) }

					<div style={ LegalNoticePriceDetailsStyles.totalDueContainer }>
						<div style={ LegalNoticePriceDetailsStyles.totalDue }>
							<div>Total à régler</div>
							<div style={ { fontWeight: 700 } }>{ TotalDue.toFixed(2).replace('.', ',') } €</div>
						</div>
					</div>

				</div>
			</>
		);
	}
}

export default LegalNoticePriceDetails;
import React, { ReactElement } from 'react';
import {
  AppConfigAdminGiftProps,
  AppConfigAdminGiftState
} from '@/Modules/Config/Admin/Component/Gift/ConfigAdminGift.interface';
import { ConfigAdminGiftService } from '@/Modules/Config/Admin/Component/Gift/ConfigAdminGift.service';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import { LuCheckCheck, LuPencilLine, LuTrash2, LuX } from 'react-icons/lu';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';

export default class ConfigAdminGift extends React.Component<AppConfigAdminGiftProps, AppConfigAdminGiftState>
{
  private componentService: ConfigAdminGiftService = new ConfigAdminGiftService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <Title type="h5">Gestion des Cadeaux</Title>

        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 5 }}>
          <Button
            variant={'primary'}
            label={ 'Ajouter un cadeau' }
            iconName={ 'LuPlus' }
            onClick={ () => {
              if (!this.state.newGift) {
                this.setState({ newGift: { id: null, label: '' } });
              }
            }}
          />
        </div>

        <TableOld>
          <TableHeader
            columnHeaderList={[{ columnTitle: 'Label', columnSize: 6, name: 'Label' }]}
            isFilters={ true }
          />

          { this.state.newGift && (
            <TableRow isCheckboxInput={ false } borderBottom={ true }>
              <TableCol columnSize={ 22 }>
                <InputTableCell
                  value={ this.state.newGift.label }
                  onChange={ (event) =>
                    this.setState({ newGift: { ...this.state.newGift, id: null, label: event.target.value } })
                  }
                />
              </TableCol>

              <TableCol columnSize={ 2 } style={{ display: 'flex', gap: 10 }}>
                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuCheckCheck /> }
                  onClick={() => {
                    if (this.state.newGift?.label.trim()) {
                      this.componentService.createCommercialGift(this.state.newGift.label);
                      this.setState({ newGift: null });
                    }
                  }}
                />

                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuX /> }
                  onClick={() => this.setState({ newGift: null })}
                />
              </TableCol>
            </TableRow>
          )}

          { this.state.giftList.map((gift) => (
           <>
             <TableRow key={ gift.id } isCheckboxInput={ false } borderBottom={ true }>
               <TableCol columnSize={ 22 } isEditMode={ this.state.editGiftId === gift.id } style={{ marginLeft: 25 }}>
                 <InputTableCell
                   value={ gift.label }
                   onChange={(event) => {
                     const updatedLabel = event.target.value;
                     this.setState((prevState) => ({
                       giftList: prevState.giftList.map((updatedGift) =>
                         updatedGift.id === gift.id ? { ...updatedGift, label: updatedLabel } : updatedGift
                       ),
                     }));
                   }}
                 />
               </TableCol>

               <TableCol columnSize={ 2 } isEditMode={ this.state.editGiftId === gift.id }>
                 { this.state.editGiftId === gift.id ? (
                   <React.Fragment key={ gift.id }>
                     <ButtonOld
                       type="pagination"
                       iconLeft={<LuCheckCheck />}
                       onClick={async () => {
                         if (gift.label.trim()) {
                           await this.componentService.updateCommercialGift(gift);
                         }
                       }}
                     />

                     <ButtonOld
                       type="pagination"
                       iconLeft={<LuX />}
                       onClick={() =>
                         this.setState((prevState) => ({
                           editGiftId: null,
                           giftList: prevState.giftList.map((defaultGift) =>
                             defaultGift.id === gift.id ? { ...defaultGift, label: gift.label } : defaultGift
                           ),
                         }))
                       }
                     />
                   </React.Fragment>
                 ) : (
                   <React.Fragment key={ gift.id }>
                     <ButtonOld
                       type="pagination"
                       iconLeft={<LuPencilLine />}
                       onClick={() =>
                         this.setState({ editGiftId: gift.id })
                       }
                     />

                     <ButtonOld
                       type="pagination"
                       iconLeft={<LuTrash2 />}
                       onClick={async () => {
                         await this.componentService.deleteCommercialGift(gift.id as number);
                       }}
                     />
                   </React.Fragment>
                 )}
               </TableCol>
             </TableRow>
           </>
          ))}
        </TableOld>
      </>
    );
  }
}
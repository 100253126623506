import React, { Component, ReactElement } from 'react';
import { PublishDateProps, PublishDateState } from './PublishDatePicker.interface';
import { PublishDatePickerService } from './PublishDatePicker.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import DatePicker, { registerLocale } from 'react-datepicker';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import {
	PublishDatePickerStyles
} from '@/Modules/LegalNotice/Components/Form/PublishDatePicker/PublishDatePicker.styles';
import { fr } from 'date-fns/locale';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
registerLocale('fr', fr);

class PublishDatePicker extends Component<PublishDateProps, PublishDateState>
{
	private publishDateService = new PublishDatePickerService();

	constructor(props: PublishDateProps)
	{
		super(props);

		// Config service
		this.publishDateService.setProps(this.props);
		this.publishDateService.subscribeState(this);

		// State
		this.state = this.publishDateService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.publishDateService.init();
	}

	componentWillUnmount(): void
	{
		this.publishDateService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedDate } = this.state;
		const isInternalNewspaper: boolean = (this.props.newspaper?.editorial === 'heraultjuridique') && (NewspaperTypeEnum.findByValue(this.props?.newspaper?.type!) === NewspaperTypeEnum.PAPER);

		return (
			<div>
				<Label label={ 'Date de publication' } isRequired={ true }/>
				<div style={ PublishDatePickerStyles.container }>
					<DatePicker
						key={ this.props.newspaper.name }
						selected={ selectedDate }
						onChange={ (date) => this.publishDateService.setSelectedDate(date) }
						filterDate={ (date) => !this.publishDateService.isDateDisabled(date) }
						dateFormat="dd/MM/yyyy"
						locale={"fr"}
						className="custom-date-picker"
						calendarClassName="custom-calendar"
						dayClassName={ (date) => (date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : 'weekday') }
						disabled={ this.state.isForceDate }
					/>

					{ isInternalNewspaper && (
						<div style={ { display: 'flex', alignItems: 'center', gap: 5 } }>
							<input
								type="checkbox"
								onChange={ (event) =>
								{
									if (event.target.checked) {
										this.publishDateService.forcePublishDayForInternalNewspaper();
									} else {
										this.publishDateService.selectFirstAvailableDate();
									}
								} }
							/>
							<Label label={ 'Forcer la date' } isRequired={ false }/>
						</div>
					) }
				</div>
				{ this.state.closurePast &&
          <div style={ {
						marginTop: 10,
						backgroundColor: colors.error50,
						...borderStyles,
						borderColor: colors.error100,
						borderRadius: 6,
						color: 'red',
						padding: '5px 10px',
						minHeight: 38,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					} }>
            <span>{ this.state.closurePast }</span>
          </div>
				}
			</div>
		);
	}
}

export default withGlobalContext(PublishDatePicker);
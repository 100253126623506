import React, { Component, ReactElement } from 'react';
import { FormSubSectionProps, FormSubSectionState } from './FormSubSection.interface';
import { FormSubSectionService } from './FormSubSection.service';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { FormSectionStyles } from '@/Modules/LegalNotice/Components/Form/FormSection/FormSection.styles';
import FormSection from '@/Modules/LegalNotice/Components/Form/FormSection/FormSection';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import {
	FormContentInterface
} from '@/Modules/LegalNotice/Components/Form/DynamicGuideForm/DynamicGuideForm.interface';
import { LabelStyles } from '@/Modules/App/Components/Library/Label/Label.styles';

class FormSubSection extends Component<FormSubSectionProps, FormSubSectionState>
{
	private formSubSectionService = new FormSubSectionService();

	constructor(props: FormSubSectionProps)
	{
		super(props);

		// Config service
		this.formSubSectionService.setProps(this.props);
		this.formSubSectionService.subscribeState(this);

		// State
		this.state = this.formSubSectionService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.formSubSectionService.init();
	}

	componentWillUnmount(): void
	{
		this.formSubSectionService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { formSection, isLoading, subSectionSelected } = this.state;

		if (isLoading) {
			return (
				<div style={ {
					...CardStyles.simpleCardGrayContainer,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				} }>
					<ClipLoader
						color={ colors.gray400 }
						loading={ isLoading }
						size={ 25 }
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			);
		}

		return (
			<div style={ FormSectionStyles.container }>
				<div style={ FormSectionStyles.title }>
					{ formSection.title }
					{ (formSection.isRequired) && (
						<span style={ {
							...LabelStyles.text,
							fontStyle: 'italic',
							fontSize: 14,
							color: colors.gray700,
							marginLeft: 5,
						} }>
							(Obligatoire)
						</span>
					) }
				</div>
				<div style={{ width: '100%'}}>
					{ formSection.sectionParentTypeOption === FormBuilderInputTypeEnum.SELECTOR &&
            <div style={{ width: '100%', display: 'flex', gap: 5 }}>
              <Select
								key={ this.state.subSectionSelected?.title }
                listOptions={ formSection.subSections }
                displayKey={ 'title' }
								styles={{ width: '100%'}}
                isRequired={ formSection.isRequired }
                onSelect={ (value) => this.formSubSectionService.setSubSectionSelectedState(value) }
								value={ this.state.subSectionSelected?.title || '' }
                errorMessage={
									this.props.handleSectionErrors.find(
										(error) => error.sectionId === formSection.id
									)?.error || ''
								}
              />
							{ (this.state.subSectionSelected) &&
                <Button
									variant={ 'close' }
									iconName={ 'LuX' }
                  onClick={ () => {
										this.props.removeSubSectionContent(this.state.subSectionSelected!);
										this.formSubSectionService.setSubSectionSelectedState(null);
									} }
                />
							}
            </div>
					}
					{ formSection.sectionParentTypeOption === FormBuilderInputTypeEnum.CHECKBOX && (
						<>
							<div style={ { display: 'flex' } }>
								{ formSection.subSections.map((subSection, index) => (
									<Checkbox
										key={ subSection.id || index }
										label={ subSection.title }
										name={ subSection.title + `-${ index }` }
										options={ { rowLeftCheckbox: true } }
										isChecked={ subSection.id === this.state.subSectionSelected?.id }
										onCheckedChange={ () =>
										{
											this.formSubSectionService.setLoading(true);
											if (this.state.subSectionSelected && this.state.subSectionSelected.id === subSection.id) {
												this.formSubSectionService.setSubSectionSelectedState(null);
												this.props.removeSubSectionContent(subSection);
											} else {
												if (this.state.subSectionSelected) {
													this.props.removeSubSectionContent(this.state.subSectionSelected);
												}
												this.formSubSectionService.setSubSectionSelectedState(subSection);
											}
										} }
									/>
								)) }
							</div>
							{ this.props.handleSectionErrors.find(error => error.sectionId === formSection.id)?.error && (
								<span style={ { color: colors.error600, fontSize: 12 } }>
									{ this.props.handleSectionErrors.find(error => error.sectionId === formSection.id)?.error }
								</span>
							) }
						</>
					) }
					{ subSectionSelected &&
            <div style={ { ...CardStyles.simpleCardGrayContainer, marginTop: 10, background: colors.gray50 } }>
              <FormSection
                key={ JSON.stringify(subSectionSelected) }
                titleNeeded={ false }
                formSection={ subSectionSelected }
                signature={ this.props.signature }
                inputContents={ this.props.inputContents }
                onChange={ (value, inputSectionId) => this.props.onChange(value, inputSectionId) }
              />
							{ subSectionSelected.isDuplicable && (
								<div>
									<Button
										variant="inline"
										label={ `Ajouter ${ subSectionSelected.title }` }
										iconName="LuPlus"
										onClick={ () => this.formSubSectionService.handleDuplicateSubSection(subSectionSelected) }
									/>
								</div>
							) }
            </div>
					}
					{/* Display duplications for the selected sub-section */}
					{ subSectionSelected && this.props.inputContents && (
						Object.entries(
							this.props.inputContents
								.filter((content) =>
									subSectionSelected && content.sectionId === subSectionSelected.parentId && content.duplicatedGroup != null
								)
								.reduce((groups: { [group: number]: FormContentInterface[] }, content) => {
									const group = content.duplicatedGroup!;
									if (!groups[group]) {
										groups[group] = [];
									}
									groups[group].push(content);
									return groups;
								}, {})
						).map(([groupKey, duplicatedContents]) => (
							<div
								key={ groupKey }
								style={{ ...CardStyles.simpleCardGrayContainer, marginTop: 10, background: colors.gray50 }}
							>
								<FormSection
									key={ `${ subSectionSelected.id }-dup-${ groupKey }` }
									titleNeeded={ false }
									formSection={ subSectionSelected }
									signature={ this.props.signature }
									inputContents={ duplicatedContents }
									duplicateId={ groupKey }
									onChange={ (value, inputSectionId, duplicateGroupKey) =>{
										this.props.onChange(value, inputSectionId, duplicateGroupKey);
									}
								}
								/>
								<div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
									<Button
										variant="smallError"
										label="Supprimer"
										iconName="LuTrash"
										onClick={ () => this.props.removeDuplicatedSubSection(Number(groupKey)) }
									/>
								</div>
							</div>
						))
					)}
				</div>

			</div>
		);

	}
}

export default FormSubSection;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CreateLegalNoticeInterface,
	CreateLegalNoticeProps,
	CreateLegalNoticeState, LegalNoticeOptionsInterface
} from './CreateLegalNotice.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import {
	LegalNoticeConfigState
} from '@/Modules/LegalNotice/Components/Form/LegalNoticeConfig/LegalNoticeConfig.interface';
import {
	LegalNoticeWritingContentState
} from '@/Modules/LegalNotice/Components/LegalNoticeWritingContent/LegalNoticeWritingContent.interface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { CreateLegalNoticeAdminModel } from '@/Modules/LegalNotice/Admin/Create/CreateLegalNoticeAdmin.model';
import { SendToSelections } from '@/Modules/LegalNotice/ComponentsOld/Form/LegalNoticeSendToFormComponent';
import { ConsumerFormService } from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm.service';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import {
	BillingPreferencesFormState
} from '@/Modules/LegalNotice/Components/Form/BillingPreferencesForm/BillingPreferencesForm.interface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import { dateToStr } from '@/Utils/DateUtils';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { ApiLegalNoticeFileService } from '@/Service/Api/ApiLegalNoticeFileService';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { UserService } from '@/Service/UserService';
import { ClientService } from '@/Modules/Client/User/Show/Client.service';
import React from 'react';
import {
	AssociateSellsyCompanyService
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.service';
import {
	FormContentInterface
} from '@/Modules/LegalNotice/Components/Form/DynamicGuideForm/DynamicGuideForm.interface';
import CryptoJS from 'crypto-js';

export const createLegalNoticeInitState: CreateLegalNoticeState = {
	css: {
		contentWidth: 0,
		contentRenderHeight: 0,
	},
	isLoading: true,
	isSellsyAssociate: false,
	isEdit: false,
	isLocalStorageUpdate: true,
	isWaitingLocalStorageUpdate: true,
	isLightConsumerForm: false,
	isCreateLoading: false,
	formContents: [],
	isDisplayBlock: {
		blockConfig: false,
		blockConsumer: false,
	},
	handleErrors: {
		consumer: {},
		guideForm: false,
		guideFormErrors: [],
	},
	charCount: 0,
	formData: {
		client: null,
		collaborator: null,
		consumer: null,
		category: null,
		childCategory: null,
		department: null,
		newspaper: null,
		legalNotice: {} as CreateLegalNoticeInterface,
		sendTo: {},
		billingAddress: null,
	}
};

export class CreateLegalNoticeService extends BaseComponentService<CreateLegalNoticeProps, CreateLegalNoticeState>
{
	// Services
	private apiLegalNoticeService: ApiAdminLegalNoticeService | ApiLegalNoticeService;
	private apiLegalNoticeFileService: ApiAdminLegalNoticeFileService | ApiLegalNoticeFileService;
	private legalNoticePriceService: LegalNoticePriceService;
	private userRoleService: UserConnectedService = new UserConnectedService();
	private clientService: ClientService = new ClientService();
	private apiPublicService: ApiPublicService = new ApiPublicService();
	private associateSellsyCompanyService: AssociateSellsyCompanyService = new AssociateSellsyCompanyService();
	private encryptionKey: string = process.env.REACT_APP_ENCRYPTION_KEY || 'defaultKey';

	constructor()
	{
		super({} as CreateLegalNoticeProps, createLegalNoticeInitState);

		// Dispatch service
		this.apiLegalNoticeService = (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin())
			? new ApiAdminLegalNoticeService()
			: new ApiLegalNoticeService();

		this.apiLegalNoticeFileService = (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin())
			? new ApiAdminLegalNoticeFileService()
			: new ApiLegalNoticeFileService();

		this.legalNoticePriceService = new LegalNoticePriceService(UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin());

		// Bind
		this.setClientState = this.setClientState.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(isStoredData: boolean = false): Promise<void>
	{
		const userConnected = await this.userRoleService.getAuthUser();
		if (userConnected?.role === UserRoleEnum.ROLE_USER.value) {
			await this.handleProcessUserInit(userConnected);

			// Set Data from LocalStorage
			const storedFormData: Partial<CreateLegalNoticeState['formData']> = await this.getFormDataLoStorage();
			const storedFormContents: Partial<CreateLegalNoticeState['formContents']> = this.getFormContentsLoStorage();
			if (isStoredData && storedFormData && Object.keys(storedFormData).length > 0) {
				this.setState({ isLocalStorageUpdate: true });
				storedFormData.legalNotice = CreateLegalNoticeAdminModel.prepare(storedFormData.legalNotice!);
				this.setFormDataState(storedFormData);
				if (storedFormContents && Object.keys(storedFormContents).length > 0) {
					this.setFormContents(storedFormContents.filter(
						(item): item is FormContentInterface => item !== undefined
					));
				}
			} else {
				this.setState({
					isLocalStorageUpdate: false,
					isWaitingLocalStorageUpdate: false,
				});

				this.setFormDataLegalNoticeState(new CreateLegalNoticeAdminModel());
			}
		} else if(UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin()) {
			this.setState({
				isLocalStorageUpdate: false,
				isWaitingLocalStorageUpdate: false,
			});

			this.setFormDataLegalNoticeState(new CreateLegalNoticeAdminModel());
		}

		// if isEdit
		const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
		const legalNoticeId: string | null = urlParams.get('legalNoticeId');
		if (legalNoticeId) {
			this.setState({ isEdit: true });
			await this.getLegalNotice(Number(legalNoticeId));
		} else {
			this.setLoadingState(false);
		}
	}

	/**
	 * Update Content with (art)
	 * @param sidebarWidthRef
	 */
	updateContentWidth = (sidebarWidthRef: React.RefObject<HTMLDivElement>): void =>
	{
		if (sidebarWidthRef.current) {
			const sidebarWidth = sidebarWidthRef.current.offsetWidth;
			if (this.state.css.contentWidth !== sidebarWidth) {
				this.setState({
					css: {
						...this.state.css,
						contentWidth: sidebarWidth,
					},
				});
			}
		}
	};

	/**
	 * Method to scroll smoothly to a specific section
	 */
	scrollToSection(scrollViewRef: React.RefObject<HTMLDivElement>): void
	{
		if (scrollViewRef?.current) {
			const container = scrollViewRef.current.parentElement;
			if (container) {
				// Calculate the position of the target element relative to the container
				const scrollOffset = scrollViewRef.current.offsetTop - container.offsetTop;
				// Smoothly scroll the container
				container.scrollTo({
					top: scrollOffset,
					behavior: 'smooth',
				});
			}
		}
	}

	/**
	 * Get LegalNotice for Edit
	 * @param legalNoticeId
	 */
	async getLegalNotice(legalNoticeId: number): Promise<void>
	{
		try {
			/* Get LegalNotice	*/
			const legalNotice: any = await (this.apiLegalNoticeService as ApiAdminLegalNoticeService).show(legalNoticeId);
			/* Set LegalNotice	*/
			this.setLegalNoticeForEdit(legalNotice);
			/* is Loader	*/
			this.setLoadingState(false);
		} catch (error: any) {
			console.error(`Error occurred in ${ this.constructor.name }`, error);
			this.setLoadingState(false);
		}
	}

	/**
	 * Set Loading State
	 * @param isLoading
	 * @param callback
	 */
	setLoadingState(isLoading: boolean, callback?: () => void): void
	{
		this.setState({ isLoading }, callback);
	}

	/**
	 * Set is Waiting LocalStorage Update
	 * @param isLoading
	 */
	setIsWaitingLocalStorageUpdateState(isLoading: boolean): void
	{
		this.setState({
			isWaitingLocalStorageUpdate: isLoading,
		});
	}

	/**
	 * Handle Selected Categories
	 * @param category
	 * @param childCategory
	 * @param isBodacc
	 * @param scrollViewRef
	 */
	handleSelectedCategories(
		category: FormBuilderCategoryInterface,
		childCategory: FormBuilderCategoryInterface,
		isBodacc: boolean = false,
		scrollViewRef?: React.RefObject<HTMLDivElement>
	): void
	{
		this.setCategoryState(category);
		this.setChildCategoryState(childCategory, () =>
			{
				if (childCategory && scrollViewRef && scrollViewRef.current) {
					if (!UserConnectedService.isAdmin() || !UserConnectedService.isSuperAdmin()) {
						this.setIsConsumerFormLightState(this.state);
					}
					this.handleDisplayBlock({ blockConsumer: true });
					this.scrollToSection(scrollViewRef);
				}
			}
		);
		this.setFormDataLegalNoticeOptionsState({ isBodacc });
	}

	/**
	 * Validates the consumer form based on the child category.
	 * It checks that all required fields are filled:
	 * - Name, Capital, RCS, Legal Status
	 * - If the siren is required (depending on the category type), it must be provided.
	 * - The address must be provided (Street, Zip Code, City).
	 *
	 * @param childCategory The selected child category.
	 * @param consumer The consumer data.
	 * @returns true if a required field is missing, false otherwise.
	 */
	validateConsumerFields(childCategory: FormBuilderCategoryInterface, consumer: ConsumerDataInterface): boolean
	{
		if (!consumer) {
			return true;
		}
		if (!consumer.name || consumer.name.trim() === '') {
			return true;
		}
		if (!consumer.capital || consumer.capital.trim() === '') {
			return true;
		}
		if (!consumer.rcs || consumer.rcs.trim() === '') {
			return true;
		}
		if (!consumer.legalStatus || consumer.legalStatus.trim() === '') {
			return true;
		}

		const isSirenRequired = !LegalNoticeTypeEnum.filterWithoutSiren.some(
			category => String(category.value) === childCategory.categoryType
		);

		if (isSirenRequired && (!consumer.siren || consumer.siren.trim() === '')) {
			return true;
		}

		if (!consumer.address) {
			return true;
		}

		const address: AddressInterface = consumer.address;
		if (!address.street || address.street.trim() === '') {
			return true;
		}
		if (!address.zipCode || address.zipCode.trim() === '') {
			return true;
		}
		if (!address.city || address.city.trim() === '') {
			return true;
		}

		return false;
	}


	/**
	 * Handle config
	 * @param configData
	 * @param scrollViewRef
	 */
	handleConfig(configData: LegalNoticeConfigState, scrollViewRef?: React.RefObject<HTMLDivElement>): void
	{
		this.setFormDataLegalNoticeOptionsState({ publishType: configData.selectedNewspaper?.type! });
		this.setDepartmentState(configData?.selectedDepartment!);
		this.setNewspaperState(configData?.selectedNewspaper!);
		this.setFormDataLegalNoticeState({
				publishDate: configData.publishDate,
				numberOfCopies: configData.numberOfCopies,
			},
			() => this.scrollToSection(scrollViewRef!)
		);
	}

	/**
	 * Handle Legal Notice content
	 * @param dataContent
	 */
	handleLegalNoticeContent(dataContent: LegalNoticeWritingContentState): void
	{
		this.setState({
			charCount: dataContent.charCount,
			formData: {
				...this.state.formData,
				legalNotice: {
					...this.state.formData.legalNotice,
					content: dataContent.content,
				}
			}
		});
	}

	/**
	 * Set Client State
	 * @param client
	 */
	setClientState(client: Partial<ClientInterface> | null): void
	{
		if (client === null) {
			this.setState({
				formData: {
					...this.state.formData,
					client: null,
				},
			}, () =>
			{
				this.setCollaboratorState(null);
				this.handleDisplayBlock({ blockConfig: false });
			});
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					client: {
						...this.state.formData?.client!,
						...client,
					},
				},
			});
		}
	}

	/**
	 * Set Collaborator State
	 * @param collaborator
	 * @param scrollViewRef
	 */
	setCollaboratorState(
		collaborator: Partial<CollaboratorInterface> | null,
		scrollViewRef?: React.RefObject<HTMLDivElement>
	): void
	{
		if (collaborator === null) {
			this.setFormDataState(
				{
					collaborator: null,
				},
				() => this.scrollToSection(scrollViewRef!)
			);
		} else {
			this.setFormDataState(
				{
					collaborator: {
						...this.state.formData.collaborator!,
						...collaborator,
					},
				},
				() => this.scrollToSection(scrollViewRef!)
			);
		}
	}

	/**
	 * Set Department State
	 * @param department
	 */
	setDepartmentState(department: Partial<DepartmentInterface>): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				department: {
					...this.state.formData.department!,
					...department,
				},
			}
		});
	}

	/**
	 * Set Newspaper State
	 * @param newspaper
	 */
	setNewspaperState(newspaper: Partial<NewspaperInterface>): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				newspaper: {
					...this.state.formData?.newspaper!,
					...newspaper,
				},
			}
		});
	}

	/**
	 * Set Category id
	 * @param category
	 */
	setCategoryState(category: Partial<FormBuilderCategoryInterface>): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				category: {
					...this.state.formData.category!,
					...category,
				},
			}
		}, () =>
		{
			if (this.state.formData.category !== category) {
				this.setChildCategoryState(null);
				this.handleDisplayBlock({ blockConsumer: false });
			}
		});
	}

	/**
	 * Set ChildCategory id
	 * @param childCategory
	 * @param callback
	 */
	setChildCategoryState(childCategory: Partial<FormBuilderCategoryInterface> | null, callback?: () => void): void
	{
		if (childCategory === null) {
			this.setState({
				formData: {
					...this.state.formData,
					childCategory: null,
				}
			}, callback);
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					childCategory: {
						...this.state.formData.childCategory!,
						...childCategory,
					},
				}
			}, callback);
		}
	}

	/**
	 * Set Legal Notice State
	 * @param data
	 * @param callback
	 */
	setFormDataLegalNoticeState(data: Partial<CreateLegalNoticeInterface>, callback?: () => void): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				legalNotice: {
					...this.state.formData.legalNotice,
					...data,
				}
			}
		}, callback);
	}

	/**
	 * Set Legal Notice Options State
	 * @param data
	 * @param callback
	 */
	setFormDataLegalNoticeOptionsState(data: Partial<LegalNoticeOptionsInterface>, callback?: () => void): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				legalNotice: {
					...this.state.formData.legalNotice,
					option: {
						...this.state.formData.legalNotice.option,
						...data,
					}
				}
			}
		}, callback);
	}

	/**
	 * Set Legal Notice State
	 * @param consumerData
	 */
	setConsumerState(consumerData: Partial<ConsumerDataInterface>): void
	{
		this.setState(
			{
				formData: {
					...this.state.formData,
					consumer: {
						...this.state?.formData?.consumer!,
						...consumerData,
					},
				},
			}, () =>
			{
				if (Object.keys(this.state.handleErrors.consumer).length > 0) {
					this.handleErrors(this.state);
				}
			}
		);
	}

	/**
	 * Set FormData State
	 * @param formData
	 * @param callBack
	 */
	setFormDataState(formData: Partial<CreateLegalNoticeState['formData']>, callBack?: () => void): void
	{
		this.setState({
			formData: {
				...this.state.formData,
				...formData,
			}
		}, callBack);
	}

	/**
	 * Set SendTo State
	 * @param sendToSelections
	 */
	setFormDataSendToState(sendToSelections: SendToSelections): void
	{
		this.setFormDataState({
			sendTo: sendToSelections,
		});
	}

	/**
	 * Set Billing Address State
	 * @param billingAddress
	 */
	setBillingAddressState(billingAddress: AddressInterface): void
	{
		this.setFormDataState({
			billingAddress,
		});
	}

	/**
	 * Set Is consumer Form Light State
	 * @param currentState
	 */
	setIsConsumerFormLightState(currentState: CreateLegalNoticeState | boolean): void
	{
		if (typeof currentState === 'boolean') {
			this.setState({ isLightConsumerForm: currentState });
		} else {
			this.setState({
				isLightConsumerForm: currentState.formData.legalNotice.configBillingPreference.billing !== 'CONSUMER'
					&& LegalNoticeTypeEnum.filterWithoutSiren.some(category =>
						String(category.value) === currentState.formData.childCategory?.categoryType!)
			}, async () =>
			{
				const storedFormData: Partial<CreateLegalNoticeState['formData']> = await this.getFormDataLoStorage();
				if (!this.state.isEdit
					&& this.state.isLightConsumerForm
					&& storedFormData
					&& Object.keys(storedFormData).length === 0) {
					this.setFormDataState({ consumer: null });
				}
			});
		}
	}

	/**
	 * Set form Contents
	 * @param formContents
	 */
	setFormContents(formContents: FormContentInterface[]): void
	{
		this.setState({
			formContents
		});
	}

	/**
	 * Handle Billing preference and set State
	 * @param billingPreferences
	 * @param scrollViewRef
	 */
	handleBillingPreference(billingPreferences: BillingPreferencesFormState, scrollViewRef?: React.RefObject<HTMLDivElement>): void
	{
		// LegalNotice
		this.setFormDataLegalNoticeState({
				discountPreference: billingPreferences.discountPreference!,
				discount: billingPreferences.discountGranted,
				configBillingPreference: {
					billing: billingPreferences.invoiceAffiliation.value,
					creditNote: billingPreferences.creditNoteAffiliation.value,
				}
			}, () =>
			{
				if (scrollViewRef?.current) {
					this.scrollToSection(scrollViewRef);
					this.handleDisplayBlock({ blockConfig: true });
				}
			}
		);

		// Billing Address
		if (billingPreferences.invoiceAffiliation.value === 'CLIENT') {
			this.setBillingAddressState({
				...this.state.formData.client?.address!,
				isBillingAddress: true,
				name: this.state.formData.client?.name!,
			});
		} else if (billingPreferences.invoiceAffiliation.value === 'CONSUMER') {
			this.setBillingAddressState({
				...this.state.formData.consumer?.address!,
				isBillingAddress: true,
				name: this.state.formData.consumer?.name!,
			});
		}
	}

	/**
	 * Handle Billing preference and set State
	 * @param event
	 */
	handleBillingPreferenceUserSide(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const newBillingPreference = event.target.checked ? 'CONSUMER' : 'CLIENT';

		this.setState({
			formData: {
				...this.state.formData,
				legalNotice: {
					...this.state.formData.legalNotice,
					configBillingPreference: {
						...this.state.formData.legalNotice.configBillingPreference,
						billing: newBillingPreference,
					},
				},
			},
		}, () =>
		{
			if (newBillingPreference === 'CLIENT') {
				this.setBillingAddressState({
					...this.state.formData.client?.address!,
					isBillingAddress: true,
					name: this.state.formData.client?.name!,
				});
			} else if (newBillingPreference === 'CONSUMER') {
				this.setBillingAddressState({
					...this.state.formData.consumer?.address!,
					isBillingAddress: true,
					name: this.state.formData.consumer?.name!,
				});
			}
		});
	}

	/**
	 * Handle Data from associate Sellsy company
	 * @param companySellsyState
	 */
	handleDataFromAssociateSellsyCompany(companySellsyState: AssociateSellsyCompanyState['selectedCompanySellsy']): void
	{
		this.setFormDataState({
			consumer: {
				...this.state.formData.consumer!,
				extSellsyId: companySellsyState?.object.id,
				address: {
					...this.state.formData.consumer?.address!,
					extSellsyId: Number(companySellsyState?.delivery_address?.id)
				}
			},
		});
		if (companySellsyState !== null) {
			this.setState({ isSellsyAssociate: true });
			this.setBillingAddressState({
				...this.state.formData.billingAddress!,
				isBillingAddress: true,
				name: companySellsyState?.name!,
				street: companySellsyState?.invoicing_address?.address_line_1!,
				additionalData: companySellsyState?.invoicing_address?.address_line_2!,
				zipCode: companySellsyState?.invoicing_address?.postal_code!,
				city: companySellsyState?.invoicing_address?.city!,
				extSellsyId: Number(companySellsyState?.invoicing_address?.id!),
			});
		} else {
			this.setFormDataState({ billingAddress: null });
			this.setState({ isSellsyAssociate: false });
		}
	}

	/**
	 * Handle Associate Sellsy from User Side
	 * @param event
	 */
	async handleAssociateConsumerSellsyUserSide(event: React.MouseEvent<HTMLButtonElement> | null): Promise<void>
	{
		const companyId = this.props.authContext?.user?.company?.id;

		try {
			const sellsyCompanies = await this.associateSellsyCompanyService.searchCompanySellsy(
				(event) ? event : null,
				this.state.formData?.consumer?.name!,
				companyId
			);

			if (sellsyCompanies.length === 0) {
				await this.associateSellsyCompanyService.createCompanySellsy(this.state.formData?.consumer!, false, companyId)
					.then(() => this.handleAssociateConsumerSellsyUserSide(null))
					.catch((error) => console.error(error));

			} else if (sellsyCompanies.length === 1) {
				const selectedCompany = await this.associateSellsyCompanyService.getSellsyCompany(
					sellsyCompanies[0].object.id,
					false,
					companyId
				);
				this.handleDataFromAssociateSellsyCompany(selectedCompany);
			} else {
				const exactMatches = sellsyCompanies.filter(
					(company: any) => company.name === this.state.formData?.consumer?.name
				);

				const companyToUse = exactMatches.length > 0 ? exactMatches[0] : sellsyCompanies[0];

				const selectedCompany = await this.associateSellsyCompanyService.getSellsyCompany(
					companyToUse.object.id,
					false,
					companyId
				);
				this.handleDataFromAssociateSellsyCompany(selectedCompany);
			}
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Check is consumer form complete
	 * @param consumerData
	 */
	isConsumerFormComplete(consumerData: ConsumerDataInterface): boolean
	{
		return Boolean(
			consumerData?.name !== ''
			&& consumerData?.rcs !== ''
			&& consumerData?.legalStatus !== ''
			&& consumerData?.capital !== ''
			&& consumerData?.address?.street !== ''
			&& consumerData?.address?.zipCode !== ''
			&& consumerData?.address?.city !== ''
		);
	}

	/**
	 * Handle Display blocks
	 * @param displayBlock
	 */
	handleDisplayBlock(displayBlock: Partial<CreateLegalNoticeState['isDisplayBlock']>): void
	{
		// 01. Display Block config
		this.setState({
			isDisplayBlock: {
				...this.state.isDisplayBlock,
				...displayBlock,
			}
		});
	}

	/**
	 * Set handle errors state
	 * @param errors
	 */
	setHandleErrorsState(errors: Partial<CreateLegalNoticeState['handleErrors']>): void
	{
		this.setState((prevState) => ({
			handleErrors: {
				...this.state.handleErrors,
				consumer: {
					...errors.consumer,
				},
			},
		}));
	}

	/**
	 * Handle Submit formData to create
	 * @param currentState
	 */
	handleErrors(currentState: CreateLegalNoticeState): boolean
	{
		const consumerFormErrors = ConsumerFormService.validateConsumerForm(
			currentState.formData?.consumer!,
			currentState.isLightConsumerForm,
			!LegalNoticeTypeEnum.filterWithoutSiren.some(category => String(category.value) === currentState.formData.childCategory?.categoryType!)
		);

		this.setHandleErrorsState({ consumer: consumerFormErrors });

		return Object.keys(consumerFormErrors).length > 0;
	}

	/**
	 * Create LegalNotice
	 */
	async createLegalNotice(type: string, nbCharacters: number): Promise<void>
	{
		//loading
		this.setState({ isCreateLoading: true });

		try {
			const formData = this.state.formData;

			const content = formData.legalNotice.content || '';
			// Check if <p></p> and add on content
			let finalContent = content.trim();
			if (!/^<p>[\s\S]*<\/p>$/.test(finalContent)) {
				finalContent = `<p>${ finalContent }</p>`;
			}

			const prepareFormData = {
				billingAddress: formData.billingAddress,
				categoryId: formData.childCategory?.id,
				clientId: formData.client?.id,
				collaboratorId: formData.collaborator?.id,
				consumer: formData.consumer,
				departmentId: formData.department?.id,
				newspaperId: formData.newspaper?.id,
				sendTo: formData.sendTo,
				legalNotice: {
					...formData.legalNotice,
					discountPreference: formData.legalNotice.discountPreference?.value,
					publishDate: dateToStr(formData.legalNotice.publishDate!),
					content: finalContent,
					nbCharacters: nbCharacters,
					price: await this.calculatedPrice(),
					quoteStatus: (type === PublishStateEnum.QUOTE.value) ? QuoteStatusEnum.PENDING.value : null,
					status: type,
					paymentStatus: (formData.legalNotice.paymentStatus)
						? formData.legalNotice.paymentStatus
						: PaymentStateEnum.PENDING.value,
					clientLogoId: formData.legalNotice?.clientLogo?.id! || null,
					option: {
						...formData.legalNotice.option,
						billingType: (typeof formData.legalNotice.option.billingType === 'string')
							? formData.legalNotice.option.billingType
							: formData.legalNotice.option.billingType.value,
						publishType: formData.newspaper?.type
					}
				},
				status: type,
			};

			// Create or Edit
			const response: any = (!this.state.isEdit)
				? await this.apiLegalNoticeService.create(prepareFormData)
				: await (this.apiLegalNoticeService as ApiAdminLegalNoticeService).edit(formData.legalNotice?.id!, prepareFormData);

			// Handle response errors
			if (response.errorMessage) {
				console.error(`Reponse Error on ${ this.constructor.name }`, response.errorMessage);
				this.setState({ isCreateLoading: false });
				return;
			}

			const legalNoticeId = response.id || this.state.formData.legalNotice.id;

			// Execute additional file service calls in background
			this.handleFileServiceCalls(type, legalNoticeId, prepareFormData);

			//loading
			this.setState({ isCreateLoading: false });

			// Flash message
			this.props?.flashMessageContext?.flashMessage(
				'Mise à jour réussie',
				`L'annonce légale a bien été ${ this.state.isEdit ? 'éditée' : 'créée' } avec succès`,
				'success'
			);

			// Navigate
			if (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()) {
				this.props.navigation(`/admin/legal-notices/${ legalNoticeId }`);
				localStorage.removeItem('legalNotice_step_1');
				localStorage.removeItem('legalNotice_form_contents');
			} else {
				this.props.navigation(`/legal-notices/${ legalNoticeId }`);
				localStorage.removeItem('legalNotice_step_1');
				localStorage.removeItem('legalNotice_form_contents');
			}

		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }: `, error);
		}
	}

	/**
	 * Create LegalNotice
	 */
	async createLegalNoticeUserSide(type: string, nbCharacters: number): Promise<void>
	{
		//loading
		this.setState({ isCreateLoading: true });

		if (this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER') {
			await this.handleAssociateConsumerSellsyUserSide(null)
				.then(async () => await this.createLegalNotice(type, nbCharacters))
				.catch(async (error) =>
				{
					await this.createLegalNotice(PublishStateEnum.ASK_USER_VALIDATE.value, nbCharacters);
					this.props.flashMessageContext?.flashMessage(
						'Une erreur est survenue',
						'Votre annonce a été envoyé auprès de nos services pour une validation',
						'error',
					);
					console.log(error);
				});
		} else {
			await this.createLegalNotice(type, nbCharacters);
		}
	}

	/**
	 * Set formData with User info
	 */
	async handleProcessUserInit(user: UserInterface): Promise<void>
	{
		// Get and Set Client
		const client = await this.clientService.getClient();
		this.setClientState(client);

		// Get and Set Collaborator
		const collaborator = UserService.getCollaboratorFromUser(user, client.id);
		this.setCollaboratorState(collaborator);

		// Is Display Block
		this.handleDisplayBlock({ blockConfig: true });
	}

	/**
	 * Handle Errors from Dynamic Inputs for the guide form.
	 * Sets handleErrors.guideForm to true if any required field is empty, false otherwise.
	 * @returns true if all required fields are filled (i.e. no errors), false otherwise.
	 */
	handleErrorsGuide(): boolean
	{
		const updatedFormContents = this.state.formContents.map((content) =>
		{
			if (content.isRequired && (!content.value || content.value.trim() === '')) {
				return {
					...content,
					error: `${ content.inputLabel || 'Ce champ' } est requis.`
				};
			}
			return {
				...content,
				error: null
			};
		});

		const hasErrors = updatedFormContents.some(content => content.error !== null);

		this.setState({
			formContents: updatedFormContents,
			handleErrors: {
				...this.state.handleErrors,
				guideForm: hasErrors
			}
		});

		return !hasErrors;
	}

	/**
	 * Checks required sub-sections in the guide form.
	 * For each required section (with subSections) in childCategory,
	 * if there is no corresponding entry in formContents with a non-empty value,
	 * an error object is added.
	 *
	 * Updates state.handleErrors.guideForm to true if there are errors,
	 * and stores the list of errors in state.handleErrors.guideFormErrors.
	 *
	 * @returns A promise that resolves to an array of error objects.
	 */
	handleErrorsGuideSubSections(): Promise<{ sectionId: number; error: string }[]>
	{
		const errors: { sectionId: number; error: string }[] =
			(this.state.formData.childCategory?.formBuilderSections || [])
				.filter(section => section.isRequired && section.subSections && section.subSections.length > 0)
				.filter(section =>
					!this.state.formContents.some(
						content => content.sectionId === section.id &&
							content.value &&
							content.value.trim() !== ''
					)
				)
				.map(section => ({
					sectionId: section.id,
					error: `${ section.title } est requis.`
				}));

		return new Promise((resolve) =>
		{
			this.setState({
				handleErrors: {
					...this.state.handleErrors,
					guideForm: errors.length > 0,
					guideFormErrors: errors,
				}
			}, () =>
			{
				resolve(errors);
			});
		});
	}

	/**
	 * Save FormData inside LocalStorage
	 * @private
	 */
	saveFormDataLocalStorage(): void
	{
		const formData = this.state.formData;
		const jsonData = JSON.stringify(formData);
		const encryptedData = CryptoJS.AES.encrypt(jsonData, this.encryptionKey).toString();
		localStorage.setItem('legalNotice_step_1', encryptedData);
	}

	/**
	 * Get FormData from LocalStorage
	 * @private
	 */
	async getFormDataLoStorage(): Promise<CreateLegalNoticeState['formData']>
	{
		const encryptedData = localStorage.getItem('legalNotice_step_1');
		if (encryptedData) {
			try {
				const bytes = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey);
				const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
				return JSON.parse(decryptedData);
			} catch (error) {
				console.error('Erreur lors du déchiffrement du formData depuis le localStorage :', error);
			}
		}
		return {} as CreateLegalNoticeState['formData'];
	}

	/**
	 * Save FormContents inside LocalStorage
	 * @private
	 */
	saveFormContentLocalStorage(): void
	{
		const formContents = this.state.formContents;
		const jsonData = JSON.stringify(formContents);
		const encryptedData = CryptoJS.AES.encrypt(jsonData, this.encryptionKey).toString();
		localStorage.setItem('legalNotice_form_contents', encryptedData);
	}

	/**
	 * Get FormContents from LocalStorage
	 * @private
	 */
	getFormContentsLoStorage(): CreateLegalNoticeState['formContents']
	{
		const encryptedData = localStorage.getItem('legalNotice_form_contents');
		if (encryptedData) {
			try {
				const bytes = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey);
				const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
				return JSON.parse(decryptedData);
			} catch (error) {
				console.error('Erreur lors du déchiffrement du formContents depuis le localStorage :', error);
			}
		}
		return {} as CreateLegalNoticeState['formContents'];
	}


	/** New method to handle file service calls
	 * @param type
	 * @param legalNoticeId
	 * @param legalNoticeData
	 * @private
	 */
	private async handleFileServiceCalls(type: string, legalNoticeId: number, legalNoticeData: any): Promise<void>
	{
		if (type === PublishStateEnum.QUOTE.value) {
			if (this.state.isEdit) {
				this.apiLegalNoticeFileService.editEstimateFile(legalNoticeId, legalNoticeData).catch(console.error);
			} else {
				this.apiLegalNoticeFileService.createEstimateFile(legalNoticeId, legalNoticeData).catch(console.error);
			}
		} else if (type === PublishStateEnum.PLANNED.value) {
			if (this.state.isEdit) {
				this.apiLegalNoticeFileService.editBillingFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
						if (legalNoticeData.legalNotice.discount > 0) {
							this.apiLegalNoticeFileService.editCreditNoteFile(legalNoticeId, legalNoticeData).catch(console.error);
						}
					})
					.catch(console.error);

				this.apiLegalNoticeFileService.editCertificateFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
					})
					.catch((error) => console.error(error));
			} else {

				this.apiLegalNoticeFileService.createBillingFile(legalNoticeId, legalNoticeData)
					.then(() =>
					{
						if (legalNoticeData.legalNotice.discount > 0) {
							return this.apiLegalNoticeFileService.createCreditNoteFile(legalNoticeId, legalNoticeData);
						}
					})
					.catch(console.error);

				this.apiLegalNoticeFileService.createCertificateFile(legalNoticeId, legalNoticeData)
					.then(() => console.log('Certificate created with success'))
					.catch(console.error);
			}
		}
	}

	private async calculatedPrice(): Promise<number>
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.department,
			this.state.formData.childCategory
		);

		// Calculate the annex price
		const annexPrice = parseFloat(
			this.legalNoticePriceService.getAnnexPrice(
				this.state.formData.department as DepartmentInterface,
				this.state.formData.childCategory as FormBuilderCategoryInterface,
				isFixedPrice
			).toFixed(2)
		);

		const getPrintPrice = await this.handlePrintPrice();
		const getShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(
			this.state.formData.legalNotice.numberOfCopies
		);
		const getExternPrice = await this.legalNoticePriceService.getExternPrice();
		const getBodaccPrice = await this.legalNoticePriceService.getBodacPrice();

		// Calculate number of characters
		const nbCharacters = isFixedPrice
			? 1
			: (
				stripHtmlTags(this.state.formData.legalNotice.content).length +
				stripHtmlTags(this.state.formData.legalNotice.title).length +
				stripHtmlTags(this.state.formData.legalNotice.signature).length +
				(this.state.charCount || 0)
			);

		const legalNoticePrice = annexPrice * nbCharacters;

		const printPrice: number = (this.state.formData.legalNotice?.option?.publishType === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getPrintPrice * this.state.formData.legalNotice.numberOfCopies
			: 0;

		const shippingCostPrice = (this.state.formData.legalNotice.option.publishType === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getShippingCostPrice
			: 0;

		const bodaccPrice = this.state.formData.legalNotice.option.isBodacc
			? getBodaccPrice
			: 0;

		const externPrice = (
				(this.state.formData.newspaper?.editorial && this.state.formData.newspaper.editorial !== 'heraultjuridique') &&
				(this.state.formData.newspaper?.name && this.state.formData.newspaper.name !== 'L\'Echo du Languedoc')
			) ? getExternPrice
				: 0
		;

		// Calculate VAT-inclusive prices
		const legalNoticePriceIncVat = parseFloat((legalNoticePrice * (1 + VAT_RATE)).toFixed(2));
		const printPriceIncVat = parseFloat((printPrice * (1 + PRINT_VAT_RATE)).toFixed(2));
		const shippingCostPriceIncVat = parseFloat((shippingCostPrice * (1 + VAT_RATE)).toFixed(2));
		const bodaccPriceIncVat = parseFloat((bodaccPrice * (1 + VAT_RATE)).toFixed(2));
		const externPriceIncVat = parseFloat((externPrice * (1 + VAT_RATE)).toFixed(2));

		// Total calculation
		return parseFloat((legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat + externPriceIncVat).toFixed(2));
	}

	private async handlePrintPrice(): Promise<number>
	{
		if (this.state.formData.newspaper?.editorial && this.state.formData.newspaper.editorial !== 'heraultjuridique') {
			const getNewspaper = await this.apiPublicService.getNewspaper(
				this.state.formData.newspaper.id,
			);

			return getNewspaper.receiptPrice;
		} else {
			return await this.legalNoticePriceService.getPrintPrice();
		}
	}


	/**
	 * Set Legal Notice for edit
	 * @param legalNotice
	 * @private
	 */
	private setLegalNoticeForEdit(legalNotice: any): void
	{
		// 02. Set State for edit
		this.setClientState(legalNotice.client);
		this.setNewspaperState(legalNotice.newspaper);
		this.setCollaboratorState(legalNotice.collaborator);
		this.setFormDataLegalNoticeState({
			id: legalNotice.id,
			tag: legalNotice.tag,
			status: legalNotice.status,
			reference: legalNotice.reference,
			option: legalNotice.option,
			formBuilderCategory: legalNotice.formBuilderCategory,
			numberOfCopies: legalNotice.numberOfCopies,
			publishDate: legalNotice.publishDate,
			logo: legalNotice.logo,
			clientLogo: legalNotice.clientLogo,
			configBillingPreference: legalNotice.configBillingPreference,
			discountPreference: legalNotice.discountPreference,
			signature: legalNotice.signature,
			discount: legalNotice.discount,
			extSupplierUid: legalNotice.extSupplierUid,
			isForcePublishDate: legalNotice.isForcePublishDate,
			paymentStatus: legalNotice.paymentStatus,
			title: legalNotice.title,
			quoteStatus: legalNotice.quoteStatus,
			content: legalNotice.content,
		});

		// Consumer form Light
		this.setState({
			isLightConsumerForm: legalNotice.configBillingPreference.billing !== 'CONSUMER'
				&& LegalNoticeTypeEnum.filterWithoutSiren.some(category =>
					String(category.value) === legalNotice.formBuilderCategory?.categoryType!)
		});

		this.setCategoryState(legalNotice.formBuilderCategory?.parent!);
		this.setChildCategoryState(legalNotice?.formBuilderCategory!);
		this.setDepartmentState(legalNotice?.publishDepartment!);
		this.setNewspaperState(legalNotice?.newspaper!);
		this.setConsumerState(legalNotice.consumer);
		this.setFormDataLegalNoticeOptionsState({ publishType: legalNotice.newspaper?.type! });
		this.setFormDataSendToState(legalNotice.sendTo);

		/* Handle Billing Config */
		this.setFormDataLegalNoticeState({
				discount: legalNotice.discount,
				configBillingPreference: {
					billing: legalNotice.configBillingPreference,
					creditNote: legalNotice.configCreditNotePreference,
				}
			}, () => this.handleDisplayBlock({ blockConfig: true })
		);
	}

	/**
	 * Checks that all required fields in formContents have a non-empty value.
	 * @returns true if all required fields are filled, false otherwise.
	 */
	validateRequiredFields(): boolean
	{
		const missingFields = this.state.formContents.filter((content) =>
			content.isRequired && (!content.value || content.value.trim() === '')
		);
		return missingFields.length === 0;
	}

}
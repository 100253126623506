import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  AppConfigAdminSupplierProps,
} from '@/Modules/Config/Admin/Component/Supplier/ConfigAdminSupplier.interface';
import {
  AppConfigAdminGiftProps,
  AppConfigAdminGiftState
} from '@/Modules/Config/Admin/Component/Gift/ConfigAdminGift.interface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { CommercialGiftInterface } from '@/Modules/Interface/CommercialGiftInterface';

const initState: AppConfigAdminGiftState = {
  isLoading: false,
  editGiftId: null,
  giftList: [],
  newGift: null
}

export class ConfigAdminGiftService extends BaseComponentService<AppConfigAdminGiftProps, AppConfigAdminGiftState>
{
  apiAdminAppService: ApiAdminAppService = new ApiAdminAppService();

  constructor()
  {
    super({} as AppConfigAdminSupplierProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.apiAdminAppService.commercialGiftList()
      .then((response)  => {
        this.setState({ isLoading: false, giftList: response })
      })
    ;
  }

  async createCommercialGift(label :string): Promise<void>
  {
    await this.apiAdminAppService.commercialGiftCreate({ label })
      .then(async () => { await this.init(); })
    ;
  }

  async updateCommercialGift(commercialGiftData: CommercialGiftInterface): Promise<void>
  {
    await this.apiAdminAppService.commercialGiftEdit(commercialGiftData.id as number, commercialGiftData)
      .then(async () => { await this.init(); })
    ;
  }

  async deleteCommercialGift(commercialGiftId :number): Promise<void>
  {
    await this.apiAdminAppService.commercialGiftDelete(commercialGiftId)
      .then(async () => { await this.init(); })
    ;
  }
}
import React, { Component, createRef, ReactElement } from 'react';
import {
	CreateLegalNoticeProps,
	CreateLegalNoticeState
} from '@/Modules/LegalNotice/Common/CreateLegalNotice.interface';
import { CreateLegalNoticeService } from '@/Modules/LegalNotice/Common/CreateLegalNotice.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import LoaderSwitchContext from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { CreateLegalNoticeStyles } from '@/Modules/LegalNotice/Common/CreateLegalNotice.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import LegalNoticeConfig from '@/Modules/LegalNotice/Components/Form/LegalNoticeConfig/LegalNoticeConfig';
import SelectorCategories from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories';
import {
	SelectorCategoriesState
} from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories.interface';
import {
	SelectorCategoriesStyles
} from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import CustomerForm from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import LegalNoticeSendToFormComponent from '@/Modules/LegalNotice/ComponentsOld/Form/LegalNoticeSendToFormComponent';
import LegalNoticePriceRender from '@/Modules/LegalNotice/Components/LegalNoticePriceRender/LegalNoticePriceRender';
import LegalNoticeOptionsHeader
	from '@/Modules/LegalNotice/Components/LegalNoticeOptionsHeader/LegalNoticeOptionsHeader';
import LegalNoticeWritingContent
	from '@/Modules/LegalNotice/Components/LegalNoticeWritingContent/LegalNoticeWritingContent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { LegalNoticeModal } from '@/Modules/LegalNotice/ComponentsOld/LegalNoticeModal';
import BillingPreferencesForm
	from '@/Modules/LegalNotice/Components/Form/BillingPreferencesForm/BillingPreferencesForm';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';

class CreateLegalNoticeFreeWriting extends Component<CreateLegalNoticeProps, CreateLegalNoticeState>
{
	private createLegalNoticeService: CreateLegalNoticeService = new CreateLegalNoticeService();
	private localStorageUpdateTimeout: NodeJS.Timeout | null = null;

	private contentWidthRef = createRef<HTMLDivElement>();

	// Refs for each section
	private searchClientRef = createRef<HTMLDivElement>();
	private collaboratorRef = createRef<HTMLDivElement>();
	private configRef = createRef<HTMLDivElement>();
	private categoryRef = createRef<HTMLDivElement>();
	private customerFormRef = createRef<HTMLDivElement>();
	private billingRef = createRef<HTMLDivElement>();
	private sendToRef = createRef<HTMLDivElement>();

	constructor(props: CreateLegalNoticeProps)
	{
		super(props);

		// Config service
		this.createLegalNoticeService.setProps(this.props);
		this.createLegalNoticeService.subscribeState(this);

		// State
		this.state = this.createLegalNoticeService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	triggerLocalStorageModal(): void
	{
		this.props.modalContext?.content(
			"Données sauvegardées",
			<div style={{ ...ModalStyles.padding, width: 500 }}>
				<div style={FontStyles.textHelp}>
					Des données préalablement sauvegardées ont été détectées à partir d'une saisie antérieure.
					Voulez-vous poursuivre avec ces informations ou recommencer une nouvelle saisie ?
				</div>
				<div style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'flex-end',
					height: 50,
					gap: 5
				}}>
					<Button
						label="Recommencer"
						variant="small"
						onClick={async () => {
							this.createLegalNoticeService.setIsWaitingLocalStorageUpdateState(false);
							localStorage.removeItem('legalNotice_step_1');
							localStorage.removeItem('legalNotice_form_contents');
							this.props.modalContext?.isOpen(false);
							await this.createLegalNoticeService.init();
						}}
					/>
					<Button
						label="Continuer"
						variant="smallDark"
						onClick={async () => {
							this.props.modalContext?.isOpen(false);
							this.createLegalNoticeService.setIsWaitingLocalStorageUpdateState(false);
							await this.createLegalNoticeService.init(true);
						}}
					/>
				</div>
			</div>
		);
	}

	async componentDidMount(): Promise<void>
	{
		const urlParams = new URLSearchParams(window.location.search);
		const isFromLegalNoticeGuide = urlParams.get('from') === 'legal-notice-guide';

		await this.createLegalNoticeService.getFormDataLoStorage()
			.then(async (storedData: CreateLegalNoticeState['formData']) => {
				if (Object.keys(storedData).length > 0 && storedData.childCategory !== null && this.state.isWaitingLocalStorageUpdate && !isFromLegalNoticeGuide) {
					setTimeout(() => {
						this.triggerLocalStorageModal();
					}, 100);
				} else if(storedData.childCategory !== null && this.state.isWaitingLocalStorageUpdate && isFromLegalNoticeGuide) {
					await this.createLegalNoticeService.init(true);
				} else {
					await this.createLegalNoticeService.init();
				}
			})
			.catch(async (error) => await this.createLegalNoticeService.init())
		;
	}

	componentDidUpdate(prevProps: CreateLegalNoticeProps, prevState: CreateLegalNoticeState): void
	{
		if (prevState.formData !== this.state.formData) {
			if (this.localStorageUpdateTimeout) {
				clearTimeout(this.localStorageUpdateTimeout);
			}
			this.localStorageUpdateTimeout = setTimeout(() =>
			{
				this.createLegalNoticeService.saveFormDataLocalStorage();
			}, 200);
		}
	}

	componentWillUnmount(): void
	{
		this.createLegalNoticeService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const {
			formData,
			isDisplayBlock,
			handleErrors
		} = this.state;

		const getAffiliationOption = (affiliation: string) =>
		{
			if (affiliation === 'CLIENT') {
				return { label: 'Donneur d\'ordre', value: 'CLIENT' };
			} else if (affiliation === 'CONSUMER') {
				return { label: 'Client final', value: 'CONSUMER' };
			}
		};

		if (this.state.isLoading) {
			return <LoaderFullPage/>;
		}

		if (this.state.isCreateLoading) {
			return <LoaderSwitchContext textLoading={ 'Creation de votre annonce légale en cours...' }/>;
		}

		console.log(this.state.formData);

		return (
			<>
				<div style={ CreateLegalNoticeStyles.container } ref={ this.contentWidthRef }>
					<div className={ 'hidden-scrollbar' } style={ CreateLegalNoticeStyles.scrollContainer }>

						{/* 03. Billing config */ }
						<div ref={ this.billingRef } style={ { display: 'none' } }>
							<BillingPreferencesForm
								key={ formData.client?.id! + formData?.collaborator?.id! }
								client={ formData?.client! }
								stateUpdater={
									(this.state.isEdit || this.state.isLocalStorageUpdate)
										? {
											clientOptions: formData.client?.options!,
											discountGranted: formData.legalNotice.discount,
											discountPreference: (formData.legalNotice.discountPreference !== null)
												? formData.legalNotice.discountPreference
												: formData.client?.options.discountPreference,
											invoiceAffiliation: getAffiliationOption(formData.legalNotice.configBillingPreference.billing!),
											creditNoteAffiliation: getAffiliationOption(formData.legalNotice.configBillingPreference.creditNote!)
										} : {}
								}
								consumerName={ formData.consumer?.name! }
								callbackState={ (state, isScrollView) =>
									this.createLegalNoticeService.handleBillingPreference(state, isScrollView ? this.configRef! : undefined)
								}
								isDisplayBlock={ Boolean(formData.collaborator) }
							/>
						</div>

						{/* 04. Configuration */ }
						<div ref={ this.configRef }>
							{ isDisplayBlock.blockConfig &&
                <LegalNoticeConfig
                  key={ isDisplayBlock.blockConfig.toString() }
                  stateUpdater={ {
										client: formData?.client!,
										legalNoticeStatus: formData?.legalNotice.status,
										selectedDepartment: formData?.department!,
										selectedNewspaper: formData?.newspaper!,
										selectedNewspaperType: formData?.newspaper?.type!,
										publishDate: (this.state.isEdit || this.state.isLocalStorageUpdate) ? formData?.legalNotice?.publishDate! : null,
										numberOfCopies: formData?.legalNotice?.numberOfCopies!
									} }
                  callbackState={ (state, isScrollView) =>
										this.createLegalNoticeService.handleConfig(state, isScrollView ? this.categoryRef! : undefined)
									}
                  isEdit={ this.state.isEdit }
                  isDisplayBlock={ isDisplayBlock.blockConfig }
                />
							}
						</div>

						<div style={ CardStyles.simpleCardGrayContainer }>
							<Label label={ 'Choix de facturation' } isRequired={ false }/>
							<div style={ { display: 'flex', gap: 10, alignItems: 'flex-start' } }>
								<input
									type="checkbox"
									checked={ this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER' }
									onChange={ (event) => this.createLegalNoticeService.handleBillingPreferenceUserSide(event) }
								/>
								<div style={ FontStyles.textHelp }>
									Si cette option est cochée, la facture sera établie pour un client final,
									utilisant les tarifs et conditions spécifiques à ce type de client.
								</div>
							</div>
						</div>

						{/* 05. Category block  */ }
						<div ref={ this.categoryRef }>
							<SelectorCategories
								isEdit={ this.state.isEdit }
								stateUpdater={ {
									isBodacc: formData.legalNotice.option.isBodacc,
									selectedCategory: formData.category,
									selectedChildCategory: formData.childCategory,
								} }
								callbackState={ (state: SelectorCategoriesState) =>
									this.createLegalNoticeService.handleSelectedCategories(
										state?.selectedCategory!,
										state?.selectedChildCategory!,
										state.isBodacc,
										this.customerFormRef
									)
								}
								isDisplayBlock={ Boolean(formData.legalNotice.publishDate) }
							/>
						</div>

						{/* 06. Référence */ }
						<div
							ref={ this.customerFormRef }
							style={ {
								...SelectorCategoriesStyles.container(isDisplayBlock.blockConsumer),
								position: 'relative',
								zIndex: 1
							} }
						>
							<div style={ CardStyles.simpleCardGrayContainer }>
								<Input
									type={ 'text' }
									label={ 'Référence' }
									name={ 'ref' }
									onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
										this.createLegalNoticeService.setFormDataLegalNoticeState({ reference: event.target.value }) }
									value={ formData?.legalNotice?.reference! }
								/>

								{/* 06. Customers */ }
								<CustomerForm
									key={
										formData?.category?.type!
										+ formData.childCategory?.type!
										+ this.state.handleErrors?.consumer!
										+ formData.legalNotice.configBillingPreference.billing
										+ this.state.isLightConsumerForm
									}
									selectedCategory={ formData?.category! }
									isFormLight={ this.state.isLightConsumerForm && formData.legalNotice.configBillingPreference.billing !== 'CONSUMER' }
									stateUpdater={ {
										consumer: formData?.consumer!,
										errors: this.state?.handleErrors?.consumer!,
									} }
									callbackState={ (state, isScrollView) =>
									{
										this.createLegalNoticeService.setConsumerState(state.consumer);
										if (isScrollView) {
											const isCheckErrors: boolean = this.createLegalNoticeService.handleErrors(this.state);
											if (!isCheckErrors) {
												this.createLegalNoticeService.scrollToSection(this.sendToRef!);
											}

										}
									}
									}
								/>
							</div>
						</div>

						{/* 08. Send to  */ }
						{ (formData.consumer && Object.keys(handleErrors?.consumer || {}).length < 1) &&
              <div
                ref={ this.sendToRef }
                style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.white, padding: 20 } }
              >
                <div style={ FontStyles.h3 }> Envois des documents</div>
                <div style={ { ...FontStyles.textHelp, marginBottom: 4 } }>
                  Vous pouvez ajouter plusieurs adresses e-mail pour envoyer les documents associés à cette procédure.
                  Assurez-vous que chaque adresse e-mail est correcte.
                  Les destinataires recevront uniquement les documents sélectionnés (factures, certificats, reçus,
                  crédits).
                  Appuyez sur "Entrée" pour valider chaque email ajouté.
                </div>
                <LegalNoticeSendToFormComponent
                  key={ this.state?.formData?.client?.id! }
                  selectedClient={ formData.client }
                  stateUpdater={ (this.state.isEdit || this.state.isLocalStorageUpdate) ?
										{
											selections: formData.sendTo,
											isEdit: true,
										}
										: { isEdit: false }
									}
                  sendTo={ formData.client?.options?.sendTo! || [] }
                  onSelections={ (selections) => this.createLegalNoticeService.setFormDataSendToState(selections) }
                  fieldsToShow={ ['invoice', 'certificate', 'receipt', 'credit'] }
                />
              </div>
						}
					</div>

					<div style={ { height: '100%', display: 'grid', gridTemplateRows: '40px auto', gap: 10 } }>
						<div style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.gray50, padding: 0 } }>
							<LegalNoticePriceRender
								key={
									formData?.category?.id!
									+ formData?.childCategory?.id!
									+ this.state.charCount
									+ formData.legalNotice.title
									+ formData.legalNotice.signature
									+ formData.legalNotice.option.isHeader
								}
								charCount={ this.state.charCount }
								consumer={ (formData.consumer && formData.legalNotice.option.isHeader) ? formData.consumer : null }
								legalNotice={ formData.legalNotice }
								department={ formData?.department! }
								childCategory={ formData?.childCategory! }
								callbackState={ (state) => this.setState({ charCount: state.numberOfCharacters }) }
							/>
						</div>
						<div style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.gray50 } }>
							<LegalNoticeOptionsHeader
								key={ formData?.client?.id! + formData?.newspaper?.toString()! }
								client={ formData?.client! }
								newspaper={ formData?.newspaper! }
								stateUpdater={ (this.state.isEdit || this.state.isLocalStorageUpdate)
									? {
										isHeader: formData.legalNotice.option.isHeader,
										selectedLogo: formData.legalNotice.clientLogo
									}
									: null
								}
								isConsumerFormComplete={ () => this.createLegalNoticeService.handleErrors(this.state) }
								isConsumerFormLight={ this.state.isLightConsumerForm }
								callbackState={ (state) =>
								{
									this.createLegalNoticeService.setFormDataLegalNoticeState({
										clientLogo: state.selectedLogo,
										option: {
											...formData.legalNotice.option,
											isHeader: state.isHeader,
											isLogo: Boolean(state.selectedLogo)
										}
									});
									this.createLegalNoticeService.setIsConsumerFormLightState(state.isConsumerFormLight);
								} }
								isDisabled={ !Boolean(formData.consumer && Object.keys(handleErrors?.consumer || {}).length < 1) }
							/>

							<Input
								type={ 'text' }
								name={ 'legal-notice-title' }
								label={ 'Ajouter un titre' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) => this.createLegalNoticeService.setFormDataLegalNoticeState(
									{ title: event.target.value }
								) }
								value={ formData.legalNotice.title! || '' }
							/>

							<LegalNoticeWritingContent
								stateUpdater={ { content: formData?.legalNotice?.content! } }
								callbackState={ (state) =>
									this.createLegalNoticeService.handleLegalNoticeContent(state) }
							/>

							<Input
								type={ 'text' }
								label={ 'signature' }
								name={ 'signature' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
									this.createLegalNoticeService.setFormDataLegalNoticeState({
										signature: event.target.value,
									})
								}
								value={ formData.legalNotice.signature || '' }
							/>

							<div style={ { display: 'flex', width: '100%', justifyContent: 'flex-end' } }>
								<Button
									label={ (!this.state.isEdit) ? 'Créer la commande' : 'Modifier cette commande' }
									variant={ 'primary' }
									disabled={ Boolean(!formData.childCategory) }
									onClick={ async (event: React.MouseEvent<HTMLButtonElement>) =>
									{
										const checkErrors: boolean = this.createLegalNoticeService.handleErrors(this.state);

										if (checkErrors) {
											return;
										}

										event.stopPropagation();
										this.props.modalContext?.content(
											'Récapitulatif de votre commande',
											<LegalNoticeModal
												modalContext={ this.props.modalContext }
												legalNotice={ formData.legalNotice }
												selectedClient={ formData.client as ClientInterface }
												selectedDepartment={ formData.department as DepartmentInterface }
												selectedNewspaper={ formData.newspaper as NewspaperInterface }
												consumer={ formData.consumer as ConsumerDataInterface }
												billingAddress={ formData.billingAddress as AddressInterface }
												sendTo={ formData.sendTo }
												selectedPrimaryCategory={ formData.category as FormBuilderCategoryInterface }
												selectedSecondaryCategory={ formData.childCategory as FormBuilderCategoryInterface }
												onSubmit={ this.createLegalNoticeService.createLegalNoticeUserSide.bind(this.createLegalNoticeService) }
											/>
										);
									} }
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(CreateLegalNoticeFreeWriting);
import {
	ConfigBillingPreferenceInterface,
	CreateLegalNoticeInterface, LegalNoticeOptionsInterface
} from '@/Modules/LegalNotice/Common/CreateLegalNotice.interface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { LegalNoticeFormBuilderCategoryModel } from '@/Modules/LegalNotice/Model/LegalNoticeFormBuilderCategory.model';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import { ClientLogosInterface } from '@/Modules/Client/Interface/ClientLogosInterface';

export class CreateLegalNoticeAdminModel implements CreateLegalNoticeInterface
{
	id: number | null = null;
	tag: string | null = null;
	title: string = '';
	content: string = '';
	signature: string = '';
	logo: string = '';
	clientLogo: ClientLogosInterface | null = null;
	publishDate: Date | null = null;
	isForcePublishDate: boolean = false;
	discount: number = 0;
	discountPreference: ClientDiscountPreferenceEnum | null = null;
	configBillingPreference: ConfigBillingPreferenceInterface = {
		billing: null,
		creditNote: null,
	};
	numberOfCopies: number = 0;
	reference: string | null = '';
	status: PublishStateEnum | null = null;
	formBuilderCategory: Partial<FormBuilderCategoryInterface> = {};
	paymentStatus: PaymentStateEnum | null = null;
	quoteStatus: QuoteStatusEnum | null = null;
	option: LegalNoticeOptionsInterface = {
		publishType: '',
		billingType: BilledToTypeEnum.ORDER_GIVER,
		isHeader: false,
		isLogo: false,
		isBodacc: false,
	};
	extSupplierUid: any | null = null;

	/**
	 * Static method to create an instance of the model.
	 * @param data - Input data for initializing the model.
	 * @returns A new instance of CreateLegalNoticeAdminModel.
	 */
	static prepare(data: Partial<CreateLegalNoticeInterface>): CreateLegalNoticeAdminModel
	{
		const model: CreateLegalNoticeAdminModel = new CreateLegalNoticeAdminModel();

		model.id = data.id ?? null;
		model.tag = data.tag ?? '';
		model.title = data.title ?? '';
		model.content = data.content ?? '';
		model.signature = data.signature ?? '';
		model.logo = data.logo ?? '';
		model.clientLogo = data.clientLogo ?? null;
		model.publishDate = data.publishDate ?? null;
		model.isForcePublishDate = data.isForcePublishDate ?? false;
		model.discountPreference = data.discountPreference ?? null;
		model.discount = data.discount ?? 0;
		model.configBillingPreference = {
			billing: data.configBillingPreference?.billing ?? null,
			creditNote: data.configBillingPreference?.creditNote ?? null,
		};
		model.numberOfCopies = data.numberOfCopies ?? 0;
		model.reference = data.reference ?? '';
		model.status = data.status ?? null;
		model.formBuilderCategory = LegalNoticeFormBuilderCategoryModel.prepare(data.formBuilderCategory!);
		model.paymentStatus = data.paymentStatus ?? null;
		model.quoteStatus = data.quoteStatus ?? null;
		model.option = data.option ?? {
			publishType: '',
			billingType: BilledToTypeEnum.ORDER_GIVER,
			isHeader: false,
			isLogo: false,
			isBodacc: false,
		};
		model.extSupplierUid = data.extSupplierUid ?? null;

		return model;
	}
}

import React, { Component, createRef, ReactElement } from 'react';
import { CreateLegalNoticeProps, CreateLegalNoticeState } from '../../Common/CreateLegalNotice.interface';
import { CreateLegalNoticeService } from '../../Common/CreateLegalNotice.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { CreateLegalNoticeStyles } from '@/Modules/LegalNotice/Common/CreateLegalNotice.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import SearchClient from '@/Modules/LegalNotice/Components/Form/SearchClient/SearchClient';
import AssociateCollaborator from '@/Modules/LegalNotice/Components/Form/AssociateCollaborator/AssociateCollaborator';
import LegalNoticeConfig from '@/Modules/LegalNotice/Components/Form/LegalNoticeConfig/LegalNoticeConfig';
import {
	AssociateCollaboratorState
} from '@/Modules/LegalNotice/Components/Form/AssociateCollaborator/AssociateCollaborator.interface';
import Input from '@/Modules/App/Components/Library/Input/Input';
import SelectorCategories from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories';
import {
	SelectorCategoriesState
} from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories.interface';
import CustomerForm from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import BillingPreferencesForm
	from '@/Modules/LegalNotice/Components/Form/BillingPreferencesForm/BillingPreferencesForm';
import LegalNoticeWritingContent
	from '@/Modules/LegalNotice/Components/LegalNoticeWritingContent/LegalNoticeWritingContent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import LegalNoticeOptionsHeader
	from '@/Modules/LegalNotice/Components/LegalNoticeOptionsHeader/LegalNoticeOptionsHeader';
import LegalNoticePriceRender from '@/Modules/LegalNotice/Components/LegalNoticePriceRender/LegalNoticePriceRender';
import {
	SelectorCategoriesStyles
} from '@/Modules/LegalNotice/Components/Form/SelectorCategories/SelectorCategories.styles';
import LegalNoticeSendToFormComponent from '@/Modules/LegalNotice/ComponentsOld/Form/LegalNoticeSendToFormComponent';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import AssociateSellsyCompany from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany';
import LoaderSwitchContext from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import { LegalNoticeModal } from '@/Modules/LegalNotice/ComponentsOld/LegalNoticeModal';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class CreateLegalNoticeAdmin extends Component<CreateLegalNoticeProps, CreateLegalNoticeState>
{
	private createLegalNoticeAdminService = new CreateLegalNoticeService();
	private contentWidthRef = createRef<HTMLDivElement>();

	// Refs for each section
	private searchClientRef = createRef<HTMLDivElement>();
	private collaboratorRef = createRef<HTMLDivElement>();
	private configRef = createRef<HTMLDivElement>();
	private categoryRef = createRef<HTMLDivElement>();
	private customerFormRef = createRef<HTMLDivElement>();
	private billingRef = createRef<HTMLDivElement>();
	private sendToRef = createRef<HTMLDivElement>();

	constructor(props: CreateLegalNoticeProps)
	{
		super(props);

		// Config service
		this.createLegalNoticeAdminService.setProps(this.props);
		this.createLegalNoticeAdminService.subscribeState(this);

		// State
		this.state = this.createLegalNoticeAdminService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.createLegalNoticeAdminService.init();

		this.createLegalNoticeAdminService.updateContentWidth(this.contentWidthRef);
		window.addEventListener('resize', () => this.createLegalNoticeAdminService.updateContentWidth(this.contentWidthRef));
	}

	componentDidUpdate(prevProps: CreateLegalNoticeProps, prevState: CreateLegalNoticeState): void
	{
		if(this.state.formData.legalNotice.configBillingPreference.billing !== null) {
			if (prevState.formData.legalNotice.configBillingPreference.billing !== this.state.formData.legalNotice.configBillingPreference.billing
				|| prevState.formData.childCategory !== this.state.formData.childCategory) {
				this.createLegalNoticeAdminService.setIsConsumerFormLightState(this.state);
				this.createLegalNoticeAdminService.setHandleErrorsState({ consumer: {} });
			}
		}
	}

	componentWillUnmount(): void
	{
		this.createLegalNoticeAdminService.unsubscribe();
		window.addEventListener('resize', () => this.createLegalNoticeAdminService.updateContentWidth(this.contentWidthRef));
	}

	//</editor-fold>

	render(): ReactElement
	{
		const {
			formData,
			isDisplayBlock,
			handleErrors
		} = this.state;

		const getAffiliationOption = (affiliation: string) =>
		{
			if (affiliation === 'CLIENT') {
				return { label: 'Donneur d\'ordre', value: 'CLIENT' };
			} else if (affiliation === 'CONSUMER') {
				return { label: 'Client final', value: 'CONSUMER' };
			}
		};

		if (this.state.isLoading) {
			return <LoaderFullPage/>;
		}

		if (this.state.isCreateLoading) {
			return <LoaderSwitchContext textLoading={ 'Creation de votre annonce légale en cours...' }/>;
		}

		console.log(this.state.formData);

		return (
			<>
				<HeroSection
					title={
						this.state.isEdit
							? `Modifier l'annonce légale ${ formData.legalNotice.tag }`
							: 'Créer une nouvelle annonce légale'
					}
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowUpLeft',
						onClick: () => null,
					} }
				/>

				<div style={ CreateLegalNoticeStyles.container } ref={ this.contentWidthRef }>
					<div className={ 'hidden-scrollbar' } style={ CreateLegalNoticeStyles.scrollContainer }>
						{ (UserConnectedService.isSuperAdmin() || UserConnectedService.isAdmin()) && this.state.formData.client?.options.commentary &&
              <div style={ {
								position: 'sticky',
								top: 0,
								zIndex: 100,
								backgroundColor: colors.warning50,
								...borderStyles,
								borderColor: colors.warning100,
								borderRadius: 6,
								color: colors.warning900,
								padding: '5px 10px',
								minHeight: 38,
								display: 'flex',
								fontSize: 12,
								alignItems: 'center',
								justifyContent: 'space-between'
							} }>
                <span> Notes: { this.state.formData.client.options.commentary }</span>
              </div>
						}

						<div style={ { ...CardStyles.simpleCardGrayContainer, zIndex: 25 } }>
							{/* 01. Search Client */ }
							<div ref={ this.searchClientRef }>
								<SearchClient
									client={ formData.client! }
									callbackState={ (state) =>
										this.createLegalNoticeAdminService.setClientState(state.selectedClient!)
									}
								/>
							</div>
							{ /* 02. Associate Collaborator */ }
							<div ref={ this.collaboratorRef }>
								<AssociateCollaborator
									key={ formData.client?.id }
									client={ formData?.client! }
									collaborator={ formData?.collaborator! }
									callbackState={ (collaboratorState: AssociateCollaboratorState) =>
										this.createLegalNoticeAdminService.setCollaboratorState(collaboratorState.selectedCollaborator!, this.billingRef)
									}
									isDisabled={ !formData.client || Object.keys(formData.client).length === 0 }
								/>
							</div>
						</div>

						{/* 03. Billing config */ }
						<div ref={ this.billingRef }>
							<BillingPreferencesForm
								key={ formData.client?.id! + formData?.collaborator?.id! }
								client={ formData?.client! }
								stateUpdater={
									(this.state.isEdit)
										? {
											clientOptions: formData.client?.options!,
											discountGranted: formData.legalNotice.discount,
											discountPreference: (formData.legalNotice.discountPreference !== null)
												? formData.legalNotice.discountPreference
												: formData.client?.options.discountPreference,
											invoiceAffiliation: getAffiliationOption(formData.legalNotice.configBillingPreference.billing!),
											creditNoteAffiliation: getAffiliationOption(formData.legalNotice.configBillingPreference.creditNote!)
										} : {}
								}
								consumerName={ formData.consumer?.name! }
								callbackState={ (state, isScrollView) =>
									this.createLegalNoticeAdminService.handleBillingPreference(state, isScrollView ? this.configRef! : undefined)
								}
								isDisplayBlock={ Boolean(formData.collaborator) }
							/>
						</div>

						{/* 04. Configuration */ }
						<div ref={ this.configRef }>
							{ isDisplayBlock.blockConfig &&
                <LegalNoticeConfig
                  key={ isDisplayBlock.blockConfig.toString() }
                  stateUpdater={ {
										client: formData?.client!,
										legalNoticeStatus: formData?.legalNotice.status,
										selectedDepartment: formData?.department!,
										selectedNewspaper: formData?.newspaper!,
										selectedNewspaperType: formData?.newspaper?.type!,
										publishDate: (this.state.isEdit) ? formData?.legalNotice?.publishDate! : null,
										numberOfCopies: formData?.legalNotice?.numberOfCopies!
									} }
                  callbackState={ (state, isScrollView) =>
										this.createLegalNoticeAdminService.handleConfig(state, isScrollView ? this.categoryRef! : undefined)
									}
                  isEdit={ this.state.isEdit }
                  isDisplayBlock={ isDisplayBlock.blockConfig }
                />
							}
						</div>

						{/* 05. Category block  */ }
						<div ref={ this.categoryRef }>
							<SelectorCategories
								stateUpdater={ {
									isBodacc: formData.legalNotice.option.isBodacc,
									selectedCategory: formData.category,
									selectedChildCategory: formData.childCategory,
								} }
								isEdit={ this.state.isEdit }
								callbackState={ (state: SelectorCategoriesState) =>
									this.createLegalNoticeAdminService.handleSelectedCategories(
										state?.selectedCategory!,
										state?.selectedChildCategory!,
										state.isBodacc,
										this.customerFormRef
									)
								}
								isDisplayBlock={ Boolean(formData.legalNotice.publishDate) }
							/>
						</div>

						{/* 06. Référence */ }
						<div
							ref={ this.customerFormRef }
							style={ {
								...SelectorCategoriesStyles.container(isDisplayBlock.blockConsumer),
								position: 'relative',
								zIndex: 1
							} }
						>
							<div style={ CardStyles.simpleCardGrayContainer }>
								<Input
									type={ 'text' }
									label={ 'Référence' }
									name={ 'ref' }
									onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
										this.createLegalNoticeAdminService.setFormDataLegalNoticeState({ reference: event.target.value }) }
									value={ formData?.legalNotice?.reference! }
								/>

								{/* 06. Customers */ }
								<CustomerForm
									key={
										formData?.category?.type!
										+ formData.childCategory?.type!
										+ this.state.handleErrors?.consumer!
										+ formData.legalNotice.configBillingPreference.billing
										+ this.state.isLightConsumerForm
									}
									selectedCategory={ formData?.category! }
									isFormLight={ this.state.isLightConsumerForm }
									isEdit={ this.state.isEdit }
									stateUpdater={ {
										consumer: formData?.consumer!,
										errors: this.state?.handleErrors?.consumer!,
									} }
									callbackState={ (state, isScrollView) =>
									{
										this.createLegalNoticeAdminService.setConsumerState(state.consumer);
										if (isScrollView) {
											const isCheckErrors: boolean = this.createLegalNoticeAdminService.handleErrors(this.state);
											if (!isCheckErrors) {
												this.createLegalNoticeAdminService.scrollToSection(this.sendToRef!);
											}

										}
									}
									}
								/>
							</div>
						</div>

						{/* 07. Block Sellsy  */ }
						{ (formData.legalNotice.configBillingPreference.billing === 'CONSUMER'
								&& (formData.consumer && Object.keys(handleErrors?.consumer || {}).length < 1))
							&& (
								<AssociateSellsyCompany
									key={ formData.consumer?.name!
										+ formData.consumer?.address?.number!
										+ formData.consumer?.address?.street!
										+ formData.consumer?.address?.additionalData!
										+ formData.consumer?.address?.zipCode!
										+ formData.consumer?.address?.city!
									}
									isWaitingCreate={ false }
									isSelectedDetailsNeeded={ true }
									containerCss={ { minWidth: '100%' } }
									consumer={ formData?.consumer! }
									isEdit={ this.state.isEdit }
									associateCompany={ formData?.consumer! }
									getComponentState={ (companySellsyState) =>
										this.createLegalNoticeAdminService.handleDataFromAssociateSellsyCompany(companySellsyState.selectedCompanySellsy)
									}
								/>
							)
						}

						{/* 08. Send to  */ }
						{ (formData.consumer && Object.keys(handleErrors?.consumer || {}).length < 1) &&
              <div
                ref={ this.sendToRef }
                style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.white, padding: 20 } }
              >
                <div style={ FontStyles.h3 }> Envois des documents</div>
                <div style={ { ...FontStyles.textHelp, marginBottom: 4 } }>
                  Vous pouvez ajouter plusieurs adresses e-mail pour envoyer les documents associés à cette procédure.
                  Assurez-vous que chaque adresse e-mail est correcte.
                  Les destinataires recevront uniquement les documents sélectionnés (factures, certificats, reçus,
                  crédits).
                  Appuyez sur "Entrée" pour valider chaque email ajouté.
                </div>
                <LegalNoticeSendToFormComponent
                  key={ this.state?.formData?.client?.id! }
                  selectedClient={ formData.client }
                  stateUpdater={ (this.state.isEdit) ?
										{
											selections: formData.sendTo,
											isEdit: true,
										}
										: { isEdit: false }
									}
                  sendTo={ formData.client?.options?.sendTo! || [] }
                  onSelections={ (selections) => this.createLegalNoticeAdminService.setFormDataSendToState(selections) }
                  fieldsToShow={ ['invoice', 'certificate', 'receipt', 'credit'] }
                />
              </div>
						}
					</div>

					<div style={ { height: '100%', display: 'grid', gridTemplateRows: '40px auto', gap: 10 } }>
						<div style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.gray50, padding: 0 } }>
							<LegalNoticePriceRender
								key={
									formData?.category?.id!
									+ formData?.childCategory?.id!
									+ this.state.charCount
									+ formData.legalNotice.title
									+ formData.legalNotice.signature
									+ formData.legalNotice.option.isHeader
								}
								charCount={ this.state.charCount }
								consumer={ (formData.consumer && formData.legalNotice.option.isHeader) ? formData.consumer : null }
								legalNotice={ formData.legalNotice }
								department={ formData?.department! }
								childCategory={ formData?.childCategory! }
								callbackState={ (state) => this.setState({ charCount: state.numberOfCharacters }) }
							/>
						</div>
						<div style={ { ...CardStyles.simpleCardGrayContainer, backgroundColor: colors.gray50 } }>
							<LegalNoticeOptionsHeader
								key={ formData?.client?.id! + formData?.newspaper?.toString()! }
								client={ formData?.client! }
								newspaper={ formData?.newspaper! }
								stateUpdater={ (this.state.isEdit)
									? {
										isHeader: formData.legalNotice.option.isHeader,
										selectedLogo: formData.legalNotice.clientLogo,
									}
									: null
								}
								isConsumerFormComplete={ () => this.createLegalNoticeAdminService.handleErrors(this.state) }
								isConsumerFormLight={ this.state.isLightConsumerForm }
								callbackState={ (state) =>
								{
									this.createLegalNoticeAdminService.setFormDataLegalNoticeState({
										clientLogo: state.selectedLogo,
										option: {
											...formData.legalNotice.option,
											isHeader: state.isHeader,
											isLogo: Boolean(state.selectedLogo)
										}
									});
									this.createLegalNoticeAdminService.setIsConsumerFormLightState(state.isConsumerFormLight);
								} }
								isDisabled={ !Boolean(formData.consumer && Object.keys(handleErrors?.consumer || {}).length < 1) }
							/>

							<Input
								type={ 'text' }
								name={ 'legal-notice-title' }
								label={ 'Ajouter un titre' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) => this.createLegalNoticeAdminService.setFormDataLegalNoticeState(
									{ title: event.target.value }
								) }
								value={ formData.legalNotice.title! || '' }
							/>

							<LegalNoticeWritingContent
								stateUpdater={ { content: formData?.legalNotice?.content! } }
								callbackState={ (state) =>
									this.createLegalNoticeAdminService.handleLegalNoticeContent(state) }
							/>

							<Input
								type={ 'text' }
								label={ 'signature' }
								name={ 'signature' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
									this.createLegalNoticeAdminService.setFormDataLegalNoticeState({
										signature: event.target.value,
									})
								}
								value={ formData.legalNotice.signature || '' }
							/>

							<div style={ { display: 'flex', width: '100%', justifyContent: 'flex-end' } }>
								<Button
									label={ (!this.state.isEdit) ? 'Créer la commande' : 'Modifier cette commande' }
									variant={ 'primary' }
									disabled={ Boolean(!formData.childCategory) }
									onClick={ (event: React.MouseEvent<HTMLButtonElement>) =>
									{
										const checkErrors: boolean = this.createLegalNoticeAdminService.handleErrors(this.state);

										if (checkErrors) {
											return;
										}

										if (!this.state.isSellsyAssociate && this.state.formData.legalNotice.configBillingPreference.billing === 'CONSUMER') {
											this.props.flashMessageContext?.flashMessage(
												'Erreur',
												'Merci d‘associer le client à Sellsy',
												'error'
											);
											return;
										}

										event.stopPropagation();
										this.props.modalContext?.content(
											'Récapitulatif de votre commande',
											<LegalNoticeModal
												key={ JSON.stringify(formData) }
												legalNotice={ formData.legalNotice }
												consumer={ formData.consumer! }
												sendTo={ formData.sendTo! }
												selectedDepartment={ formData.department! }
												selectedPrimaryCategory={ formData.category! }
												selectedSecondaryCategory={ formData.childCategory! }
												selectedClient={ formData.client! }
												modalContext={ this.props.modalContext }
												selectedNewspaper={ formData.newspaper! }
												billingAddress={ formData?.billingAddress! }
												onSubmit={ this.createLegalNoticeAdminService.createLegalNotice.bind(this.createLegalNoticeAdminService) }
											/>
										);
									} }
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(CreateLegalNoticeAdmin);
import React, { ReactElement } from 'react';
import {
  CommercialClientAdminProps, CommercialClientAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Show/CommercialClientAdmin.interface';
import {
  CommercialClientAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Show/CommercialClientAdmin.service';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import ClientGiftAdmin from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/ClientGift/ClientGiftAdmin';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { ClipLoader } from 'react-spinners';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import CollaboratorsAdmin from '@/Modules/Collaborator/Admin/List/CollaboratorsAdmin';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

export default class CommercialClientAdmin extends React.Component<CommercialClientAdminProps, CommercialClientAdminState>
{
  private componentService: CommercialClientAdminService = new CommercialClientAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    const { client } = this.props;

    if (this.state.isLoading) {
      return <ClipLoader/>;
    }

    return (
      <>
        <div style={ { lineHeight: 0.5, marginBottom: 20 } }>
          <h4>
            { client.name }
          </h4>
          <div style={ { fontSize: 11, color: colors.gray400 } }>Siren : { client.siren }</div>
        </div>

        {/* Reproduce the section collap style */}
        <div style={{ ...SectionCollapsedStyles.container, marginTop: 15 }}>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Détails
            </div>
          </div>
        </div>

        <div style={{ width: '98%', marginLeft: 5 }}>
          <LineDetailsRender
            info={ 'Ville' }
            formData={ client?.address.city }
            isEditable={ false }
          />
          <LineDetailsRender
            info={ 'Code postal' }
            formData={ client?.address.zipCode }
            isEditable={ false }
          />
          <LineDetailsRender
            info={ 'N°' }
            formData={ client?.address.number }
            isEditable={ false }
          />
          <LineDetailsRender
            info={ 'Rue' }
            formData={ client?.address.street }
            isEditable={ false }
          />
        </div>

        <div style={{ ...SectionCollapsedStyles.container, marginTop: 15 }}>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Collaborateur(s)
            </div>
          </div>
        </div>

        <CollaboratorsAdmin isRemoveActions={ true } client={ client }/>

        {/* Reproduce the section collap style */}
        <div style={{ ...SectionCollapsedStyles.container, marginTop: 15 }}>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Cadeaux
            </div>
          </div>
        </div>

        <ClientGiftAdmin
          client={ client }
          refreshClient={ this.props.refreshClient }
          modalContext={ this.props.modalContext! }
          flashMessageContext={ this.props.flashMessageContext }
        />

        {/* Reproduce the section collap style */}
        <div style={{ ...SectionCollapsedStyles.container, marginTop: 15 }}>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Secteur
            </div>
          </div>
        </div>

        { this.state.commercialSectorList.length > 0 &&
          <Select
            styles={{ width: '100%' }}
            listOptions={ this.state.commercialSectorList.map((sector) => ({
              label: sector.label,
              id: sector.id
            })) }
            isRequired={ true }
            value={ this.state.selectedSelector?.label || '' }
            onSelect={ (selectedSector: { id: number, label: string }) => {
              this.setState({ selectedSelector: selectedSector });
              this.componentService.editSector(client?.id, selectedSector.id);
            }}
          />
        }
      </>
    );
  }
}
import React, { CSSProperties, ReactElement } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

interface TagStatProps
{
  label: React.ReactNode;
  variant?: 'success' | 'danger' | 'warning' | 'secondary' | 'primary';
  icon?: React.ReactNode;
  style?: CSSProperties;
}

interface TagStatState {}

export default class TagStat extends React.Component<TagStatProps, TagStatState>
{
  private variantStyles: Record<string, { backgroundColor: string; color: string; border: string }> = {
    success: { backgroundColor: colors.success100, color: colors.success600, border: `1px solid ${ colors.success200 }` },
    danger: { backgroundColor: colors.error100, color: colors.error600, border: `1px solid ${ colors.error200 }` },
    warning: { backgroundColor: colors.warning100, color: colors.warning600, border: `1px solid ${ colors.warning200 }` },
    primary: { backgroundColor: colors.blueRibbon100, color: colors.blueRibbon600, border: `1px solid ${ colors.blueRibbon200 }` },
    secondary: { backgroundColor: colors.gray100, color: colors.gray600, border: `1px solid ${ colors.gray200 }` },
  };

  render(): ReactElement
  {
    const { label, variant = 'secondary', icon, style } = this.props;
    const appliedStyle = this.variantStyles[variant] || this.variantStyles.secondary;

    return (
      <div
        style={{
          height: 'auto',
          fontSize: 10,
          padding: '3px 6px',
          backgroundColor: appliedStyle.backgroundColor,
          color: appliedStyle.color,
          border: appliedStyle.border,
          borderRadius: 5,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 600,
          ...style,
        }}
      >
        { icon && <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>{ icon }</div>}
        <span>{ label }</span>
      </div>
    );
  }
}

import { GlobalContextInterface } from '@/Context/Global/Global.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ClientOptionsInterface } from '@/Modules/Client/Interface/ClientOptionsInterface';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';

export const billingPreferencesListOptions: { label: string, value: string} [] = [
	{ label: "Donneur d'ordre", value: "CLIENT" },
	{ label: "Client final", value: "CONSUMER" },
];

export interface BillingPreferencesFormProps extends GlobalContextInterface
{
	client: ClientInterface,
	stateUpdater: Partial<BillingPreferencesFormState>,
	consumerName: string,
	callbackState: (state: BillingPreferencesFormState, isScrollView?: boolean) => void,
	isDisplayBlock?: boolean,
}

export interface BillingPreferencesFormState
{
	clientOptions: ClientOptionsInterface,
	isFree: boolean,
	discountGranted: number,
	discountPreference: ClientDiscountPreferenceEnum | null,
	invoiceAffiliation: { label: string, value: string },
	creditNoteAffiliation: { label: string, value: string },
}
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { SellsyCompanyResponseInterface } from '@/Interface/Sellsy/SellsyCompanyResponse.interface';
import { SellsyErrorResponseInterface } from '@/Interface/Sellsy/SellsyErrorResponse.interface';
import {
	SellsyCompanyAddressesResponseInterface
} from '@/Interface/Sellsy/SellsyCompanyAddressesResponse.interface';
import {
	SellsyCreateCompanyFromLegalNoticeInterface
} from '@/Interface/Sellsy/SellsyCreateCompanyFromLegalNotice.interface';
import { SellsyCompanyAddressResponseInterface } from '@/Interface/Sellsy/SellsyCompanyAddressResponse.interface';
import { CreateCompanySellsyRequestInterface } from '@/Interface/Sellsy/CreateCompanySellsyRequestInterface';

export class ApiAdminSellsyService extends BaseService
{
	clientSellsyUrl: string;

	constructor(isAdmin: boolean = true) {
		super();
		this.clientSellsyUrl = isAdmin
			? `${BaseService.API_URL}/v1/admin/sellsy`
			: `${BaseService.API_URL}/v1/sellsy`;
	}

	async createCompanyFromLegalNotice(companyId: number, createClientData: CreateCompanySellsyRequestInterface): Promise<SellsyCreateCompanyFromLegalNoticeInterface | SellsyErrorResponseInterface>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/${ companyId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(createClientData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
			throw new Error(error.message);
		}
	}

	async searchCompanyById(sellsyCompanyId: string, companyId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/search/${ companyId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify({ 'sellsyCompanyId': parseInt(sellsyCompanyId) }),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async searchCompanyByTerm(companyId: number, term: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/search/${ companyId }/${ term }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getCompany(companyId: number, sellsyClientId: number): Promise<SellsyCompanyResponseInterface | SellsyErrorResponseInterface>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/${ companyId }/${ sellsyClientId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
			throw new Error(error.message);
		}
	}

	async getCompanyContact(companyId: number, sellsyClientId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/contacts/${ companyId }/${ sellsyClientId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async listCompanyContact(companyId: number, sellsyClientId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/contacts/list/${ companyId }/${ sellsyClientId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getContact(companyId: number, sellsyContactId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/contacts/${ companyId }/${ sellsyContactId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createContact(companyId: number, contactData: any): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/contacts/${ companyId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(contactData)
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async linkContactToCompany(companyId: number, sellsyCompanyId: number, sellsyContactId: number, isCollaboratorOwner: boolean): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/contacts/link/${ companyId }/${ sellsyCompanyId }/${ sellsyContactId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(
						{
							roles: (isCollaboratorOwner)
								? 'main'
								: 'dunning'
						}
					)
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getCompanyAddressList(companyId: number, sellsyCompanyId: number): Promise<SellsyCompanyAddressesResponseInterface | undefined>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/addresses/${ companyId }/${ sellsyCompanyId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async updateCompanyName(companyId: number, sellsyCompanyId: number, name: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/${ companyId }/${ sellsyCompanyId }`,
				{
					method: HttpVerbEnum.PUT,
					headers: this.buildHeaders(),
					body: JSON.stringify({ name })
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createClientSellsyAddress(companyId: number, sellsyCompanyId: number, clientId: number): Promise<SellsyCompanyAddressResponseInterface | undefined>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/companies/addresses/${ companyId }/${ sellsyCompanyId }/${ clientId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
				}
			);

			return await response.json();

		} catch (error: any) {
			console.error(`Error Occurred on ${ this.constructor.name } : `, error);
			throw new Error(`Error Occurred on ${ this.constructor.name } : `, error);
		}
	}

	async updateCompanyAddress(companyId: number, sellsyCompanyId: number, sellsyAddressId: number, addressData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.clientSellsyUrl }/companies/addresses/${ companyId }/${ sellsyCompanyId }/${ sellsyAddressId }`,
				{
					method: HttpVerbEnum.PUT,
					headers: this.buildHeaders(),
					body: JSON.stringify(addressData)
				}
			);
			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getInvoice(companyId: number, sellsyCompanyId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.clientSellsyUrl }/invoice/${ companyId }/${ sellsyCompanyId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles, boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';

export const SearchStyles = {
	container: {
		position: 'relative',
	} as CSSProperties,

	openList: (isOpen: boolean, top?: number) => ({
		position: 'absolute',
		visibility: (isOpen) ? 'visible' : 'hidden',
		top: (top) ? top : 98,
		backgroundColor: colors.white,
		borderRadius: 8,
		...borderStyles,
		zIndex: 100,
		maxHeight: 200,
		minHeight: 50,
		overflow: 'scroll',
		width: '100%',
		padding: 10,
		...boxShadows.main
	}) as CSSProperties,

	item: (isHovered: boolean) => ({
		width: '100%',
		backgroundColor: (isHovered) ? colors.gray100 : 'inherit',
		borderRadius: 6,
		padding: '3px 6px',
		cursor: 'pointer',
	}) as CSSProperties,

	notFound: {
		textAlign: 'center'
	} as CSSProperties,


} as const;

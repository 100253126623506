import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export class LegalNoticeRenderStyle
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center'
    };
  }

  static contentContainerStyle(): CSSProperties
  {
    return {
      position: 'relative',
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 40,
      borderRadius: 8,
      backgroundColor: colors.white,
      fontSize: 12,
      width: '300px',
      overflowY: 'auto',
    };
  }

  static borderStyle(): CSSProperties
  {
    return {
      borderWidth: '2px',
      borderColor: '#000',
      width: '150px',
      margin: '10px 0 0 0',
      opacity: '1'
    };
  }

  static renderStaticMessage(): CSSProperties
  {
    return {
      width: '200px',
      fontSize: '10px',
      fontStyle: 'italic',
      lineHeight: '12px',
      marginTop: '10px',
      marginRight: '5px',
      color: '#9C9B98',
      textAlign: 'right'
    };
  }
}
import React, { Component, ReactElement } from 'react';
import { UserDetailsProps, UserDetailsState } from './UserDetails.interface';
import { UserDetailsService } from './UserDetails.service';
import { dateFormat } from '@/Utils/DateUtils';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Editable from '@/Modules/App/Components/Library/Editable/Editable';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { UserDetailsStyles } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.styles';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class UserDetails extends Component<UserDetailsProps, UserDetailsState>
{
	private userDetailsService = new UserDetailsService();

	constructor(props: UserDetailsProps)
	{
		super(props);

		// Config service
		this.userDetailsService.setProps(this.props);
		this.userDetailsService.subscribeState(this);

		// State
		this.state = this.userDetailsService.getState();
	}

	async componentDidMount(): Promise<void>
	{
		await this.userDetailsService.init();
	}

	componentWillUnmount(): void
	{
		this.userDetailsService.unsubscribe();
	}

	render(): ReactElement
	{
		const { formData, isLoading } = this.state;

		if (isLoading) {
			return <LoaderComponent />;
		}

		const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;

			if (name === 'companyId') {
				this.setState((prevState) => ({
					...prevState,
					formData: {
						...prevState.formData,
						company: {
							...prevState.formData.company,
							id: parseInt(value),
						},
					},
				}));
			} else {
				handleChangeInput(event, this.setState.bind(this));
			}
		};

		return (
			<>
				<div style={ { lineHeight: 0.5, marginBottom: 20 } }>
					<h4 style={{
						color: UserConnectedService.isDeveloper() ? colors.white : undefined,
					}}>
						{ formData.lastname } { formData.firstname }
					</h4>
					<div style={ { fontSize: 11, color: colors.gray400 } }>#{ formData.tag }</div>
				</div>

				{/* Reproduce the section collap style */}
				<div style={ SectionCollapsedStyles.container }>
					<div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
						<div style={ SectionCollapsedStyles.title }>
							Information Utilisateur
						</div>
					</div>
				</div>

				<Editable onEdit={ () => this.userDetailsService.handleEdit(this.state) }>
					{ ({ isEditable }) => (
						<div style={ UserDetailsStyles.container }>
							<div style={ UserDetailsStyles.column }>
								<LineDetailsRender
									info={ 'Utilisateur depuis' }
									formData={ dateFormat(formData.createdAt) }
								 	isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Nom' }
									formData={ formData.lastname }
								 	inputEdit={ { type: 'text', name: 'lastname', onChange } }
								 	isEditable={ isEditable }
								/>
								<LineDetailsRender
									info={ 'Prénom' }
									formData={ formData.firstname }
									inputEdit={ { type: 'text', name: 'firstname', onChange } }
									isEditable={ isEditable }
								/>
								{ UserConnectedService.isDeveloper()
									? <LineDetailsRender
										info={ 'Email' }
										formData={ formData.email }
										inputEdit={ { type: 'text', name: 'email', onChange } }
										isEditable={ isEditable }
									/>
									: <LineDetailsRender
										info={ 'Email' }
										formData={ formData.email }
										isEditable={ isEditable }
									/>
								}
								<LineDetailsRender
									info={ 'Téléphone' }
									formData={ formData.phone }
									inputEdit={ { type: 'text', name: 'phone', onChange } }
									isEditable={ isEditable }
								/>
								{ UserConnectedService.isDeveloper()
									? <LineDetailsRender
										info={ 'Rôle' }
										render={ <Tag value={ formData.role.toString() } enumName={ 'UserRoleEnum' }/> }
										inputEdit={ { type: 'text', name: 'role', onChange } }
										formData={ formData.role }
										isEditable={ isEditable }
									/>
									: <LineDetailsRender
										info={ 'Rôle' }
										render={ <Tag value={ formData.role.toString() } enumName={ 'UserRoleEnum' }/> }
										formData={ formData.role }
										isEditable={ isEditable }
									/>
								}

								{ UserConnectedService.isDeveloper() &&
									<LineDetailsRender
										info={ 'CompanyID' }
										formData={ formData.company?.id || '' }
										inputEdit={ { type: 'text', name: 'companyId', onChange } }
										isEditable={ isEditable }
									/>
								}

								<LineDetailsRender
									info={ 'Statut' }
									render={
										<>
											<div style={ { display: 'flex', gap: 10 } }>
												<Button
													label={ (formData.status.toString() === ActiveStatusEnum.ACTIVE.value) ? 'Rendre Inactif' : 'Rendre Actif' }
													variant={ 'secondary' }
													onClick={ () => this.userDetailsService.handleStatus(formData.status.toString()) }
												/>
												<Tag value={ formData.status.toString() } enumName={ 'ActiveStatusEnum' }/>
											</div>
										</>
									}
									formData={ formData.status.toString() }
									isEditable={ isEditable }
								/>
							</div>
						</div>
					) }
				</Editable>
			</>
		);
	}
}

export default withGlobalContext(UserDetails);

import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType,
	navigation: NavigateFunction
}

interface ViewState
{
	email: string,
	newPassword: string,
	user: UserInterface | null,
	errorMessage: string | null,
	isLoading: boolean,
}

export default class AskForgotPasswordView extends React.Component<ViewProps, ViewState>
{
	publicService: ApiPublicService;
	state = {
		email: '',
		newPassword: '',
		user: null,
		errorMessage: null,
		isLoading: false,
	};

	constructor(props: any)
	{
		super(props);

		// Services
		this.publicService = new ApiPublicService();

		// Bind
		this.submitFormAccountHandler = this.submitFormAccountHandler.bind(this);
		this.formAccountContent = this.formAccountContent.bind(this);

		// Document title
		document.title = 'Publisur - Mot de passe oublié';
	}

	render(): ReactElement
	{
		if( this.state.isLoading) {
			return <LoaderFullPageComponent />;
		}
		return (
				this.formAccountContent()
		);
	}

	private formAccountContent(): ReactElement
	{
		return (
			/* CONTAINER */
			<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{/* CARD */}
				<div style={ {
					width: 500,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: CssVariableEnum['--color-white'],
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
					padding: 25,
					borderRadius: 15
				} }>
					<div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }>
						<div style={ FontStyle.h2() }>Réinitialiser votre mot de passe</div>
						<p style={ { ...FontStyle.littleGrey(), textAlign: 'center', margin: '5px 0 15px 0', lineHeight: 1.1 } }>
							Afin de vérifier si votre adresse email est bien liée à compte existant, <br/>
							merci de remplir le champ ci-dessous.
						</p>

						{ this.state.errorMessage
							&& <div className="error-message" role="alert">{ this.state.errorMessage }</div>
						}

						<Input
							type={ 'text' }
							width={ '100%' }
							name={ 'forgot-password-email' }
							placeholder="Votre email"
							value={ this.state.email }
							onChange={ (event) => this.onChangeEmail(event) }
							required={ true }
						/>

						<div style={ {
							display: 'flex',
							justifyContent: 'flex-end',
							gap: 20,
							height: 50,
							width: '100%',
							alignItems: 'flex-end'
						} }>
							<ButtonOld
								type={ 'danger' }
								label={ 'Annuler' }
								onClick={ () => this.props.navigation('/auth') }
							/>

							<ButtonOld
								type="default-blue"
								label={ 'Valider mon email' }
								onClick={ this.submitFormAccountHandler }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private onChangeEmail(event: React.ChangeEvent<any>): void
	{
		this.setState({ email: event.target.value });
	}

	private async submitFormAccountHandler(event: React.FormEvent<HTMLFormElement>): Promise<void>
	{
		event.preventDefault();

		if (!this.state.email.trim()) {
			this.setState({ errorMessage: 'Merci de remplir le champ email' });

			return;
		}

		try {
			this.setState({ isLoading: true });

			const response = await this.publicService.askForgotPassword({ email: this.state.email.trim() });

			if (response.errorMessage) {
				this.setState({ errorMessage: response.errorMessage });
			} else {
				this.props.flashMessageContext.flashMessage(
					'Demande de changement de mot de passe bien enregistrée',
					'Vous allez recevoir un email pour la procédure de changement de mot de passe',
					'success'
				);

				setTimeout(() => {
					this.props.navigation('/auth');
				}, 3000);
			}

		} catch (error: any) {
			console.error(error.message);
			this.props.flashMessageContext.flashMessage(
				'Erreur',
				error.message || "Une erreur est survenue. Veuillez réessayer plus tard.",
				'error'
			);
		} finally {
			this.setState({ isLoading: false });
		}
	}

}
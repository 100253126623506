import React, { Component, ReactElement } from 'react';
import { TabsProps, TabsState } from '@/Modules/App/Components/Tabs/Tabs.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

class Tabs extends Component<TabsProps, TabsState>
{
  constructor(props: TabsProps)
  {
    super(props);

    // Init state
    this.state = {
      activeTab: 0
    };
  }

  /**
   * Change active tab
   * @param index
   */
  setActiveTab(index: number): void {
    this.setState({ activeTab: index });
  }

  render(): ReactElement
  {
    const { tabs } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '2px solid #ddd', marginBottom: 20 }}>
          { tabs.map((tab, index) => (
            <button
              key={ index }
              onClick={ () => this.setActiveTab(index) }
              style={{
                padding: '10px 20px',
                cursor: 'pointer',
                border: 'none',
                background: activeTab === index ? colors.secondary : 'transparent',
                color: activeTab === index ? 'white' : '#888',
                fontWeight: activeTab === index ? 'bold' : 'normal',
                transition: 'background 0.3s ease-in-out'
              }}
            >
              { tab.label }
            </button>
          ))}
        </div>

        <div>{ tabs[activeTab].content }</div>
      </div>
    );
  }
}

export default Tabs;

import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LegalNoticeModalStyle
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      maxHeight: 800,
      overflow: 'scroll',
    };
  }

  static detailsContainerStyle(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-white'],
      padding: 20,
    };
  }

  static detailsDisplayFlexColumnStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: 10
    };
  }

  static alertContainerStyle(isMarginRightNeeded: boolean = false): CSSProperties
  {
    return {
      marginLeft: '10px',
      marginBottom: '0',
      fontSize: '14px',
      padding: '5px 5px',
      minWidth: '300px',
      width: 'auto',
      marginRight: (isMarginRightNeeded) ? '10px' : '0'
    };
  }

  static sendToContainerStyle(): CSSProperties
  {
    return {
      width: '100%',
      backgroundColor: CssVariableEnum['--color-grey-100'],
      borderRadius: 15,
      border: `1px solid ${ CssVariableEnum['--color-grey-200']}`,
      padding: 20,
      justifyContent: 'space-between',
    };
  }

  static sendToLineStyle(): CSSProperties
  {
    return {
      fontSize: 12,
      fontWeight: 500,
      color: CssVariableEnum['--color-grey-900'],
      marginLeft: 8,
      marginBottom: 10,
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5
    };
  }

  static sendToTagStyle(): CSSProperties
  {
    return {
      padding: '5px 8px',
      background: CssVariableEnum['--color-blue-light-200'],
      color: CssVariableEnum['--color-blue-light-800'],
      borderRadius: '4px'
    };
  }

  static buttonActionsContainerStyle(): CSSProperties
  {
    return {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      padding: 20,
    };
  }
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeCheckoutProps, LegalNoticeCheckoutState } from './LegalNoticeCheckout.interface';

const initState: LegalNoticeCheckoutState = {
	isLoading: true
};

export class LegalNoticeCheckoutService extends BaseComponentService<LegalNoticeCheckoutProps, LegalNoticeCheckoutState>
{
	constructor()
	{
		super({} as LegalNoticeCheckoutProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setIsLoadingState(false);
	}

	/**
	 * Set IsLoading State
	 * @param isLoading
	 */
	setIsLoadingState(isLoading: boolean): void
	{
		this.setState({
			isLoading
		});
	}
}
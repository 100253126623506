import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export class ApiClientDeveloperService extends BaseService
{
  clientUrl: string = `${ BaseService.API_URL }/v1/developer/clients`;

  async list(params?: string): Promise<any>
  {
    try {
      const url = params ? this.clientUrl + '?' + params : this.clientUrl;
      const response = await fetch(
        url,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch client list');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching client list: ', error);
      throw error;
    }
  }

  async search(term: string): Promise<any> {
    try {
      const url = `${this.clientUrl}/search?term=${encodeURIComponent(term)}`;
      const response = await fetch(url, {
        method: HttpVerbEnum.GET,
        headers: this.buildHeaders(),
      });

      return await response.json();
    } catch (error: any) {
      console.error('Error searching clients:', error.message);
    }
  }

  async show(clientId: number): Promise<any> {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(clientModel: ClientModel): Promise<any>
  {
    try {
      const response = await fetch(
        this.clientUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientModel),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(clientUpdateData: any, clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientUpdateData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async delete(clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
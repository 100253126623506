import React, { Component, ReactElement } from 'react';
import UserAdminService from '@/Modules/User/Components/Show/UserAdmin.service';
import UserDetails from '@/Modules/User/Components/Blocks/UserDetails/UserDetails';
import { UserDetailsState } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { UserDeveloperProps, UserDeveloperState } from '@/Modules/User/Developer/Show/UserDeveloper.interface';

class UserDeveloper extends Component<UserDeveloperProps, UserDeveloperState>
{
  private userAdminService: UserAdminService = new UserAdminService();

  constructor(props: UserDeveloperProps)
  {
    super(props);

    // Config service
    this.userAdminService.setProps(this.props);
    this.userAdminService.subscribeState(this);

    // State
    this.state = this.userAdminService.getState();
  }

  componentDidMount(): void
  {
    this.userAdminService.init();
  }

  render(): ReactElement
  {
    return (
      <>
        <UserDetails
          user={ this.props.user }
          getComponentState={ (componentState: UserDetailsState) => {
            this.props.getComponentState!(componentState)
          }}
        />
      </>
    );
  }
}

export default withGlobalContext(UserDeveloper);
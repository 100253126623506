import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  CommercialStatAdminProps,
  CommercialStatAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Show/CommercialStatAdmin.interface';
import {
  ClientLegalNoticeStatsInterface
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats.interface';

const initState: CommercialStatAdminState = {
  refreshKey: 0,
  statsData: []
}

export class CommercialStatAdminService extends BaseComponentService<CommercialStatAdminProps, CommercialStatAdminState>
{
  constructor()
  {
    super({} as CommercialStatAdminProps, initState);
  }

  async init(): Promise<void>
  {
    await this.setState({ statsData: this.formatStatsData(this.props.client) });
  }

  async refreshStats(clientLegalNoticeStatsState: ClientLegalNoticeStatsInterface[]): Promise<void>
  {
    const newStatsData = clientLegalNoticeStatsState.map((stat: any) => ({
      year: Number(stat.year),
      intern: stat.intern ?? 0,
      extern: stat.extern ?? 0
    })).sort((a, b) => a.year - b.year);

    await this.setState({ statsData: newStatsData, refreshKey: this.state.refreshKey + 1 });
    this.props.refreshClient();
  }

  formatStatsData(client: any): any
  {
    return client.legalNoticeStats?.map((stat: any) => ({
      year: Number(stat.year),
      intern: stat.intern ?? 0,
      extern: stat.extern ?? 0
    })).sort((a: any, b: any) => a.year - b.year) || [];
  }
}
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';

export class ApiClientService extends BaseService
{
	clientUrl: string = `${ BaseService.API_URL }/v1/clients`;

	async list(): Promise<any>
	{
		try {
			const response = await fetch(
				this.clientUrl,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(clientId: number): Promise<ClientInterface> {
		try {
			const response = await fetch(
				`${this.clientUrl}/${clientId}`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			const client = await response.json();

			if (client === undefined) {
				throw new Error('Client undefined');
			}

			// Response data
			return client;
		} catch (error: any) {
			console.error(error.message);
			throw error;
		}
	}


	async create(clientModel: ClientModel): Promise<any>
	{
		try {
			const response = await fetch(
				this.clientUrl,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(clientModel),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async addLogo(logoData: any, clientId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.clientUrl + '/logos/' + clientId,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildMultipartHeaders(),
					body: logoData,
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async deleteLogo(clientId: number, logoId: any): Promise<any>
	{
		try {
			const response = await fetch(
				this.clientUrl + '/logos/' + clientId + '/' + logoId,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildMultipartHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	NewspaperClosureManagementProps,
	NewspaperClosureManagementState
} from './NewspaperClosureManagement.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { ApiAdminNewspaperClosureManagementService } from '@/Service/Admin/ApiAdminNewspaperClosureManagementService';
import {
	NewspaperClosureManagementInterface
} from '@/Modules/NewspaperManagement/Common/NewspaperClosureManagement.interface';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

const initState: NewspaperClosureManagementState = {
	css: {
		contentWidth: 0,
	},
	closureDate: null,
	newspaperClosureManagement: {} as NewspaperClosureManagementInterface,
	isLoading: true,
	errorMessage: null,
	isAuthorization: false,
	legalNotices: {} as PaginateInterface,
};

export class NewspaperClosureManagementService extends BaseComponentService<NewspaperClosureManagementProps, NewspaperClosureManagementState>
{
	private apiAdminNewspaperClosureManagementService: ApiAdminNewspaperClosureManagementService = new ApiAdminNewspaperClosureManagementService();
	private apiAdminAppService: ApiAdminAppService = new ApiAdminAppService();
	private userService: UserConnectedService = new UserConnectedService();

	constructor()
	{
		super({} as NewspaperClosureManagementProps, initState);

		//bind
		this.isCanEditClosureNewspaperManagement = this.isCanEditClosureNewspaperManagement.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.handleShow();
		await this.isCanEditClosureNewspaperManagement();
		this.setState({ isLoading: false });
	}

	/**
	 *
	 */
	async handleShow(): Promise<any>
	{
		const currentPath: string = window.location.pathname;
		const id: number = Number(currentPath.split('/').pop());

		try {
			if (id) {
				const response = await this.apiAdminNewspaperClosureManagementService.show(id);

				const closureDate: string = this.calculateClosureDate(response);

				this.setState({
					newspaperClosureManagement: {
						...response,
					},
					closureDate,
				}, () => this.setState({ legalNotices: this.legalNoticeList(this.state) })
				);
			} else {
				console.error('No ID found in the URL');
			}
		} catch (e) {
			console.error(`Error occurred on ${ this.constructor.name }: SHOW`);
		}
	}

	/**
	 * Check if the user can edit closure newspaper management.
	 * Updates the state 'isAuthorization' based on company ID and companyContexts.
	 */
	async isCanEditClosureNewspaperManagement(): Promise<void>
	{
		try {
			// Retrieve the authenticated user
			const user: UserInterface | undefined = await this.userService.getAuthUser();

			// Default authorization condition: Check user.company ID
			const isCompanyAuthorized = user?.company?.id === 1 || user?.company?.id === 4;

			// Check companyContexts if any "isCurrent: true" has an id other than 1 or 4
			const hasInvalidCurrentContext = user?.companyContexts?.some(
				(context) => context.isCurrent === true && context.id !== 1 && context.id !== 4
			);

			// Combine both conditions: If company is authorized and no invalid current context
			const isAuthorized = isCompanyAuthorized && !hasInvalidCurrentContext;

			// Update the state 'isAuthorization'
			this.setState({ isAuthorization: isAuthorized });

		} catch (e) {
			console.error('Error occurred in isCanEditClosureNewspaperManagement:', e);

			// In case of an error, set isAuthorization to false
			this.setState({ isAuthorization: false });
		}
	}

	/**
	 * Check if the user can edit a specific newspaper closure based on authorization and company context.
	 * @returns {boolean} - Returns true if conditions are met, otherwise false.
	 */
	canEditSpecificNewspaper(): boolean
	{
		const { isAuthorization, newspaperClosureManagement } = this.state;

		// Ensure the user is authorized and newspaper information exists
		if (!isAuthorization || !newspaperClosureManagement || !newspaperClosureManagement.newspaper) {
			return false;
		}

		const companyId: number = newspaperClosureManagement.company?.id;
		const newspaperName: string = newspaperClosureManagement.newspaper?.name;

		// Check conditions based on company ID and newspaper name
		if (companyId === 1 && newspaperName === 'L\'Hérault Juridique et Economique') {
			return true;
		} else if (companyId === 4 && newspaperName === 'L\'Echo du Languedoc') {
			return true;
		}

		return false;
	}

	async handleEdit(event: any, currentState: NewspaperClosureManagementState, isPublish: boolean): Promise<any>
	{
		event.preventDefault();
		this.setState({ isLoading: true  });

		if(isPublish) {
			if (currentState.newspaperClosureManagement.depositedAt && !currentState.newspaperClosureManagement.documentPath) {
				this.setState({
					errorMessage: 'Merci de remplir l’url du justificatif avant de clôturer'
				});
				return;
			}
		}

		try {
			const updatedData = {
				publishDate: currentState.newspaperClosureManagement.publishDate,
				depositedAt: currentState.newspaperClosureManagement.depositedAt,
				closedAt: currentState.newspaperClosureManagement.closedAt,
				documentPath: currentState.newspaperClosureManagement.documentPath,
			};

			await this.apiAdminNewspaperClosureManagementService.edit(currentState.newspaperClosureManagement?.id!, updatedData, true, isPublish)
				.then(() =>{
					this.init();
				})
				.catch((error) =>
				{
					this.setState({ isLoading: false });
					console.error(error);
				})
			;

		} catch (error) {
			console.error(`Error occurred on ${ this.constructor.name }: EDIT`);
			this.setState({ isLoading: false });
		}
	}

	legalNoticeList(currentState: NewspaperClosureManagementState): PaginateInterface
	{
		return {
			items: currentState.newspaperClosureManagement.legalNotices,
			totalCount: 20,
			totalPages: 1,
			itemsPerPage: 15
		};
	}

	/**
	 * Generate PDF routing
	 * @param currentState
	 */
	async handleGeneratePdf(currentState: NewspaperClosureManagementState): Promise<void>
	{
		const currentPath: string = window.location.pathname;
		const newspaperClosureManagementId: number = Number(currentPath.split('/').pop());

		try {
			const response = await this.apiAdminNewspaperClosureManagementService.generateRoutingPdf(
				currentState.newspaperClosureManagement.company.id,
				newspaperClosureManagementId
			);

			if (!response.ok) {
				throw new Error('Failed to generate PDF');
			}

			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.download = `routing-${ currentState.newspaperClosureManagement.company.name }.pdf`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			window.open(url, '_blank');

			// Révoquer l'URL après un délai
			setTimeout(() =>
			{
				window.URL.revokeObjectURL(url);
			}, 1000 * 60);

		} catch (e) {
			console.error(`Error occurred on ${ this.constructor.name }: GENERATE PDF`, e);
		}
	}

	/**
	 * Calculate Number of copies for this cloture management
	 * @param currentState
	 */
	calculateTotalNumberOfCopies(currentState: NewspaperClosureManagementState): number
	{
		return currentState.newspaperClosureManagement.legalNotices.reduce(
			(total: number, legalNotice: LegalNoticeInterface) => total + legalNotice.numberOfCopies,
			0
		);
	}

	/**
	 *
	 */
	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Donneur d\'ordre',
				tag: 'clientName',
				columnWidth: 300,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'client.name'
			},
			{
				title: 'N° d\'annonce',
				tag: 'tag',
				columnWidth: 150,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['tag']
			},
			{
				title: 'Publication au ...',
				tag: 'publishDate',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'publishDate'
			},
			{
				title: 'Journal',
				tag: 'newspaper',
				columnWidth: 300,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'newspaper.name'
			},
			{
				title: 'Client final',
				tag: 'consumer',
				columnWidth: 300,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'consumer.name'
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 100,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'PublishStateEnum',
				keyTag: 'status'
			},
		];
	}

	/**
	 * Calculate Closure Date
	 * @param data
	 * @private
	 */
	private calculateClosureDate(data: NewspaperClosureManagementInterface): string
	{
		const closureDay = data.newspaper.newspaperPublishDays[0].closureDay;

		if (!closureDay) {
			console.error('No closure day found');
			return '';
		}

		const targetDay = closureDay.day; // e.g., "Tuesday"
		const targetHours: number = parseInt(closureDay.hours, 10);
		const targetMinutes: number = parseInt(closureDay.minute, 10);

		const currentDate = new Date();
		const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

		const daysMap: { [key: string]: number } = {
			'Sunday': 0,
			'Monday': 1,
			'Tuesday': 2,
			'Wednesday': 3,
			'Thursday': 4,
			'Friday': 5,
			'Saturday': 6,
		};

		const targetDayIndex: number = daysMap[targetDay];

		if (targetDayIndex === undefined) {
			console.error('Invalid day specified in closureDay');
			return '';
		}

		let daysUntilClosure = targetDayIndex - currentDay;
		if (daysUntilClosure < 0) {
			daysUntilClosure += 7;
		}

		const closureDate = new Date(currentDate);
		closureDate.setDate(currentDate.getDate() + daysUntilClosure);
		closureDate.setHours(targetHours);
		closureDate.setMinutes(targetMinutes);
		closureDate.setSeconds(0);
		closureDate.setMilliseconds(0);

		return closureDate.toString();
	}

	async exportXmlZip(newspaperClosureManagementId: number): Promise<void>
	{
		try {
			const response = await this.apiAdminAppService.exportClosureManagementXml(newspaperClosureManagementId);

			if (!response.ok) {
				throw new Error('Failed to fetch the ZIP file.');
			}

			const blob = await response.blob();

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'export-legal-notice.zip';
			link.click();

			URL.revokeObjectURL(link.href);
		} catch (error) {
			console.error('An error occurred during the file download:', error);
		}
	}
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAdminCommercialService } from '@/Service/Admin/ApiAdminCommercialService';
import {
  CommercialSectorsAdminProps, CommercialSectorsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialSectors/CommercialSectorsAdmin.interface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { CommercialSectorInterface } from '@/Modules/Interface/CommercialSectorInterface';

const initState: CommercialSectorsAdminState = {
  isLoading: false,
  isClientsLoading: false,
  sectorList: null,
  selectedSector: null,
  clientsBySectorList: null
}

export class CommercialSectorsAdminService extends BaseComponentService<CommercialSectorsAdminProps, CommercialSectorsAdminState>
{
  private apiCommercialService: ApiAdminCommercialService = new ApiAdminCommercialService();
  private apiAdminAppService: ApiAdminAppService = new ApiAdminAppService();
  constructor()
  {
    super({} as CommercialSectorsAdminProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.apiAdminAppService.commercialSectorList()
      .then((response) => { this.setState({ sectorList: response, isLoading: false }) })
      .catch((error) => this.setState({ isLoading: false }))
    ;
  }

  async onSelectedSector(sector: CommercialSectorInterface): Promise<any>
  {
    this.setState({ selectedSector: sector, isClientsLoading: true });
    await this.apiCommercialService.sectorList(sector.id as number)
      .then((response) => { this.setState({ clientsBySectorList: response, isClientsLoading: false }) })
      .catch((error: any) => { this.setState({ isClientsLoading: false }) })
    ;
  }
}
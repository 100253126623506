import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  CommercialClientsAdminProps, CommercialClientsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/List/CommercialClientsAdmin.interface';
import { FiltersInterface, TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

const initState: CommercialClientsAdminState = {
  refreshKey: 0,
  clientList: {} as PaginateInterface,
}

export class CommercialClientsAdminService extends BaseComponentService<CommercialClientsAdminProps, CommercialClientsAdminState>
{
  constructor()
  {
    super({} as CommercialClientsAdminProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({
      refreshKey: this.state.refreshKey + 1,
    });
  }

  buildColumnHeader(): TableHeadColumnsInterface[]
  {
    return [
      {
        title: 'Dénomination',
        tag: 'name',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'name'
      },
      {
        title: 'Adresse',
        tag: 'address',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'addressFull',
        keyTag: 'address.street'
      },
      {
        title: 'Secteur',
        tag: 'client.sector',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'string',
        keyTag: 'sector.label'
      },
      {
        title: 'Cadeaux',
        tag: 'gift',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 400,
        keyType: 'gift',
        keyTag: 'clientGifts'
      },
      {
        title: 'Statut',
        tag: 'client.clientGifts.status',
        columnWidth: 80,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'giftStatus',
        enumClass: 'CommercialGiftStatusEnum',
        keyTag: 'status'
      },
    ];
  }

  buildFilters(): FiltersInterface[]
  {
    return [
      {
        title: 'Statut',
        tag: 'status',
        type: 'selector',
        options: ActiveStatusEnum.optionsFilter
      },
      {
        title: 'Ville',
        tag: 'city',
        type: 'search'
      }
    ];
  }
}
import React, { Component, ReactElement } from 'react';
import { LegalNoticeGuideRenderProps, LegalNoticeGuideRenderState } from './LegalNoticeGuideRender.interface';
import { LegalNoticeGuideRenderService } from './LegalNoticeGuideRender.service';
import {
	LegalNoticeGuideRenderStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeGuideRender/LegalNoticeGuideRender.styles';
import LegalNoticeRender from '@/Modules/LegalNotice/ComponentsOld/Render/LegalNoticeRender';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class LegalNoticeGuideRender extends Component<LegalNoticeGuideRenderProps, LegalNoticeGuideRenderState>
{
	private legalNoticeGuideRenderService = new LegalNoticeGuideRenderService();

	// ref
	private containerRef = React.createRef<HTMLDivElement>();

	constructor(props: LegalNoticeGuideRenderProps)
	{
		super(props);

		// Config service
		this.legalNoticeGuideRenderService.setProps(this.props);
		this.legalNoticeGuideRenderService.subscribeState(this);

		// State
		this.state = this.legalNoticeGuideRenderService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeGuideRenderService.init();

		this.legalNoticeGuideRenderService.updateContainerMaxHeight(this.containerRef);
		window.addEventListener('resize', () => this.legalNoticeGuideRenderService.updateContainerMaxHeight(this.containerRef));

	}

	componentWillUnmount(): void
	{
		this.legalNoticeGuideRenderService.unsubscribe();
		this.legalNoticeGuideRenderService.updateContainerMaxHeight(this.containerRef);
		window.addEventListener('resize', () => this.legalNoticeGuideRenderService.updateContainerMaxHeight(this.containerRef));
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<div ref={ this.containerRef } style={ LegalNoticeGuideRenderStyles.container }>
				{ this.props.isGuide &&
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              label={ 'Modifier le texte' }
              variant={ 'inline' }
              onClick={ (event) =>
							{
								event.stopPropagation();

								this.props.modalContext?.content(
									'Transfère vers rédaction libre',
									<div style={ { ...ModalStyles.padding, width: 500 } }>
										<div style={ FontStyles.textHelp }>
											Vous êtes sur le point de passer du mode guidé au mode de rédaction libre pour votre annonce
											légale.
											Le texte généré sera transféré, et vous pourrez ensuite le modifier manuellement.
											Notez toutefois que le retour au mode guidé ne sera plus possible ;
											toute modification ultérieure devra être réalisée en mode libre,
											ce qui impliquera de recommencer votre annonce si nécessaire.
										</div>
										<div style={ {
											width: '100%',
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'flex-end',
											height: 50,
											gap: 5
										} }>
											<Button
												label={ 'Annuler' }
												variant={ 'small' }
												onClick={ () => this.props.modalContext?.isOpen(false) }
											/>
											<Button
												label={ 'Continuer' }
												variant={ 'smallDark' }
												onClick={ () =>
												{
													this.props.navigation!('legal-notices/free-writing/create?from=legal-notice-guide');
													this.props.modalContext?.isOpen(false);
												} }
											/>
										</div>
									</div>
								);
							} }
            />
          </div>
				}
				<div style={ { maxHeight: this.state.containerMaxHeight, overflow: 'scroll' } }>
					<LegalNoticeRender
						legalNotice={ this.props.legalNotice }
						consumer={ this.props.consumer }
						isShow={ true }
					/>
				</div>
			</div>
		);
	}
}

export default withGlobalContext(LegalNoticeGuideRender);
import React, { ReactElement } from 'react';
import {
  AppConfigAdminSectorProps,
  AppConfigAdminSectorState
} from '@/Modules/Config/Admin/Component/Sector/ConfigAdminSector.interface';
import { ConfigAdminSectorService } from '@/Modules/Config/Admin/Component/Sector/ConfigAdminSector.service';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuCheckCheck, LuPencilLine, LuTrash2, LuX } from 'react-icons/lu';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';

export default class ConfigAdminSector extends React.Component<AppConfigAdminSectorProps, AppConfigAdminSectorState>
{
  private componentService: ConfigAdminSectorService = new ConfigAdminSectorService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <Title type="h5">Gestion des Secteurs</Title>

        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 5 }}>
          <Button
            variant={'primary'}
            label={ 'Ajouter un Secteur' }
            iconName={ 'LuPlus' }
            onClick={ () => {
              if (!this.state.newSector) {
                this.setState({ newSector: { id: null, label: '' } });
              }
            }}
          />
        </div>

        <TableOld>
          <TableHeader
            columnHeaderList={[{ columnTitle: 'Label', columnSize: 6, name: 'Label' }]}
            isFilters={ true }
          />

          { this.state.newSector && (
            <TableRow isCheckboxInput={ false } borderBottom={ true }>
              <TableCol columnSize={ 22 }>
                <InputTableCell
                  value={ this.state.newSector.label }
                  onChange={ (event) =>
                    this.setState({ newSector: { ...this.state.newSector, id: null, label: event.target.value } })
                  }
                />
              </TableCol>

              <TableCol columnSize={ 2 } style={{ display: 'flex', gap: 10 }}>
                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuCheckCheck /> }
                  onClick={() => {
                    if (this.state.newSector?.label.trim()) {
                      this.componentService.createCommercialSector(this.state.newSector.label);
                      this.setState({ newSector: null });
                    }
                  }}
                />

                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuX /> }
                  onClick={() => this.setState({ newSector: null })}
                />
              </TableCol>
            </TableRow>
          )}

          { this.state.sectorList.map((sector) => (
            <>
              <TableRow key={ sector.id } isCheckboxInput={ false } borderBottom={ true }>
                <TableCol columnSize={ 22 } isEditMode={ this.state.editSectorId === sector.id } style={{ marginLeft: 25 }}>
                  <InputTableCell
                    value={ sector.label }
                    onChange={(event) => {
                      const updatedLabel = event.target.value;
                      this.setState((prevState) => ({
                        sectorList: prevState.sectorList.map((updatedSector) =>
                          updatedSector.id === sector.id ? { ...updatedSector, label: updatedLabel } : updatedSector
                        ),
                      }));
                    }}
                  />
                </TableCol>

                <TableCol columnSize={ 2 } isEditMode={ this.state.editSectorId === sector.id }>
                  { this.state.editSectorId === sector.id ? (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuCheckCheck />}
                        onClick={async () => {
                          if (sector.label.trim()) {
                            await this.componentService.updateCommercialSector(sector);
                          }
                        }}
                      />

                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuX />}
                        onClick={() =>
                          this.setState((prevState) => ({
                            editSectorId: null,
                            sectorList: prevState.sectorList.map((defaultSector) =>
                              defaultSector.id === sector.id ? { ...defaultSector, label: sector.label } : defaultSector
                            ),
                          }))
                        }
                      />
                    </>
                  ) : (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuPencilLine />}
                        onClick={() =>
                          this.setState({ editSectorId: sector.id })
                        }
                      />

                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuTrash2 />}
                        onClick={async () => {
                          await this.componentService.deleteCommercialSector(sector.id as number);
                        }}
                      />
                    </>
                  )}
                </TableCol>
              </TableRow>
            </>
          ))}
        </TableOld>
      </>
    );
  }
}
import React, { Component, ReactElement } from 'react';
import { SelectProps, SelectState } from './Select.interface';
import { SelectService } from './Select.service';
import { SelectStyles } from '@/Modules/App/Components/Library/Select/Select.styles';
import { LuChevronsUpDown, LuSearch } from 'react-icons/lu';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { InputStyles } from '@/Modules/App/Components/Library/Input/Input.styles';

class Select extends Component<SelectProps, SelectState>
{
	private selectService = new SelectService();

	constructor(props: SelectProps)
	{
		super(props);

		// Config service
		this.selectService.setProps(this.props);
		this.selectService.subscribeState(this);

		// State
		this.state = this.selectService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.selectService.init();
	}

	componentWillUnmount(): void
	{
		this.selectService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { selectedValue, searchValue } = this.state;
		const {
			text,
			label,
			textHelp,
			displayKey = 'label',
			onSelect,
			isRequired,
			isSearchNeeded,
			isDisabled,
			value,
		} = this.props;

		return (
			<div style={ {
				...SelectStyles.base,
				...this.props.styles,
				gap: (label) ? 4 : 0,
				opacity: isDisabled ? 0.6 : 1,
				pointerEvents: isDisabled ? 'none' : 'auto'
			} }>
				<div>
					{ label && <Label label={ label } isRequired={ isRequired }/> }
					{ textHelp && <div style={ FontStyles.textHelp }>{ textHelp }</div> }
				</div>

				<ToggleOpenClosed isClickOutside={ true }>
					{ ({ isOpen, toggle, menuPosition, menuRef }) => (
						<div style={ SelectStyles.container }>
							<Hovered>
								{ ({ isHovered }) => (
									<div style={ SelectStyles.button(isHovered, this.props) } onClick={ () =>
									{
										toggle();
									} }>
										<div style={ SelectStyles.content }>
                      <span style={ SelectStyles.text }>
                        { (value) ? (
													value
												) : (selectedValue) ? selectedValue : text

												}
                      </span>
											<LuChevronsUpDown style={ SelectStyles.icon }/>
										</div>
									</div>
								) }
							</Hovered>

							<div ref={ menuRef } style={ {
								...SelectStyles.openList(isOpen),
								...(this.props.menuPosition ? this.props.menuPosition : menuPosition)
							} }>
								{ isSearchNeeded && (
									<div style={ { display: 'flex', gap: 10 } }>
										<LuSearch fontSize={ 18 } style={ { marginTop: 5 } }/>
										<Input
											type="text"
											name="select-search"
											placeholder="Rechercher..."
											value={ searchValue }
											onChange={ (event) => this.selectService.onSearchChange(event) }
										/>
									</div>
								) }

								<div style={ { marginTop: isSearchNeeded ? 10 : 0 } }>
									{ this.selectService.getFilteredOptions(this.props)?.map((listItem: any, index: number) =>
									{
										const isDisabled = this.props.disabledResultKey
											? listItem[this.props.disabledResultKey] === null
											: false;

										return (
											<Hovered
												key={ `${ listItem[displayKey] }-${ index }` }
												backgroundHoveredColor={ colors.gray100 }
											>
												{ ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
													<div
														ref={ ref }
														style={ {
															...SelectStyles.listItem,
															...(isHovered && !isDisabled ? SelectStyles.hoveredItem : {}),
															...(isDisabled ? SelectStyles.disabledItem : {}),
														} }
														onMouseEnter={ !isDisabled ? onMouseEnter : undefined }
														onMouseLeave={ !isDisabled ? onMouseLeave : undefined }
														onClick={ (event: React.MouseEvent<any>) =>
														{
															if (!isDisabled) {
																event.preventDefault();
																event.stopPropagation();
																this.selectService.handleSelect(listItem[displayKey]);
																toggle();
																if (onSelect) onSelect(listItem, event);
															}
														} }
													>
														<span>{ listItem[displayKey] }</span>
														<span>{ isDisabled! && this.props.disabledResultText }</span>
													</div>
												) }
											</Hovered>
										);
									}) }
								</div>

							</div>
						</div>
					) }
				</ToggleOpenClosed>

				{ this.props.errorMessage &&
          <div style={ InputStyles.errors }>
						{ this.props.errorMessage }
          </div>
				}
			</div>
		);
	}
}

export default Select;

import { AddressFormInterface } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.interface';

export class AddressFormModel implements AddressFormInterface
{
	// Properties
	name: string | null = null;
	street: string = '';
	number: number | null = null;
	additionalData: string = '';
	zipCode: string = '';
	city: string = '';
	country: string = '';
	phone: string | null = null;
	extSellsyId: number | null = null;
	isBillingAddress: boolean = false;

	// Add signature index
	[key: string]: string | number | boolean | null | undefined;

	/**
	 * Static method to create an instance of the model.
	 * @param data - Input data for initializing the model.
	 * @returns A new instance of AddressFormModel.
	 */
	static prepare(data: Partial<AddressFormInterface>): AddressFormModel
	{
		const model = new AddressFormModel();

		// Map predefined properties
		model.name = data.name ?? null;
		model.street = data.street ?? '';
		model.number = data.number ?? null;
		model.additionalData = data.additionalData ?? '';
		model.zipCode = data.zipCode ?? '';
		model.city = data.city ?? '';
		model.country = data.country ?? '';
		model.phone = data.phone ?? null;
		model.extSellsyId = data.extSellsyId ?? null;
		model.isBillingAddress = data.isBillingAddress ?? false;

		// Handle additional dynamic properties
		for (const key in data) {
			if (!(key in model)) {
				model[key] = data[key];
			}
		}
		return model;
	}

}

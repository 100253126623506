import React, { Component, ReactElement } from 'react';
import { UserAdminProps, UserAdminState } from '@/Modules/User/Components/Show/UserAdmin.interface';
import UserAdminService from '@/Modules/User/Components/Show/UserAdmin.service';
import UserDetails from '@/Modules/User/Components/Blocks/UserDetails/UserDetails';
import { UserDetailsState } from '@/Modules/User/Components/Blocks/UserDetails/UserDetails.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class UserAdmin extends Component<UserAdminProps, UserAdminState>
{
  private userAdminService: UserAdminService = new UserAdminService();

  constructor(props: UserAdminProps)
  {
    super(props);

    // Config service
    this.userAdminService.setProps(this.props);
    this.userAdminService.subscribeState(this);

    // State
    this.state = this.userAdminService.getState();
  }

  componentDidMount(): void
  {
    this.userAdminService.init();
  }

  render(): ReactElement
  {
    return (
      <>
        <UserDetails
          user={ this.props.user }
          getComponentState={ (componentState: UserDetailsState) => {
            this.props.getComponentState!(componentState)
          }}
        />
      </>
    );
  }
}

export default withGlobalContext(UserAdmin);
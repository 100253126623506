import React, { ReactElement } from 'react';
import { AppConfigAdminProps, AppConfigAdminState } from '@/Modules/Config/Admin/AppConfigAdmin/AppConfigAdmin.interface';
import { AppConfigAdminStyles } from '@/Modules/Config/Admin/AppConfigAdmin/AppConfigAdmin.styles';
import { AppConfigAdminService } from '@/Modules/Config/Admin/AppConfigAdmin/AppConfigAdmin.service';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';
import ConfigAdminSupplier from '@/Modules/Config/Admin/Component/Supplier/ConfigAdminSupplier';
import ConfigAdminGift from '@/Modules/Config/Admin/Component/Gift/ConfigAdminGift';
import ConfigAdminSector from '@/Modules/Config/Admin/Component/Sector/ConfigAdminSector';

export default class AppConfigAdmin extends React.Component<AppConfigAdminProps, AppConfigAdminState>
{
  private appConfigAdminService: AppConfigAdminService = new AppConfigAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.appConfigAdminService.setProps(this.props);
    this.appConfigAdminService.subscribeState(this);

    // State
    this.state = this.appConfigAdminService.getState();
  }

  render(): ReactElement
  {
    return (
      <>
        <div style={ AppConfigAdminStyles.container }>
          <div style={{ display: 'grid', gridTemplateColumns: '240px auto', height: '100%', gap: 10, borderTop: '1px solid #E6E6E6' }}>
            {/* MENU */}
            <div style={ AppConfigAdminStyles.menuContainer }>
              <MenuItem
                label={'Réglages général'}
                type={ 'separatorItem' }
              />
              <MenuItem
                urlPath={'#!'}
                label={'Fournisseurs'}
                iconName={'LuStore'}
                type={ 'menuItem' }
                style={ AppConfigAdminStyles.menuItem(this.state.activeTab === 'suppliers') }
                location={''}
                onClick={ () => this.appConfigAdminService.setActiveTab('suppliers') }
              />
              <MenuItem
                label={'Commercial'}
                type={ 'separatorItem' }
              />
              <MenuItem
                urlPath={'#!'}
                label={'Cadeaux'}
                iconName={'LuGift'}
                type={ 'menuItem' }
                style={ AppConfigAdminStyles.menuItem(this.state.activeTab === 'gifts') }
                location={''}
                onClick={() => this.appConfigAdminService.setActiveTab('gifts')}
              />
              <MenuItem
                urlPath={'#!'}
                label={'Secteurs'}
                iconName={'LuSlidersHorizontal'}
                type={ 'menuItem' }
                style={ AppConfigAdminStyles.menuItem(this.state.activeTab === 'sector') }
                location={''}
                onClick={() => this.appConfigAdminService.setActiveTab('sector')}
              />
            </div>

            <div style={ AppConfigAdminStyles.contentContainer }>
              { this.state.activeTab === 'suppliers' && <ConfigAdminSupplier company={ this.props.company } onEdit={ this.props.onEditSupplier } /> }
              { this.state.activeTab === 'gifts' && <ConfigAdminGift /> }
              { this.state.activeTab === 'sector' && <ConfigAdminSector /> }
            </div>
          </div>
        </div>
      </>
    );
  }
}
import React, { ReactElement } from 'react';
import { ClientGiftAdminService } from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/ClientGift/ClientGiftAdmin.service';
import {
  ClientGiftAdminProps,
  ClientGiftAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/ClientGift/ClientGiftAdmin.interface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuCheckCheck, LuPencilLine, LuTrash2, LuX } from 'react-icons/lu';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';
import Select from '@/Modules/App/Components/Library/Select/Select';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import CommercialGiftStatusEnum from '@/Enum/CommercialGiftStatusEnum';

export default class ClientGiftAdmin extends React.Component<ClientGiftAdminProps, ClientGiftAdminState>
{
  private componentService: ClientGiftAdminService = new ClientGiftAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <LoaderComponent />;
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 5, gap: 10 }}>
          <Button
            variant={ 'dark' }
            label={
              (this.state.clientGiftList && this.state.clientGiftList.length > 0 && this.state.clientGiftList[0].status === 'DELIVERY')
              ? 'Rendre non livré'
              : 'Livré'
            }
            iconName={ 'LuGift' }
            disabled={ this.state.clientGiftList && this.state.clientGiftList.length === 0 }
            onClick={ async (event: any) => {
              event.preventDefault();
              event.stopPropagation();

              await this.componentService.editClientGiftStatus(this.props.client?.id);
            }}
          />
          <Button
            variant={ 'primary' }
            label={ 'Ajouter' }
            iconName={ 'LuPlus' }
            onClick={ () => {
              if (!this.state.newClientGift) {
                this.setState({ newClientGift: { id: null, commercialGift: null, quantity: null, status: CommercialGiftStatusEnum.TO_DELIVER } });
              }
            }}
          />
        </div>

        <TableOld>
          <TableHeader
            columnHeaderList={ this.componentService.buildColumnHeader() }
            isFilters={ true }
          />

          { this.state.newClientGift && (
            <TableRow isCheckboxInput={ false } borderBottom={ true }>
              <TableCol columnSize={ 35 }>
                <Select
                  styles={{ width: '100%' }}
                  listOptions={ this.state.giftList.map((gift) => ({
                    label: gift.label,
                    id: gift.id
                  })) }
                  isRequired={ true }
                  value={ this.state.newClientGift.commercialGift?.label || '' }
                  onSelect={ (selectedGift: { value: number, label: string }) =>
                    this.setState((prevState: any) => ({
                      newClientGift: { ...prevState.newClientGift, commercialGift: selectedGift }
                    }))
                  }
                />

                <InputTableCell
                  value={ this.state.newClientGift?.quantity }
                  onChange={ (event) =>
                    this.setState((prevState: any) => ({
                      newClientGift: { ...prevState.newClientGift, quantity: event.target.value }
                    }))
                  }
                />
              </TableCol>

              <TableCol columnSize={ 2 } style={{ display: 'flex', gap: 10 }}>
                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuCheckCheck /> }
                  onClick={() => {
                    if (this.state.newClientGift?.quantity) {
                      this.componentService.createClientGift(this.state.newClientGift);
                      this.setState({ newClientGift: null });
                    }
                  }}
                />

                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuX /> }
                  onClick={() => this.setState({ newClientGift: null })}
                />
              </TableCol>
            </TableRow>
          )}

          { this.state.clientGiftList.length === 0 && (
            <TableRow isCheckboxInput={ false } borderBottom={ true }>
              <TableCol columnSize={ 8 } style={{ textAlign: 'center', fontStyle: 'italic', color: '#888', width: '100%' }}>
                Aucun cadeau attribué
              </TableCol>
            </TableRow>
          )}

          { this.state.clientGiftList.map((clientGift) => (
            <>
              <TableRow key={ clientGift.id } isCheckboxInput={ false } borderBottom={ true }>
                <TableCol columnSize={ 20 } isEditMode={this.state.editClientGiftId === clientGift.id} style={{ marginLeft: 25 }}>
                  { this.state.editClientGiftId === clientGift.id ? (
                    <Select
                      styles={{ width: '100%' }}
                      listOptions={ this.state.giftList.map((gift) => ({
                        label: gift.label,
                        id: gift.id
                      })) }
                      isRequired={ true }
                      value={ clientGift.commercialGift?.label || null }
                      onSelect={ (selectedGift: { value: { id: number, label: string } }) =>
                        this.setState((prevState: any) => ({
                          clientGiftList: prevState.clientGiftList.map((currentClientGift: any) =>
                            currentClientGift.id === clientGift.id
                              ? { ...currentClientGift, commercialGift: selectedGift }
                              : currentClientGift
                          )
                        }))
                      }
                    />
                  ) : (
                    <>{ clientGift.commercialGift?.label }</>
                  )}
                </TableCol>

                <TableCol columnSize={ 10 } isEditMode={ this.state.editClientGiftId === clientGift.id } style={{ marginLeft: 25 }}>
                  <InputTableCell
                    value={ clientGift.quantity }
                    onChange={(event) =>
                      this.setState((prevState) => ({
                        clientGiftList: prevState.clientGiftList.map((currentClientGift) =>
                          currentClientGift.id === clientGift.id
                            ? { ...currentClientGift, quantity: event.target.value }
                            : currentClientGift
                        )
                      }))
                    }
                  />
                </TableCol>

                <TableCol columnSize={ 10 } style={{ display: 'flex', gap: 10 }}>
                  <Tag value={ clientGift?.status.toString() } enumName={ 'CommercialGiftStatusEnum' } />
                </TableCol>

                <TableCol columnSize={ 2 } isEditMode={ this.state.editClientGiftId === clientGift.id }>
                  { this.state.editClientGiftId === clientGift.id ? (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuCheckCheck />}
                        onClick={async () => {
                          if (clientGift.quantity) {
                            await this.componentService.editClientGift(clientGift);
                          }
                        }}
                      />

                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuX />}
                        onClick={() =>
                          this.setState((prevState: any) => ({
                            editClientGiftId: null,
                            clientGiftList: prevState.clientGiftList.map((defaultClientGift: any) =>
                              defaultClientGift.id === clientGift.id ? { ...defaultClientGift } : defaultClientGift
                            ),
                          }))
                        }
                      />
                    </>
                  ) : (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuPencilLine />}
                        onClick={() =>
                          this.setState({ editClientGiftId: clientGift.id })
                        }
                      />

                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuTrash2 />}
                        onClick={async () => {
                          await this.componentService.deleteClientGift(clientGift.id as number);
                        }}
                      />
                    </>
                  )}
                </TableCol>
              </TableRow>
            </>
          ))}
        </TableOld>
      </>
    );
  }
}
import React, { ReactElement } from 'react';
import {
  CommercialStatAdminProps, CommercialStatAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Show/CommercialStatAdmin.interface';
import {
  CommercialStatAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Show/CommercialStatAdmin.service';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import ClientLegalNoticeStats
  from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats';
import StatsLineChart from '@/Modules/App/Components/Library/Chart/StatsChart/StatsLineChart';
import {
  ClientLegalNoticeStatsInterface
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats.interface';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

export default class CommercialStatAdmin extends React.Component<CommercialStatAdminProps, CommercialStatAdminState>
{
  private componentService: CommercialStatAdminService = new CommercialStatAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    const { client } = this.props;

    return (
      <>
        {/* Reproduce the section collap style */}
        <div style={ SectionCollapsedStyles.container }>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Statistique
            </div>
          </div>
        </div>

        <ClientLegalNoticeStats
          client={ client }
          refreshClient={ async (state: ClientLegalNoticeStatsInterface[]) => this.componentService.refreshStats(state) }
          modalContext={ this.props.modalContext! }
          flashMessageContext={ this.props.flashMessageContext }
        />

        {/* Reproduce the section collap style */}
        <div style={{ ...SectionCollapsedStyles.container, marginTop: 20 }}>
          <div style={ SectionCollapsedStyles.heading(UserConnectedService.isDeveloper(), colors.blueRibbon50) }>
            <div style={ SectionCollapsedStyles.title }>
              Graphique
            </div>
          </div>
        </div>

        <StatsLineChart
          key={ this.state.refreshKey }
          data={ this.state.statsData }
          series={[
            { key: 'intern', color: '#8884d8', name: 'Internes' },
            { key: 'extern', color: '#82ca9d', name: 'Externes' }
          ]}
        />
      </>
    );
  }
}
import React, { ReactElement } from 'react';
import * as Icons from 'react-icons/lu';
import { Link } from 'react-router-dom';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { MenuItemPros } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.interface';
import { MenuItemStyles } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.styles';
import { withLocation } from '@/Modules/HOC/withLocation';
import { LuChevronDown } from 'react-icons/lu';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class MenuItem extends React.Component<MenuItemPros, { legalNoticeToValidate: number }>
{
  private apiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
  private pollingIntervalId?: number;

  constructor(props: MenuItemPros)
  {
    super(props);

    // State
    this.state = {
      legalNoticeToValidate: 0,
    };
  }

  async componentDidMount(): Promise<void>
  {
    if(this.props.urlPath === '/admin/legal-notices/validate') {
      this.pollingIntervalId = window.setInterval(() => {
        this.countLegalNoticeToValidate();
      }, 900000);

      await this.countLegalNoticeToValidate();
    }
  }

  componentWillUnmount(): void
  {
    if (this.pollingIntervalId) {
      clearInterval(this.pollingIntervalId);
    }
  }

  render(): ReactElement
  {
    const IconComponent = Icons[this.props.iconName!];
    const isActive: boolean = this.props.location.pathname === this.props.urlPath;

    return (
      <>
        { this.props.type === 'separatorItem' && (
          <div style={ MenuItemStyles.separatorItem }>
            <LuChevronDown size={ 14 }/>
            <span>{ this.props.label }</span>
          </div>
        ) }

        { this.props.type === 'menuItem' && (
          <Hovered<HTMLDivElement>>
            { ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
              <Link to={ this.props.urlPath! } >
                <div
                  ref={ ref }
                  style={{
                    ...(this.props.style || MenuItemStyles.item),
                    ...(isHovered ? UserConnectedService.isDeveloper() ? MenuItemStyles.devHoveredItem : MenuItemStyles.hoveredItem : {}),
                  }}
                  onMouseEnter={ onMouseEnter }
                  onMouseLeave={ onMouseLeave }
                  onClick={ this.props.onClick }
                >
                  { IconComponent && (
                    <IconComponent
                      fontSize={ 18 }
                      color={
                        isActive ? colors.blueRibbon500 : (UserConnectedService.isDeveloper()) ? colors.white : colors.gray600 }
                    />
                  )}
                  <span style={ MenuItemStyles.text(isActive, UserConnectedService.isDeveloper()) }>{ this.props.label }</span>
                  { this.props.urlPath === '/admin/legal-notices/validate' && this.state.legalNoticeToValidate > 0 &&
                    <div style={ MenuItemStyles.notification }>
                      { this.state.legalNoticeToValidate }
                    </div>
                  }
                </div>
              </Link>
            ) }
          </Hovered>
        ) }
      </>
    );
  }

  private async countLegalNoticeToValidate(): Promise<void>
  {
    await this.apiAdminLegalNoticeService.specificListCount('validate')
      .then((response) => {
        if(response.totalCount !== undefined) {
          this.setState({ legalNoticeToValidate: response.totalCount });
        } else {
          this.setState({ legalNoticeToValidate: 0 });
        }
      })
    ;
  }
}

export default withLocation(MenuItem);

import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { DynamicGuideFormProps, DynamicGuideFormState, FormContentInterface } from './DynamicGuideForm.interface';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import { CreateLegalNoticeService } from '@/Modules/LegalNotice/Common/CreateLegalNotice.service';

const initState: DynamicGuideFormState = {
	isLoading: true,
	formSections: {} as FormBuilderSectionInterface[],
	formSubSections: [],
	sectionErrors: [],
	consumer: null,
	signature: '',
	formContents: [],
	content: '',
};

export class DynamicGuideFormService extends BaseComponentService<DynamicGuideFormProps, DynamicGuideFormState>
{
	private updateTimers: { [id: number]: NodeJS.Timeout } = {};
	private createLegalNoticeService: CreateLegalNoticeService = new CreateLegalNoticeService();

	constructor()
	{
		super({} as DynamicGuideFormProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.stateUpdater) {
			this.setFormContentsState(this.props.stateUpdater.formContents!);

			this.setState({ sectionErrors: this.props.stateUpdater.sectionErrors });
		}
		this.handleInit();
	}

	handleInit(childCategory?: FormBuilderCategoryInterface): void
	{
		this.setFormSectionsState((childCategory) ? childCategory : this.props.childCategory,
			() =>
			{
				this.handleFormBuilderContent(this.state.formSections);
				this.setLoading(false);
			}
		);
	}

	/**
	 * Set Form sections
	 * @param childCategory
	 * @param callback
	 */
	setFormSectionsState(childCategory: FormBuilderCategoryInterface, callback?: () => void): void
	{
		this.setState({
			formSections: childCategory.formBuilderSections
		}, callback);
	}

	/**
	 * Set Form Contents
	 * @param formContents
	 * @param callback
	 */
	setFormContentsState(formContents: FormContentInterface[], callback?: () => void): void
	{
		this.setState({
			formContents,
		}, callback);
	}

	/**
	 * Set From SubSections State
	 * @param selectedSubSection
	 */
	setFormSubSectionsState(selectedSubSection: FormBuilderSectionInterface): void
	{
		this.setState((prevState) =>
		{

			const updatedSubSections = [...prevState.formSubSections];

			const existingIndex = updatedSubSections.findIndex(
				(subSection) => subSection.id === selectedSubSection.id
			);

			if (existingIndex !== -1) {
				return { formSubSections: updatedSubSections };
			}

			const parentIndex = updatedSubSections.findIndex(
				(subSection) => subSection.parentId === selectedSubSection.parentId
			);

			if (parentIndex !== -1) {
				updatedSubSections[parentIndex] = selectedSubSection;
			} else {
				updatedSubSections.push(selectedSubSection);
			}

			return { formSubSections: updatedSubSections };
		}, async () =>
		{
			this.handleFormBuilderContent(this.state.formSections);
			const updateErrors = await this.createLegalNoticeService.handleErrorsGuideSubSections();
			this.setState({
				sectionErrors: updateErrors
			});
		});
	}

	/**
	 * Remove all formContents associated with the given subSection.
	 * @param subSectionParentId The id of the subSection to remove from formContents.
	 */
	removeSubSectionContent(subSectionParentId: number): void
	{
		const updatedFormContents = this.state.formContents.filter(
			(content) => content.sectionId !== subSectionParentId
		);

		this.setState({
			formContents: updatedFormContents,
			content: this.combineFormContents(updatedFormContents),
		}, () =>
		{
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Handle set Form Content
	 * @param formSections
	 */
	handleFormBuilderContent(formSections: FormBuilderSectionInterface[]): void
	{
		let formBuilderContents: FormContentInterface[] = [];

		formSections.forEach((section) =>
		{
			let inputs: any[] = [];
			if (section.subSections && section.subSections.length > 0) {
				const existingSubSection = this.state.formSubSections.find(
					(subSection) => subSection.parentId === section.id
				);
				inputs = existingSubSection && existingSubSection.formBuilderInputs
					? existingSubSection.formBuilderInputs
					: section.formBuilderInputs;
			} else {
				inputs = section.formBuilderInputs;
			}

			inputs.forEach((input) =>
			{
				if (input.label && input.label.toLowerCase() === 'signature') {
					return;
				}

				const existingContent = this.state.formContents.find(
					(content) => content.id === input.id
				);

				if (existingContent) {
					formBuilderContents.push({
						...existingContent,
						sectionId: section.id,
						parentId: (section.subSections && section.subSections.length > 0)
							? section.id
							: section.parentId,
						inputLabel: input.label || null,
						initContent: input.cmsContent!,
					});
				} else {
					formBuilderContents.push({
						id: input.id as number,
						sectionId: section.id,
						parentId: (section.subSections && section.subSections.length > 0)
							? section.id
							: section.parentId,
						inputLabel: input.label || null,
						isRequired: input.isRequired,
						initContent: input.cmsContent!,
						updateContent: input.cmsContent!,
						error: null,
						value: null,
					});
				}
			});
		});

		this.setState({
			formContents: formBuilderContents,
			content: this.combineFormContents(formBuilderContents),
		}, () =>
		{
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Handle Change content
	 * @param value The new value (e.g. "true" or "false" for checkboxes).
	 * @param inputSectionId The ID of the input that changed.
	 * @param duplicateGroupKey
	 */
	handleChangeContent(value: string, inputSectionId: number, duplicateGroupKey?: string): void
	{
		// Détermine l'ID cible en fonction de la présence d'un duplicateGroupKey.
		const targetId = duplicateGroupKey !== undefined ? Number(`${ inputSectionId }${ duplicateGroupKey }`) : inputSectionId;

		let contentItem: FormBuilderInputsInterface | undefined = undefined;
		this.state.formSections.some(section =>
		{
			const found = section.formBuilderInputs.find(input => input.id === inputSectionId);
			if (found) {
				contentItem = found;
				return true;
			}
			return false;
		});

		if (contentItem &&
			(contentItem as FormBuilderInputsInterface).label &&
			(contentItem as FormBuilderInputsInterface).label!.toLowerCase() === 'signature') {
			this.updateSignature(value);
			return;
		}

		// Si la valeur ressemble à une date au format "YYYY-MM-DD", on la formate pour l'affichage.
		const formattedValue = this.formatDateDisplay(value);

		const updatedFormContents = this.state.formContents.map((content) =>
		{
			if (content.id === targetId) {
				return {
					...content,
					value: value, // on conserve la valeur d'origine
					updateContent: value === 'false'
						? ''
						: content.initContent.replace('{{var}}', formattedValue),
					error: value && value.trim() !== '' ? null : content.error,
				};
			}
			return content;
		});
		this.setFormContentsState(updatedFormContents);

		if (this.updateTimers[targetId]) {
			clearTimeout(this.updateTimers[targetId]);
		}

		this.updateTimers[targetId] = setTimeout(() =>
		{
			const updatedContents = this.state.formContents.map((content) =>
			{
				if (content.id === targetId) {
					const replacement = content.value || '';
					const formattedReplacement = this.formatDateDisplay(replacement);
					return {
						...content,
						updateContent: replacement === 'false'
							? ''
							: content.initContent.replace('{{var}}', formattedReplacement),
						error: replacement.trim() !== '' ? null : content.error,
					};
				}
				return content;
			});

			this.setState({
				formContents: updatedContents,
				content: this.combineFormContents(updatedContents)
			}, () =>
			{
				this.handlePrepareConsumerState(this.state.formContents);
				this.props.callbackState!(this.state);
			});

			delete this.updateTimers[targetId];
		}, 500);
	}


	handleConsumerContentFromSiren(consumerData: Partial<ConsumerDataInterface>): void
	{
		const mapping: { [key: string]: string | null } = {
			'Dénomination': consumerData.name!,
			'Numéro de RCS': consumerData.siren!,
			'Capital': consumerData.capital!,
			'Forme juridique': consumerData.legalStatus!,
			'Greffe': consumerData.rcs!,
			'Adresse': consumerData.address?.street!,
			'Numéro': consumerData.address?.number ? consumerData.address?.number.toString() : '',
			'Code postal': consumerData?.address?.zipCode!,
			'Ville': consumerData?.address?.city!,
		};

		const updatedFormContents = this.state.formContents.map((content) =>
		{
			if ((content.sectionId === 45 || content.sectionId === 11) &&
				content.inputLabel && mapping.hasOwnProperty(content.inputLabel)) {
				const newValue: string = mapping[content.inputLabel] || '';
				return {
					...content,
					value: newValue,
					updateContent: content.initContent.replace('{{var}}', newValue),
					error: newValue.trim() !== '' ? null : content.error,
				};
			}
			return content;
		});

		this.setState({
			formContents: updatedFormContents,
			content: this.combineFormContents(updatedFormContents)
		}, () =>
		{
			this.handlePrepareConsumerState(this.state.formContents);
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Handle and prepare Consumer
	 * @param formContentsState
	 */
	handlePrepareConsumerState(formContentsState: DynamicGuideFormState['formContents']): void
	{
		const consumerSections = formContentsState.filter(
			(section: FormContentInterface) => section.sectionId === 11 || section.sectionId === 45
		);

		if (consumerSections.length > 0) {
			const rcsSection = formContentsState.find(
				(section: FormContentInterface) => section.inputLabel === 'Lieu d\'immatriculation'
			);

			const capitalSection = formContentsState.find(
				(section: FormContentInterface) => section.inputLabel === 'Capital'
			);

			const getFieldValue = (label: string): string | null =>
			{
				const found = consumerSections.find(section => section.inputLabel === label);
				return found ? found.value : null;
			};

			const prepareConsumerData: ConsumerDataInterface = {
				name: getFieldValue('Dénomination') || '',
				siren: getFieldValue('Numéro de RCS') || '',
				// Utilise la valeur trouvée pour "Greffe", sinon la valeur du champ "Lieu d'immatriculation", ou null
				rcs: getFieldValue('Greffe') || rcsSection?.value || null,
				capital: capitalSection?.value || '',
				legalStatus: getFieldValue('Forme juridique'),
				address: {
					number: Number(getFieldValue('Numéro')) || null,
					name: getFieldValue('Dénomination') || '',
					city: getFieldValue('Ville') || '',
					street: getFieldValue('Adresse') || '',
					additionalData: '',
					zipCode: getFieldValue('Code postal') || '',
					country: '',
					phone: null,
					isBillingAddress: false,
					extSellsyId: null,
				}
			};

			this.setState({ consumer: prepareConsumerData }, () => this.props.callbackState!(this.state));
		}
	}

	/**
	 * Set consumer State
	 * @param consumerData
	 */
	setConsumerState(consumerData: Partial<ConsumerDataInterface>): void
	{
		this.setState({
			consumer: consumerData,
		}, () =>
		{
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Met à jour la signature directement dans le state,
	 * afin qu'elle soit renvoyée au parent.
	 * @param value La nouvelle valeur de la signature.
	 */
	updateSignature(value: string): void
	{
		this.setState({
			signature: value
		}, () => this.props.callbackState!(this.state));
	}

	/**
	 * Set Loading
	 * @param isLoading
	 */
	setLoading(isLoading: boolean): void
	{
		this.setState({
			isLoading
		});
	}

	/**
	 * Duplicates the inputs of the given sub-section and appends them to formContents.
	 * Each duplicated input is given a unique ID (for example, by concatenating the original ID with a counter)
	 * and tagged with a duplicatedGroup identifier.
	 * @param subSection The sub-section to duplicate.
	 */
	duplicateSubSection(subSection: FormBuilderSectionInterface): void
	{
		const duplicateGroupId = Date.now();

		const duplicatedContents: FormContentInterface[] = subSection.formBuilderInputs.map(input =>
		{
			return {
				id: Number(`${ input.id }${ duplicateGroupId }`),
				sectionId: subSection.parentId,
				parentId: subSection.parentId,
				inputLabel: input.label || null,
				isRequired: input.isRequired ?? false,
				initContent: input.cmsContent!,
				updateContent: input.cmsContent!,
				error: null,
				value: null,
				duplicatedGroup: duplicateGroupId,
			};
		});

		const currentContents = this.state.formContents;
		const lastIndex = currentContents.reduce((last, content, index) =>
		{
			if (content.parentId === subSection.parentId) {
				return index;
			}
			return last;
		}, -1);

		let updatedFormContents: FormContentInterface[];
		if (lastIndex === -1) {
			updatedFormContents = [...currentContents, ...duplicatedContents];
		} else {
			updatedFormContents = [
				...currentContents.slice(0, lastIndex + 1),
				...duplicatedContents,
				...currentContents.slice(lastIndex + 1),
			];
		}

		this.setState({
			formContents: updatedFormContents,
			content: this.combineFormContents(updatedFormContents),
		}, () =>
		{
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Supprime les contenus d'une duplication donnée.
	 * @param duplicatedGroup Le numéro du groupe de duplication à supprimer.
	 */
	removeDuplicatedSubSection(duplicatedGroup: number): void
	{
		const updatedFormContents = this.state.formContents.filter(
			(content) => content.duplicatedGroup !== duplicatedGroup
		);
		this.setState({
			formContents: updatedFormContents,
			content: this.combineFormContents(updatedFormContents),
		}, () =>
		{
			this.props.callbackState!(this.state);
		});
	}

	/**
	 * Combine all updateContent values into a single string, separating them with a space.
	 * For non-required fields, only include them if they have a non-empty value.
	 * @return {string} La chaîne résultante.
	 */
	private combineFormContents(formContents: FormContentInterface[]): string
	{
		return formContents
			.filter(content =>
				content.isRequired ? true : (content.value !== null && content.value.trim() !== '')
			)
			.map(content => content.updateContent || '')
			.join(' ');
	}

	private getConsumerInputIds(formContents: FormContentInterface[]): { [label: string]: number }
	{
		const consumerSections = formContents.filter(
			(content) => content.sectionId === 11
		);

		const inputIds: { [label: string]: number } = {};
		consumerSections.forEach((content) =>
		{
			if (content.inputLabel) {
				inputIds[content.inputLabel] = content.id;
			}
		});
		return inputIds;
	}

	/**
	 * Formate une chaîne de date du format "YYYY-MM-DD" en "DD/MM/YYYY".
	 * Si la chaîne ne correspond pas à ce format, la retourne telle quelle.
	 * @param dateStr La chaîne de date à formater.
	 * @returns La date formatée.
	 */
	private formatDateDisplay(dateStr: string): string
	{
		const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
		const match = dateStr.match(regex);
		if (match) {
			return `${ match[3] }/${ match[2] }/${ match[1] }`;
		}
		return dateStr;
	};
}
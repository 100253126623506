import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import {
  CommercialTableStatsAdminProps,
  CommercialTableStatsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin.interface';
import { ApiAdminCommercialService } from '@/Service/Admin/ApiAdminCommercialService';

const initState: CommercialTableStatsAdminState = {
  isLoading: false,
  clientList: {} as PaginateInterface,
  filterParams: {
    page: 1,
    itemsPerPage: 10,
    search: null,
    filters: null,
    orderBy: null,
    sort: null,
  },
}

export class CommercialTableStatsAdminService extends BaseComponentService<CommercialTableStatsAdminProps, CommercialTableStatsAdminState>
{
  private apiCommercialService: ApiAdminCommercialService = new ApiAdminCommercialService();
  constructor()
  {
    super({} as CommercialTableStatsAdminProps, initState);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.apiCommercialService.list(paramsToString(this.state.filterParams))
      .then((response) => { this.setState({ clientList: response, isLoading: false }) })
    ;
  }

  getLastThreeYears(): number[]
  {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear - 1, currentYear - 2];
  }

  buildColumnHeader(): TableHeadColumnsInterface[]
  {
    const columns: TableHeadColumnsInterface[] = [
      {
        title: 'Dénomination',
        tag: 'name',
        columnWidth: 200,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'name'
      }
    ];

    if (this.state.clientList.items?.length > 0) {
      const years = new Set<number>();

      this.state.clientList.items.forEach(client => {
        if (Array.isArray(client.legalNoticeStats)) {
          client.legalNoticeStats.forEach((stat: any) => years.add(stat.year));
        }
      });

      const sortedYears = Array.from(years).sort((a, b) => b - a);

      sortedYears.forEach((year, index) => {
        columns.push({
          title: `Statistiques ${year}`,
          tag: `year_${year}`,
          columnWidth: 150,
          textAlign: 'center',
          fontWeight: 400,
          keyType: 'string',
          keyTag: `legalNoticeStats.${year}`
        });

        if (index < sortedYears.length - 1) {
          const prevYear = sortedYears[index + 1];
          columns.push({
            title: `Ratio`,
            tag: `ratio_${prevYear}_${year}`,
            columnWidth: 120,
            textAlign: 'center',
            fontWeight: 400,
            keyType: 'stats',
            keyTag: `ratio_${prevYear}_${year}`
          });
        }
      });
    }

    return columns;
  }

  handlePageChange(page: number): void
  {
    this.setState(
      (prevState) => ({
        filterParams: { ...prevState.filterParams, page },
      }),
      async () => await this.init()
    );
  }

  onSearchTerm(term: string): void
  {
    this.setState(
      (prevState) => ({
        filterParams: { ...prevState.filterParams, search: term },
      }),
      async () => await this.init()
    );
  }
}
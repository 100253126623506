import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const BreadcrumbStyles = {
	container: {
		marginBottom: -2,
	} as CSSProperties,

	item: {
		fontSize: 12,
		fontWeight: 400,
		margin: 0,
		cursor: 'pointer'
	} as CSSProperties,

	devItem: {
		fontSize: 12,
		fontWeight: 400,
		margin: 0,
		cursor: 'pointer',
		color: colors.white
	} as CSSProperties
} as const;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ConsumerFormProps, ConsumerFormState } from './ConsumerForm.interface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import {
	AddressFormInterface,
} from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.interface';
import { AddressFormService } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.service';

const initState: ConsumerFormState = {
	isLoading: true,
	consumer: {} as ConsumerDataInterface,
	errors: {},
};

export class ConsumerFormService extends BaseComponentService<ConsumerFormProps, ConsumerFormState>
{
	constructor()
	{
		super({} as ConsumerFormProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if(this.props.isEdit) {
			this.setConsumerState(this.props.stateUpdater?.consumer!, true);
		} else {
			this.setConsumerState(this.props.stateUpdater?.consumer!, false);
		}
	}

	/**
	 * Set Consumer State
	 * @param consumerData
	 * @param isCallbackState
	 */
	setConsumerState(consumerData: Partial<ConsumerDataInterface>, isCallbackState: boolean = true): void
	{
		this.setState({
			consumer: {
				...this.state.consumer,
				...consumerData,
			}
		}, () => {
			if (isCallbackState) {
				if(!this.props.isEdit) {
					this.props.callbackState!(this.state);
				} else {
					this.props.callbackState!(this.state, true);
				}
			}
			this.setState({ isLoading: false });
		});
	}

	/**
	 * Set Consumer Address State
	 * @param consumerAddressData
	 */
	setConsumerAddressState(consumerAddressData: Partial<AddressFormInterface>): void
	{
		this.setState(
			(prevState) => ({
				consumer: {
					...prevState.consumer,
					address: {
						...prevState.consumer?.address!,
						...consumerAddressData,
					},
				},
			}), () => this.props.callbackState!(this.state)
		);
	}

	/**
	 * Validate consumer Form
	 * @param consumer
	 * @param isConsumerFormLight
	 * @param isSirenRequired
	 */
	static validateConsumerForm(
		consumer: ConsumerDataInterface,
		isConsumerFormLight: boolean,
		isSirenRequired: boolean
	): { [key: string]: string | null }
	{
		let errors: { [key: string]: string | null } = {};

		if (!consumer?.name || consumer?.name.trim() === '') {
			errors.name = 'La dénomination est obligatoire.';
		}
		if(isSirenRequired && (!consumer?.siren || consumer?.siren.trim() === '')) {
			errors.siren = 'Le Siren est obligatoire.';
		}
		if(!isConsumerFormLight) {
			if (!consumer?.rcs || consumer?.rcs.trim() === '') {
				errors.rcs = 'Le lieu d’immatriculation est obligatoire.';
			}
			if (!consumer?.legalStatus || consumer?.legalStatus.trim() === '') {
				errors.legalStatus = 'La forme juridique est obligatoire.';
			}
			if (!consumer?.capital || consumer?.capital.trim() === '') {
				errors.capital = 'Le capital est obligatoire.';
			}

			const addressErrors = AddressFormService.validateAddressForm(consumer?.address!);

			errors = {
				...errors,
				...addressErrors,
			};
		} else {
			errors = {
				...errors,
			};
		}

		return errors;
	}

	/**
	 * Transform to lowercase or Uppercase
	 * @param field
	 */
	toggleCaseForField<K extends keyof ConsumerDataInterface>(field: K): void {
		const currentValue = this.state.consumer[field] as string || '';
		const newValue = currentValue === currentValue.toUpperCase()
			? currentValue.toLowerCase()
			: currentValue.toUpperCase();

		this.setConsumerState({ [field]: newValue } as Partial<ConsumerDataInterface>, true);
	}
}
import React, { Component, ReactElement } from 'react';
import { LineDetailsRenderProps, LineDetailsRenderState } from './LineDetailsRender.interface';
import { ClientDetailsStyles } from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class LineDetailsRender extends Component<LineDetailsRenderProps, LineDetailsRenderState>
{
	render(): ReactElement
	{
		const { isEditable, inputEdit, info, render, formData, textColor } = this.props;
		return (
			<div>
				<div style={ ClientDetailsStyles.line }>
					<span style={
						{ color: (textColor?.colorInfo) ? textColor.colorInfo : colors.gray400 }
					}>
						{ info }
					</span>
					{ isEditable && inputEdit ? (
						<Input
							type={ inputEdit.type }
							name={ inputEdit.name }
							value={ formData || '' }
							onChange={ this.props.inputEdit?.onChange }
							allowedCharacters={ inputEdit.allowedCharacters }
						/>
					) : (
						<span style={ {
							fontWeight: 500,
							color: (textColor?.colorData) ? textColor.colorData : UserConnectedService.isDeveloper() ? 'white' : 'inherit'
						} }>
							{ (formData)
								? (render)
									? render
									: formData
								: '—'
							}
						</span>
					) }
				</div>
			</div>
		);
	}
}

export default LineDetailsRender;
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { FormSubSectionProps, FormSubSectionState } from './FormSubSection.interface';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';

const initState: FormSubSectionState = {
	isLoading: true,
	formSection: {} as FormBuilderSectionInterface,
	subSectionSelected: null,
};

export class FormSubSectionService extends BaseComponentService<FormSubSectionProps, FormSubSectionState>
{
	constructor()
	{
		super({} as FormSubSectionProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setFormSectionState(this.props.formSection,
			() => this.setLoading(false)
		);
	}

	/**
	 * Set Form Section
	 * @param formSection
	 * @param callback
	 */
	setFormSectionState(formSection: FormBuilderSectionInterface, callback?: () => void): void
	{
		this.setState({
			formSection,
		}, callback);
	}

	/**
	 * Set Loading
	 * @param isLoading
	 */
	setLoading(isLoading: boolean): void
	{
		this.setState({
			isLoading
		});
	}

	/**
	 * Duplicates the selected sub-section by adding its inputs to formContents with new unique IDs.
	 * @param subSection The sub-section to duplicate.
	 */
	handleDuplicateSubSection(subSection: FormBuilderSectionInterface): void
	{
		// Call the service method to duplicate the sub-section inputs.
		this.props.duplicateSubSection(subSection);
	}

	/**
	 * Set SubSection Selected
	 * @param subSectionSelected
	 */
	setSubSectionSelectedState(subSectionSelected: FormBuilderSectionInterface | null): void
	{
		this.setState({
			subSectionSelected
		}, () => {
			if (subSectionSelected) {
				this.props.selectedSubSection(subSectionSelected!);
			}
			this.setLoading(false);
		});
	}

}
import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import {
  CommercialTableStatsAdminProps, CommercialTableStatsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin.interface';
import {
  CommercialTableStatsAdminService
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin.service';
import { ClipLoader } from 'react-spinners';
import { LuArrowDown, LuArrowUp, LuMinus, LuBan } from 'react-icons/lu';
import TagStat from '@/Modules/App/Components/Library/TagStat/TagStat';
import {
  CommercialTableStatsAdminStyles
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/Components/Table/CommercialTableStatsAdmin.styles';
import Pagination from '@/Modules/App/Components/Library/Pagination/Pagination';
import TableControls from '@/Modules/App/Components/Library/TableControls/TableControls';

export default class TableStats extends React.Component<CommercialTableStatsAdminProps, CommercialTableStatsAdminState>
{
  private componentService: CommercialTableStatsAdminService = new CommercialTableStatsAdminService();
  public TABLE_TAG = 'commercial-clients';

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  async componentDidUpdate(prevProps: CommercialTableStatsAdminProps): Promise<any>
  {
    if (this.props.refreshKey !== prevProps.refreshKey) {
      await this.componentService.init();
    }
  }

  //</editor-fold>

  render(): ReactElement
  {
    const { filterParams } = this.state;

    return (
      <>
        <div style={{ position: 'relative', overflowX: 'auto' }}>
          { this.state.isLoading && (
            <div style={ CommercialTableStatsAdminStyles.loaderContainer }>
              <ClipLoader />
            </div>
          )}

          <div style={{ position: 'absolute', width: '405px' }}>
            <TableControls
              key={ filterParams?.orderBy?.toString()! + filterParams?.sort?.toString() + filterParams?.filters?.toString()! }
              filters={ [] }
              tag={ this.TABLE_TAG }
              onFilterChange={ () => {} }
              onApplyFilter={ () => {} }
              onChangeTerm={ (term: string) => this.componentService.onSearchTerm(term) }
              onClearFilters={ () => {} }
            />
          </div>

          { this.tableRender() }

          <Pagination
            tag={ this.TABLE_TAG }
            currentPage={this.state.filterParams.page}
            itemsPerPage={this.state.filterParams.itemsPerPage}
            list={this.state.clientList}
            onPageChange={(page: number) => { this.componentService.handlePageChange(page) }}
          />
        </div>
      </>
    );
  }

  private tableRender(): ReactElement
  {
    // Init vars
    const { clientList } = this.state;
    const { onRowClick } = this.props;
    const years = this.componentService.getLastThreeYears();

    return (
      <>
        <table style={ CommercialTableStatsAdminStyles.table }>
          <thead>
          <tr>
            <th style={ CommercialTableStatsAdminStyles.tableTh }></th>
            { years.map((year: number, index: number) => (
              <th
                colSpan={ 2 }
                key={`year_${year}`}
                style={ CommercialTableStatsAdminStyles.emptyTableRow(index) }
              >
                { year }
              </th>
            ))}
            { years.slice(1).map((year: number, index: number) => (
              <th
                key={ `ratio_${years[index]}_${year}` }
                style={ CommercialTableStatsAdminStyles.yearsRow }
              >
                Ratio { years[index] } → {year}
              </th>
            ))}
          </tr>

          <tr style={{ background: '#f8f9fa', borderBottom: '1px solid #ddd' }}>
            <th rowSpan={ 2 } style={ CommercialTableStatsAdminStyles.firstTh }>
              Dénomination
            </th>
            { years.map((year: number) => (
              <React.Fragment key={ year }>
                <th key={`intern_${year}`} style={{ padding: '10px', textAlign: 'center' }}>Internes</th>
                <th key={`extern_${year}`} style={{ padding: '10px', textAlign: 'center' }}>Externes</th>
              </React.Fragment>
            ))}
            { years.slice(1).map((year: number, index: number) => (
              <th
                key={`ratio_value_${years[index]}_${year}`}
                style={{ padding: '10px', textAlign: 'center' }}
              >
                %
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          { clientList.items?.length > 0 ? (
            clientList.items.map((client: ClientInterface, rowIndex: number) => (
              <tr
                key={ rowIndex }
                style={ CommercialTableStatsAdminStyles.tableBodyRow }
                onClick={ (event: React.MouseEvent<HTMLTableRowElement>) => onRowClick?.(event, client) }
              >
                <td style={{ padding: '10px', fontWeight: 'bold' }}>{ client.name }</td>

                { years.map((year: number) =>
                  {
                    const stat = client.legalNoticeStats?.find((currentStat: any) => currentStat.year === year);
                    return (
                      <React.Fragment key={ `stats_${client.id}_${year}` }>
                        <td key={ `intern_val_${ year }` } style={{ padding: '10px', textAlign: 'center' }}>
                          { stat ? stat.intern : '—' }
                        </td>
                        <td key={ `extern_val_${ year }` } style={{ padding: '10px', textAlign: 'center' }}>
                          { stat ? stat.extern : '—' }
                        </td>
                      </React.Fragment>
                    );
                  }
                )}

                { years.slice(1).map((year: any, index: any) =>
                  {
                    const prevYear = years[index];
                    const prevStat = client.legalNoticeStats?.find((s: any) => s.year === prevYear);
                    const currStat = client.legalNoticeStats?.find((s: any) => s.year === year);

                    const totalPrev = prevStat ? prevStat?.intern! + prevStat?.extern! : 0;
                    const totalCurr = currStat ? currStat?.intern! + currStat?.extern! : 0;

                    let ratio = '0';
                    let trend = <LuMinus />;

                    let variant: 'secondary' | 'success' | 'danger' | 'warning' | 'primary' = 'secondary';

                    if (totalPrev > 0) {
                      ratio = ((totalCurr - totalPrev) / totalPrev * 100).toFixed(1);
                      trend = totalCurr > totalPrev ? <LuArrowUp /> : (totalCurr < totalPrev ? <LuArrowDown /> : <LuMinus />);
                      variant = totalCurr > totalPrev ? 'success' : totalCurr < totalPrev ? 'danger' : 'secondary';
                    } else if (totalCurr > 0) {
                      ratio = 'N/A';
                      trend = <LuBan />;
                      variant = 'warning';
                    }

                    return (
                      <td key={ `ratio_${ prevYear }_${ year }` } style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
                        <TagStat label={ <>{ ratio } { ratio !== 'N/A' ? '%' : '' } { trend }</> } variant={ variant } />
                      </td>
                    );
                  }
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={ years.length * 2 + years.length - 1 } style={{ textAlign: 'center', padding: '20px' }}>
                Aucune donnée disponible
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </>
    );
  }
}

import React, { Component, ReactElement } from 'react';
import { ButtonProps, ButtonState } from './Button.interfaces';
import { ButtonService } from './Button.service';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { ButtonStyles } from '@/Modules/App/Components/Library/Button/Button.styles';
import * as Icons from 'react-icons/lu';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ClipLoader } from 'react-spinners';

class Button extends Component<ButtonProps, ButtonState>
{
	private buttonService = new ButtonService();

	constructor(props: ButtonProps)
	{
		super(props);

		// Config service
		this.buttonService.setProps(this.props);
		this.buttonService.subscribeState(this);

		// State
		this.state = this.buttonService.getState();
	}

	render(): ReactElement
	{
		const { variant = 'primary', iconName, label, onClick, disabled = false } = this.props;
		const IconComponent = iconName ? Icons[iconName] : null;

		return (
			<Hovered>
				{ ({ isHovered, ref, onMouseEnter, onMouseLeave }) => (
					<div
						ref={ ref }
						onMouseEnter={ disabled ? undefined : onMouseEnter }
						onMouseLeave={ disabled ? undefined : onMouseLeave }
						style={{
							...ButtonStyles.container(isHovered, variant, disabled),
							...this.props.style
						}}
						onClick={ disabled ? undefined : onClick }
					>
						{ (this.props.isLoading) ? (
							<ClipLoader
								color={ colors.gray400 }
								loading
								size={ 14 }
								speedMultiplier={ 1 }
							/>
						) : (
							<>
								{ IconComponent && <IconComponent
                  color={
										variant === 'document' ? colors.blueRibbon600 :
											(variant === 'inline' || variant === 'close' ? ButtonStyles.container(isHovered, variant, disabled).color : 'inherit')
									}
                  fontSize={ variant === 'filter' ? 14 : 16 }
                  style={ this.props.iconStyle || {} }
                /> }
								{ label && variant !== 'close' && <span style={ ButtonStyles.text(disabled, variant) }>{ label }</span> }
							</>
						)}
					</div>
				) }
			</Hovered>
		);
	}
}

export default Button;

import React, { Component, ReactElement } from 'react';
import { LegalNoticeWritingContentProps, LegalNoticeWritingContentState } from './LegalNoticeWritingContent.interface';
import { LegalNoticeWritingContentService } from './LegalNoticeWritingContent.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { LegalNoticeWritingContentStyles } from '@/Modules/LegalNotice/Components/LegalNoticeWritingContent/LegalNoticeWritingContent.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class LegalNoticeWritingContent extends Component<LegalNoticeWritingContentProps, LegalNoticeWritingContentState> {
	private legalNoticeWritingContentService = new LegalNoticeWritingContentService();
	private inputTimeout: ReturnType<typeof setTimeout> | null = null;

	constructor(props: LegalNoticeWritingContentProps) {
		super(props);

		// Config service
		this.legalNoticeWritingContentService.setProps(this.props);
		this.legalNoticeWritingContentService.subscribeState(this);

		// State
		this.state = this.legalNoticeWritingContentService.getState();
	}

	async componentDidMount(): Promise<void> {
		await this.legalNoticeWritingContentService.init();
		if (this.legalNoticeWritingContentService.contentEditableRef.current) {
			this.legalNoticeWritingContentService.contentEditableRef.current.innerHTML = this.state.content;
		}
	}

	componentDidUpdate(prevProps: LegalNoticeWritingContentProps, prevState: LegalNoticeWritingContentState): void {
		if (prevState.content !== this.state.content && this.legalNoticeWritingContentService.contentEditableRef.current) {
			const editableDiv = this.legalNoticeWritingContentService.contentEditableRef.current;
			const caretOffset = this.getCaretCharacterOffsetWithin(editableDiv);
			editableDiv.innerHTML = this.state.content;
			this.setCaretPosition(editableDiv, caretOffset);
		}
	}

	private getCaretCharacterOffsetWithin(element: Node): number {
		let caretOffset = 0;
		const selection = window.getSelection();
		if (selection && selection.rangeCount > 0) {
			const range = selection.getRangeAt(0).cloneRange();
			const preCaretRange = range.cloneRange();
			preCaretRange.selectNodeContents(element);
			preCaretRange.setEnd(range.endContainer, range.endOffset);
			caretOffset = preCaretRange.toString().length;
		}
		return caretOffset;
	}

	private setCaretPosition(element: Node, offset: number): void {
		const range = document.createRange();
		range.selectNodeContents(element);
		let currentOffset = 0;
		let found = false;
		const treeWalker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null);
		while (treeWalker.nextNode()) {
			const currentNode = treeWalker.currentNode;
			const nodeLength = currentNode.textContent ? currentNode.textContent.length : 0;
			if (currentOffset + nodeLength >= offset) {
				range.setStart(currentNode, offset - currentOffset);
				range.collapse(true);
				found = true;
				break;
			}
			currentOffset += nodeLength;
		}
		if (!found) {
			range.selectNodeContents(element);
			range.collapse(false);
		}
		const selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}

	shouldComponentUpdate(nextProps: LegalNoticeWritingContentProps, nextState: LegalNoticeWritingContentState): boolean {
		return nextState.content !== this.state.content;
	}

	render(): ReactElement {
		return (
			<div style={LegalNoticeWritingContentStyles.container}>
				<div style={LegalNoticeWritingContentStyles.menu}>
					<Button iconName="LuBold" variant="small" onClick={() => this.legalNoticeWritingContentService.applyBoldCustom()} />
					<Button iconName="LuItalic" variant="small" onClick={() => this.legalNoticeWritingContentService.applyStyle('italic')} />
					<Button iconName="LuCaseUpper" variant="small" onClick={() => this.legalNoticeWritingContentService.applyCaseTransformation('uppercase')} />
					<Button iconName="LuCaseLower" variant="small" onClick={() => this.legalNoticeWritingContentService.applyCaseTransformation('lowercase')} />
					<Button iconName="LuAlignJustify" variant="small" onClick={() => this.legalNoticeWritingContentService.HandleCondensedContent()} />
				</div>

				<div
					contentEditable={true}
					ref={this.legalNoticeWritingContentService.contentEditableRef}
					style={LegalNoticeWritingContentStyles.editableDiv}
					onInput={(event) => {
						if (this.inputTimeout) clearTimeout(this.inputTimeout);
						this.inputTimeout = setTimeout(() => {
							this.legalNoticeWritingContentService.setContentState(event);
						}, 300);
					}}
					onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => {
						event.preventDefault();
						const clipboardData = event.clipboardData;
						let plainText = clipboardData.getData('text/plain');

						plainText = plainText
							.replace(/&nbsp;/gi, ' ')
							.replace(/\s+/g, ' ')
							.trim();
						document.execCommand('insertText', false, plainText);
					}}
					onMouseUp={(event) => this.legalNoticeWritingContentService.handleTextSelect(event)}
				></div>
			</div>
		);
	}
}

export default withGlobalContext(LegalNoticeWritingContent);

import React, { Component, ReactElement } from 'react';
import {
  ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats.interface';
import {
  ClientLegalNoticeStatsService
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/CommercialClientLegalNoticeStats/ClientLegalNoticeStats.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { LuCheckCheck, LuPencilLine, LuX } from 'react-icons/lu';
import TableOld from '@/Modules/App/Components/Atom/Table/TableOld';

export default class ClientLegalNoticeStats extends Component<ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState>
{
  private componentService = new ClientLegalNoticeStatsService();

  //<editor-fold desc="Ctor" defaultstate="collapsed">

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //</editor-fold>

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <LoaderComponent />;
    }

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 5 }}>
          <Button
            variant={ 'primary' }
            label={ 'Ajouter' }
            iconName={ 'LuPlus' }
            onClick={ () => {
              if (!this.state.newStats) {
                this.setState({ newStats: { id: null, extern: null, intern: null, year: null, client: this.props.client } });
              }
            }}
          />
        </div>

        <TableOld>
          <TableHeader
            columnHeaderList={ this.componentService.buildColumnHeader() }
            isFilters={ true }
          />

          { this.state.newStats && (
            <TableRow isCheckboxInput={ false } borderBottom={ true }>
              <TableCol columnSize={ 22 }>
                <InputTableCell
                  value={ this.state.newStats.year }
                  onChange={ (event) =>
                    this.setState((prevState: any) => ({
                      newStats: { ...prevState.newStats, year: event.target.value }
                    }))
                  }
                />
                <InputTableCell
                  value={ this.state.newStats.extern }
                  onChange={ (event) =>
                    this.setState((prevState: any) => ({
                      newStats: { ...prevState.newStats, extern: event.target.value }
                    }))
                  }
                />
                <InputTableCell
                  value={ this.state.newStats.intern }
                  onChange={ (event) =>
                    this.setState((prevState: any) => ({
                      newStats: { ...prevState.newStats, intern: event.target.value }
                    }))
                  }
                />
              </TableCol>

              <TableCol columnSize={ 2 } style={{ display: 'flex', gap: 10 }}>
                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuCheckCheck /> }
                  onClick={() => {
                    if (this.state.newStats?.year) {
                      this.componentService.createStat(this.state.newStats);
                      this.setState({ newStats: null });
                    }
                  }}
                />

                <ButtonOld
                  type="pagination"
                  iconLeft={ <LuX /> }
                  onClick={() => this.setState({ newStats: null })}
                />
              </TableCol>
            </TableRow>
          )}

          { this.state.statsList.map((stat) => (
            <React.Fragment key={ stat.id?.toString() }>
              <TableRow key={ stat.id } isCheckboxInput={ false } borderBottom={ true }>
                <TableCol columnSize={ 22 } isEditMode={ this.state.editStatsId === stat.id } style={{ marginLeft: 25 }}>
                  <InputTableCell
                    value={ stat.year }
                    onChange={(event) =>
                      this.setState((prevState) => ({
                        statsList: prevState.statsList.map((currentStat) =>
                          currentStat.id === stat.id ? { ...currentStat, year: event.target.value } : currentStat
                        ),
                      }))
                    }
                  />
                  <InputTableCell
                    value={ stat.intern }
                    onChange={(event) =>
                      this.setState((prevState) => ({
                        statsList: prevState.statsList.map((currentStat) =>
                          currentStat.id === stat.id ? { ...currentStat, intern: event.target.value } : currentStat
                        ),
                      }))
                    }
                  />
                  <InputTableCell
                    value={ stat.extern }
                    onChange={(event) =>
                      this.setState((prevState) => ({
                        statsList: prevState.statsList.map((currentStat) =>
                          currentStat.id === stat.id ? { ...currentStat, extern: event.target.value } : currentStat
                        ),
                      }))
                    }
                  />
                </TableCol>

                <TableCol columnSize={ 2 } isEditMode={ this.state.editStatsId === stat.id }>
                  { this.state.editStatsId === stat.id ? (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuCheckCheck />}
                        onClick={async () => {
                          if (stat.year) {
                            await this.componentService.editStat(stat);
                          }
                        }}
                      />

                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuX />}
                        onClick={() =>
                          this.setState((prevState: any) => ({
                            editStatsId: null,
                            statsList: prevState.statsList.map((defaultStat: any) =>
                              defaultStat.id === stat.id ? { ...defaultStat } : defaultStat
                            ),
                          }))
                        }
                      />
                    </>
                  ) : (
                    <>
                      <ButtonOld
                        type="pagination"
                        iconLeft={<LuPencilLine />}
                        onClick={() =>
                          this.setState({ editStatsId: stat.id })
                        }
                      />
                    </>
                  )}
                </TableCol>
              </TableRow>
            </React.Fragment>
          ))}
        </TableOld>
      </>
    );
  }
}
import React, { Component, ReactElement } from 'react';
import { ClientsLogosProps, ClientsLogosState } from './ClientsLogos.interface';
import { ClientsLogosService } from './ClientsLogos.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Table from '@/Modules/App/Components/Library/Table/Table';
import { ModalContext } from '@/Provider/ModalProvider';
import ClientLogo from '@/Modules/Client/Components/ClientLogos/Show/ClientLogo';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import AddCustomLogoClientFormComponent
	from '@/Modules/Client/Components/ClientLogos/Create/Form/AddCustomLogoClientFormComponent';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';

class ClientsLogos extends Component<ClientsLogosProps, ClientsLogosState>
{
	private clientsLogosService = new ClientsLogosService();

	constructor(props: ClientsLogosProps)
	{
		super(props);

		// Config service
		this.clientsLogosService.setProps(this.props);
		this.clientsLogosService.subscribeState(this);

		// State
		this.state = this.clientsLogosService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientsLogosService.init();
	}

	componentWillUnmount(): void
	{
		this.clientsLogosService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<ModalContext.Consumer>
					{ (modalContext) => (
						<>
							<div style={ { display: 'flex', width: '100%', justifyContent: 'flex-end' } }>
								<Button
									variant={ 'inline' }
									label={ 'Créer un logo' }
									onClick={ (event: any) =>
									{
										event.stopPropagation();
										modalContext?.content(
											'Créer un logo',
											<AddCustomLogoClientFormComponent
												client={ this.props.client }
												modalContext={ modalContext }
												onCreateLogo={ this.clientsLogosService.onCreateLogo }
											/>
										);
									} }
								/>
							</div>
							<Table
								columns={ this.clientsLogosService.buildColumnHeader() }
								list={ this.state.list }
								filterParams={ null }
								onOrderBy={ () => null }
								onRowClick={ (logo: any, event: any) =>
								{
									event.stopPropagation();
									modalContext?.content(
										logo.name,
										<GlobalContextProvider>
											<ClientLogo
												clientId={ this.props.client.id }
												listLogos={ this.state.list }
												logo={ logo }
												getComponentState={ () => { this.clientsLogosService.fetchLogos(); } }
											/>
										</GlobalContextProvider>
									);
								} }
							/>
						</>
					) }
				</ModalContext.Consumer>
			</>
		);
	}
}

export default ClientsLogos;
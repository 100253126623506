import React, { ReactElement } from 'react';
import { CompanyInterface } from '@/Modules/Interface/CompanyInterface';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { AuthCompanyStyles } from '@/Modules/AuthCompany/AuthCompany.styles';
import CardComponent from '@/Modules/App/Components/Atom/Card/Card.component';
import { AuthCompanyService } from '@/Modules/AuthCompany/AuthCompany.service';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { AuthCompanyProps, AuthCompanyState } from '@/Modules/AuthCompany/AuthCompany.interface';

class AuthCompany extends React.Component <AuthCompanyProps, AuthCompanyState>
{
	companyService: ApiAdminCompanyService;
	authCompanyService = new AuthCompanyService();

	constructor(props: any)
	{
		super(props);

		// Config state
		this.authCompanyService.setProps(this.props);
		this.authCompanyService.subscribeState(this);

		// State
		this.state = this.authCompanyService.getState();

		// Services
		this.companyService = new ApiAdminCompanyService();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.authCompanyService.init();
	}

	componentWillUnmount(): void
	{
		this.authCompanyService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		const { authContext } = this.props;

		if (!authContext || !authContext.user) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div style={ AuthCompanyStyles.container }>
					<h1 style={ AuthCompanyStyles.h1 }>
						👋
						Bonjour { UserConnectedService.convertCivilityToFrench(authContext.user.civility) }. { authContext.user.lastname }
					</h1>
					<div style={ AuthCompanyStyles.textSmall }>
						Veuillez sélectionner l'entreprise sur laquelle vous souhaitez travailler aujourd'hui. Choisissez dans
						la liste ci-dessous pour accéder à votre espace dédié.
					</div>
				</div>
				<div style={ AuthCompanyStyles.cardCompaniesContainer }>
					{ this.state.companies.length > 0 &&
						this.state.companies.map(
							(company: CompanyInterface) => (
								<CardComponent
									key={ company.id }
									onClick={ () => this.authCompanyService.onSwitchCompany(company, this.props) }
								>
									<span style={ AuthCompanyStyles.text }>{ company.name }</span>&nbsp;
									<span style={ AuthCompanyStyles.textSmall }>({ company.city })</span>
								</CardComponent>
							)
						)
					}
				</div>
			</>
		);
	}
}

export default withGlobalContext(AuthCompany);
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { PublishDateProps, PublishDateState } from './PublishDatePicker.interface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';

const initState: PublishDateState = {
	selectedDate: null,
	isForceDate: false,
	publishDays: [],
	closurePast: null,
};

export class PublishDatePickerService extends BaseComponentService<PublishDateProps, PublishDateState>
{
	constructor()
	{
		super({} as PublishDateProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.initializePublishDaysAndClosureDays(this.props?.newspaper?.newspaperPublishDays!);
		if (this.props.stateUpdater.selectedDate) {
			this.setSelectedDate(this.props.stateUpdater.selectedDate);
		} else {
			this.selectFirstAvailableDate();
		}
	}

	/**
	 * Selected publish date
	 * @param date
	 */
	setSelectedDate(date: Date | null): void
	{
		const parsedDate = (typeof date === 'string') ? new Date(date) : date;
		this.setState({
			selectedDate: parsedDate
		}, () => this.props.callbackState(this.state));
	}

	/**
	 * Select the first available date
	 */
	selectFirstAvailableDate(): void
	{
		this.setState({ isForceDate: false });
		const currentDate = new Date();

		for (let i = 0; i < 30; i++) {
			const candidateDate = new Date(currentDate);
			candidateDate.setDate(currentDate.getDate() + i);

			if (!this.isDateDisabled(candidateDate)) {
				this.setSelectedDate(candidateDate);
				break;
			}
		}
	}

	/**
	 * Checks whether a candidate date is not allowed.
	 * For a given day, the configuration (closureDay) indicates the day and the cutoff time
	 * (for example: for a publication on Monday, the effective cutoff date is the preceding Friday at 5 PM).
	 * If the current time exceeds this effective cutoff date, the candidate date is disabled.
	 *
	 * @param candidateDate The date to check.
	 * @returns true if the date should be disabled, false otherwise.
	 */
	isDateDisabled(candidateDate: Date): boolean {
		const currentDate = new Date();
		const candidateDayLabel = candidateDate.toLocaleDateString('en-US', { weekday: 'long' });
		const allowedDays = this.state.publishDays;

		if (!allowedDays.includes(candidateDayLabel)) {
			return true;
		}

		const closureConfig = this.props.newspaper?.newspaperPublishDays?.find(
			(cfg) => cfg.day === candidateDayLabel
		);

		if (closureConfig) {
			const closureDayIndex = this.getDayIndex(closureConfig.closureDay.day);
			const candidateDayIndex = candidateDate.getDay();

			let effectiveClosureDate: Date;

			if (candidateDayIndex < closureDayIndex) {
				const daysToSubtract = candidateDayIndex + (7 - closureDayIndex);
				effectiveClosureDate = new Date(candidateDate);
				effectiveClosureDate.setDate(candidateDate.getDate() - daysToSubtract);
			} else {
				const daysToSubtract = candidateDayIndex - closureDayIndex;
				effectiveClosureDate = new Date(candidateDate);
				effectiveClosureDate.setDate(candidateDate.getDate() - daysToSubtract);
			}

			effectiveClosureDate.setHours(Number(closureConfig.closureDay.hours), Number(closureConfig.closureDay.minute), 0, 0);

			if (currentDate > effectiveClosureDate) {
				return true;
			}
		}

		const today = new Date(currentDate.toDateString());
		if (candidateDate < today) {
			return true;
		}

		return false;
	}


	/**
	 * Force publish date on this current week
	 */
	forcePublishDayForInternalNewspaper(): void {
		this.setState({ isForceDate: true });
		if (
			this.props.newspaper?.editorial === 'heraultjuridique'
			&& NewspaperTypeEnum.findByValue(this.props.newspaper?.type!) === NewspaperTypeEnum.PAPER
		) {
			const currentDate = new Date();

			for (let i = 0; i < 7; i++) {
				const candidateDate = new Date(currentDate);
				candidateDate.setDate(currentDate.getDate() + i);

				if (!this.isDateDisabled(candidateDate)) {
					this.setSelectedDate(candidateDate);
					break;
				}
			}
		}
	}

	/**
	 * Initialize publish days from selected newspaper
	 * @param closureDates
	 */
	initializePublishDaysAndClosureDays(closureDates: { day: string }[]): void
	{
		const allowedDays = closureDates.map((publish) => publish.day);

		this.setState({
			publishDays: allowedDays,
		});
	}

	private getDayIndex(day: string): number
	{
		const days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		return days.indexOf(day);
	}

	private getCurrentWeekDate(dayOfWeek: string): Date
	{
		const currentDate: Date = new Date();
		const targetDayIndex: number = this.getDayIndex(dayOfWeek);
		const currentDayIndex: number = currentDate.getDay();

		const daysUntilTarget: number = (7 + targetDayIndex - currentDayIndex) % 7;
		const targetDate: Date = new Date(currentDate);
		targetDate.setDate(currentDate.getDate() + daysUntilTarget);
		targetDate.setHours(0, 0, 0, 0);

		return targetDate;
	}



}
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiLegalNoticeDeveloperService extends BaseService
{
	legalNoticeUrl: string = `${ BaseService.API_URL }/v1/developer/legal-notices`;

	async list(params?: string): Promise<any>
	{
		try {
			const url = params ? this.legalNoticeUrl + '?' + params : this.legalNoticeUrl;
			const response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(legalNoticeId: number): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(legalNoticeId: number, legalNoticeData: any): Promise<any>
	{
		try {
			const response = await fetch(
				this.legalNoticeUrl + '/' + legalNoticeId,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(legalNoticeId: number, nbCharacters: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
					body: JSON.stringify({ nbCharacters: nbCharacters }),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async sendBackEmail(legalNoticeId: number, sendBackEmailData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/send-back-email`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(sendBackEmailData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async sendToAdditional(legalNoticeId: number, sendToAdditionalData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.legalNoticeUrl }/${ legalNoticeId }/send-to-additional`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(sendToAdditionalData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}
import { CSSProperties } from 'react';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export const SectionCollapsedStyles = {
	container: {} as CSSProperties,
	heading: (isDeveloper: boolean, highLightColors?: string) => ({
		display: 'flex',
		justifyContent: 'space-between',
		...(highLightColors) ? { backgroundColor: (isDeveloper) ? themeColors.success : highLightColors } : {},
		...(!highLightColors) ? { paddingBottom: 10 } : { padding: '5px 8px'},
		borderRadius: (!highLightColors) ? 0 : 8,
		marginBottom: 15,
		borderBottom: (!highLightColors) ? borderStyles.border : 'none',
	}) as CSSProperties,

	control: {
		display: 'flex',
		gap: 10,
		alignItems: 'center',
	} as CSSProperties,

	title: {
		...FontStyles.h3,
		fontWeight: 700
	} as CSSProperties,

	content: (isOpen: Boolean) => ({
		maxHeight: isOpen ? 1000 : 0,
		transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
		opacity: isOpen ? 1 : 0,
		padding: '0 20px 20px 20px',
	}) as CSSProperties,

} as const;
import React, { ReactElement, createContext } from 'react';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { ButtonType } from '@/Modules/App/Components/Atom/Button/Type/ButtonType';
import ModalStyle from '@/Modules/App/Style/Components/ModalStyle';
import { ModalProps, ModalState } from '@/Provider/Modal/Modal.interface';
import { ModalService } from '@/Provider/Modal/Modal.service';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export interface ModalContextType
{
	isLoadingFull: (isLoading: boolean) => void,
	isLoadingOverlay: (isLoading: boolean) => void,
	content: (title: string | null, content: ReactElement, isStack?: boolean) => void
	isOpen: (isOpen: boolean) => void,
	errorMessage: (errorMessage: string | null) => void,
	actions: (actions: {
		label: string,
		buttonType?: ButtonType,
		icons?: ReactElement,
		action: ((event: any) => Promise<void>) | ((event?: any) => any)
	}[]) => void
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export default class ModalProvider extends React.Component<ModalProps, ModalState>
{
	private modalService = new ModalService();

	constructor(props: any)
	{
		super(props);

		// Config
		this.modalService.setProps(this.props);
		this.modalService.subscribeState(this);

		// State
		this.state = this.modalService.getState();
	}

	componentDidUpdate(prevProps: ModalProps, prevState: ModalState): void
	{
		if (this.state.modalStack.length > prevState.modalStack.length) {
			const newModal = this.state.modalStack[this.state.modalStack.length - 1];
			if (newModal.modalRef && newModal.modalRef.current) {
				window.addEventListener('click', this.modalService.handleClickOutside);
			}
		} else if (this.state.modalStack.length < prevState.modalStack.length) {
			if (prevState.modalStack.length > 0) {
				const oldModal = prevState.modalStack[prevState.modalStack.length - 1];
				if (oldModal.modalRef && oldModal.modalRef.current) {
					window.removeEventListener('click', this.modalService.handleClickOutside);
				}
			}
		}
	}

	render(): ReactElement
	{
		return (
			<ModalContext.Provider value={ {
				isLoadingFull: this.modalService.isLoadingUpdated,
				isLoadingOverlay: this.modalService.isLoadingOverlay.bind(this),
				actions: this.modalService.modalActions,
				content: (title: string | null, content: ReactElement, isStack: boolean = false) =>
				{
					if (!isStack) {
						this.modalService.clearModals();
					}
					this.modalService.modalContent(title, content);
				},
				isOpen: this.modalService.isOpenModal,
				errorMessage: this.modalService.handleErrorMessage,
			} }>
				{ this.state.modalStack.map((modal, index) =>
					<div key={ index } style={ ModalStyles.backdrop(modal.isOpen) }>
						<div ref={ modal.modalRef } style={ ModalStyles.container(modal.isOpen) }>
							{ this.state.isLoadingOverlay && (
								<div style={ ModalStyles.overlay }>
									<ClipLoader
										color={ colors.gray400 }
										loading={ true }
										size={ 50 }
										aria-label="Loading Overlay"
										data-testid="loader-overlay"
									/>
								</div>
							) }


							{/* If is loading */ }
							{ modal.isLoading ? (
								<div style={ ModalStyles.loading }>
									<ClipLoader
										color={ colors.gray400 }
										loading={ true }
										size={ 24 }
										aria-label="Loading Spinner"
										data-testid="loader"
									/>
								</div>
							) : (
								<>
									{/* Modal Header */ }
									<div style={ ModalStyles.header(modal.title!) }>
										<div style={ ModalStyles.headerContainer }>
											{ modal.title && <div style={ FontStyles.h4 }> { modal.title }</div> }
										</div>
										<div>
											{/* Modal Action */ }
											{ modal.actions &&
												modal.actions.map((item) => (
													<ButtonOld
														key={ item.label }
														type={ item.buttonType }
														iconLeft={ item.icons }
														onClick={ (event?: any) =>
														{
															if (typeof item.action === 'function') {
																item.action(event);
															} else {
																return item.action;
															}
														} }
													>
														{ item.label }
													</ButtonOld>
												)) }
											<Button
												variant={ 'close' }
												iconName={ 'LuX' }
												onClick={ () =>
												{
													const modalRefToRemove = modal.modalRef;
													this.setState((prevState) => ({
														modalStack: prevState.modalStack.filter(
															(modal) => modal.modalRef !== modalRefToRemove
														),
													}));
												} }
											/>
										</div>
									</div>

									{/* Check is error */ }
									{ modal.errorMessage && (
										<div style={ ModalStyle.errorMessage() }>
											<div
												style={ {
													padding: '5px 20px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
												} }
											>
												{ modal.errorMessage }
												<Button
													variant={ 'inline' }
													iconName={ 'LuX' }
													onClick={ (event: any) =>
													{
														event.stopPropagation();
														this.setState((prevState) =>
														{
															const modalStack = [...prevState.modalStack];
															const lastModal = modalStack[modalStack.length - 1];
															if (lastModal) {
																lastModal.errorMessage = null;
															}
															return { modalStack };
														});
													} }
												/>
											</div>
										</div>
									) }

									{/* Modal content */ }
									{ modal.content }
								</>
							) }
						</div>
					</div>
				) }

				{ this.props.children }
			</ModalContext.Provider>
		);
	}
}



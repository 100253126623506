import { GlobalsStyles } from '@/Modules/App/Style/Globals/Globals.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { CSSProperties } from 'react';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const LayoutStyles = {
	container: {
		background: themeColors.background,
		height: '100%',
		width: '100%'
	},
	topBar: {
		background: colors.blueRibbon50,
		width: '100%',
		height: 50,
		borderBottom: borderStyles.border,
		borderColor: colors.gray200
	},
	topBarContainer: {
		background: colors.white,
		display: 'flex',
		width: '100%',
		height: '100%',
		paddingRight: 25,
		paddingLeft: 25,
		colors: themeColors.textPrimary
	},
	topBarLogoContainer: {
		width: 240,
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		cursor: 'pointer'
	},
	logo: {
		width: 100,
	},
	topBarMenu: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	topBarNav: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		gap: 20,
		...FontStyles.text
	},
	topBarSwitcher: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer'
	} as CSSProperties,
	topBarIcon: {
		cursor: 'pointer',
		fontSize: 18,
		color: themeColors.textPrimary,
	} as CSSProperties,
	mainContainer: {
		width: '100vw',
		display: 'flex',
		height: 'calc(100% - 50px)'
	} as CSSProperties,
	// > LAYOUT DEV STYLES
	devContainer: {
		background: themeColors.darkBackground,
		height: '100%',
		width: '100%'
	} as CSSProperties,
	devTopBar: {
		background: themeColors.darkBackground,
		width: '100%',
		height: 50,
		borderBottom: borderStyles.border,
		borderColor: colors.gray200
	} as CSSProperties,
	devTopBarContainer: {
		background: themeColors.darkBackground,
		display: 'flex',
		width: '100%',
		height: '100%',
		paddingRight: 25,
		paddingLeft: 25,
		colors: colors.white
	} as CSSProperties,
	devTopBarIcon: {
		cursor: 'pointer',
		fontSize: 18,
		color: colors.white,
	} as CSSProperties,
	tooltip: {
		position: 'absolute',
		bottom: '45px',
		right: '-4px',
		width: '300px',
		backgroundColor: 'white',
		color: '#333',
		padding: '10px 15px',
		borderRadius: '10px',
		boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
		zIndex: 1000,
		animation: 'fadeIn 0.3s ease-in-out',
	},
	arrow: {
		position: 'absolute',
		bottom: '-10px',
		right: '10px',
		width: '0',
		height: '0',
		borderLeft: '10px solid transparent',
		borderRight: '10px solid transparent',
		borderTop: '10px solid white',
	},
	content: {
		fontSize: '14px',
		lineHeight: '1.5',
	},
	title: {
		fontSize: '16px',
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '5px',
	},
	closeButton: {
		marginTop: '10px',
		padding: '5px 10px',
		border: 'none',
		backgroundColor: '#007BFF',
		color: 'white',
		borderRadius: '5px',
		cursor: 'pointer',
		width: '100%',
	},
	...GlobalsStyles,
} as const;
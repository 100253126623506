import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiLogDeveloperService } from '@/Service/Developer/ApiLogDeveloperService';

export class DashboardDeveloperService extends BaseComponentService<any, { logs: any, isLoading: boolean, lastUpdated: Date|null }>
{
  apiLogService: ApiLogDeveloperService = new ApiLogDeveloperService();

  constructor()
  {
    super({} as any, { logs: [], isLoading: true, lastUpdated: null });
  }

  async init(): Promise<void>
  {
    await this.refreshLogs();
  }

  async refreshLogs(): Promise<void>
  {
    try {
      let response = await this.apiLogService.get();
      const logs = response.logs ?? [];

      this.setState({ logs, isLoading: false });
    } catch (error) {
      console.error("Failed to fetch logs:", error);
      this.setState({ logs: [], isLoading: false });
    }
  }

  async clearLogs(): Promise<void>
  {
    try {
      let response = await this.apiLogService.clear();
      const logs = response.logs ?? [];

      this.setState({ logs, isLoading: false });
    } catch (error) {
      console.error("Failed to fetch logs:", error);
      this.setState({ logs: [], isLoading: false });
    }
  }

}
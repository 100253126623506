import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticeDocumentsProps, LegalNoticeDocumentsState } from './LegalNoticeDocuments.interface';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { HttpStatusCodeEnum } from '@/Enum/HttpStatusCode';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { ModalContextType } from '@/Provider/ModalProvider';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { ApiLegalNoticeFileService } from '@/Service/Api/ApiLegalNoticeFileService';
import { openPdfFile } from '@/Utils/FileUtils';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import PublishStateEnum from '@/Enum/PublishStateEnum';

const initState: LegalNoticeDocumentsState = {
	sendBackSendTo: {},
	isSendMailLoading: false,
	progressBar: 0,
	legalNoticeFiles: [],
	isLoading: false,
	isWaitingDocuments: {
		QUOTE: false,
		CERTIFICATE: false,
		INVOICE: false,
		CREDIT_NOTE: false,
		ESTIMATE: false,
	},
};

export class LegalNoticeDocumentsService extends BaseComponentService<LegalNoticeDocumentsProps, LegalNoticeDocumentsState>
{
	service : ApiLegalNoticeFileService|ApiAdminLegalNoticeFileService;
	private apiAdminLegalNoticeFileService= new ApiAdminLegalNoticeFileService();
	private apiLegalNoticeFileService= new ApiLegalNoticeFileService();
	private apiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	private pollingInterval: number = 3000;
	private maxPollingAttempts: number = 3;

	constructor()
	{
		super({} as LegalNoticeDocumentsProps, initState);

		// Service
		this.service = this.getFileService();
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(props: LegalNoticeDocumentsProps): Promise<void>
	{
		this.setLegalNoticeFiles();
		this.setState({ sendBackSendTo: this.props.legalNotice.sendTo });

		await Promise.all([
			this.startDocumentPolling(props.legalNotice, 'QUOTE'),
			this.startDocumentPolling(props.legalNotice, 'CERTIFICATE'),
			this.startDocumentPolling(props.legalNotice, 'INVOICE'),
			this.startDocumentPolling(props.legalNotice, 'CREDIT_NOTE'),
		]);
	}

	/**
	 * Set LegalNotice Files
	 */
	setLegalNoticeFiles(): void
	{
		this.setState({
			legalNoticeFiles: this.props.legalNotice.legalNoticeFiles
		});
	}

	async getFile(file: any): Promise<void>
	{
		try {
			const baseUrl = process.env.REACT_APP_API_URL || '';
			openPdfFile(baseUrl, this.props.legalNotice.id, file, Boolean(file.extSellsyId || (this.props.legalNotice.extSupplierUid !== null && this.props.legalNotice.extSupplierUid.source === 'REGIEPRO')));
		} catch (error) {
			console.error('Error opening file:', error);
		}
	}

	async refreshFiles(legalNotice: LegalNoticeInterface): Promise<void>
	{
		try {
			const legalNoticeFiles = await this.service.getFiles(legalNotice?.id);
			this.setState({ legalNoticeFiles });
		} catch (error: any)
		{
			console.error(error);
		}
	}

	/**
	 * Handle Sendback email
	 * @param sendToData
	 */
	onSelectionMail(sendToData: any): void
	{
		this.setState({ sendBackSendTo: sendToData });
	}

	/**
	 * Send Back email from API
	 * @param event
	 * @param data
	 * @param modalContext
	 */
	async sendBackEmailApi(event: any, data: { label: string, value: string }, modalContext: ModalContextType, sendToData: any): Promise<any>
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState({isLoading: true });
		modalContext.isLoadingOverlay(true);

		try {
			this.setState({ isLoading: true, isSendMailLoading: true }, async () =>
			{
				let progressProcess: number = 0;
				const progressInterval = setInterval(() =>
				{
					if (progressProcess < 95) {
						progressProcess += 5;
						this.setState({ progressBar: progressProcess });
					}
				}, 1000);

				try {
					const sendBackEmailCallApi = await this.apiAdminLegalNoticeService.sendBackEmail(
						this.props.legalNotice.id,
						{
							type: data.value,
							sendTo: sendToData
						}
					);

					clearInterval(progressInterval);
					this.setState({ progressBar: 100 });
					modalContext.isOpen(false);

					if (sendBackEmailCallApi.statusCode === HttpStatusCodeEnum.HTTP_OK) {
						this.props.flashMessageContext?.flashMessage(sendBackEmailCallApi.message);
					}
				} catch (error) {
					console.error('Error sending email:', error);
				} finally {
					this.setState({ isLoading: false, isSendMailLoading: false, progressBar: 0 });
				}
			});
		} catch (error) {
			console.error(`An error occurred on ${this.constructor.name}:`, error);
		}
	}

	/**
	 * Start polling to check if Quote file is Ready
	 */
	async startDocumentPolling(
		legalNotice: LegalNoticeInterface,
		documentType: string,
		isPollNeeded: boolean = false
	): Promise<void>
	{
		if (!isPollNeeded) {
			if (String(legalNotice.status) === PublishStateEnum.DRAFT.value || String(legalNotice.quoteStatus) === QuoteStatusEnum.REFUSED.value) {
				return;
			}

			if (String(legalNotice.quoteStatus) === QuoteStatusEnum.PENDING.value && documentType !== 'QUOTE') {
				return;
			}

			if ( String(legalNotice.status) === PublishStateEnum.PLANNED.value && documentType === 'QUOTE') {
				return;
			}

			if(documentType === 'CREDIT_NOTE'
				&& String(legalNotice.status) === PublishStateEnum.PLANNED.value
				&& legalNotice.discount === 0 ) {
				return;
			}

			if (
				legalNotice.legalNoticeFiles &&
				legalNotice.legalNoticeFiles.some((file: any) => file.type === documentType && file.file)
			) {
				return;
			}

			this.setState((prevState) => ({
				isWaitingDocuments: {
					...prevState.isWaitingDocuments,
					[documentType]: true,
				},
			}));
		}

		let attempts: number = 0;

		const poll = async () => {
			attempts++;

			const isReady: boolean = await this.getDocumentStatus(legalNotice, documentType);

			if (isReady) {
				this.setState((prevState) => ({
					isWaitingDocuments: {
						...prevState.isWaitingDocuments,
						[documentType]: false,
					},
				}));

				this.refreshFiles(legalNotice);

				return;
			}

			if (attempts < this.maxPollingAttempts) {
				setTimeout(poll, this.pollingInterval);
			} else {
				this.setState((prevState) => ({
					isWaitingDocuments: {
						...prevState.isWaitingDocuments,
						[documentType]: false,
					},
				}));
			}
		};

		poll();
	}


	/**
	 * Check if File is Ready
	 */
	async getDocumentStatus(
		legalNotice: LegalNoticeInterface,
		documentType: string
	): Promise<boolean> {
		try {
			const documentStatus = await this.service.getFileStatus(
				legalNotice.id,
				documentType
			);

			if (documentStatus.status === 'READY') {
				return true;
			} else if (documentStatus.status === 'NOT_FOUND') {
				return false;
			} else {
				console.warn('Statut inattendu reçu :', documentStatus);
				return false;
			}
		} catch (error: any) {
			console.error(`Erreur lors de la récupération du statut de ${documentType} :`, error.message);
			return false;
		}
	}


	/**
	 *
	 * @param responsePayment
	 */
	handleCreatePayment(responsePayment: any): void
	{}

	/**
	 * Dispatch service depending on User role (Sellsy or not)
	 * @private
	 */
	private getFileService(): ApiAdminLegalNoticeFileService | ApiLegalNoticeFileService {
		return UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()
			? this.apiAdminLegalNoticeFileService
			: this.apiLegalNoticeFileService;
	}

}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAdminSupplierService } from '@/Service/Admin/ApiAdminSupplierService';
import { SupplierInterface } from '@/Modules/Interface/SupplierInterface';
import {
  AppConfigAdminSupplierProps,
  AppConfigAdminSupplierState
} from '@/Modules/Config/Admin/Component/Supplier/ConfigAdminSupplier.interface';

const initState: AppConfigAdminSupplierState = {
  isLoading: false,
  isEdit: false,
  supplierList: [],
  selectedSupplier: null
}

export class ConfigAdminSupplierService extends BaseComponentService<AppConfigAdminSupplierProps, AppConfigAdminSupplierState>
{
  private apiSupplierService: ApiAdminSupplierService

  constructor()
  {
    super({} as AppConfigAdminSupplierProps, initState);

    // Service
    this.apiSupplierService = new ApiAdminSupplierService();

    // Bind
    this.onSelectedSupplier = this.onSelectedSupplier.bind(this);
  }

  async init(): Promise<void>
  {
    this.setState({ isLoading: true });
    const supplierList = await this.apiSupplierService.list();
    this.setState({ supplierList, selectedSupplier: this.props.company.supplier, isLoading: false });
  }

  async onSelectedSupplier(supplier: SupplierInterface): Promise<void>
  {
    this.setState({ selectedSupplier: supplier, isEdit: true });
  }

  async edit(state: AppConfigAdminSupplierState): Promise<void>
  {
    await this.apiSupplierService.edit(this.props.company.id, state.selectedSupplier?.id as number);
    this.setState({ isEdit: false }, async () => { await this.init() });
    await this.init();
    this.props.onEdit();
  }
}
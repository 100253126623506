import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	createDefaultUserForm,
	CreateUserFormProps,
	CreateUserFormState
} from './CreateUserForm.interface';
import React from 'react';
import { isValidFrenchPhoneNumber } from '@/Utils/PhoneNumberUtils';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
const initState: CreateUserFormState = {
	formData: createDefaultUserForm(),
	errorMessage: '',
	isFormValid: false,
	isLoading: false,
};

export class CreateUserFormService extends BaseComponentService<CreateUserFormProps, CreateUserFormState>
{
	constructor()
	{
		super({} as CreateUserFormProps, initState);

		// Bind
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleSelectCivility = this.handleSelectCivility.bind(this);
		this.handleSelectUserRole = this.handleSelectUserRole.bind(this);
		this.validateForm = this.validateForm.bind(this);

	}

	init(): void {
		const userRole = UserConnectedService.isSuperAdmin()
			? ''
			: UserConnectedService.isAdmin()
				? 'ROLE_USER'
				: 'ROLE_USER';

		this.setState((prevState) => ({
			...prevState,
			formData: {
				...prevState.formData,
				user: {
					...prevState.formData.user,
					role: userRole,
				},
			},
		}));
	}

	/**
	 * Validate the form before submission
	 * @return boolean - Returns true if the form is valid
	 */
	validateForm(state: CreateUserFormState): boolean
	{
		let errorMessage: string = '';
		const { user } = state.formData;
		// Required Field
		if (!user.civility) {
			errorMessage = 'La civilité est requise.';
		} else if (!user.lastname) {
			errorMessage = 'Le nom est requis.';
		} else if (!user.firstname) {
			errorMessage = 'Le prénom est requis.';
		} else if (user.phone && !isValidFrenchPhoneNumber(user.phone)) {
			errorMessage = 'Le numéro de téléphone est invalide.';
		} else if (!user.role) {
			errorMessage = 'Le rôle est requis.';
		} else if (!user.email) {
			errorMessage = 'L\'adresse email est requise.';
		}

		if (errorMessage) {
			this.setState({ errorMessage });
			return false;
		}

		this.setState({ errorMessage: '' });
		return true;
	}

	/**
	 * Handle form submission
	 * @param event
	 * @param state - The current state of the form
	 * @return Promise<any>
	 */
	async handleFormSubmit(event: React.FormEvent<HTMLFormElement>, state: CreateUserFormState): Promise<any>
	{
		event.preventDefault();
		event.stopPropagation();

		// Validate the form before submission
		if (!this.validateForm(state)) {
			this.setState({ formData: state.formData });
			return;
		}

		this.setState({ isLoading: true },
			() => this.props.getComponentState?.(this.state)
		);

		// If form is Validated
		this.setState({ formData: state.formData, errorMessage: '' },
			async () =>
			{
				this.props.onFormSubmit(this.state.formData.user);
				this.setState({ isLoading: false }, () => this.props.getComponentState?.(this.state));
			}
		);
	}

	/**
	 * Handle Selected value = Civility
	 * @param civility
	 * @param state - The current state of the form
	 */
	handleSelectCivility(civility: string, state: CreateUserFormState): void
	{
		this.setState({
			formData: {
				...state.formData,
				user: {
					...state.formData.user,
					civility,
				}
			}
		});
	}

	/**
	 * Handle Selected value = UserRole
	 * @param role
	 * @param state - The current state of the form
	 */
	handleSelectUserRole(role: string, state: CreateUserFormState): void
	{
		this.setState({
			formData: {
				...state.formData,
				user: {
					...state.formData.user,
					role,
				}
			}
		});
	}
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { AppConfigAdminProps, AppConfigAdminState } from '@/Modules/Config/Admin/AppConfigAdmin/AppConfigAdmin.interface';

const initState: AppConfigAdminState = {
  activeTab: 'suppliers'
}

export class AppConfigAdminService extends BaseComponentService<AppConfigAdminProps, AppConfigAdminState>
{
  constructor()
  {
    super({} as AppConfigAdminProps, initState);
  }

  async init(): Promise<void>
  {}

  /**
   * Change active Tab
   * @param tabName name of tab
   */
  setActiveTab(tabName: string): void
  {
    this.setState({ activeTab: tabName });
  }
}
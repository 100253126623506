import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import MultiEmailInput from '@/Modules/App/Components/Library/SelectMultiple/MultiEmailInput';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
	selectedClient: ClientInterface | null,
	sendTo: {
		[key: string]: string[];
	},
	stateUpdater?: Partial<ComponentState>
	onSelections: (selections: SendToSelections) => void,
	fieldsToShow?: string[]
}

interface ComponentState
{
	selections: SendToSelections,
	isEdit: boolean,
	isLoading: boolean,
}

export interface SendToSelections
{
	[key: string]: string[] | string | undefined,

	invoice?: string[],
	certificate?: string[],
	receipt?: string[],
	credit?: string[],
}

export default class LegalNoticeSendToFormComponent extends React.Component<ComponentProps, ComponentState>
{
	state = {
		isLoading: false,
		selections: {} as SendToSelections,
		isEdit: this.props.stateUpdater?.isEdit!,
	};

	constructor(props: any)
	{
		super(props);

		// Bind
		this.initSelectionsProps = this.initSelectionsProps.bind(this);
		this.onChangeSelectTag = this.onChangeSelectTag.bind(this);
	}

	componentDidMount(): void
	{
		this.setState({ isLoading: true });
		if (this.state.isEdit) {
			this.setState({ selections: this.props.stateUpdater?.selections! }, () => this.setState({ isLoading: false }));
		} else if (
			!this.state.isEdit
			&& this.props.selectedClient?.options
			&& this.props.selectedClient?.options?.sendTo
		) {
			this.initSelectionsProps();
		}
	}

	componentDidUpdate(prevProps: Readonly<{ selectedClient: ClientInterface | null }>, prevState: Readonly<{
		selections: SendToSelections
	}>, snapshot?: any): void
	{
		if (this.isAllSelected() && prevState.selections !== this.state.selections) {
			this.props.onSelections(this.state.selections);
		}
	}

	render(): ReactElement
	{
		const fieldsToShow = this.props.fieldsToShow || ['invoice', 'certificate', 'certificate-web', 'receipt', 'credit'];

		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<div style={ { display: 'flex', flexDirection: 'column', gap: 10 } }>
				{ fieldsToShow.includes('invoice') && this.buildBillingStepContent('Facture / Devis', 'invoice') }
				{ fieldsToShow.includes('certificate') && this.buildBillingStepContent('Attestation', 'certificate') }
				{ fieldsToShow.includes('certificate-web') && this.buildBillingStepContent('Certificat numérique', 'certificate-web') }
				{ fieldsToShow.includes('receipt') && this.buildBillingStepContent('Justificatif', 'receipt') }
				{ fieldsToShow.includes('credit') && this.buildBillingStepContent('Avoir', 'credit') }
			</div>
		);
	}

	private buildBillingStepContent(title: string, billingStep: string): ReactElement
	{
		return (
			<>
				<div style={ { display: 'grid', gridTemplateColumns: '180px auto', alignItems: 'center' } }>
					<div style={ {
						marginBottom: '2px',
						fontWeight: 600,
						color: CssVariableEnum['--color-grey-900']
					} }>
						{ title }:
					</div>
					<MultiEmailInput
						collaboratorList={ this.props.selectedClient?.collaborators || [] }
						initialTags={ this.state.selections[billingStep] as string[] }
						onChangeTag={ (event: any, selectedMails: string[]) => this.onChangeSelectTag(event, selectedMails, billingStep) }
					/>
				</div>
			</>
		);
	}

	private onChangeSelectTag(event: any, selectedMails: string[], billingStep: string): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState(prevState => ({
			selections: {
				...prevState.selections,
				[billingStep]: selectedMails
			}
		}), () =>
		{
			this.props.onSelections(this.state.selections);
		});
	}

	private isAllSelected(): boolean
	{
		const requiredSteps: string[] = ['invoice', 'certificate', 'receipt', 'credit'];
		return requiredSteps.every(billingStep =>
		{
			const selection = this.state.selections[billingStep];
			return Array.isArray(selection) && selection.length > 0;
		});
	}

	private initSelectionsProps(): void
	{
		this.setState({ selections: this.props.sendTo },
			() =>
			{
				this.props.onSelections(this.state.selections);
				this.setState({ isLoading: false });
			});
	}
}
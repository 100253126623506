import React, { ReactElement } from 'react';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { LegalNoticeContentHeader } from '@/Modules/LegalNotice/Style/LegalNoticeContentHeader';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { Alert } from 'react-bootstrap';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LuPlus, LuXCircle } from 'react-icons/lu';
import { ModalContextType } from '@/Provider/ModalProvider';
import AddCustomLogoClientFormComponent
	from '@/Modules/Client/Components/ClientLogos/Create/Form/AddCustomLogoClientFormComponent';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

interface ComponentProps
{
	modalContext: ModalContextType,
	flashMessageContext: FlashMessageContextType,
	isConsumerFormFilled: boolean,
	selectedClient: ClientInterface | null,
	isHeaderOptionDisplay: boolean, // If category not need siren then hide this checkbox
	isLogoOptionDisplay: boolean,
	isHeader: boolean,
	isLogo: boolean,
	onCheckIsHeader: (event: React.ChangeEvent<HTMLInputElement>) => void
	onCheckIsLogo: (isChecked: boolean) => void
	onSelectedLogo: (logo: any) => void
}

interface ComponentState
{
	logos: any[],
	isLoading: boolean
}

export default class HeaderOptionContentComponent extends React.Component<ComponentProps, ComponentState>
{
	apiClientService: ApiClientService | ApiAdminClientService;

	constructor(props: ComponentProps)
	{
		super(props);

		// Service
		this.apiClientService = UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()
			? new ApiAdminClientService()
			: new ApiClientService();


		this.state = {
			logos: props.selectedClient?.logos || [],
			isLoading: false,
		};
	}

	render(): ReactElement
	{
		return (
			<>
				<div>
					<div style={ LegalNoticeContentHeader.mainContainerBodyStyle() }>
						<div style={ { ...FontStyles.h3, fontWeight: 700 } }>Option d'annonce</div>
					</div>
					{ (!this.props.isConsumerFormFilled || !this.props.selectedClient)
						? this.renderAlert()
						: this.renderOptions()
					}
				</div>
			</>
		);
	}

	//<editor-fold desc="State methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.refreshLogos();
	}

	componentDidUpdate(prevProps: ComponentProps): void
	{
		if (prevProps.selectedClient !== this.props.selectedClient && this.props.selectedClient) {
			this.refreshLogos();
		}
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private renderAlert(): ReactElement
	{
		return (
			<>
				{ (!this.props.isConsumerFormFilled || !this.props.selectedClient) &&
          <Alert style={ { fontSize: '12px', padding: '5px', margin: 0 } } variant={ 'warning' }>
            Pour accéder aux options, merci de remplir les sélections suivantes : <br/>
						{ (!this.props.selectedClient)
							? <span> • Client </span>
							: ''
						}
						{ (!this.props.isConsumerFormFilled)
							? <span> • Société (client final) </span>
							: ''
						}
          </Alert>
				}
			</>
		);
	}

	private renderOptions(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					justifyContent: 'space-between',
					position: 'relative',
					backgroundColor: colors.white,
					...borderStyles,
					borderColor: colors.gray300,
					borderRadius: 6,
					padding: 10
				} }>
					<div className={ this.props.isConsumerFormFilled ? '' : 'disabled-item' }>
						{ (this.props.isHeaderOptionDisplay) &&
              <Checkbox
                label="Entête d'annonce ?"
                name="isHeader"
                isChecked={ this.props.isHeader }
                onCheckedChange={ (event: any) => this.props.onCheckIsHeader(event) }
              />
						}
					</div>
					{ this.props.isLogoOptionDisplay && !this.props.isLogo ? (
						<div>
							<Checkbox
								label="Voulez-vous mettre le logo ?"
								name="isLogo"
								isChecked={this.props.isLogo}
								onCheckedChange={() => this.props.onCheckIsLogo(true)}
							/>
						</div>
					) : this.handleDisplayLogo()}
				</div>
			</>
		);
	}

	private handleDisplayLogo(): ReactElement | null
	{
		if (!this.props.isLogo) {
			return null;
		}

		const { selectedClient } = this.props;

		if (!selectedClient) {
			return null;
		}

		const options = [
			...(this.state.logos || []),
			{ name: 'Créer un logo', id: 'create-logo' },
		];

		return (
			<div
				style={ {
					display: 'flex',
					alignItems: 'center',
					width: '50%',
					justifyContent: 'flex-end',
					gap: 5,
				} }
			>
				<SelectComponentOld
					buttonWidth={ 200 }
					listOptions={ options }
					onSelectedOption={ (event: any) =>
					{
						if (event.id === 'create-logo') {
							this.handleModalCreateLogo();
						} else {
							this.props.onSelectedLogo(event);
						}
					} }
					renderOptionLabel={ (option) =>
					{
						if (option.id === 'create-logo') {
							return (
								<div style={ { display: 'flex', alignItems: 'center', color: CssVariableEnum['--color-blue-400'] } }>
									<LuPlus style={ { marginRight: 5 } }/>
									<span>{ option.name }</span>
								</div>
							);
						}
						return option.name;
					} }
				/>
				<ButtonOld
					iconLeft={ <LuXCircle/> }
					type={ 'danger' }
					onClick={ () => this.props.onCheckIsLogo(false) }
				/>
			</div>
		);
	}

	private handleModalCreateLogo(): void
	{
		this.props.modalContext.content(
			'Ajouter un logo',
			// TODO : Refacto this component
			<AddCustomLogoClientFormComponent
				client={ this.props.selectedClient as ClientInterface }
				modalContext={ this.props.modalContext }
				flashMessageContext={ this.props.flashMessageContext }
				onCreateLogo={ this.onCreateLogo.bind(this) }
			/>
		);
	}

	private async onCreateLogo(logoData: any): Promise<void>
	{
		if (this.props.selectedClient) {
			this.setState({ isLoading: true });
			const response = await this.apiClientService.addLogo(logoData, this.props.selectedClient?.id);

			if (!response.errorMessage) {
				console.log(response.errorMessage);
			}

			if (response) {
				this.setState({ isLoading: false });
				await this.refreshLogos();
			}
		}
	}

	private async refreshLogos(): Promise<void>
	{
		if (this.props.selectedClient) {
			const updatedClient = await this.apiClientService.show(this.props.selectedClient.id);
			if (updatedClient) {
				this.setState({ logos: updatedClient.logos });
			}
		}
	}

	//</editor-fold>
}

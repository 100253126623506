import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticePriceRenderProps, LegalNoticePriceRenderState } from './LegalNoticePriceRender.interface';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import {
	LegalNoticePriceDetailsService
} from '@/Modules/LegalNotice/ComponentsOld/LegalNoticePriceDetails/LegalNoticePriceDetails.service';

const initState: LegalNoticePriceRenderState = {
	isFixedPrice: false,
	numberOfCharacters: 0,
	fixedPrice: null,
	characterPrice: null,
	totalPrice: 0,
};

export class LegalNoticePriceRenderService extends BaseComponentService<LegalNoticePriceRenderProps, LegalNoticePriceRenderState>
{
	private legalNoticePriceService: LegalNoticePriceService = new LegalNoticePriceService(true);
	private legalNoticePriceDetailsService: LegalNoticePriceDetailsService = new LegalNoticePriceDetailsService();

	constructor()
	{
		super({} as LegalNoticePriceRenderProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props?.department! && this.props?.childCategory!){
			this.setIsFixedPrice();
			this.calculTotalCharacterPrice(this.state.numberOfCharacters);
		}
	}

	/**
	 * Set isFixed price
	 */
	setIsFixedPrice(): void
	{
		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(this.props?.department!, this.props?.childCategory!);

		this.setState({
			isFixedPrice,
		}, () => this.getPrice(this.state.isFixedPrice));
	}

	/**
	 * Get price from FormBuilder Category
	 */
	async getPrice(isFixedPrice: boolean): Promise<void>
	{
		try {

			const price: number = this.legalNoticePriceService.getAnnexPrice(
				this.props.department,
				this.props.childCategory,
				isFixedPrice
			);

			if (isFixedPrice) {
				this.setFixedPriceState(price);
			} else {
				this.setCharacterPriceState(price);
			}

		} catch (error: any) {
			console.error(`Error occurred on getPrice ${ this.constructor.name }:`, error);
		}
	}

	/**
	 * Set Fixed Price State
	 * @param fixedPrice
	 */
	setFixedPriceState(fixedPrice: number | null): void
	{
		this.setState({
			fixedPrice,
		});
	}

	/**
	 * Set Character Price State
	 * @param characterPrice
	 */
	setCharacterPriceState(characterPrice: number | null): void
	{
		this.setState({
			characterPrice,
		});
	}

	/**
	 * Calcul total price if Characters
	 * @param charCount
	 * @private
	 */
	private calculTotalCharacterPrice(charCount: number): number
	{
		let updatedCharCount = charCount;

		if (this.props.legalNotice.content) {
			updatedCharCount += this.props.charCount;
		}

		if (this.props.legalNotice.signature) {
			const signaturePlainText = this.props.legalNotice.signature.replace(/<\/?[^>]+(>|$)/g, '').trim();
			updatedCharCount += signaturePlainText.length;
		}

		if (this.props.legalNotice.title) {
			const titlePlainText = this.props.legalNotice.title.replace(/<\/?[^>]+(>|$)/g, '').trim();
			updatedCharCount += titlePlainText.length;
		}

		if (this.props.consumer) {
			updatedCharCount += this.countTempHeaderContent(this.props.consumer);
		}

		if (this.state.characterPrice) {
			this.setState({
				numberOfCharacters: updatedCharCount,
				totalPrice: this.state.characterPrice * updatedCharCount
			});
		}

		return updatedCharCount;
	}

	private countTempHeaderContent(consumerData: any): number
	{
		const headerContent = `
    ${ consumerData.name }
    ${ consumerData.legalStatus }
    au capital de ${ consumerData.capital }
    siège social: ${ consumerData.address.zipCode } ${ consumerData.address.city }
    ${ consumerData.address.number }, ${ consumerData.address.street }
    ${ consumerData.siren } RCS ${ consumerData.rcs }
  `;

		const strippedHeaderContent = headerContent
			.trim()
			.replace(/\s+/g, ' ')
			.replace(/, /g, ',')
			.replace(/: /g, ':')
			.replace(/\s{2,}/g, ' ')
		;

		return strippedHeaderContent.length;
	}

}
import React, { ReactElement } from 'react';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { ApiPappersService } from '@/Service/Pappers/ApiPappers.service';
import { ConsumerFormModel } from '@/Modules/LegalNotice/Components/Form/ConsumerForm/ConsumerForm.model';
import { AddressFormModel } from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm.model';
import Button from '@/Modules/App/Components/Library/Button/Button.';

interface ComponentProps
{
	sirenData: (sirenData: any) => void,
	sirenValue: string | null,
	isInseeApi: boolean,
	isDisabled?: boolean
}

interface ComponentState
{
	siren: string | null,
	errorMessage: string | null,
	isLoading: boolean
}

export default class SirenInputComponent extends React.Component<ComponentProps, ComponentState>
{
	appService: ApiAppService;
	private apiPappers: ApiPappersService = new ApiPappersService();

	state = {
		siren: this.props.sirenValue || null,
		errorMessage: null,
		isLoading: false
	};

	constructor(props: any)
	{
		super(props);

		// Service
		this.appService = new ApiAppService();

		// Bind
		this.callApiSiren = this.callApiSiren.bind(this);
		this.fillFormField = this.fillFormField.bind(this);
	}

	render(): ReactElement
	{
		const isValidSiren = this.state.siren && this.state.siren.length === 9;

		return (
			<>
				<div style={ { display: 'flex', gap: 10, alignItems: 'flex-end' } }>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						<Input
							type="text"
							label="Siren"
							name="siren"
							value={ this.state.siren || '' }
							onChange={(event) => {
								const cleanedValue = this.cleanSiren(event.target.value);
								this.setState({ siren: cleanedValue });
							}}
							allowedCharacters={ 'digits' }
							required={ true }
						/>
						{ this.state.isLoading && (
							<div style={ { marginLeft: '10px', marginTop: '6px' } }>
								<LoaderComponent variant={ 'primary' }/>
							</div>
						) }
					</div>

					<div>
						<Button
							label={ ' Auto ' }
							onClick={ async () => await this.callApiSiren(Number(this.state.siren!)) }
							disabled={ !isValidSiren }
						/>
					</div>
				</div>
				{ this.state.errorMessage && (
					<div className="error-message" role="alert">
						{ this.state.errorMessage }
					</div>
				) }
			</>
		);
	}

	/**
	 * Get Response API siren
	 * @param siren
	 */
	async callApiSiren(siren: number): Promise<void>
	{
		this.setState({ isLoading: true });

		try {
			// Call Api
			const responseSiren = await this.apiPappers.getCompanyBySiren(String(siren));

			// Check and return Errors
			if (responseSiren.statusCode === 404) {
				this.setState({
					isLoading: false,
					errorMessage: `${ responseSiren.error } (${ responseSiren.message })`
				});
				console.error('Erreur API SIREN:', responseSiren.message);
				return;
			}

			// Prepare Consumer Data
			const preparedConsumerData: ConsumerFormModel = ConsumerFormModel.prepare({
				name: responseSiren.nom_entreprise,
				apeCode: responseSiren.code_naf,
				siren: responseSiren.siren,
				capital: String(responseSiren.capital),
				rcs: responseSiren.greffe,
				legalStatus: responseSiren.forme_juridique,
				address: AddressFormModel.prepare({
					number: responseSiren.siege.numero_voie,
					street: `${ responseSiren.siege.type_voie } ${ responseSiren.siege.libelle_voie }`,
					additionalData: responseSiren.siege.complement_adresse,
					zipCode: responseSiren.siege.code_postal,
					city: responseSiren.siege.ville,
				})
			});

			this.setState(
				{ isLoading: false, errorMessage: null }, // Clear error message on success
				() => this.fillFormField(preparedConsumerData)
			);
		} catch (error: any) {
			// Return error
			console.error(`Erreur inattendue:`, error);
			this.setState({ isLoading: false, errorMessage: 'Une erreur inattendue est survenue.' });
		}
	}

	private fillFormField(responseSiren: ConsumerFormModel)
	{
		this.setState({ errorMessage: '' });

		this.props.sirenData(responseSiren);
	}

	private cleanSiren(value: string): string
	{
		return value.replace(/[\s.]/g, '');
	}
}
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';

export class LegalNoticeFormBuilderCategoryModel implements Partial<FormBuilderCategoryInterface>
{
	id: number = 0;
	label: string = '';
	type: string = '';
	categoryType: string = '';
	sortOrder: number = 0;
	childCategories: FormBuilderCategoryInterface[] = [];
	parent: FormBuilderCategoryInterface | null = null;
	createdAt: Date = new Date();
	updatedAt: Date = new Date();

	static prepare(data: Partial<FormBuilderCategoryInterface>): LegalNoticeFormBuilderCategoryModel
	{
		const model = new LegalNoticeFormBuilderCategoryModel();

		model.id = data.id!;
		model.label = data.label!;
		model.type = data.type!;
		model.categoryType = data.categoryType!;
		model.sortOrder = data.sortOrder!;
		model.parent = data.parent!;
		model.parent = data.parent!;

		// Convertir les sous-catégories en instances du modèle
		model.childCategories = Array.isArray(data.childCategories)
			? data.childCategories.map(child => LegalNoticeFormBuilderCategoryModel.prepare(child))
			: [];

		// Assurer que `createdAt` et `updatedAt` sont bien formatés
		model.createdAt = data.createdAt ? new Date(data.createdAt) : new Date();
		model.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date();

		return model;
	}
}
import React, { Component, ReactElement } from 'react';
import { ClientsDeveloperService } from './ClientsDeveloper.service';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { ApiClientDeveloperService } from '@/Service/Developer/ApiClientDeveloperService';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import ClientDeveloper from '@/Modules/Client/Developer/Show/ClientDeveloper';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import {
	ClientsDeveloperProps,
	ClientsDeveloperState
} from '@/Modules/Client/Developer/List/ClientsDeveloper.interface';

class ClientsDeveloper extends Component<ClientsDeveloperProps, ClientsDeveloperState>
{
	private clientService: ClientsDeveloperService = new ClientsDeveloperService();
	private apiClientService: ApiClientDeveloperService = new ApiClientDeveloperService();

	constructor(props: ClientsDeveloperProps)
	{
		super(props);

		// Config service
		this.clientService.setProps(this.props);
		this.clientService.subscribeState(this);

		// State & Props
		this.state = this.clientService.getState();

		// Document title
		document.title = 'Publisur - DEVELOPER - Clients';
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	componentWillUnmount(): void
	{
		this.clientService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Donneur d'ordre"
					icon={ null }
				/>

				<OffcanvaContext.Consumer>
					{ (offcanvaContext) => (
						<ListTableManager
							isTableControls={ true }
							tag={ 'client' }
							refreshList={ this.state.clientList }
							tableColumns={ this.clientService.buildColumnHeader() }
							filters={ this.clientService.buildFilters() }
							serviceList={ this.apiClientService.list.bind(this.apiClientService) }
							onRowClick={ (event: any, item: ClientInterface) => {
								event.stopPropagation();

								offcanvaContext?.content(
									null,
									<GlobalContextProvider>
										<ClientDeveloper
											client={ item }
											getComponentState={ () => this.clientService.refresh() }
										/>
									</GlobalContextProvider>
								);

								offcanvaContext?.isOpen(true);
							}}
						/>
					)}
				</OffcanvaContext.Consumer>
			</>
		);
	}
}

export default withGlobalContext(ClientsDeveloper);
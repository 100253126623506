import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const LegalNoticePriceDetailsStyles = {
	container: {} as CSSProperties,
	blockResume: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 15,
		gap: 4,
	} as CSSProperties,

	amountDue: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		fontWeight: 600,
		gap: 50
	} as CSSProperties,

	totalDueContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: 10,
		gap: 50
	} as CSSProperties,

	totalDue: {
		width: 300,
		display: 'flex',
		gap: 50,
		justifyContent: 'flex-end',
		borderTop: `1px solid ${ colors.gray300 }`,
		borderBottom: `1px solid ${ colors.gray300 }`,
		fontWeight: 600,
	} as CSSProperties,

	infoAmount: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		fontWeight: 400,
		gap: 50
	} as CSSProperties,
} as const;
import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiUserService extends BaseService {
  userUrl: string = `${ BaseService.API_URL }/v1/users`;

  async show(userId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.userUrl }/${ userId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}
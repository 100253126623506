import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const BillingPreferencesFormStyles = {
	container: (isDisplayBlock: boolean) => ({
		position: 'relative',
		zIndex: 24,
		backgroundColor: colors.gray50,
		...borderStyles,
		borderColor: colors.gray100,
		borderRadius: 6,
		padding: 20,
		visibility: isDisplayBlock ? 'visible' : 'hidden',
		flexDirection: 'column',
		gap: 10,
		opacity: isDisplayBlock ? 1 : 0,
		transform: isDisplayBlock ? 'translateY(0)' : 'translateY(20px)',
		transition: 'opacity 300ms ease, transform 300ms ease',
	}) as CSSProperties,

	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,
} as const;
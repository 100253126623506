import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  UpdateLegalNoticeContentProps, UpdateLegalNoticeContentState
} from '@/Modules/LegalNotice/Components/UpdateLegalNoticeContent/UpdateLegalNoticeContent.interface';
import { ContentState, convertFromRaw } from 'draft-js';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';

const initState: UpdateLegalNoticeContentState = {
  title: null,
  content: null,
  signature: null
};

export class UpdateLegalNoticeContentService extends BaseComponentService<UpdateLegalNoticeContentProps, UpdateLegalNoticeContentState>
{
  private timeoutId: NodeJS.Timeout | null = null;
  private ApiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();

  constructor()
  {
    super({} as UpdateLegalNoticeContentProps, initState);
  }

  /**
   * Initialize the service by setting the data
   * @return Promise<void>
   */
  async init(): Promise<void>
  {
    this.setState({
      title: this.props.legalNotice?.title,
      content: this.props.legalNotice.content,
      signature: this.props.legalNotice?.signature
    });
  }

  formatTextWithLineBreaks(text: string): string
  {
    let formattedText = text.replace(/\n\n/g, '</p><p>');
    formattedText = formattedText.replace(/\n/g, '<br/>');
    return formattedText;
  }

  onChangeContent(content: string): void
  {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Configure un nouveau timeout
    this.timeoutId = setTimeout(() =>
    {
      try {
        const contentState = convertFromRaw(JSON.parse(content));
        const htmlContent = this.formatHtmlLegalNoticeContent(contentState);

        this.setState({ content: htmlContent });
      } catch (error) {
        console.error('Invalid content format:', error);
      }
    }, 500);
  }

  async save(state: UpdateLegalNoticeContentState): Promise<void>
  {
    this.props.modalContext.isLoadingOverlay(true);
    await this.ApiAdminLegalNoticeService.editContent(this.props.legalNotice?.id, state)
      .then(
        () => {
          this.props.modalContext.isLoadingOverlay(false);
          this.props.modalContext.isOpen(false);
          this.props.callbackState!(state);
        }
      )
      .catch()
    ;
  }

  private formatHtmlLegalNoticeContent(content: ContentState): string
  {
    const blocks = content.getBlocksAsArray();
    let html = '';

    blocks.forEach((block: any) =>
    {
      let blockHTML = '';
      let lastIndex = 0;
      let text = block.getText();

      block.findStyleRanges(
        (character: any) => character.getStyle().has('BOLD'),
        (start: number, end: number) =>
        {
          blockHTML += text.slice(lastIndex, start);
          blockHTML += `<b>${ text.slice(start, end) }</b>`;
          lastIndex = end;
        }
      );

      blockHTML += text.slice(lastIndex);

      switch (block.getType()) {
        case 'header-one':
          html += `<h1>${ blockHTML }</h1>`;
          break;
        case 'header-two':
          html += `<h2>${ blockHTML }</h2>`;
          break;
        case 'unstyled':
        default:
          html += `<p>${ blockHTML }</p>`;
          break;
      }
    });

    return html;
  }
}
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  CommercialStatsAdminProps, CommercialStatsAdminState
} from '@/Modules/Commercial/Admin/Components/CommercialStatistical/List/CommercialStatsAdmin.interface';

const initState: CommercialStatsAdminState = {
  refreshKey: 0
}

export class CommercialStatsAdminService extends BaseComponentService<CommercialStatsAdminProps, CommercialStatsAdminState>
{
  constructor()
  {
    super({} as CommercialStatsAdminProps, initState);
  }

  async init(): Promise<void>
  {}
}
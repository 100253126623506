import AddressModel from '@/Modules/Client/Model/AddressModel';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export interface CreateClientFormInterface
{
}

export interface CreateClientFormProps
{
	getComponentState: (createClientFormState: CreateClientFormState, currentState?: any) => void
}

export interface CreateClientFormState
{
	isLoading: boolean,
	formData: ClientModel,
	showSiretInput: boolean,
	errorMessage: string,
}

export interface CreateClientFormSchema
{
	userId?: number,
	name: string,
	siren: string,
	siret?: string | null,
	apeCode: string,
	phone: string,
	address: AddressModel,
	emailCollaboratorOwner?: string | null,
	extSellsyId: string,
	isSellsyCreated: boolean
}

export function createDefaultClientForm(): CreateClientFormSchema
{
	return {
		userId: 0,
		name: '',
		siren: '',
		siret: '',
		apeCode: '',
		phone: '',
		address: new AddressModel(),
		emailCollaboratorOwner: '',
		extSellsyId: '',
		isSellsyCreated: false,
	};
}

export function resetForm(): any
{
	return {
		name: '',
		siren: '',
		apeCode: '',
		phone: '',
		address: new AddressModel(),
		emailCollaboratorOwner: ''
	};
}

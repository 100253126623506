import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientsLogosProps, ClientsLogosState } from './ClientsLogos.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

const initState: ClientsLogosState = {
	isLoading: true,
	list: {} as PaginateInterface,
};

export class ClientsLogosService extends BaseComponentService<ClientsLogosProps, ClientsLogosState>
{
	apiClientService: ApiAdminClientService | ApiClientService = (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin())
		? new ApiAdminClientService()
		: new ApiClientService()
	;

	constructor()
	{
		super({} as ClientsLogosProps, initState);

		// Bind
		this.list = this.list.bind(this);
		this.onCreateLogo = this.onCreateLogo.bind(this);
		this.fetchLogos = this.fetchLogos.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		try {
			this.list();
		} catch (error) {
			console.error('', error);
		}
	}

	list(): void
	{
		this.setState({
			list: {
				items: this.props.client.logos,
				itemsPerPage: 10,
				totalCount: this.props.client.logos.length,
				totalPages: 1,
			}
		}, () => this.setState({ isLoading: false }));
	}

	/**
	 * @param logoData
	 */
	async onCreateLogo(logoData: any): Promise<void>
	{
		this.setState({ isLoading: true });
		const response = await this.apiClientService.addLogo(logoData, this.props.client.id);

		if (!response.errorMessage) {
			console.log(response.errorMessage);
		}

		await this.fetchLogos();
	}

	/**
	 * FetchLogos onRefresh
	 * @return Promise<void>
	 */
	async fetchLogos(): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			const updatedClient = await this.apiClientService.show(this.props.client.id);

			this.setState(prevState => ({
				list: {
					...prevState.list,
					items: updatedClient.logos
				}
			}));

			this.setState({ isLoading: false });
		} catch (error) {
			console.error('Error occurred on fetchLogo', error);
		}
	}

	/**
	 * @return TableHeadColumnsInterface[]
	 */
	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Intitulé',
				tag: 'name',
				columnWidth: 400,
				fontWeight: 600,
				textAlign: 'left',
				keyType: 'string',
				keyTag: 'name',
			},
			{
				title: 'Aperçu logo',
				tag: 'logoUrl',
				columnWidth: 400,
				fontWeight: 600,
				textAlign: 'left',
				keyType: 'url',
				keyTag: 'logoUrl',
			},
		];
	}
}
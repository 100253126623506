import React, { ReactElement } from 'react';
import { CommercialAdminService } from '@/Modules/Commercial/Admin/Page/CommercialAdmin.service';
import { CommercialAdminProps, CommercialAdminState } from '@/Modules/Commercial/Admin/Page/CommercialAdmin.interface';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import Tabs from '@/Modules/App/Components/Tabs/Tabs';
import CommercialClientsAdmin from '@/Modules/Commercial/Admin/Components/CommercialClientList/List/CommercialClientsAdmin';
import CommercialStatsAdmin from '@/Modules/Commercial/Admin/Components/CommercialStatistical/List/CommercialStatsAdmin';
import CommercialSectorsAdmin from '@/Modules/Commercial/Admin/Components/CommercialSectors/CommercialSectorsAdmin';

export default class CommercialAdmin extends React.Component<CommercialAdminProps, CommercialAdminState>
{
  private componentService: CommercialAdminService = new CommercialAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.componentService.setProps(this.props);
    this.componentService.subscribeState(this);

    // State
    this.state = this.componentService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.componentService.init();
  }

  componentWillUnmount(): void
  {
    this.componentService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <HeroSection
          title="Partie commercial"
          icon={ null }
        />

        <Tabs
          tabs={[
            {
              label: 'Tournée',
              content: (
                <CommercialClientsAdmin />
              )
            },
            {
              label: 'Statistiques',
              content: (
                <CommercialStatsAdmin />
              )
            },
            {
              label: 'Secteurs',
              content: (
                <CommercialSectorsAdmin />
              )
            }
          ]}
        />
      </>
    );
  }
}
import React, { Component, ReactElement } from 'react';
import { ConsumerFormProps, ConsumerFormState } from './ConsumerForm.interface';
import { ConsumerFormService } from './ConsumerForm.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Input from '@/Modules/App/Components/Library/Input/Input';
import AddressForm from '@/Modules/LegalNotice/Components/Form/AddressForm/AddressForm';
import SirenConsumerAutoFill from '@/Modules/LegalNotice/Components/Form/SirenConsumerAutoFill/SirenConsumerAutoFill';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

class ConsumerForm extends Component<ConsumerFormProps, ConsumerFormState>
{
	private consumerFormService: ConsumerFormService = new ConsumerFormService();

	constructor(props: ConsumerFormProps)
	{
		super(props);

		// Config service
		this.consumerFormService.setProps(this.props);
		this.consumerFormService.subscribeState(this);

		// State
		this.state = this.consumerFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.consumerFormService.init();
	}

	componentWillUnmount(): void
	{
		this.consumerFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if(this.state.isLoading) {
			return <LoaderComponent />;
		}

		return (
			<>
				{ !LegalNoticeTypeEnum.filterWithoutSiren.some(category => String(category.value) === this.props?.selectedCategory?.categoryType!) && (
					<SirenConsumerAutoFill
						key={ this.state.consumer.toString() + this.props.stateUpdater?.errors }
						sirenValue={ this.state.consumer.siren }
						errorMessage={ this.props.stateUpdater?.errors?.siren! }
						callbackState={ (state) => this.consumerFormService.setConsumerState({ siren: state.siren }) }
						callbackResponseApi={ (consumerData) =>
							this.consumerFormService.setConsumerState(consumerData)
						}
					/>
				) }

				<div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
					<Input
						type={ 'text' }
						style={ { width: '100%' } }
						label={ 'Dénomination' }
						name={ 'denomination' }
						onChange={ (event: any) => this.consumerFormService.setConsumerState({ name: event.target.value }) }
						value={ this.state.consumer.name || '' }
						errorMessage={ this.props.stateUpdater?.errors?.name! }
						required={ true }
					/>
					<Button
						iconName={ 'LuCaseUpper' }
						variant={ 'secondary' }
						onClick={ () => this.consumerFormService.toggleCaseForField('name') }
					/>
				</div>

				{ !this.props?.isFormLight! &&
          <>
            <div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
              <Input
                type={ 'text' }
                style={ { width: '100%' } }
                label={ 'Lieu d‘immatriculation' }
                name={ 'rcs' }
                onChange={ (event: any) => this.consumerFormService.setConsumerState({ rcs: event.target.value }) }
                value={ this.state.consumer.rcs || '' }
                errorMessage={ this.props.stateUpdater?.errors?.rcs! }
                required={ true }
              />
              <Button
                iconName={ 'LuCaseUpper' }
                variant={ 'secondary' }
                onClick={ () => this.consumerFormService.toggleCaseForField('rcs') }
              />
            </div>

            <div style={ { display: 'flex', gap: 5, alignItems: 'flex-end' } }>
              <Input
                type={ 'text' }
                style={ { width: '100%' } }
                label={ 'Forme juridique' }
                name={ 'legal-status' }
                onChange={ (event: any) => this.consumerFormService.setConsumerState({ legalStatus: event.target.value }) }
                required={ true }
                value={ this.state.consumer.legalStatus || '' }
                errorMessage={ this.props.stateUpdater?.errors?.legalStatus! }
              />
              <Button
                iconName={ 'LuCaseUpper' }
                variant={ 'secondary' }
                onClick={ () => this.consumerFormService.toggleCaseForField('legalStatus') }
              />
            </div>

            <Input
              type={ 'text' }
              label={ 'Capital' }
              textHelp='Pour les montants comportant des centimes, veuillez utiliser le point (.) au lieu de (,) comme séparateur décimal.'
              name={ 'capital' }
              onChange={ (event: any) => this.consumerFormService.setConsumerState({ capital: event.target.value }) }
              required={ true }
              value={ this.state.consumer.capital || '' }
              errorMessage={ this.props.stateUpdater?.errors?.capital! }
              allowedCharacters={ 'digits' }
            />

            <AddressForm
              key={ JSON.stringify(this.state.consumer) + this.state?.errors! }
              errors={ this.props.stateUpdater?.errors! }
              consumerAddress={ this.state.consumer?.address! }
              callbackState={ (state) => this.consumerFormService.setConsumerAddressState(state.address) }
            />
          </>
				}

				<div
					style={ { width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: 50 } }>
					<Button
						label={ 'Suivant' }
						variant={ 'small' }
						onClick={
							() => this.props.callbackState!(this.state, true)
						}
					/>
				</div>
			</>
		);
	}
}

export default withGlobalContext(ConsumerForm);
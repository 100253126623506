import React, { Component, ReactElement } from 'react';
import { UsersDeveloperService } from './UsersDeveloper.service';
import { UsersDeveloperProps, UsersDeveloperState } from './UsersDeveloper.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import CreateUserForm from '@/Modules/User/Components/Form/Create/CreateUserForm';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { ModalContextType } from '@/Provider/ModalProvider';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import UserDeveloper from '@/Modules/User/Developer/Show/UserDeveloper';
import { ApiUserDeveloperService } from '@/Service/Developer/ApiUserDeveloperService';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';

class UsersDeveloper extends Component<UsersDeveloperProps, UsersDeveloperState>
{
	apiUserService = new ApiUserDeveloperService();
	private usersDeveloperService: UsersDeveloperService = new UsersDeveloperService();
	private createUserModal: ModalContextType;

	constructor(props: UsersDeveloperProps)
	{
		super(props);

		// Config service
		this.usersDeveloperService.setProps(this.props);
		this.usersDeveloperService.subscribeState(this);

		// State & props
		this.state = this.usersDeveloperService.getState();
		this.createUserModal = this.props.modalContext!;

		// Bind
		this.createUserModalRender = this.createUserModalRender.bind(this);

		// Document title
		document.title = 'Publisur - DEVELOPER - Users';
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	componentWillUnmount(): void
	{
		this.usersDeveloperService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Utilisateurs"
					icon={ null }
					button={{
						label: 'Ajouter un utilisateur',
						variant: 'primary',
						iconName: 'LuPlus',
						onClick: this.createUserModalRender
					}}
				/>

				<OffcanvaContext.Consumer>
					{ (offcanvaContext) => (
						<ListTableManager
							isTableControls={ true }
							refreshList={ this.state.userList }
							tag={ 'user' }
							tableColumns={ this.usersDeveloperService.buildColumnHeader() }
							serviceList={ this.apiUserService.list.bind(this.apiUserService) }
							filters={ this.usersDeveloperService.buildFilters() }
							onRowClick={ (event: any, item: UserInterface) => {
								event.stopPropagation();

								offcanvaContext?.content(
									null,
									<GlobalContextProvider>
										<UserDeveloper
											user={ item }
											getComponentState={ () => this.usersDeveloperService.refresh() }
										/>
									</GlobalContextProvider>
								);

								offcanvaContext?.isOpen(true);
							}}
						/>
					)}
				</OffcanvaContext.Consumer>
			</>
		);
	}

	private createUserModalRender(event: any): void
	{
		event.stopPropagation();

		this.createUserModal.content(
			'Créer un utilisateur',
			<CreateUserForm
				key={ this.state.errorMessage }
				isAdmin={ true }
				onFormSubmit={ this.usersDeveloperService.create }
				errorMessage={ this.state.errorMessage! }
				allowedUserRole={ true }
			/>
		);
	}
}

export default withGlobalContext(UsersDeveloper);
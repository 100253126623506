import React, { Component, ReactElement } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { StatsLineChartInterface } from '@/Modules/App/Components/Library/Chart/StatsChart/StatsLineChart.interface';

class StatsLineChart extends Component<StatsLineChartInterface>
{
  render(): ReactElement
  {
    const { data } = this.props;

    return (
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={ data } margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" tick={{ fontSize: 14 }} />
            <YAxis />
            <Tooltip formatter={(value: any) => `${value} publications`} />
            <Legend />
            { this.props.series.map((serie, index) => (
              <Line
                key={ `line-${index}` }
                type="monotone"
                dataKey={ serie.key as string }
                stroke={ serie.color }
                name={ serie.name }
                strokeWidth={ 2 }
                dot={{ r: 4 }}
                animationDuration={ 800 }
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default StatsLineChart;

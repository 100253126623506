import React, { Component, ReactElement } from 'react';
import { SectionCollapsedProps, SectionCollapsedState } from './SectionCollapsed.interface';
import { SectionCollapsedService } from './SectionCollapsed.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { SectionCollapsedStyles } from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed.styles';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class SectionCollapsed extends Component<SectionCollapsedProps, SectionCollapsedState>
{
	private sectionCollapsedService = new SectionCollapsedService();

	constructor(props: SectionCollapsedProps)
	{
		super(props);

		// Config service
		this.sectionCollapsedService.setProps(this.props);
		this.sectionCollapsedService.subscribeState(this);

		// State
		this.state = this.sectionCollapsedService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.sectionCollapsedService.init();
	}

	componentWillUnmount(): void
	{
		this.sectionCollapsedService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<ToggleOpenClosed isClickOutside={ false } forceIsOpen={ (!this.props.defaultCollapsed) ?? true }>
				{ ({ isOpen, toggle }) => (
					<div style={ SectionCollapsedStyles.container }>
						<div style={ SectionCollapsedStyles.heading(false, this.props.highlightColor) }>
							<div style={ SectionCollapsedStyles.control }>
								{ (this.state.isCollapsed) &&
                  <Button
                    label={ '' }
                    variant={ 'close' }
                    iconName={ (isOpen) ? 'LuChevronDownCircle' : 'LuChevronRightCircle' }
                    onClick={ toggle }
                  />
								}
								<div style={ SectionCollapsedStyles.title }>
									{ this.props.title }
								</div>
							</div>
						</div>
						<div style={ SectionCollapsedStyles.content(isOpen) }>
							{ this.props.children }
						</div>
					</div>
				) }
			</ToggleOpenClosed>
		);
	}
}

export default withGlobalContext(SectionCollapsed);
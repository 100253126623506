import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  ClientGiftAdminProps,
  ClientGiftAdminState, ClientGiftInterface
} from '@/Modules/Commercial/Admin/Components/CommercialClientList/Components/ClientGift/ClientGiftAdmin.interface';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { ApiAdminClientGiftService } from '@/Service/Admin/ApiAdminClientGiftService';

const initState: ClientGiftAdminState = {
  isLoading: false,
  clientGiftList: [],
  giftList: [],
  editClientGiftId: null,
  newClientGift: null
}

export class ClientGiftAdminService extends BaseComponentService<ClientGiftAdminProps, ClientGiftAdminState>
{
  apiAdminAppService: ApiAdminAppService = new ApiAdminAppService();
  apiAdminClientGiftService : ApiAdminClientGiftService = new ApiAdminClientGiftService();

  constructor()
  {
    super({} as ClientGiftAdminProps, initState);
  }

  async init(): Promise<void>
  {
    await this.getCommercialGiftList();
    await this.getClientGiftList();
  }

  async getClientGiftList(): Promise<void>
  {
    await this.apiAdminClientGiftService.list(this.props.client?.id)
      .then((response) => {
        this.setState({ clientGiftList: response });
      });
  }

  async createClientGift(createClientGiftData: ClientGiftInterface): Promise<void>
  {
    await this.apiAdminClientGiftService.create(this.props.client?.id, createClientGiftData)
      .then( async () => { await this.init(); this.props.refreshClient(); })
    ;
  }

  async editClientGift(editClientGiftData: ClientGiftInterface): Promise<void>
  {
    await this.apiAdminClientGiftService.edit(this.props.client?.id, editClientGiftData?.id as number, editClientGiftData)
      .then( async () => { await this.init(); this.props.refreshClient(); })
    ;
  }

  async editClientGiftStatus(clientId: number): Promise<void>
  {
    await this.apiAdminClientGiftService.editStatus(clientId)
      .then( async () => { await this.init(); this.props.refreshClient(); })
    ;
  }

  async deleteClientGift(editClientGiftId: number): Promise<void>
  {
    await this.apiAdminClientGiftService.delete(this.props.client?.id, editClientGiftId)
      .then( async () => { await this.init(); this.props.refreshClient(); })
    ;
  }

  async getCommercialGiftList(): Promise<void>
  {
    this.setState({ isLoading: true });
    await this.apiAdminAppService.commercialGiftList()
      .then((response)  => {
        this.setState({ isLoading: false, giftList: response })
      })
    ;
  }

  buildColumnHeader(): TableHeaderColumnInterface[]
  {
    return [
      { columnTitle: 'Cadeaux', columnSize: 8, name: 'gift' },
      { columnTitle: 'Quantité', columnSize: 4, name: 'quantity' },
      { columnTitle: 'Statut', columnSize: 4, name: 'status' },
    ];
  }
}
import React, { ReactElement } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavigateFunction } from 'react-router-dom';
import { BreadcrumbsComponentProps } from '@/Modules/App/Type/BreadcrumbsComponentTypes';
import { BreadcrumbStyles } from '@/Modules/App/Style/Components/Breadcrumb.styles';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

export default class BreadcrumbsComponent extends React.Component<BreadcrumbsComponentProps>
{
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);
		this.navigation = props.navigation;
	}

	render(): ReactElement
	{
		const { matches } = this.props;

		const crumbs = matches
			.filter((match: any) => Boolean(match.handle?.crumb))
			.map((match: any) =>
			{
				const crumbInfo = match.handle.crumb(match.data);
				let path = crumbInfo.path;

				Object.keys(this.props.params).forEach(param =>
				{
					path = path.replace(`:${ param }`, this.props.params[param]);
				});

				if (crumbInfo.isAdmin && !path.startsWith('/admin')) {
					path = `/admin${ path }`;
				}

				return { text: crumbInfo.title, path: path };
			});

		return (
			<Breadcrumb style={ BreadcrumbStyles.container }>
				{ crumbs.map((crumb, index) => (
					<Breadcrumb.Item
						key={ index }
						onClick={ () => this.navigation(crumb.path) }
						style={ UserConnectedService.isDeveloper() ? BreadcrumbStyles.devItem : BreadcrumbStyles.item }
					>
						{ crumb.text }
					</Breadcrumb.Item>
				)) }
			</Breadcrumb>
		);
	}
}

import React, { Component, ReactElement } from 'react';
import { NewspapersClosureManagementProps, NewspapersClosureManagementState } from './NewspapersClosureManagements.interface';
import { NewspapersClosureManagementsService } from './NewspapersClosureManagements.service';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';

class NewspapersClosureManagements extends Component<NewspapersClosureManagementProps, NewspapersClosureManagementState>
{
	private newspapersManagementService = new NewspapersClosureManagementsService();

	constructor(props: NewspapersClosureManagementProps)
	{
		super(props);

		// Config service
		this.newspapersManagementService.setProps(this.props);
		this.newspapersManagementService.subscribeState(this);

		// State
		this.state = this.newspapersManagementService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.newspapersManagementService.init();
	}

	componentWillUnmount(): void
	{
		this.newspapersManagementService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Bouclage du journal"
					icon={ null }
				/>

				<ListTableManager
					isTableControls={ true }
					refreshList={ this.state.newspaperManagementList }
					tag={ 'newspaper-closure-management' }
					tableColumns={ this.newspapersManagementService.buildColumnHeader() }
					serviceList={ (params) => this.newspapersManagementService.list({ filterParams: params }) }
					filters={ this.newspapersManagementService.buildFilters() }
					onRowClick={ (event: any, item: any) => this.props.navigation('/admin/newspaper-management/' + item.id) }
				/>
			</>
		);
	}
}

export default withGlobalContext(NewspapersClosureManagements);